import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { INavbarData } from '../nav-data';
import { PermissionService } from '../../../../shared/services/permission.service';
import { SideNavToggle } from '../../../../shared/models/side-nav-toggle';
import { NavService } from '../../../../shared/services/nav.service';
import { ActiveSecondaryLinksPipe } from '../../../../shared/pipes/active-secondary-links.pipe';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({opacity: 0}),
    animate('350ms',
      style({opacity: 1})
    )
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('350ms',
      style({opacity: 0})
    )
  ])
])

@Component({
  selector: 'app-sublevel-menu',
  // templateUrl: './sublevel-menu.component.html',
  template:`
     <ul *ngIf="collapsed && data.childs && data.childs.length > 0"
    [@submenu]="expanded
      ? {value: 'visible', 
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', height: '*'}}
      : {value: 'hidden', 
          params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.07, 1)', height: '0'}}"
      class="sublevel-nav"
    >
      <li *ngFor="let item of data.childs" class="sublevel-nav-item" [ngClass]="router.url.includes(item.routeLink) ? 'active' :''">
          <!-- If has childs  -->
          <a class="sublevel-nav-link"
          #tooltip="matTooltip"
          [matTooltip]="screenWidth>1280 ? '' : item.label|translate"
          matTooltipPosition="right" 
          (click)="handleClick(item)"
            *ngIf="(item.childs && item.childs.length > 0)&& item | rolesPermissionsCheck"
            [ngClass]="getActiveClass(item)"
          >
            <div class="d-flex align-items-center justify-content-between w-100">
              <div>
                <!-- <img [src]='item.icon'> -->
                <lucide-icon [name]="item.icon"></lucide-icon>
                <span class="sublevel-link-text" [ngClass]="!isMobileView$()?'hide-sublevel-link-text':''" @fadeInOut *ngIf="collapsed">{{item.label | translate}}</span>
              </div>
              <i *ngIf="item.childs && collapsed" class="menu-collapse-icon"
                [ngClass]="!item.expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"
              ></i>
            </div>
          </a>
          <!-- If No childs  -->
          <a class="sublevel-nav-link"
           (click)="closeMobileMenu.emit()"
          #tooltip="matTooltip"
          [matTooltip]="screenWidth>1280 ? '' : item.label|translate"
          matTooltipPosition="right" 
            *ngIf="(!item.childs || (item.childs && item.childs.length === 0)) &&  item | rolesPermissionsCheck"
            [routerLink]="[item.routeLink]"
            routerLinkActive="active-sublevel"
            [routerLinkActiveOptions]="{exact: true}"
          >
            <!-- <img [src]='item.icon'> -->
            <lucide-icon [name]="item.icon"></lucide-icon>
            <span class="sublevel-link-text" [ngClass]="!isMobileView$()?'hide-sublevel-link-text':''" @fadeInOut 
               *ngIf="collapsed">{{item.label | translate}}</span>
          </a>
          <div *ngIf="item.childs && item.childs.length > 0">
            <app-sublevel-menu
              [data]="item"
              [collapsed]="collapsed"
              [multiple]="multiple"
              [expanded]="item.expanded"
            ></app-sublevel-menu>
          </div>
      </li>
    </ul>
  `,
  styleUrls: ['../side-nav.component.scss'],
  animations: [
    fadeInOut,
    trigger('submenu', [
      state('hidden', style({
        height: '0',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden', [style({overflow: 'hidden'}), 
        animate('{{transitionParams}}')]),
      transition('void => *', animate(0))
    ])
  ]
})
export class SublevelMenuComponent {

  @Input() data:INavbarData = {
    routeLink: '',
    icon: '',
    label:"",
    permissions:[],
  }
  @Input() collapsed = false;
  @Input() animating:boolean|undefined;
  @Input() expanded:boolean|undefined;
  @Input() multiple:boolean = false;
  @Output() closeMobileMenu: EventEmitter<any> = new EventEmitter();
  screenWidth:number = 0
  isMobileView$ = this.navService.isMobileView$

  constructor(
    public router: Router, 
    public permissionService:PermissionService,
    private navService:NavService,
    private activeSecondaryLinksPipe:ActiveSecondaryLinksPipe
  ) {}

  ngOnInit(){
    this.screenWidth = window.innerWidth;

    // On start check if router match expand 2nd sub level
    this.data.childs?.map((i=>{
      if(this.router.url.includes(i.routeLink)|| this.activeSecondaryLinksPipe.transform(i) ){
        i.expanded=true
      }
    }))
  }

  @HostListener('window: resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  handleClick(item:any):void {
    if(!this.multiple){
      if(this.data.childs && this.data.childs.length>0){
        for(let modelItem of this.data.childs){
          if(item!==modelItem && modelItem.expanded){
            modelItem.expanded = false
          }
        }
      }
    }
    item.expanded = !item.expanded;
  }

  getActiveClass(item: INavbarData): string {
    return item.expanded && (this.router.url.includes(item.routeLink))
      ? 'active-sublevel' 
      : '';
  }

  ngOnDestroy(){
    // Collapse all on destroy 
    this.data.childs!.map((i)=>i.expanded=false)
  }
}
