<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle"> {{'Common.ClientName'| translate}}</th>
          <th class="align-middle">{{'Common.AccountId'| translate}} </th>
          <th class="align-middle">{{'Common.Company'| translate}}</th>
          <th class="align-middle">{{'Common.CodeName'| translate}}</th>
          <th class="align-middle">{{'Common.Class'| translate}}</th>
          <th class="align-middle">{{'Common.Number'| translate}}</th>
          <th class="align-middle">{{'Common.DistributorPrice'| translate}} </th>
          <th class="align-middle">{{'Common.Total'| translate}} </th>
          <!-- <th class="align-middle">{{'Common.Date'| translate}}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.CUSTOMER_NAME}}</a> </td>
          <td>{{data.account_id}}</td>
          <td> {{data.service_id}} </td>
          <td>{{data.code_name}}</td>
          <td>{{data.note}}</td>
          <td> {{data.cardsNo}} </td>
          <!-- <td>{{data.enter_date | date: dateFormatService.dateFormat}}</td> -->
          <td>{{data.sell_price}}</td>
          <td>{{data.TotalSellPrice}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{totalSellPrice==0 ? '-' :totalSellPrice}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>