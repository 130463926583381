import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThPackingCardService {

  API_URLGetAllCardsStatus = `${environment.apiUrl}/ThdbPackingCard/GetAllCardsStatus`;
  API_URLGetpackingCards = `${environment.apiUrl}/ThdbPackingCard/GetpackingCards`;

  constructor(private http:HttpClient) { }

  GetAllCardsStatus(): Observable<any> {
    const url = this.API_URLGetAllCardsStatus;
    //Http request-
    return this.http.get<any>(url)
  }

  GetpackingCards(body): Observable<any> {
    const url = this.API_URLGetpackingCards;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
