<div class="d-flex justify-content-between nav-header">

    <div class="">
        <div class="logo-container">
            <div class="d-flex align-items-center gap-3 nav-padding ">
                <img src="../../../../assets/images/logo-onPurple.png" height="35" alt="">
                <!-- <span class="text-white">ATC</span> -->
            </div>
        </div>
    </div>
    
    <div class="d-flex flex-wrap align-items-center gap-3 gap-md-4 px-3">

        <!-- Language Select -->
        <app-language-selector></app-language-selector>
        <!-- Profile -->
        <div class="" ngbDropdown>
            <app-profile-menu></app-profile-menu>
        </div>
        <!-- Mobile menu toggler -->
        <div class="d-md-none" role="button" (click)="openMobileNav()">
            <lucide-icon color="white" name="text"></lucide-icon>
        </div>
    </div>
</div>