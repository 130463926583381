<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Card Serial -->
                    <div class="col-lg-3">
                        <label>{{'Common.Serial'|translate}} :</label>
                        <input type="text" formControlName="packingCardId" class="form-control" placeholder="{{'Common.InsertSerial'|translate}}">
                    </div>
                    <!-- Card Serial -->
                    <div class="col-lg-3">
                        <label>{{'REPORTS.PackCardReport.BatchNumber'|translate}} :</label>
                        <input type="text" formControlName="batch_no" class="form-control">
                    </div>
                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>


<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-packing-table [datasource]="datasource"></app-packing-table>
    
   <!-- Pagination -->
   <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>