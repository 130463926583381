import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetInventoryMovementComponent } from './get-inventory-movement/get-inventory-movement.component';

const routes: Routes = [
  { path: 'get-movements', component: GetInventoryMovementComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbInventoryMovement.View'], roles:['SuperAdmin','Admin']} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryMovementRoutingModule { }
