import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThManageIncomingAmountsService {


  API_URLGetAllIncomingAmountsApprovedOrNot = `${environment.apiUrl}/thdbManageIncomingAmounts/GetAllIncomingAmountsApprovedOrNot`;
  API_URLCreateUpdateIncomingAmounts = `${environment.apiUrl}/thdbManageIncomingAmounts/CreateUpdateIncomingAmounts`;
  // API_URLCreateManageIncomingAmountData = `${environment.apiUrl}/thdbManageIncomingAmounts/CreateManageIncomingAmountData`;
  API_URLDeleteIncomingAmounts = `${environment.apiUrl}/thdbManageIncomingAmounts/DeleteIncomingAmounts`;
  API_URLSendIncomingAmounts = `${environment.apiUrl}/thdbManageIncomingAmounts/SendIncomingAmounts`;
  
  constructor(private http:HttpClient) { }

  GetAllIncomingAmountsApprovedOrNot(body): Observable<any> {
    const url = this.API_URLGetAllIncomingAmountsApprovedOrNot;
    //Http request-
    return this.http.post<any>(url,body)
  }

  CreateUpdateIncomingAmounts(body): Observable<any> {
    const url = this.API_URLCreateUpdateIncomingAmounts;
    //Http request-
    return this.http.post<any>(url,body)
  }
  // CreateManageIncomingAmountData(body): Observable<any> {
  //   const url = this.API_URLCreateManageIncomingAmountData;
  //   //Http request-
  //   return this.http.post<any>(url,body)
  // }

  DeleteIncomingAmounts(rowNo): Observable<any> {
    const url = this.API_URLDeleteIncomingAmounts;
    //Http request-
    return this.http.get<any>(`${url}?row_no=${rowNo}`)
  }

  SendIncomingAmounts(rowNo): Observable<any> {
    const url = this.API_URLSendIncomingAmounts;
    //Http request-
    return this.http.get<any>(`${url}?row_no=${rowNo}`)
  }
}
