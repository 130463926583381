import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProvidersRoutingModule } from './providers-routing.module';
import { GetProvidersComponent } from './get-providers/get-providers.component';
import { CoreModule } from 'src/app/core/core.module';
import { ProvidersTableComponent } from './get-providers/_components/providers-table/providers-table.component';
import { AddProviderComponent } from './get-providers/_components/add-provider/add-provider.component';


@NgModule({
  declarations: [
    GetProvidersComponent,
    ProvidersTableComponent,
    AddProviderComponent,
  ],
  imports: [
    CommonModule,
    ProvidersRoutingModule,
    CoreModule
  ]
})
export class ProvidersModule { }
