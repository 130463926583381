<!-- Table -->
<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="text-center" style="width: 120px;">{{ 'Common.Date' | translate}}</th>
          <th class="text-center">{{ 'Common.AccountId' | translate}}</th>
          <th class="text-center">{{ 'Common.Distributor' | translate}}</th>
          <th class="text-center">{{ 'Common.Agent' | translate}}</th>
          <th class="text-center">{{ 'Common.CompanyNo' | translate}}</th>
          <th class="text-center">{{ 'Common.SumPointPrice' | translate}}</th>
          <th class="text-center">{{ 'Common.Balance' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td class="text-center" style="width: 120px;">{{ data.trans_date  | date: "yyyy-MM-dd"}}</td>
          <td class="text-center">{{ data.account_id  }}</td>
          <td class="text-center">{{ data.dest_account_id  }}</td>
          <td class="text-center">{{ data.agent_account_id }}</td>
          <td class="text-center">{{ data.company_no }}</td>
          <td class="text-center">{{ data.SumPointPrice }}</td>
          <td class="text-center">{{ data.balance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  