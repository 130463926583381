
<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    
    <div class="table-responsive mb-0">
        <table class="table table-centered">
          <thead class="table-light">
            <tr>
                <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
                <th class="align-middle">{{'Common.CardName'| translate}}</th>
                <th class="align-middle">{{'Common.ProductId'| translate}}</th>
                <th class="align-middle">{{'Common.CardAmount'| translate}}</th>
                <th class="align-middle">{{'Common.Price'| translate}}</th>
                <th class="align-middle">{{'Common.Total'| translate}}</th>
                <th class="align-middle">{{'Common.UserId'| translate}}</th>
                <th class="align-middle">{{'Common.Date'| translate}}</th>
                <th class="align-middle">{{'Common.Note'| translate}}</th>
                <th class="align-middle" *ngIf="permissionService.checkPermission(['ThdbOutstandingClassServices.Update'])"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of datasource">
              <td>
                <a class="text-body fw-bold">{{data.service_id}}</a>
              </td>
              <td>{{data.class_name}}</td>
              <td>{{data.product_id}}</td>
              <td>{{data.Count}}</td>
              <td>{{data.class_cost}}</td>
              <td>{{data.Total}}</td>
              <td>{{data.noteusername}}</td>
              <td>
                <div>{{data.notedate | date:dateFormatService.dateOnlyFormat}}</div>
                <div>{{data.notedate | date:dateFormatService.timeOnlyFormat}}</div>
              </td>
              <td>{{data.note}}</td>
              <td *ngIf="permissionService.checkPermission(['ThdbOutstandingClassServices.Update'])">
                <button class="btn btn-primary" (click)="addNote(data)">{{'Common.AddNote'| translate}}</button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div class="d-flex flex-column">
                  <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                  <span>{{datasource.length>0? datasource[0].SumCount==0 ? '-' : datasource[0].SumCount : '-'}}</span>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                  <span>{{datasource.length>0? datasource[0].SumTotal==0 ? '-' : (datasource[0].SumTotal | number : '1.0-2') : '-'}}</span>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

            </tr>
          </tbody>
        </table>
    </div>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>