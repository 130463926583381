import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ThProfitUserService } from 'src/app/core/services/thuraya/th-profit-user.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ThMoveBlanaceBetweenAccountsService } from 'src/app/core/services/thuraya/th-move-blanace-between-accounts.service';
import { DatePipe } from '@angular/common';
import { PermissionService } from 'src/app/core/services/permission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SadadServicesService } from 'src/app/core/services/sadad/sadad-services.service';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { SadadRequestsTableComponent } from '../get-requests/_component/sadad-requests-table/sadad-requests-table.component';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-get-transactions',
  templateUrl: './get-transactions.component.html',
  styleUrls: ['./get-transactions.component.scss']
})
export class GetTransactionsComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //ejs grid variables
  public pageOptions: Object;
  public transactionChildrenData = []
  @ViewChild("grid") grid: GridComponent;
  public toolbarOptions: ToolbarItems[];
  public aggreagtes: Object;
  public pageSettings: Object;
 

  //dropdown variables
  agentsDistribList:any = []
  public agentsDistribFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };
  public value: string = '';
  public height: string = '400px';


  @ViewChild("allCustomersDropDown") allCustomersDropDown;

  isExporting:boolean = false;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private sadadServicesService:SadadServicesService,
    public permissionService:PermissionService,
    private modalService:NgbModal,
    private authService:AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelService:ExcelService,
    private ref:ChangeDetectorRef,
    public languageService:LanguageService,
  ) { 
   
  }
  
  ngOnInit(): void {
    this.initForm();
    // this.getAllAgentsByCompany();
    this.toolbarOptions = ["ExcelExport", "Print"];
    this.pageSettings = { pageSize: 10 };
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body ={
      dateFrom: this.fromDateCom ? this.fromDateCom : "" ,
      dateTo: this.toDateCom ? this.toDateCom : "",
      isPaginated: false,
      requestorPhone: this.f.requestorPhone.value,
      isSetteled: this.f.isSetteled.value
    }
    this.sadadServicesService.GetTransactions(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "تاريخ التحويل": this.datePipe.transform(data[i].TransferDate, this.dateFormatService.dateOnlyFormat),
              "المبلغ": data[i].Amount,
              "المبلغ المحول": data[i].TransferedAmount,
              "اسم مقدم الطلب": data[i].RequestorUser.UserFullName,
              "رقم مقدم الطلب": data[i].RequestorUser.UserName,
              "اسم منفذ الطلب": data[i].ApprovalUser.UserFullName,
              "رقم منفذ الطلب": data[i].ApprovalUser.UserName,
              "المبلغ المسوى": data[i].SetteledAmount,
              "العهده": data[i].Amount - (data[i].TransferedAmount + data[i].SetteledAmount),
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "المسحوب": Number.parseFloat(result.Data[0].TotalDebitAmount).toFixed(2),
          //   "المضاف": Number.parseFloat(result.Data[0].TotalCreditAmount).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*------------------------ Ejs grid functions ------------------------ */
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        if(this.transactionChildrenData.length>0){
          this.grid.excelExport();
        }
        break;
    }
  }

  calculateRemaining(field: string, data: any, column: Object): string { 
    return (data.Amount - data.SetteledAmount).toString();
  }
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      // userPhone: new FormControl(""),
      requestorPhone: new FormControl(""),
      isSetteled: new FormControl("")
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getTransactions(){
    this.spinner.show()
    let api = null
    let body ={
      dateFrom: this.fromDateCom ? this.fromDateCom : "" ,
      dateTo: this.toDateCom ? this.toDateCom : "",
      pageNumber:this.pageNumber,
      pageSize:this.pageSize,
      isPaginated: true,
      // userPhone: this.f.userPhone.value,
      requestorPhone: this.f.requestorPhone.value,
      isSetteled: this.f.isSetteled.value
    }
    //Api depend on which page we are
    return this.sadadServicesService.GetTransactions(body).subscribe((result) => {
      this.spinner.hide()
      if(result.IsSuccess && result.Data.length){
        this.datasource = result.Data;
        this.totalCount = result.TotalCount
      }
      else{
        this.datasource = []
        this.totalCount = 0
        this.pageNumber = 1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      },(err)=>{
        this.spinner.hide()
        this.datasource = []
        this.totalCount = 0;
      }
    );
  }

  getRequestsByTransactionId(event){
    return this.sadadServicesService.GetRequestesByTransactionId(event.Id).subscribe(
      (result) => {

        let modalRef = this.modalService.open(SadadRequestsTableComponent, { centered: true,size: 'xl' });
        modalRef.componentInstance.id = event.Id ;
        modalRef.componentInstance.datasource = result.Data;
        modalRef.componentInstance.showConfirmDeclineColumn = false;
        // modalRef.componentInstance.updateParent.subscribe((newData) => {
        //   let index = this.dataSource.findIndex(d=>d.Id == user.Id)
        //   this.dataSource[index].IsCustomer = true;
        //   this.ref.detectChanges();
        // })
      },(err)=>{
        console.log("err",err)
        this.toastr.error(err.message, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

  getTransactionchildrens(data){
    this.transactionChildrenData=[]
    // console.log("rowSelect",data)
    if(data.Children.length){
      this.transactionChildrenData = data.Children;
    }else{
      // this.toastr.error("لا يوجد بيانات", "",{
      //   positionClass: 'toast-bottom-left',
      // });
    }
  }


  // get all agents and distributors for dropdown select
  // getAllAgentsByCompany(){
  //   let body = {
  //     isPaginated: false,
  //   }

  //   this.thGeneralSystemService.GetAllAgentsByCompany(body).subscribe(
  //     (result)=>{
  //       if(result.IsSuccess && result.Data.length){
  //         this.getAllDistributorsByAgent(result.Data);
  //       }
  //   })
  // }

  // getAllDistributorsByAgent(agentsList){
  //   let body = {
  //     isPaginated: false,
  //   }

  //   this.thGeneralSystemService.GetAllDistributorsByAgent(body).subscribe(
  //     (result)=>{
  //       if(result.IsSuccess && result.Data.length){
  //         this.agentsDistribList = [...agentsList,...result.Data];
  //       }
  //   })
  // }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }


  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getTransactions();
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    this.getTransactions();
  }

  reset(){
    this.initForm()
    this.allCustomersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset();
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.datasource = []
    this.getTransactions()
  }

  /*----------------------Filtering event ng dropdown---------------------- */
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    let predicateQuery = query.where(new Predicate('CUSTOMER_NAME', 'contains', e.text, true).or('CUSTOMER_ID', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.agentsDistribList, query);
  };

  /*----------------------when Choosing customer---------------------- */
  setSelectedCustomer(event){
    this.form.controls.requestorPhone.setValue(event)
  }
  

}
