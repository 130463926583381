import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SublevelMenuComponent } from './components/side-nav/sublevel-menu/sublevel-menu.component';


@NgModule({
  declarations: [
    LayoutComponent,
    NavHeaderComponent,
    SideNavComponent,
    SublevelMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class LayoutModule { }
