<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'Common.ProductId'| translate}}</th>
          <th class="align-middle">{{'Common.CardName'| translate}} </th>
          <th class="align-middle">{{'Common.Serial'| translate}}</th>
          <th class="align-middle">{{'Common.Pin'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}}</th>
          <th class="align-middle">{{'THURAYA.TrxRefund.TrxRef'| translate}} </th>
          <th class="align-middle">{{'Common.Status'| translate}} </th>
          <th class="align-middle">{{'Common.Message'| translate}} </th>
          <th class="align-middle">{{'Common.Refund'| translate}} </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.product_id}}</td>
          <td>{{data.class_name}}</td>
          <td>{{data.card_serial}}</td>
          <td>{{data.card_scratch}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.txref}}</td>
          <td>{{data.ResponseStatus}}</td>
          <td>{{data.ResponseMessage}}</td>
          <td style="width: 180px;">
            <button *ngIf="data.ResponseStatus==200" class="btn btn-success d-flex align-items-center" [disabled]="true">
              <i class="fa-solid fa-check" style="font-size: 18px; margin-inline-end: 12px;"></i>
              <span>{{'Common.RefundSuccess'| translate}}</span>
            </button>
            <button *ngIf="data.ResponseStatus!=200 && permissionService.checkPermission(['ThdbTrxRefund.Update'])" class="btn btn-danger d-flex align-items-center" [disabled]="data.ResponseStatus==500" (click)="refud(data)">
              <i class="fa-solid fa-rotate-left" style="font-size: 18px; margin-inline-end: 12px;"></i>
              <span>{{'Common.Refund'| translate}}</span>
            </button>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalCost==0 ? '-' :SumTotalCost}}</span>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>