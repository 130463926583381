<div class="container px-3 py-3">
    <h4 class="header">{{'Common.EditUserRole'|translate}} : {{userData.FullName}}</h4>
    <hr>
    <div class="content mt-3">
        <ngx-spinner class="spinnerContainer" [fullScreen]="false" color="#3333" size="medium" type="ball-beat"></ngx-spinner>
        <ul *ngFor="let role of roles">
            <div class="custom-control custom-switch">
                <ejs-switch cssClass="e-small" [enableRtl]="true" [checked]="checkRoleExist(role)" (change)='fieldsChange($event,role)'></ejs-switch>
                <span style="margin-inline-start: 15px; font-size: 16px;">{{role.RoleName}}</span>
              </div>
        </ul>
    </div>

    <div class="dialog-footer mt-3 d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" (click)="cancelChanges()" >{{'Common.Discard'|translate}}</button>
        <button type="button" class=" btn btn-primary" (click)="saveChanges()" [disabled]="!changes">{{'Common.Save'|translate}}</button>
    </div>
    
    
</div>