<div class="layout-container" id="main-wrapper">
    <!-- <app-loading></app-loading> -->
    <app-nav-header (onToggleSideNav)="onToggleSideNav($event)"></app-nav-header>
    <!-- <app-header></app-header>
    <app-navigation></app-navigation> -->

    <div class="content-body">
        <div class="container-fluid">
            <div class="main-container d-flex">
                <div class="d-none d-md-block">
                    <app-side-nav></app-side-nav>
                </div>
                <div class="pages-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>

    @if (showMobileMenu) {
        <div class="mobile-menu-container">
            <div class="d-flex justify-content-between">
                <div class="mobile-menu">
                    <app-side-nav (closeMobileMenu)="showMobileMenu=false; isMobileView$.set(false)"></app-side-nav>
                </div>
                <div class="p-3" role="button" (click)="showMobileMenu=false">
                    <lucide-icon name="circle-x" color="white"></lucide-icon>
                </div>
            </div>
        </div>
    }

    <!-- <app-footer></app-footer> -->
</div>
