import { Component, OnInit, ChangeDetectorRef,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HalaManageCardsService } from 'src/app/core/services/hala/hala-manage-cards.service';
import { HalaProvidersService } from 'src/app/core/services/hala/hala-providers.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { DatePipe } from "@angular/common";
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import Swal from 'sweetalert2';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ExcelService } from 'src/app/core/services/excel.service';
import { Router } from '@angular/router';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import {MatDialog} from '@angular/material/dialog';
import { CardEditDialogComponent } from './_components/card-edit-dialog/card-edit-dialog.component';
import { NgxSpinnerService } from "ngx-spinner";
import { LanguageService } from 'src/app/core/services/language.service';


@Component({
  selector: 'app-approve-cards',
  templateUrl: './approve-cards.component.html',
  styleUrls: ['./approve-cards.component.scss']
})
export class ApproveCardsComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR APPROVE AND REPORT AND GENERAL REPORT so we decide from route and with these varibale we change logic
  IsApproveComponent:boolean = false;
  IsMangerReportComponent:boolean = false;
  IsEmployeeReportComponent:boolean = false;

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp

  //dropdown lists variables
  ClassNameList:any = []

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false;

  //ej grid varibles
  public toolbarOptions: ToolbarItems[] | object;
  @ViewChild("detailgrid") detailGrid: GridComponent;
  @ViewChild("detailsRow") detailsRow: ElementRef;
  public pageSettings: Object;
  public detailSource:any = [];
  public format = { type: "date", format: "yyyy/MM/dd" };
  public formatTime = { type: "date", format: " h:mm a" };
 
  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;
  @ViewChild("providersDropDown") providersDropDown;
  @ViewChild("usersDropDown") usersDropDown;

  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  constructor(  
    private fb: FormBuilder, 
    public halaProvidersService: HalaProvidersService,
    private thGeneralSystemService:ThGeneralSystemService,
    private halaManageCardsService: HalaManageCardsService,
    private halaAccountsService: HalaAccountsService,
    private msgTranslateService:MessageTranslateService,
    public languageService:LanguageService,
    private toastr: ToastrService,
    private ref:ChangeDetectorRef,
    private excelService:ExcelService,
    private datePipe:DatePipe,
    private router: Router,
    public dateFormatService:DateFormatService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
    ) { 

      //[IMPORTANT] This component IS USED FOR APPROVE AND REPORT 
      // console.log("route",this.router.url)
      //
      if(this.router.url=="/hala/halamanagecards/approvecards"){
        this.IsApproveComponent = true;
        this.IsMangerReportComponent = false;
        this.IsEmployeeReportComponent = false
      }else if(this.router.url=="/hala/halamanagecards/cardsreportmanger"){
        this.IsMangerReportComponent = true;
        this.IsApproveComponent = false
        this.IsEmployeeReportComponent = false
      }else if(this.router.url=="/hala/halamanagecards/cardsreport"){
        this.IsMangerReportComponent = false;
        this.IsApproveComponent = false
        this.IsEmployeeReportComponent = true
      }
    }

  ngOnInit(): void {
    this.initForm();
    this.toolbarOptions = [ "ExcelExport", "Print"];
    this.pageSettings={ pageSize:10 }
    // As all previous cases changepage gets called oninit so no need to getAllCardsToApprove() here
    // this.getAllCardsToApprove();
    
  }
  
  //format date to be also exported in excel with this format
  dateFormatter(field: string, data: any, column: Object): string { 
    const date = new Date(data[field]);
    if (date instanceof Date) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    } else {
      return "";
    }
  }

  timeFormatter(field: string, data: any, column: Object): string { 
    const date = new Date(data[field]);
    if (date instanceof Date) {
      return `${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',hour12: true })}`;
    } else {
      return "";
    }
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true;
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      serialNo: this.f.serialNo.value,
      userId: this.f.userId.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      providerId: this.f.providerId.value,
      isForManager: this.IsMangerReportComponent ? true : false,
      isForApprove: this.IsApproveComponent ? true : false
    }
    const subscr = this.halaManageCardsService.GetCardsHeaderReportManager(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          let finalSheet={};
          for (let i = 0; i < data.length; i++) {            
            let sheet={ 
              "مقدم الخدمة": data[i].Service_Id,
              "اسم الكرت": data[i].Class_Name,
              "عدد الكروت": data[i].cardsNo,
              "من تاريخ": this.datePipe.transform(data[i].AddedDate,this.dateFormatService.dateFormat),
              "الى تاريخ": this.datePipe.transform(data[i].ExpirationDate,this.dateFormatService.dateFormat),
              "المستخدم":data[i].UserName,
              "المورد":data[i].ProviderName,
              "رقم الطلب":data[i].OrderNo,
            }
            if(this.IsEmployeeReportComponent){
               finalSheet = {
                ...sheet,
                "تمت الموافقه": data[i].IsApproved,
                "تم الارسال": data[i].IsSendToManager,
              }
            }else if(this.IsMangerReportComponent){
              finalSheet = {
               ...sheet,
               "رقم التحويل": data[i].oracleNo,
               "رقم الاستلام": data[i].transNo,
             }
           }
            else{
              finalSheet = sheet
            }
            this.excelService.addToSheet(finalSheet);
          }
          if(this.IsMangerReportComponent){
            let total={ 
              "عدد الكروت":  Number.parseFloat(data[0].SumCardsNo).toFixed(0),
            }
            this.excelService.addToSheet(total);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    ServiceId: new FormControl(""),
    class_no: new FormControl(""),
    providerId: new FormControl(""),
    serialNo: new FormControl(""),
    orderNo: new FormControl(""),
    userId: new FormControl(""),
    oracleNo: new FormControl(""),
    transNo: new FormControl("")
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Grid Function---------------------- */
  toolbarClickDetails(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.detailGrid.excelExport();
        break;
    }
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  /*----------------------API Requests---------------------- */
  getAllCardsToApprove() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      serialNo: this.f.serialNo.value,
      orderNo: this.f.orderNo.value,
      userId: this.f.userId.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      providerId: this.f.providerId.value,
      isForManager: (this.IsMangerReportComponent || this.IsApproveComponent) ? true : false,
      isForApprove: this.IsApproveComponent ? true : false
    }
    return this.halaManageCardsService.GetCardsHeaderReportManager(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.detailSource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  approveCards(params){
    return this.halaManageCardsService.ApproveCards(params).subscribe((result) => {
      if(result.IsSuccess){
        this.updateDataSource(params.cardHeaderId,true)
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccApprove'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    });

  }

  returnCards(cardHeaderId){
    return this.halaManageCardsService.ReturnCards(cardHeaderId).subscribe((result) => {
      if(result.IsSuccess){
        this.updateDataSource(cardHeaderId,true)
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccReturn'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    });

  }

  /*----------------------Approve card box / Return Alert box---------------------- */
  showApproveBox(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.CardApprove'),
      html:
      '<div>'+
        '<label>'+ this.msgTranslateService.msgTranslate('HALA.ApproveCards.ReceiveNo')+'</label>' +
        '<input type="text" id="receiveNo" class="swal2-input">'+
      '</div>'+
      '<div>'+
        '<label>'+this.msgTranslateService.msgTranslate('HALA.ApproveCards.TransferNo')+'</label>' +
        '<input type="text" id="transferNo" class="swal2-input"' +
      '</div>',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let receiveNo = (document.getElementById('receiveNo')  as HTMLInputElement).value
        let transNo = (document.getElementById('transferNo')  as HTMLInputElement).value
        if(receiveNo=="" || transNo==""){
          Swal.fire({
            title:this.msgTranslateService.msgTranslate('SwalMessages.EmptyTranRec'),
            icon:"error",
            showCloseButton:true,
            showConfirmButton:false
          })
        }else{
          let params= {
            cardHeaderId: data.CardHeaderId,
            oracleNo: receiveNo,
            transNo:  transNo
          }
          this.approveCards(params);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  showReturnBox(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.Return'),
      icon:"info",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.returnCards(data.CardHeaderId);
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
  
  /*----------------------Show Serial and pins detials---------------------- */
  showDetails(data){
    this.detailSource = []
    let body = {
      isPaginated: false,
      cardHeaderId: data.CardHeaderId,
      isManager: false
    }
    return this.halaManageCardsService.GetThisHeaderDetails(body)
      .subscribe((result) => {
        if(result.IsSuccess){
          this.detailSource= result.Data;
          this.ref.detectChanges();
          let scope = this
          setTimeout(function(){
            scope.detailsRow.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
          },100);
      }
    });
  }

  /*----------------------Edit cards (from isMangerComponent only)---------------------- */
  editCard(data){
    // console.log("edit data",data)
    this.dialog.open(CardEditDialogComponent, {
      // width: '400px',
      // height: '400px',
      data: {
        selectedCard: data,
        dialog:this.dialog,
        updateView:this.updateView,
        scope:this,
      },
    });
  }

  updateView(newData,scope){
    let toEdit =  scope.datasource.findIndex(d=>d.CardHeaderId == newData.id)
    scope.datasource[toEdit].OrderNo = newData.orderNo
    scope.datasource[toEdit]. ProviderId = newData.providerId
    scope.datasource[toEdit].ProviderName = newData.providerName
    scope.datasource[toEdit].oracleNo = newData.oracleNo
    scope.datasource[toEdit].transNo = newData.transNo
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getAllCardsToApprove();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    if (this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.pageNumber = 1;
      this.detailSource = []
      this.getAllCardsToApprove();
    } 
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.providersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.usersDropDown.reset()
    this.toDateCom = ""
    this.fromDateCom = ""
    this.pageNumber = 1
    this.getAllCardsToApprove()
  }

  /*----------------------Update table after approve/return---------------------- */
  updateDataSource(CardHeaderId,success){
    //If Approved then remove from table
    if(success){
      this.datasource = this.datasource.filter(c=>c.CardHeaderId != CardHeaderId)
      this.ref.detectChanges();
      // console.log("datasource after change", this.datasource)
    }
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }


  /*----------------------Select Service from dropdown---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------when Choosing Provider---------------------- */
  setSelectedProvider(event){ 
    this.form.controls.providerId.setValue(event)
  }

  /*----------------------when Choosing User---------------------- */
  setSelectedUser(event){
    this.form.controls.userId.setValue(event)
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

}
