import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThServiceClassSettingsService } from 'src/app/core/services/thuraya/th-service-class-settings.service';
import { CreateUpdateClassDialogComponent } from './_components/create-update-class-dialog/create-update-class-dialog.component';

@Component({
  selector: 'app-get-service-class-data',
  templateUrl: './get-service-class-data.component.html',
  styleUrls: ['./get-service-class-data.component.scss']
})
export class GetServiceClassDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  dataSource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  @ViewChild("excelFile") excelFile: ElementRef;
  isActive: boolean = false;
  GFile: File;
  GFileName: string;
  GDataFileList = [];
  arrayBuffer: any;

  @ViewChild("servicesDropDown") servicesDropDown;
    
  isExporting:boolean = false;

  constructor(
    private fb: FormBuilder,
    private thServiceClassSettingsService:ThServiceClassSettingsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private msgTranlsateService:MessageTranslateService,
    private excelService:ExcelService,
    private modalService: NgbModal,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      service_id: this.f.ServiceId.value,
    }
    this.thServiceClassSettingsService.GetAllServiceClassData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "row_no": data[i].row_no,
              "service_id": data[i].service_id,
              "class_no": data[i].class_no,
              "class_name": data[i].class_name,
              "class_type": data[i].class_type,
              "class_price": data[i].class_price,
              "class_cost": data[i].class_cost,
              "status": data[i].status,
              "product_id": data[i].product_id,
              "request_code":  data[i].request_code,
              "auto_min_qty": data[i].auto_min_qty,
              "auto_reorder_count": data[i].auto_reorder_count,
              "auto_order_status": data[i].auto_order_status,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }
  
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    ServiceId: new FormControl("",Validators.required),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getAllServiceClassData() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
    }
    this.thServiceClassSettingsService.GetAllServiceClassData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.dataSource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }else{
          this.dataSource = []
          this.totalCount = 0;
          this.pageNumber = 1
          this.toastr.error(this.msgTranlsateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.dataSource = []
        this.totalCount = 0;
        this.pageNumber = 1
        this.spinner.hide(); 
      }
    )
  }

  /*----------------------Filter---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.totalCount = 0
    //Cancel excel data if shown before 
    // this.showingExcelData = false;
    // this.isActive = false
    // this.GFile = null;
    // this.GFileName = null;
    // if(this.dataSource.length!=0){
    //   this.excelFile.nativeElement.value = "";
    // }
    this.getAllServiceClassData()
  
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.pageNumber = 1
    this.dataSource = []
    this.totalCount =0
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    //as change page get called on init we do not want to get data if no search made
    if(this.dataSource.length!=0){
      this.getAllServiceClassData();
    }
  }

  /*----------------------Select from dropdown---------------------- */
  setSelectedService(event){
    this.form.controls.ServiceId.setValue(event)
    // console.log("service selected", this.f.ServiceId.value)
  }

  openAddDialog(){
    let modalRef = this.modalService.open(CreateUpdateClassDialogComponent, { centered: true });
    modalRef.componentInstance.isEditing = false;
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      if(this.dataSource.length==10){
        //drop last element to insert new at top
        this.dataSource.pop();
        this.dataSource.unshift(newData)
      }else{
        this.dataSource.unshift(newData)
      }
    })
  }
}
