import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPurchaseService } from 'src/app/core/services/thuraya/th-purchase.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ThPurchaseRateService } from 'src/app/core/services/thuraya/th-purchase-rate.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ThCardBatchesService } from 'src/app/core/services/thuraya/th-card-batches.service';

@Component({
  selector: 'app-get-batches-data',
  templateUrl: './get-batches-data.component.html',
  styleUrls: ['./get-batches-data.component.scss']
})
export class GetBatchesDataComponent implements OnInit {

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;  

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  SumDifferenceBatchCount:number=0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thCardBatchesService:ThCardBatchesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalUpload')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.FileNumber')], icon: 'coins', iconColor: '#181c32', amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Card')]},
      { headerText: [this.msgTranslateService.msgTranslate('THURAYA.CardBatches.TotalInFiles')],icon: 'dollar-sign', iconColor: '#1bc5bd', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCost')], icon: 'coins', iconColor: '#181c32',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Transaction')]},
    ];
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      batch_no: this.f.batch_no.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    const subscr = this.thCardBatchesService.GeCardBatchesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {         
            let sheet={ 
              "رقم الدفعة": data[i].batch_no,
              "مقدم الخدمة": data[i].service_id,
              "اسم الملف": data[i].file_name,
              "التاريخ": this.datePipe.transform(data[i].batch_date,this.dateFormatService.dateFormat),
              "اسم الكرت": data[i].class_name,
              "عدد الاصلي": data[i].batch_count,
              "اجمالي المرفوع": data[i].realBatchCount,
              "العجز": data[i].differenceBatchCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "عدد الاصلي": Number.parseFloat(result.Data.SumBatchesCount).toFixed(2),
            "اجمالي المرفوع":Number.parseFloat(result.Data.SumRealBatchCount).toFixed(2),
            "العجز":Number.parseFloat(result.Data.SumDifferenceBatchCount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl(""),
      class_no: new FormControl(""),
      batch_no: new FormControl("")
    });
  }
  
  get f(){
    return this.form.controls;
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }

  /*----------------------API Requests---------------------- */
  geCardBatchesData() {
    this.spinner.show();
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      batch_no: this.f.batch_no.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thCardBatchesService.GeCardBatchesData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.infoList[0].amount[0] = result.Data.SumRealBatchCount
        this.infoList[1].amount[0] = result.Data.totalRows
        this.infoList[2].amount[0] = result.Data.SumBatchesCount
        // this.infoList[3].amount[0] = result.Data.SumBatchesCount
        this.totalCount = result.Data.totalRows;
        this.SumDifferenceBatchCount = result.Data.SumDifferenceBatchCount
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0
        this.infoList[1].amount[0] = 0
        this.infoList[2].amount[0] = 0
        this.SumDifferenceBatchCount = 0;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.geCardBatchesData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.geCardBatchesData()
    } 
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0;
    this.geCardBatchesData()
  }
  
  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

}
