
<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
            <th class="text-center" style="width: 120px;">{{ 'Common.TransactionDate' | translate}}</th>
            <th class="text-center">{{ 'Common.Amount2' | translate}}</th>
            <th class="text-center">{{ 'Common.TransferedAmount' | translate}}</th>
            <th class="text-center">{{ 'Common.RequestorName' | translate}}</th>
            <th class="text-center">{{ 'Common.RequestorPhone' | translate}}</th>
            <th class="text-center">{{ 'Common.RequestExecuterName' | translate}}</th>
            <th class="text-center">{{ 'Common.RequestExecuterPhone' | translate}}</th>
            <th class="text-center">{{ 'Common.SettledAmount' | translate}}</th>
            <th class="text-center">{{ 'Common.Custody' | translate}}</th>
            <!-- <th class="text-center">العمليات</th> -->
            <th class="text-center">{{ 'Common.Details' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource" role="button" (click)="rowSelect(data)">
            <td class="text-center" style="width: 120px;">{{ data.TransferDate | date: "yyyy-MM-dd"}}</td>
            <td class="text-center">{{ data.Amount }}</td>
            <td class="text-center">{{ data.TransferedAmount }}</td>
            <td class="text-center">{{ data.RequestorUser.UserFullName }}</td>
            <td class="text-center">{{ data.RequestorUser.UserName }}</td>
            <td class="text-center">{{ data.ApprovalUser.UserFullName }}</td>
            <td class="text-center">{{ data.ApprovalUser.UserName }}</td>
            <td class="text-center">{{ data.SetteledAmount}}</td>
            <td class="text-center">{{ data.Amount - (data.TransferedAmount + data.SetteledAmount) }}</td>
            <!-- Actoins -->
            <!-- <td class="text-center">
              <div class="d-flex flex-column">
                  <button  class="btn btn-success">تسويه</button>
                  <button  class="btn btn-primary">دفع</button>
              </div>
              <span> - </span>
            </td> -->
            <!-- Details -->
            <td class="text-center">
              <div class="d-flex flex-column">
                  <button class="btn btn-primary" (click)="getDetails(data)">{{ 'Common.Details' | translate}}</button>
              </div>
            </td>
          </tr>
      </tbody>
    </table>
</div>
