import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';

@Component({
  selector: 'app-services-dropdown',
  templateUrl: './services-dropdown.component.html',
  styleUrls: ['./services-dropdown.component.scss']
})
export class ServicesDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private thGeneralSystemService:ThGeneralSystemService,
  ) { }

  ngOnInit(): void {
    this.initComboSharedInput()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data){
    // console.log("eventdata",data)
    this.serviceNameVal = `[${data.Service_Id}] - ${data.Service_Id}`;
  }
  reset(){
    // console.log("called")
    this.serviceNameVal = ""
  }

  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public servicescomboName="services";
  serviceNameVal: string="";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      services:{},
    };

    this.initComboSharedInputValues(this.servicescomboName);
    
    this.getServices()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting Services for dropdown select---------------------- */
  getservicesBody(){
    let body={}
    if(this.shrdInpt[this.servicescomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.servicescomboName].pageNumber,
        pageSize: this.shrdInpt[this.servicescomboName].pageSize,
        // searchString: this._shrdInpt[this._comboName].searchText,
        isPaginated: true
      }
    }else{
      this.shrdInpt[this.servicescomboName].pageNumberBeforeSearch = this.shrdInpt[this.servicescomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.servicescomboName].pageNumber,
        pageSize: this.shrdInpt[this.servicescomboName].pageSize,
        isPaginated: true
      }
    } 
    return body;
  }

  getServices() {
    let body = this.getservicesBody();
    this.shrdInpt[this.servicescomboName].isLoading = true;
    const subscr = this.thGeneralSystemService.GetServicesData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let services = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.servicescomboName].isSearching){
            this.shrdInpt[this.servicescomboName].comboBoxList = []
              if(services.length){
                this.shrdInpt[this.servicescomboName].endData = false;
                for (let i = 0; i < services.length; i++) {
                  this.shrdInpt[this.servicescomboName].comboBoxList.push(`[${services[i].service_id}] - ${services[i].fullName}`);
                  this.shrdInpt[this.servicescomboName].loadedList.push(services[i]);
                  
                }
                this.shrdInpt[this.servicescomboName].comboboxref.addItem(this.shrdInpt[this.servicescomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.servicescomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.servicescomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.servicescomboName].endData = true;
              }
          }else{
            if(services.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.servicescomboName].oldSearchText!=this.shrdInpt[this.servicescomboName].searchText){
                this.shrdInpt[this.servicescomboName].searchList= [];
                //update old value
                this.shrdInpt[this.servicescomboName].oldSearchText = this.shrdInpt[this.servicescomboName].searchText;
              }
              this.shrdInpt[this.servicescomboName].endData = false;
              for (let i = 0; i < services.length; i++) {
                this.shrdInpt[this.servicescomboName].searchList.push(`[${services[i].service_id}] - ${services[i].fullName}`);
              }
              this.shrdInpt[this.servicescomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.servicescomboName].combBoxEvent.updateData(this.shrdInpt[this.servicescomboName].searchList);
            }else{
              this.shrdInpt[this.servicescomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.servicescomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.servicescomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.servicescomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }

  /*----------------------when Choosing service---------------------- */
  changeSelectedService(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      //Getting id of selected service and setting value in for combobox and in form
      let indexofendId = selectedValue.indexOf(']')
      let serviceSelected = selectedValue.substring(1,indexofendId)
      this.serviceNameVal = selectedValue;
      this.updateParent.emit(serviceSelected);   
    }else{
      this.updateParent.emit("");
    }
  }

}
