  <!--Filter Row-->
  <div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2" *ngIf="!isEditing">
        <form class="form" [formGroup]="form">
        <div class="card-body">
            <div class="form-group row">
                <!-- Date to -->
                <div class="col-lg-4">
                    <label>{{'Common.DateTo'|translate}}</label>
                    <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                </div>
                <!-- Service -->
                <div class="col-lg-4">
                    <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                </div>
                <!-- Card Name -->
                <div class="col-lg-4">
                    <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                    <select formControlName="class_no" [(ngModel)]="classNoVal" class="form-control">
                        <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                        <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                           [{{ item.class_no }}] - {{ item.class_name }}
                        </option>
                    </select>
                    <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                </div> 
                <!-- Provider -->
                <div class="col-lg-4 mt-3 ">
                    <app-providers-dropdown #providersDropDown (updateParent)="setSelectedProvider($event)"></app-providers-dropdown>
                </div>
                <!-- Order no -->
                <div class="col-lg-4 mt-3">
                    <label>{{'HALA.SendCardsEmployee.OrderNo'|translate}}:</label>
                    <input type="text" class="form-control" [(ngModel)]="orderNoVal" placeholder=""
                        formControlName="orderNo" />
                </div>
                <!-- Action Buttons -->
                <div class="col-lg-4 d-flex align-items-end">
                    <div class="form-group row">
                    <div class="col-lg-4 action-btns me-3">
                        <button type="submit" (click)="submitSearch()"
                        class="btn btn-primary font-weight-bold px-10 mr-2">
                            {{'Common.Search'|translate}}
                        </button>
                    </div>
                    <div class="col-lg-4 action-btns">
                        <button type="submit" (click)="reset()"  [disabled]="isEditing" class="btn btn-danger font-weight-bold px-10 mr-2">
                            {{'Common.Clear'|translate}}
                        </button>
                    </div>
                    <div class="col-lg-4">

                    </div>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
    </div>
</div>

<!--Adding card row-->
<div class="row" *ngIf="permissionService.checkPermission(['HalaManageHalaCards.Create','HalaManageHalaCards.Update'])">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
        <div class="card-title">
            <h3 class="card-label"> {{'HALA.SendCardsEmployee.AddCard'|translate}} </h3>
        </div>
        </div>
        <!--begin::Form-->
        <div class="form-group">
        <form class="form" [formGroup]="formCard" (keydown.enter)="$event.preventDefault()">
            <div class="card-body">
            <div class="form-group row">
                <div class="col-lg-4">
                    <label> {{'Common.Serial'|translate}} :</label>
                    <input formControlName="serialCard" *ngIf="focusSerial" (keydown.enter)="tryToAddCard()"
                        #FocusInputSerial type="text" class="form-control" [(ngModel)]="serialCardVal"
                        placeholder="{{'Common.InsertSerial'|translate}}" />
                    <div *ngIf="formCard.controls.serialCard.touched && formCard.controls.serialCard.errors" class="invalid-input">
                        <p *ngIf="formCard.controls.serialCard.hasError('pattern')">{{'Common.SerialInvalid'|translate}}</p>
                        <p *ngIf="formCard.controls.serialCard.hasError('minlength')">{{'Common.SerialShort'|translate}}</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label> {{'Common.Pin'|translate}} :</label>
                    <input formControlName="pinCard" type="text" *ngIf="focusPin" (keydown.enter)="tryToAddCard()"
                        #FocusInputPin class="form-control" [(ngModel)]="pinCardVal" placeholder="{{'Common.InsertPin'|translate}}" />
                    <div *ngIf="formCard.controls.pinCard.touched && formCard.controls.pinCard.errors" class="invalid-input">
                        <p *ngIf="formCard.controls.pinCard.hasError('pattern')">{{'Common.SerialInvalid'|translate}}</p>
                        <p *ngIf="formCard.controls.pinCard.hasError('minlength')">{{'Common.SerialShort'|translate}}</p>
                    </div>
                </div>

                <div class="col-lg-4 d-flex align-items-end">
                <button style="margin-top: 7%;" (click)="tryToAddCard()"
                    class="btn btn-primary font-weight-bold px-10 mr-2">{{'Common.Add'|translate}}</button>
                </div>
            </div>
            </div>
        </form>
        </div>
        <!--end::Form-->
    </div>
    </div>
</div>

<!--Header Info Table-->
<div class="row">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact px-4 py-2">
            <div class="table-striped table-responsive">
                <ejs-grid #grid id="Grid" [enableRtl]="languageService.selectedLanguage=='ar'" [dataSource]="dataSource"
                [toolbar]="toolbarOptions" [allowPaging]="true" [allowExcelExport]="true" 
                    (toolbarClick)="toolbarClick($event)" [selectedRowIndex]="selectedRowIndex"
                    >
                    <e-columns>
                    <e-column field="Service_Id" headerText=" {{'HALA.SendCardsEmployee.ServiceId'|translate}}  " width="120"></e-column>
                    <e-column field="Class_Name" headerText="  {{'HALA.SendCardsEmployee.CardName'|translate}} " width="150"></e-column>
                    <e-column field="cardsNo" headerText="{{'HALA.SendCardsEmployee.CardAmount'|translate}}"width="110"></e-column>
                    <e-column field="AddedDate" type="date" [format]="format" headerText="{{'Common.DateFrom'|translate}}"width="120" [valueAccessor]='dateFormatter'></e-column>
                    <e-column field="ExpirationDate" type="date" [format]="format" headerText="{{'Common.DateTo'|translate}}"width="120" [valueAccessor]='dateFormatter'></e-column>
                    <e-column field="ProviderName" headerText=" {{'HALA.SendCardsEmployee.ProviderId'|translate}} "width="200"></e-column>
                    <e-column field="OrderNo" headerText=" {{'HALA.SendCardsEmployee.OrderNo'|translate}} "width="120"></e-column>
                    <e-column headerText="{{'Common.Edit'|translate}}" width="80"  *ngIf="permissionService.checkPermission(['HalaManageHalaCards.Update'])">
                        <ng-template #template let-dataSource>
                            <i *ngIf="selectedRowIndex!=dataSource.CardHeaderId" class='mdi mdi-pencil font-size-18 text-info' (click)="rowSelected(dataSource)"></i>
                            <i *ngIf="isEditing && selectedRowIndex==dataSource.CardHeaderId" class='mdi mdi-close font-size-18 text-danger' (click)="cancelEdit()"></i>
                            </ng-template>
                    </e-column>
                    <e-column headerText="{{'Common.Delete'|translate}}" width="80" *ngIf="permissionService.checkPermission(['HalaManageHalaCards.Delete'])">
                        <ng-template #template let-dataSource>
                            <i *ngIf="!isEditing" class='mdi mdi-delete font-size-18 text-danger' (click)="showAlertDeleteHeader(dataSource)"></i>
                        </ng-template>
                    </e-column>
                    <e-column headerText="{{'Common.Send'|translate}}" width="80" *ngIf="permissionService.checkPermission(['HalaManageHalaCards.Create'])">
                        <ng-template #template let-dataSource>
                            <i class='mdi mdi-send font-size-18 text-success' (click)="showAlert(dataSource)"></i>
                            </ng-template>
                    </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</div>

 <!--Detail Table (Serila Pin)-->
<div class="row">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact px-4 py-2">
            <div class="table-striped table-responsive">
                <ejs-grid #detailgrid [enableRtl]="languageService.selectedLanguage=='ar'" [pageSettings]="pageSettings" [toolbar]="toolbarOptions"
                    [allowPaging]="true" [allowExcelExport]="true" [dataSource]="detailSource"
                    [allowSelection]="false" (toolbarClick)="toolbarClickDetails($event)">
                    >
                    <e-columns>

                    <e-column field="SerialCard" style="font-weight: 700; font-size: large;" headerText="{{'Common.Serial'|translate}}">
                    </e-column>
                    <e-column field="PinCard" style="font-weight: bold;" headerText="{{'Common.Pin'|translate}}"></e-column>
                    <e-column field="CreateDate" style="font-weight: bold;" type="date" [format]="format"  [valueAccessor]='dateFormatter'
                        headerText="{{'Common.CreateDate'|translate}}"></e-column>
                    <e-column field="CreateDate" style="font-weight: bold;" type="date" [valueAccessor]='timeFormatter'
                        headerText="{{'Common.CreateTime'|translate}}">
                        <ng-template #template let-dataSource>
                            <span>{{dataSource.CreateDate | date: "hh:mm a"}}</span>
                        </ng-template>
                    </e-column>

                    <e-column headerText="{{'Common.Delete'|translate}}" *ngIf="permissionService.checkPermission(['HalaManageHalaCards.Delete'])">
                        <ng-template #template let-dataSource>
                            <i class='mdi mdi-delete font-size-24 text-danger' (click)="DeleteThisCard(dataSource)"></i>
                       
                        </ng-template>
                    </e-column>

                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</div>