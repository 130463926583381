import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ThCurrentStockBalanceService } from 'src/app/core/services/thuraya/th-current-stock-balance.service';
import { ThOutstandingClassServiceService } from 'src/app/core/services/thuraya/th-outstanding-class-service.service';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-get-outstanding-class-data',
  templateUrl: './get-outstanding-class-data.component.html',
  styleUrls: ['./get-outstanding-class-data.component.scss']
})
export class GetOutstandingClassDataComponent implements OnInit {

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Export Variable for loading
  isExporting:boolean = false;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thOutstandingClassServiceService:ThOutstandingClassServiceService,
    private spinner: NgxSpinnerService,
    public permissionService:PermissionService,
  ) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
    }
    const subscr =  this.thOutstandingClassServiceService.GetOutstandingClassServicesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          let sheet = {}
          for (let i = 0; i < data.length; i++) {     
            sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "اسم الكرت": data[i].class_name,
              "رقم المنتج": data[i].product_id,
              "عدد الكروت": data[i].Count,
              "السعر": data[i].class_cost,
              "الاجمالى": data[i].Total,
              "المستخدم": data[i].noteusername,
              "التاريخ": this.datePipe.transform(data[i].notedate,this.dateFormatService.dateFormat),
              "ملاحظه": data[i].note,
            }
            this.excelService.addToSheet(sheet);
          }

          let total={ 
            "عدد الكروت": data[0].SumCount,
            "السعر":  Number.parseFloat(data[0].SumTotal).toFixed(2),
          }

          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )    
  
  } 
  
  /*----------------------API Requests---------------------- */
  getOutstandingClassServicesData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
    }
    return this.thOutstandingClassServiceService.GetOutstandingClassServicesData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }
 
  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getOutstandingClassServicesData();
  }

  /*----------------------Add/Update row note---------------------- */
  addNote(data){
    Swal.fire({
      input: 'textarea',
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Save'),
      inputLabel: this.msgTranslateService.msgTranslate('Common.Note'),
      inputValidator:(value) => {
        if (!value ) {
          return this.msgTranslateService.msgTranslate('SwalMessages.NoteReq')
        }
      },
      preConfirm: (value) => {
        let body = {
          row_no: data.row_no,
          note: value,
        }
        this.thOutstandingClassServiceService.SaveUserNote(body).subscribe(
          (result)=>{
            if(result.IsSuccess){
              this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccAdd'), "",{
                positionClass: 'toast-bottom-left',
              });
              let index = this.datasource.findIndex(d=>d.row_no == data.row_no);
              this.datasource[index].note = value;
            }
          },(err)=>{
            this.toastr.error(err.error.errors, "",{
              positionClass: 'toast-bottom-left',
            });
          }
        )
      },
      showCancelButton: true
    })
  }
 

}
