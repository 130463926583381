<!-- Add balance amount -->
<div class="d-flex mb-5" *ngIf="checkCanAdd()">
    <button class="btn btn-success"  (click)="showCreateRequestDialog()">
        <i class="fa fa-plus"></i>
        <span>طلب رصيد</span>
    </button>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

 <!-- Table/Filter -->
 <div class="card py-3 px-3"> 
  <!-- Filter Form -->
  <form class="form" [formGroup]="form">
      <div class="card-body">
        <div class="form-group row">
          <!-- Date From -->
          <div class="col-lg-3 mb-3">
            <label>{{'Common.DateFrom'|translate}}</label>
            <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
          </div>
          <!-- Date to -->
          <div class="col-lg-3 mb-3">
            <label>{{'Common.DateTo'|translate}}</label>
            <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
          </div>
          <!-- Request Status -->
          <div class="col-lg-3 mb-3">
              <label> {{'Common.RequestStatus'|translate}}:</label>
              <select formControlName="requestStatus" class="form-select">
                <option selected value=""> {{'Common.Choose'|translate}} </option>
                <option value="{{requestStatus.Pendding}}">{{'Common.Pending'|translate}}</option>
                <option value="{{requestStatus.Accepted}}">{{'Common.Approved'|translate}}</option>
                <option value="{{requestStatus.Rejected}}">{{'Common.Declined'|translate}}</option>
              </select>
          </div>
          <!-- Request Type -->
          <div class="col-lg-3 mb-3">
              <label> {{'Common.RequestType'|translate}}:</label>
              <select formControlName="requestType" class="form-select" (change)="changeRequestType($event)">
                <option selected value=""> {{'Common.Choose'|translate}} </option>
                <option value="{{requestType.Balance}}">{{'Common.Balance'|translate}}</option>
                <option value="{{requestType.Pay}}">{{'Common.Payment'|translate}}</option>
                <option value="{{requestType.Settlement}}">{{'Common.Settle'|translate}}</option>
              </select>
          </div>
          <!-- Request Payment type -->
          <div class="col-lg-3 mb-3" *ngIf="showRequestPaymentType">
              <label>  {{'Common.PaymentType'|translate}}:</label>
              <select formControlName="requestPaymentType" class="form-select">
                <option selected value=""> {{'Common.Choose'|translate}} </option>
                <option value="{{requestPaymentType.Cash}}">{{'Common.Cash'|translate}}</option>
                <option value="{{requestPaymentType.Bank}}">{{'Common.Bank'|translate}}</option>
              </select>
          </div>
          <!-- Customers filter -->
          <div class="col-lg-3 mb-3">
            <label>{{'Common.Requestor'|translate}} :</label>
            <app-all-customers-dropdown #allCustomersDropDown (updateParent)="setSelectedCustomer($event)"></app-all-customers-dropdown>
          </div>
         
          <div class="col-lg-2 mb-3 d-flex align-items-end">
            <div class="action-btns" style="margin-inline-end: 20px;">
                <button type="submit" (click)="search()"
                class="btn btn-primary font-weight-bold px-10 mr-2">
                    {{'Common.Search'|translate}}
                </button>
            </div>
            <div class="action-btns">
                <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                    {{'Common.Clear'|translate}}
                </button>
            </div>
          </div>
        </div>
      </div>
  </form>

  <!-- Table -->
  <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
  <app-sadad-requests-table [datasource]="datasource" [showConfirmDeclineColumn]="true" (showDialog)="showApproveRequest($event)"></app-sadad-requests-table>
  <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>