import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-target-table',
  templateUrl: './target-table.component.html',
  styleUrls: ['./target-table.component.scss']
})
export class TargetTableComponent implements OnInit {

  @Input() datasource;
  @Input() isMainTarget;
  @Input() isLevelOne;

  @Output() showStatusInExcel: EventEmitter<any> = new EventEmitter();

  SumCompanyProfit:number = 0;
  SumCardsNo:number = 0;
  SumDistributorPrice:number = 0;
  
  statusShown = false;
  //Form variables
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumCompanyProfit = this.datasource[0].SumCompanyProfit;
      this.SumCardsNo = this.datasource[0].SumCardsNo;
      this.SumDistributorPrice = this.datasource[0].SumDistributorPrice;
    }else{
      this.SumCompanyProfit = 0;
      this.SumCardsNo = 0
      this.SumDistributorPrice = 0;
    }
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      statusValue: new FormControl(""),
    });
  }

  get f(){
    return this.form.controls;
  }

  inputChange(event){
    if(event.target.value!=""){
      this.statusShown = true;
      let obj={
        show:true,
        value:this.f.statusValue.value
      }
      this.showStatusInExcel.emit(obj)
    }else{
      this.statusShown = false;
      let obj={
        show:false,
        value:this.f.statusValue.value
      }
      this.showStatusInExcel.emit(obj)
    }
  }

}
