import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanySalesRoutingModule } from './company-sales-routing.module';
import { GetCompanySalesComponent } from './get-company-sales/get-company-sales.component';
import { CompaniesSalesTableComponent } from './get-company-sales/_components/companies-sales-table/companies-sales-table.component';
import { SalesRateTableComponent } from './get-company-sales/_components/sales-rate-table/sales-rate-table.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetCompanySalesComponent,
    CompaniesSalesTableComponent,
    SalesRateTableComponent
  ],
  imports: [
    CommonModule,
    CompanySalesRoutingModule,
    CoreModule
  ]
})
export class CompanySalesModule { }
