import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { EncryptionService } from '../services/encryption.service';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService, 
    private encryptionService: EncryptionService,
    ) { }

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let encryptData:boolean = environment.enableEncryption //To enable/disable encryption

    // add auth header with jwt if user is logged in and request is to the api url
    let authToken = localStorage.getItem(environment.tokenName)!;
    let lang =  localStorage.getItem("lang")!
    if(authToken){
        authToken = authToken.replace(/['"]+/g, '');
        request = request.clone({
            setHeaders: {
                'Authorization': `Bearer ${authToken}`,
                'accept-language': lang
            }
        });
    }else{
        request = request.clone({
            setHeaders: {
                'accept-language':lang
            }
        });
    }

    let req= request

    if (request.body && encryptData) {
      // Encrypt the JSON body
      const encryptedBody = this.encryptionService.encryptBody(request.body);
      // Clone the request and replace the body with the encrypted one
      const clonedRequest = request.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8' //For encryption //[IMPORTANT] THIS ENCRYPTION DOES NOT WORK WITH FORMDATA
        },
        body: encryptedBody
      });
      req=clonedRequest;
    }

    // debugger;
    return next.handle(req).pipe(
        tap(
          event => { 
            // if (event instanceof HttpResponse)
            //   console.log('request succeeded');
          },
          error => {
            if (error instanceof HttpErrorResponse) {
                if(error.status==401){
                    this.authService.logout();
                    console.log('Invalid User Permissions will logout',error.status);
                }
            }
          }
        ));;
  }
}
