import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ThBulkOrderCardService } from 'src/app/core/services/thuraya/th-bulk-order-card.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-get-inserted-order-data',
  templateUrl: './get-inserted-order-data.component.html',
  styleUrls: ['./get-inserted-order-data.component.scss']
})
export class GetInsertedOrderDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
 
  //dropdown lists variables
  ClassNameList:any = []

  //card values
  agentCardDetails={
    price:"",
    cost:"",
    availQuant:"",
    ClientBalance:""
  }

  //as we use agentCardDetails in ngmodel so any change in value will change the object but these value must not be changed so we refer with this object
  agentCardDetailsNOTEditable={
    price:"",
    cost:"",
    availQuant:"",
    ClientBalance:""
  }
  
  authUser = ""
  // @ViewChild("agentsDropDown") agentsDropDown;
  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thBulkOrderCardService:ThBulkOrderCardService,
    private spinner: NgxSpinnerService,
    private authService:AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*----------------------Swal loaders---------------------- */
  showSwalAlert(){
    Swal.fire({
      title: `<div class="py-2">
      <h2> ${this.msgTranslateService.msgTranslate("SwalMessages.Wait")} </h2>  
      <div class="d-flex justify-content-center">
        <div class="spinner-grow"  role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Loading...</span>
        </div> 
      </div>
      </div>`,
      allowOutsideClick: false,
      showCancelButton: false, // There won't be any cancel button
      showConfirmButton: false // There won't be any confirm button
     
    });
  }
  
  CloseSwalAlert(){
    Swal.close();
  }
 
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.authUser = `${this.authService.authUser.PhoneNumber}_${this.authService.authUser.FullName}`
    this.form = this.fb.group({
      ServiceId: new FormControl("",Validators.required),
      class_no: new FormControl("",Validators.required),
      account_id: new FormControl(this.authUser),
      quantity: new FormControl("",Validators.required),
      ClientPrice: new FormControl(""),
      CompanyCost: new FormControl(""),
      Available: new FormControl(""),
      Total: new FormControl(""),
      TotalBalance: new FormControl(""),
    });
   
  }

  get f(){
    return this.form.controls;
  }

  resetDefaultValues(){
    this.agentCardDetails ={
      price:"",
      cost:"",
      availQuant:"",
      ClientBalance:"",
    }
    this.agentCardDetailsNOTEditable = {
      price:"",
      cost:"",
      availQuant:"",
      ClientBalance:"",
    }
  }

  /*----------------------API Requests---------------------- */
  getClientCardPrice(){
    return this.thBulkOrderCardService.GetClientCardPrice(this.f.ServiceId.value,this.f.class_no.value).subscribe((result) => {
      if(result.IsSuccess){
       this.agentCardDetails.price = result.Data.ClientPrice
       this.agentCardDetails.cost = result.Data.CompanyCost
       this.agentCardDetails.availQuant = result.Data.Available
       this.agentCardDetails.ClientBalance = result.Data.ClientBalance
       //get a new copy from the values
       this.agentCardDetailsNOTEditable = {...this.agentCardDetails}
      }
      else{
       this.resetDefaultValues()
      }
    },(err)=>{
      this.resetDefaultValues()
    }
    );
  }

  getInsertBulkOrderCardsData(body){
    return this.thBulkOrderCardService.GetInsertBulkOrderCardsData(body).subscribe((result) => {
      if(result.IsSuccess){
        this.datasource = result.Data;
      }
      else{
        this.datasource = []
      }
      },(err)=>{
        this.datasource = []
      }
    );
  }

  confirmAlertBeforeInsert(){
    Swal.fire({
      title: 
      '<div style="margin-bottom:25px">'+
        "<h2>" + this.msgTranslateService.msgTranslate('SwalMessages.ConfirmBuy') +"</h2>"+
      '</div>'+
      '<div>'+
        "<h3>"+ this.msgTranslateService.msgTranslate('Common.CompanyName') + ":" + "<span style='margin-inline-start:20px; color:#62686e'>" + this.f.ServiceId.value + "</span>" + " </h3>"+ 
      '</div>' +
      '<div>'+
        "<h3>"+ this.msgTranslateService.msgTranslate('Common.CardName') + ":" + "<span style='margin-inline-start:20px; color:#62686e'>" + this.ClassNameList.filter(a => a.class_no == this.f.class_no.value)[0].class_name + "</span>" + " </h3>"+ 
      '</div>'+
      '<div>'+
        "<h3>"+ this.msgTranslateService.msgTranslate('Common.Amount') + ":" + "<span style='margin-inline-start:20px; color:#62686e'>" + this.f.quantity.value + "</span>" + " </h3>"+ 
      '</div>'+
      '<div>'+
        "<h3>"+ this.msgTranslateService.msgTranslate('Common.Total') + ":" + "<span style='margin-inline-start:20px; color:#62686e'>" + this.f.Total.value + "</span>" + " </h3>"+ 
      '</div>',
      icon:"question",
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        this.insertBulkOrderCardsData()   
      },
    })
  
  }

  insertBulkOrderCardsData(){
    //Empty Previous data
    this.datasource = []
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }

    //Check total not higher than balance
    if(parseInt(this.f.Total.value) > parseInt(this.agentCardDetailsNOTEditable.ClientBalance)){
      Swal.fire({
        title:  this.msgTranslateService.msgTranslate('SwalMessages.BalanceNotEnought'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }

    //Check Quantity request not higher than available
    if(this.f.quantity.value > parseInt(this.agentCardDetailsNOTEditable.availQuant) ){
      Swal.fire({
        title:  this.msgTranslateService.msgTranslate('SwalMessages.QuantityNotAvailable'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    
    //Check price not less or equal cost
    if(parseInt(this.agentCardDetailsNOTEditable.price) <= parseInt(this.agentCardDetailsNOTEditable.cost)){
      Swal.fire({
        title:  this.msgTranslateService.msgTranslate('SwalMessages.PriceLessThanCost'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }

    //Else continue
    this.showSwalAlert()
    let body = {
      // account_id: this.f.account_id.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      quantity: this.f.quantity.value
    }
    this.thBulkOrderCardService.InsertBulkOrderCardsData(body).subscribe((result) => {  
      this.CloseSwalAlert()
      if(result.IsSuccess){
        Swal.fire({
          title: "<h4> بيانات الشراء</h4> <br/><h4>العميل رقم :"
            + result.Data.account_id + " </h4><h4>  اسم الشركة :"
            + result.Data.service_id + "</h4><h4>  اسم الكرت:"
            + this.ClassNameList.filter(a => a.class_no == result.Data.class_no)[0].class_name + "</h4><h4>  العدد المطلوب:"
            + result.Data.requestQuantity + "</h4><h4>    عدد الكروت الناجحة:"
            + result.Data.successQuantity + "</h4><h4> عدد الكروت الفاشلة :"
            + result.Data.failedQuantity + "</h4><h4>  الرقم المرجعي هو :"
            + result.Data.invoiceId + "</h4> ",
          icon: (result.Data.failedQuantity >0) ? 'warning' : 'success',
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonText:this.msgTranslateService.msgTranslate('Common.Done'),
          focusConfirm: false,
        });
        //Display the orderd data
        this.getInsertBulkOrderCardsData(result.Data);
        this.reset()
      }else{
        this.toastr.error(result.Data, "", {
          positionClass: 'toast-bottom-left',
        });
      }

    },(err)=>{
      this.CloseSwalAlert()
      this.toastr.error(err, "", {
        positionClass: 'toast-bottom-left',
      });
    }
    );
  }
  
  reset(){
    this.initForm();
    // this.agentsDropDown.reset()
    this.servicesDropDown.reset()
  }

  resetValues(){
    this.resetDefaultValues();
    this.f.quantity.setValue("");
    this.f.Total.setValue("");
  }

  /*----------------------Getting class name for dropdown select---------------------- */
  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
  }

  /*----------------------when Choosing Agent---------------------- */
  // setSelectedAgent(event){
  //   this.form.controls.account_id.setValue(event)
  // }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    //when change service rset values
    this.resetValues()
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------when Choosing Card---------------------- */
  setSelectedCard(){
    //when change card rset values
    this.resetValues()
    this.getClientCardPrice()
  }

  /*----------------------when Changing quantity---------------------- */
  setTotalPrice(event){
    //Can not set quantity or total if class does not have price
    if(this.agentCardDetailsNOTEditable.price!="" && parseInt(this.agentCardDetailsNOTEditable.price)!=0){
      this.f.Total.setValue( Number(event.target.value*parseFloat(this.agentCardDetails.price)).toFixed(2) )
    }else{
      this.f.quantity.setValue("")
    }
  }
}
