import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPurchaseService } from 'src/app/core/services/thuraya/th-purchase.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ThPurchaseRateService } from 'src/app/core/services/thuraya/th-purchase-rate.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ThPurchaseTargetService } from 'src/app/core/services/thuraya/th-purchase-target.service';

@Component({
  selector: 'app-get-all-purchases-data',
  templateUrl: './get-all-purchases-data.component.html',
  styleUrls: ['./get-all-purchases-data.component.scss']
})
export class GetAllPurchasesDataComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR thdbPurchasedata AND thdbPurchaserate AND thdbPurchasestarget  so we decide from route and with these varibale we change logic
  IsPurchaseReportComponent:boolean = false;
  IsPurcaseRateComponent:boolean = false;
  IsPurchaseTargetComponent:boolean = false;
  
  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []
  
  //Multiselect variables for purchase target page
  selectedServicesList:any = []
  selectedClassList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;
  @ViewChild("serviceMultiSelect") serviceMultiSelect;
  @ViewChild("classMultiSelect") classMultiSelect;
  
  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thPurchaseService:ThPurchaseService,
    private thPurchaseRateService:ThPurchaseRateService,
    private thPurchaseTargetService:ThPurchaseTargetService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { 

    //[IMPORTANT] This component IS USED FOR Purchase Report AND Purchase rate
    // console.log("route",this.router.url)
    //
    if(this.router.url=="/thuraya/purchases/get-all-purchases-data"){
      this.IsPurchaseReportComponent = true;
      this.IsPurcaseRateComponent = false;
      this.IsPurchaseTargetComponent = false
    }else if(this.router.url=="/thuraya/purchases/get-all-purchases-rate-data"){
      this.IsPurchaseReportComponent = false;
      this.IsPurcaseRateComponent = true;
      this.IsPurchaseTargetComponent = false
    }else if(this.router.url=="/thuraya/purchases/get-all-purchases-target-data"){
      this.IsPurchaseReportComponent = false;
      this.IsPurcaseRateComponent = false;
      this.IsPurchaseTargetComponent = true
    }
  }

  ngOnInit(): void {
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalValue')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCost')], icon: 'dollar-sign', iconColor: '#1bc5bd', amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Amount')], icon: 'coins', iconColor: '#181c32',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Unit')]},
    ];
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      isOnline: this.f.isOnline.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    //Export case purchase report page
    if(this.IsPurchaseReportComponent){   
      const subscr =  this.thPurchaseService.GetAllPurchasesData(body).subscribe(
        (result)=>{
          if(result.IsSuccess && result.Data.resultList.length>0){
            let data = result.Data.resultList
            for (let i = 0; i < data.length; i++) {            
              let sheet={ 
                "مقدم الخدمة": data[i].service_id,
                "رقم المنتج": data[i].product_id,
                "نوع المنتج": data[i].cardType,
                "اسم الفئة": data[i].ClassName,
                "اجمالي القيمة": data[i].TotalPrice,
                "اجمالي التكلفة":data[i].TotalCost,
                "الكمية":data[i].Amount,
                "معدل تكلفة الوحدة":data[i].AvgCost
              }
              this.excelService.addToSheet(sheet);
            }
            let total={ 
              "اجمالي القيمة": Number.parseFloat(result.Data.AllTotalPrice).toFixed(2),
              "اجمالي التكلفة":Number.parseFloat(result.Data.AllTotalCost).toFixed(2),
              "الكمية":Number.parseFloat(result.Data.AllAmount).toFixed(2),
            }
            this.excelService.addToSheet(total);
            this.excelService.excelExport();
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.subscriptions.push(subscr);
    }
    //Export case purchase rate page
    else if(this.IsPurcaseRateComponent){
      const subscr =  this.thPurchaseRateService.GetAllPurchasesRatePerDayData(body).subscribe(
        (result)=>{
          if(result.IsSuccess && result.Data.length>0){
            let data = result.Data
            for (let i = 0; i < data.length; i++) {            
              let sheet={ 
                "مقدم الخدمة": data[i].service_id,
                "اسم الكرت": data[i].class_name,
                "نوع الكرت": data[i].source_type,
                "عدد الكروت": data[i].count,
                "التكلفة": data[i].class_cost,
                "اجمالي التكلفة":data[i].TotalCost,
                "اجمالي السعر":data[i].TotalPrice,
                "عدد الايام":data[i].DiffDays,
                "المعدل":data[i].Rate
              }
              this.excelService.addToSheet(sheet);
            }
            let total={ 
              "اجمالي التكلفة":Number.parseFloat(data[0].SumTotalCost).toFixed(2),
              "اجمالي السعر": Number.parseFloat(data[0].SumTotalPrice).toFixed(2),
            }
            this.excelService.addToSheet(total);
            this.excelService.excelExport();
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.subscriptions.push(subscr);
    }
   
  }


  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    ServiceId: new FormControl(""),
    class_no: new FormControl(""),
    isOnline: new FormControl("")
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAllPurchasesData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      isOnline: this.f.isOnline.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thPurchaseService.GetAllPurchasesData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.infoList[0].amount[0] = result.Data.AllTotalPrice
        this.infoList[1].amount[0] = result.Data.AllTotalCost
        this.infoList[2].amount[0] = result.Data.AllAmount
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0;
        this.infoList[1].amount[0] = 0;
        this.infoList[2].amount[0] = 0;
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getAllPurchasesRatePerDayData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      isOnline: this.f.isOnline.value,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thPurchaseRateService.GetAllPurchasesRatePerDayData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      else if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0;
        this.pageNumber = 1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.datasource = [];
        this.totalCount = 0;
        this.pageNumber = 1;
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getAllPurchaseTargetData(){
    this.spinner.show(); 
    let body={
      service_id: this.selectedServicesList,
      class_no: this.selectedClassList,
      source_type: this.f.isOnline.value,
      fromDate: this.fromDateCom,
      toDate: this.toDateCom
    }
    return this.thPurchaseTargetService.GetAllPurchasesTargetData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        // this.totalCount = result.Data[0].TotalRows;
      }
      else if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0;
        this.pageNumber = 1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.datasource = [];
        this.totalCount = 0;
        this.pageNumber = 1;
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.IsPurchaseReportComponent ? this.getAllPurchasesData() : this.getAllPurchasesRatePerDayData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      if(this.IsPurchaseReportComponent){
        this.getAllPurchasesData()
      }else if (this.IsPurcaseRateComponent){
        this.getAllPurchasesRatePerDayData()
      }else{
        this.getAllPurchaseTargetData()
      }
    } 
  }

  reset(){
    this.initForm();
    (this.IsPurcaseRateComponent || this.IsPurchaseReportComponent) && this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.selectedServicesList = [];
    this.selectedClassList = [];
    this.serviceMultiSelect.resetSelected()
    this.classMultiSelect.resetSelected()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.IsPurchaseReportComponent ? this.getAllPurchasesData() : this.getAllPurchasesRatePerDayData()
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------when selecting-deselecting service multiselect target page---------------------- */
  addToServicesList(event){
    this.selectedServicesList.push(event)
    //get new classes for selected service
    this.classMultiSelect.getClassData(this.selectedServicesList)
    this.selectedClassList = []
    // console.log("add", this.selectedServicesList)
  }

  removeFromServicesList(event){
    this.selectedServicesList = this.selectedServicesList.filter( s => s != event )
    //get new classes for selected service and remove previous selected classes
    this.classMultiSelect.getClassData(this.selectedServicesList)
    this.selectedClassList = []
    // console.log("remove", this.selectedServicesList)
  }

  /*----------------------when selecting-deselecting class multiselect target page---------------------- */
  addToClassList(event){
    this.selectedClassList.push(event)
    // console.log("add", this.selectedClassList)
  }

  removeFromClassList(event){
    this.selectedClassList = this.selectedClassList.filter( s => s != event )
    // console.log("remove", this.selectedClassList)
  }
}
