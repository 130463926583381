import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonthlyTargetRoutingModule } from './monthly-target-routing.module';
import { GetMonthlyTargetComponent } from './get-monthly-target/get-monthly-target.component';
import { TargetTableComponent } from './get-monthly-target/_components/target-table/target-table.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetMonthlyTargetComponent,
    TargetTableComponent,
  ],
  imports: [
    CommonModule,
    MonthlyTargetRoutingModule,
    CoreModule
  ]
})
export class MonthlyTargetModule { }
