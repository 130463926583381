import {Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HalaManageCardsService } from 'src/app/core/services/hala/hala-manage-cards.service';
import { HalaProvidersService } from 'src/app/core/services/hala/hala-providers.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';

@Component({
  selector: 'app-card-edit-dialog',
  templateUrl: './card-edit-dialog.component.html',
  styleUrls: ['./card-edit-dialog.component.scss']
})
export class CardEditDialogComponent implements OnInit {

  //Variable passed from the dialog
  selectedCard:any
  dialog:any;
  scope:any;
  updateView:any

  // Local Variables
  prvoideComboValue
  updateform: FormGroup;
  selectedValues={
    oracleNo: "",
    transNo: "",
    orderNo: ""
  }

  constructor(
    private fb: FormBuilder,
    private halaProvidersService:HalaProvidersService,
    private halaManageCardsService: HalaManageCardsService,
    private toastr:ToastrService,
    private msgTranslateService:MessageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {selectedCard: object,dialog:any,updateView:any,scope:any}) { 
      this.initUpdateForm();
    }

  ngOnInit(): void {
    //Getting data from material dialog
    this.selectedCard = this.data.selectedCard;
    this.dialog = this.data.dialog;
    this.scope = this.data.scope;
    this.updateView = this.data.updateView;

    //Set the value of the combobox with the provider selected from coming data
    this.prvoideComboValue = `[${this.selectedCard.ProviderId}]-${this.selectedCard.ProviderName}`
    this.updateform.controls.providerId.setValue(this.selectedCard.ProviderId);
    this.updateform.controls.providerName.setValue(this.selectedCard.ProviderName);

    //Set old values
    this.selectedValues.oracleNo = this.selectedCard.oracleNo
    this.selectedValues.transNo = this.selectedCard.transNo
    this.selectedValues.orderNo = this.selectedCard.OrderNo


    // Init combobox
    this.initComboSharedInput()
  }

  initSelectedValues(){
    this.selectedValues={
      oracleNo: "",
      transNo: "",
      orderNo: ""
    }
  }

  /*----------------------Form Functions---------------------- */
  initUpdateForm(){
    this.updateform = this.fb.group({
      providerId: new FormControl(""),
      providerName: new FormControl(""),
      oracleNo: new FormControl("",Validators.required),
      transNo: new FormControl("",Validators.required),
      orderNo: new FormControl("",Validators.required),
    });
  }

  get f(){
    return this.updateform.controls;
  }

  editCard(){
    let body = {
      id: this.selectedCard.CardHeaderId,
      oracleNo: this.f.oracleNo.value,
      transNo: this.f.transNo.value,
      providerId: this.f.providerId.value,
      providerName: this.f.providerName.value,
      orderNo:  this.f.orderNo.value
    }
    return this.halaManageCardsService.UpdateCardHeaderManager(body).subscribe((result) => {
      // console.log("update result",result)
      if(result.IsSuccess){
        //Close dialog
        this.updateView(body,this.scope)
        this.dialog.closeAll();
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    });
  }
  /*----------------------Cancel Edit---------------------- */
  cancelEdit(){
    this.initUpdateForm();
    this.initSelectedValues();
    this.dialog.closeAll()
  }

  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public providerscomboName="providers";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      providers:{},
    };
    this.initComboSharedInputValues(this.providerscomboName);
    this.getProviders()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting providers for dropdown select---------------------- */

  getProvidersBody(){
    let body={}
    if(this.shrdInpt[this.providerscomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.providerscomboName].pageNumber,
        pageSize: this.shrdInpt[this.providerscomboName].pageSize,
        // searchString: this.shrdInpt[this._comboName].searchText,
        isPaginated: true,
      }
    }else{
      this.shrdInpt[this.providerscomboName].pageNumberBeforeSearch = this.shrdInpt[this.providerscomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.providerscomboName].pageNumber,
        pageSize: this.shrdInpt[this.providerscomboName].pageSize,
        isPaginated: true,
      }
    } 
    return body;
  }

  getProviders() {
    let body = this.getProvidersBody();
    this.shrdInpt[this.providerscomboName].isLoading = true;
    const subscr = this.halaProvidersService.GetAllProvidersActiveData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let data = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.providerscomboName].isSearching){
            this.shrdInpt[this.providerscomboName].comboBoxList = []
              if(data.length){
                this.shrdInpt[this.providerscomboName].endData = false;
                for (let i = 0; i < data.length; i++) {
                  this.shrdInpt[this.providerscomboName].comboBoxList.push(`[${data[i].id}] - ${data[i].ProviderName}`);
                  this.shrdInpt[this.providerscomboName].loadedList.push(data[i]);
                 
                }
                this.shrdInpt[this.providerscomboName].comboboxref.addItem(this.shrdInpt[this.providerscomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.providerscomboName].endData = true;
              }
          }else{
            if(data.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.providerscomboName].oldSearchText!=this.shrdInpt[this.providerscomboName].searchText){
                this.shrdInpt[this.providerscomboName].searchList= [];
                //update old value
                this.shrdInpt[this.providerscomboName].oldSearchText = this.shrdInpt[this.providerscomboName].searchText;
              }
              this.shrdInpt[this.providerscomboName].endData = false;
              for (let i = 0; i < data.length; i++) {
                this.shrdInpt[this.providerscomboName].searchList.push(`[${data[i].id}] - ${data[i].ProviderName}`);
              }
              this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.providerscomboName].combBoxEvent.updateData(this.shrdInpt[this.providerscomboName].searchList);
            }else{
              this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.providerscomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.providerscomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.providerscomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }


  /*----------------------when Choosing Provider---------------------- */
  changeSelectedProvider(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let indexofendId = selectedValue.indexOf(']')
      let providerSelected = selectedValue.substring(1,indexofendId)
      let indexofStartName = selectedValue.indexOf('-')
      let providerName = selectedValue.substring(indexofStartName+1,selectedValue.length)
      // console.log("selected name",providerName)
      this.updateform.controls.providerId.setValue(providerSelected)
      this.updateform.controls.providerName.setValue(providerName)
      this.prvoideComboValue = selectedValue;
    }else{
      this.prvoideComboValue = "";
      this.updateform.controls.providerId.setValue("")
      this.updateform.controls.providerName.setValue("")
    }
  }


}
