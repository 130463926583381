import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetClientPricesDataComponent } from './get-client-prices-data/get-client-prices-data.component';

const routes: Routes = [
  { path: 'get-client-prices-data', component: GetClientPricesDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbClientEditPrices.View','ThdbClientEditPrices.Update'], roles:[]} },
  { path: 'client-edit-Prices', component: GetClientPricesDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbClientEditPrices.View','ThdbClientEditPrices.Update'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientEditPricesRoutingModule { }
