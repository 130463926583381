import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BankRoutingModule } from './bank-routing.module';
import { GetBanksComponent } from './get-banks/get-banks.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetBanksComponent
  ],
  imports: [
    CommonModule,
    BankRoutingModule,
    CoreModule,
  ]
})
export class BankModule { }
