import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
        roles: [], 
        permissions: []
    },
    //Hala Menu
    {
        label: 'MENUITEMS.HALA.TEXT',
        icon: 'bx-menu',
        // badge: {
        //     variant: 'info',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        subItems: [
            // HalaReceivedamount menu
            {
                label: 'MENUITEMS.HALA.RecievedAmounts.TEXT',
                link: '/hala/halareceivedamounts/getamounts',
                roles: [], 
                permissions: ['HalaReceivedAmounts.View']
            },
            // HalaBank Menu
            {
                label: 'MENUITEMS.HALA.BANK.TEXT',
                link: '/hala/halabank/getbanks',
                roles: [], 
                permissions: ['HalaBank.View','HalaBank.Create','HalaBank.Update','HalaBank.Delete']
            },
            //HalaProviders Menu
            {
                label: 'MENUITEMS.HALA.Providers.TEXT',
                link: '/hala/halaproviders/getproviders',
                roles: [], 
                permissions: ['HalaProviders.View','HalaProviders.Create','HalaProviders.Update','HalaProviders.Delete']
            },
            //HalaAccounts Menu
            {
                label: 'MENUITEMS.HALA.Accounts.Register',
                link: '/hala/halaaccounts/register',
                roles: [], 
                permissions: ['HalaAccounts.Create']
            },
            //HalaUsers Menu
            {
                label: 'MENUITEMS.HALA.Accounts.Users',
                link: '/hala/halaaccounts/getusers',
                roles: [], 
                permissions: ['HalaAccounts.View','HalaAccounts.Update']
            },
            
            //HalaAdditions Menu
            {
                label: 'MENUITEMS.HALA.Additions.TEXT',
                link: '/hala/halaadditions/getadditions',
                roles: [], 
                permissions: ['ThdbHalaAdditions.View']
            },
            //HalaWithdrawals Menu
            {
                label: 'MENUITEMS.HALA.Withdrawals.TEXT',
                link: '/hala/halawithdrawals/getwithdrawals',
                roles: [], 
                permissions: ['HalaWithdrawals.View','HalaWithdrawals.Create']
            },
        ]
    },
    //Cards Menu
    {
        label: 'MENUITEMS.CARDS.TEXT',
        icon: 'bx-credit-card',
        subItems: [
            //HalaCardsEmployee Menu
            {
                label: 'MENUITEMS.CARDS.EmployeeCards.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.CARDS.EmployeeCards.AddCard',
                        link: '/hala/halamanagecards/sendcards',
                        roles: [], 
                        permissions: ['HalaManageHalaCards.Create','HalaManageHalaCards.Update','HalaManageHalaCards.Delete']
                    },
                    {
                        label: 'MENUITEMS.CARDS.EmployeeCards.CardReport',
                        link: '/hala/halamanagecards/cardsreport',
                        roles: [], 
                        permissions: ['HalaManageHalaCards.View']
                    },
                ]        
            },
            //HalaCardsManger Menu
            {
                label: 'MENUITEMS.CARDS.MangerCards.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.CARDS.MangerCards.AddCardFile',
                        link: '/hala/halamanagecards/sendcardsexcel',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['HalaManageHalaCards.Create']
                    },
                    {
                        label: 'MENUITEMS.CARDS.MangerCards.ApproveCard',
                        link: '/hala/halamanagecards/approvecards',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['HalaManageHalaCards.Update']
                    },
                    {
                        label: 'MENUITEMS.CARDS.MangerCards.MangerCardReport',
                        link: '/hala/halamanagecards/cardsreportmanger',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['HalaManageHalaCards.View','HalaManageHalaCards.Update']
                    },
                    {
                        label: 'MENUITEMS.CARDS.MangerCards.ActivateBatchCards',
                        link: '/hala/halamanagecards/active-batch-cards',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['HalaManageHalaCards.Update']
                    },
                    {
                        label: 'MENUITEMS.CARDS.MangerCards.CardsActivatedReports',
                        link: '/hala/halamanagecards/get-active-cards',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['HalaManageHalaCards.View']
                    },
                    {
                        label: 'MENUITEMS.CARDS.TrxRefund.TEXT',
                        link: '/thuraya/trx-refund/get-refunds',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['ThdbTrxRefund.View']
                    },
                    {
                        label: 'MENUITEMS.CARDS.CardBatches.TEXT',
                        link: '/thuraya/card-batches/get-batches',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['ThdbCardBatches.View']
                    },
                    {
                        label: 'MENUITEMS.CARDS.OutstandingCards.TEXT',
                        link: '/thuraya/outstanding-card/get-outstand-card',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['ThdbOutStandingCards.View']
                    },
                    {
                        label: 'MENUITEMS.CARDS.InventoryMovements.TEXT',
                        link: '/thuraya/inventory-movement/get-movements',
                        roles: ['SuperAdmin','Admin'], 
                        permissions: ['ThdbInventoryMovement.View']
                    },
                ]       
            },
        ]
    },
    //Reports Menu
    {
        label: 'MENUITEMS.REPORTS.TEXT',
        icon: 'bx-file',
        subItems: [
            //Purchase Report Menu
            {
                label: 'MENUITEMS.REPORTS.PurchaseReport.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.REPORTS.PurchaseReport.Purchase.Text',
                        link: '/thuraya/purchases/get-all-purchases-data',
                        roles: [], 
                        permissions: ['ThdbPurchases.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.PurchaseReport.PurchaseRate.Text',
                        link: '/thuraya/purchases/get-all-purchases-rate-data',
                        roles: [], 
                        permissions: ['ThdbPurchasesRatePerDay.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.PurchaseReport.PurchaseTarget.Text',
                        link: '/thuraya/purchases/get-all-purchases-target-data',
                        roles: [], 
                        permissions: ['ThdbPurchasesTarget.View']
                    },
                ]        
            },
            //Sales Report Menu
            {
                label: 'MENUITEMS.REPORTS.SalesReport.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.REPORTS.SalesReport.CompaniesSales.Text',
                        link: '/thuraya/company-sales/get-companies-sales',
                        roles: [], 
                        permissions: ['ThdbCompanySales.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.SalesReport.SalesRate.Text',
                        link: '/thuraya/company-sales/get-all-sales-rate',
                        roles: [], 
                        permissions: ['ThdbSalesRatePerDay.View']
                    },
                ]        
            },
            //Other Report Menu
            {
                label: 'MENUITEMS.REPORTS.Others.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.REPORTS.Others.TargetReport.Text',
                        link: '/thuraya/monthly-target/get-monthly-target',
                        roles: [], 
                        permissions: ['ThdbMonthlyTarget.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.TargetReport1.Text',
                        link: '/thuraya/monthly-target/get-monthly-target-level-1',
                        roles: [], 
                        permissions: ['ThdbMonthlyTarget.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.TargetReport2.Text',
                        link: '/thuraya/monthly-target/get-monthly-target-level-2',
                        roles: [], 
                        permissions: ['ThdbMonthlyTarget.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.PackCardReport.Text',
                        link: '/thuraya/packing-card/get-packing-cards',
                        roles: [], 
                        permissions: ['ThdbPackingCard.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.BillHeader.Text',
                        link: '/big-data/bill/get-bill-header',
                        roles: [], 
                        permissions: ['BigDataBill.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.AccountReport.Text',
                        link: '/thuraya/account-report/get-account-bill-data',
                        roles: [], 
                        permissions: ['ThdbAccountReport.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.POSScratchReport.Text',
                        link: '/thuraya/pos-scratch-report/get-pos-scratch-data',
                        roles: [], 
                        permissions: ['ThdbPosScratchReport.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.EffectivePosReport.Text',
                        link: '/thuraya/effective-pos-report/get-effective-pos-data',
                        roles: [], 
                        permissions: ['ThdbEffectivePosReport.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.AdvancedBulkAccount.Text',
                        link: '/thuraya/advanced-bulk-account/get-advanced-bulk-data',
                        roles: [], 
                        permissions: ['ThdbAdvancedBulkAccount.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.CompanyProfit.Text',
                        link: '/thuraya/company-profit/get-company-profit',
                        roles: [], 
                        permissions: ['ThdbCompanyProfit.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.CustomerSalesDetails.Text',
                        link: '/thuraya/customer-sales-details/get-customer-sales-details',
                        roles: [], 
                        permissions: ['ThdbCustomerSalesDetails.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.AdminMovement.Text',
                        link: '/thuraya/admin-movement/get-admin-data',
                        roles: [], 
                        permissions: ['ThdbAdminMovement.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.GetAdvancedAccountReportData.Text',
                        link: '/thuraya/advanced-account-report-data/get-advanced-account-report-data',
                        roles: [], 
                        permissions: ['ThdbAdvancedAccountReport.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.StartInventoryBalance.Text',
                        link: '/thuraya/start-inv-balance/get-inventory-data',
                        roles: [], 
                        permissions: ['ThdbStartInventoryBalance.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.CurrentStockBalance.Text',
                        link: '/thuraya/current-stock-balance/get-current-stock-data',
                        roles: [], 
                        permissions: ['ThdbCurrentStockBalance.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.OutstandingClassServices.Text',
                        link: '/thuraya/outstanding-class-services/get-outstanding-class-data',
                        roles: [], 
                        permissions: ['ThdbOutstandingClassServices.View','ThdbOutstandingClassServices.Update']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.GamePurchaseInvoice.Text',
                        link: '/thuraya/game-purchase-invoice/get-game-purchase-data',
                        roles: [], 
                        permissions: ['ThdbGamePurchaseInvoices.View',]
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.CardUpdates.Text',
                        link: '/hala/card-update/get-card-updates',
                        roles: [], 
                        permissions: ['HalaCardUpdate.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.DataUpdates.Text',
                        link: '/hala/data-update/get-data-updates',
                        roles: [], 
                        permissions: ['HalaDataUpdate.View']
                    },
                    {
                        label: 'MENUITEMS.REPORTS.Others.JobApplications.Text',
                        link: '/hala/job-application/get-all-job-applications',
                        roles: [], 
                        permissions: ['HalaJobApplication.View']
                    },
                    
                    //TODO:Continue this page
                    // {
                    //     label: 'MENUITEMS.REPORTS.Others.InventoryReport.Text',
                    //     link: '/reports/other-reports/inventory-report',
                    //     roles: [], 
                    //     permissions: []
                    // },
                ]        
            },
        ]
    },
    //System Settings Menu
    {
        label: 'MENUITEMS.SYSTEMSETTINGS.TEXT',
        icon: 'bx-cog',
        subItems: [
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.CompaniesSettings.TEXT',
                link: '/thuraya/companies-settings/get-all-companies',
                roles: [], 
                permissions: ['ThdbCompaniesSettings.View','ThdbCompaniesSettings.Create','ThdbCompaniesSettings.Update','ThdbCompaniesSettings.Delete']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.CreateAccounts.TEXT',
                link: '/thuraya/manage-accounts/get-all-created-accounts',
                roles: [], 
                permissions: ['ThdbManageAccounts.View','ThdbManageAccounts.Create','ThdbManageAccounts.Update']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.GroupsSettings.TEXT',
                link: '/hala/halacompaniesgrouping/get-all-groups',
                roles: [], 
                permissions: ['HalaCompaniesGrouping.View','HalaCompaniesGrouping.Create','HalaCompaniesGrouping.Update','HalaCompaniesGrouping.Delete',]
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.PriceSettings.TEXT',
                link: '/thuraya/price-settings/get-all-price-data',
                roles: [], 
                permissions: ['ThdbPriceSettings.View','ThdbPriceSettings.Create','ThdbPriceSettings.Update']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.IcomingAmounts.TEXT',
                link: '/thuraya/manage-incoming-amounts/get-all-incoming-amounts',
                roles: [], 
                permissions: ['ThdbManageIncomingAmounts.View','ThdbManageIncomingAmounts.Create','ThdbManageIncomingAmounts.Update','ThdbManageIncomingAmounts.Delete']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.IcomingAmountsReport.TEXT',
                link: '/thuraya/manage-incoming-amounts/incoming-amounts-report',
                roles: ['SuperAdmin','Admin'],
                permissions: ['ThdbManageIncomingAmounts.View',]
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.SystemClassSettings.TEXT',
                link: '/thuraya/service-class-settings/get-service-class-data',
                roles: [], 
                permissions: ['ThdbServiceClassSetting.View','ThdbServiceClassSetting.Create','ThdbServiceClassSetting.Update']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.RolesSettings.TEXT',
                link: '/hala/roles/get-roles',
                roles: ['SuperAdmin'], 
                permissions: ['HalaRole.View','HalaRole.Create','HalaRole.Update','HalaRole.Delete']
            },
            {
                label: 'MENUITEMS.SYSTEMSETTINGS.ValidationCode.TEXT',
                link: '/thuraya/otp/validation-code',
                roles: [], 
                permissions: ['ThdbOtp.View']
            },
        ]
    },

    //Profit User Menu
    {
        label: 'MENUITEMS.PROFITUSER.TEXT',
        icon: 'bx-line-chart',
        subItems: [
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompany.TEXT',
                link: '/thuraya/profit-user/profit-user-company',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyCard.TEXT',
                link: '/thuraya/profit-user/profit-user-company-card',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyClient.TEXT',
                link: '/thuraya/profit-user/profit-user-company-client',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyCardClient.TEXT',
                link: '/thuraya/profit-user/profit-user-company-card-client',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserClient.TEXT',
                link: '/thuraya/profit-user/profit-user-client',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyPayments.TEXT',
                link: '/thuraya/profit-user/profit-user-company-payments',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserBalanceTransfer.TEXT',
                link: '/thuraya/profit-user/profit-user-balance-transfers',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },
            {
                label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyInvoice.TEXT',
                link: '/thuraya/profit-user/profit-user-company-invoice',
                roles: [], 
                permissions: ['ThdbProfitUser.View']
            },           
        ]
    },

    //Pins Menu
    {
        label: 'MENUITEMS.PINS.TEXT',
        icon: 'bx-barcode',
        subItems: [
            {
                label: 'MENUITEMS.PINS.BulkOrderCard.TEXT',
                link: '/thuraya/bulk-order-card/get-bulk-order-cards-data',
                roles: [], 
                permissions: ['ThdbBulkOrderCard.Create']
            },    
            {
                label: 'MENUITEMS.PINS.BulkCards.Text',
                link: '/thuraya/bulk-cards/get-bulk-card-header',
                roles: [], 
                permissions: ['ThdbBulkCards.View']
            },
            {
                label: 'MENUITEMS.PINS.PosBulkBill.Text',
                link: '/thuraya/pos-bulk-bill/get-pos-bulk-data',
                roles: [], 
                permissions: ['ThdbPosBulkBill.View']
            },
            {
                label: 'MENUITEMS.PINS.ClientEditPrices.Text',
                link: '/thuraya/client-edit-prices/get-client-prices-data',
                roles: [], 
                permissions: ['ThdbClientEditPrices.View','ThdbClientEditPrices.Update']
            },

           
               
        ]
    },

    //Distributors Menu
    {
        label: 'MENUITEMS.DISTRIBUTORS.TEXT',
        icon: 'bx-user',
        subItems: [
            {
                label: 'MENUITEMS.DISTRIBUTORS.ClientBalances.TEXT',
                link: '/thuraya/distributor-pages/client-balances',
                roles: [], 
                permissions: ['ThdbDistributerPages.View']
            },   
            {
                label: 'MENUITEMS.DISTRIBUTORS.ClientsOfClients.TEXT',
                link: '/thuraya/distributor-pages/clients-of-clients',
                roles: [], 
                permissions: ['ThdbDistributerPages.View']
            }, 
            {
                label: 'MENUITEMS.DISTRIBUTORS.ClientsWithdrawals.TEXT',
                link: '/thuraya/distributor-pages/clients-withdrawals',
                roles: [], 
                permissions: ['ThdbDistributerPages.View']
            },  
            {
                label: 'MENUITEMS.DISTRIBUTORS.UserClients.TEXT',
                link: '/thuraya/distributor-pages/clients-of-user',
                roles: [], 
                permissions: ['ThdbDistributerPages.View']
            },  
            {
                label: 'MENUITEMS.DISTRIBUTORS.PaymentRequestCreditClient.TEXT',
                link: '/thuraya/move-balance-between-accounts/get-movement-data',
                roles: [], 
                permissions: ['ThdbMoveBalanceBetweenAccounts.View']
            },  
            {
                label: 'MENUITEMS.DISTRIBUTORS.ClientInformation.TEXT',
                link: '/thuraya/profit-user/user-summary-data',
                roles: [], 
                permissions: ['ThdbDistributerPages.View']
            }, 
            // ref to same page as in pins tab here different Role only
            {
                label: 'MENUITEMS.PINS.ClientEditPrices.Text',
                link: '/thuraya/client-edit-prices/client-edit-Prices',
                roles: ['Distributor'], 
                permissions: ['ThdbClientEditPrices.View','ThdbClientEditPrices.Update']
            },
            //Reports
            {
                label: 'MENUITEMS.DISTRIBUTORS.Report.TEXT',
                subItems: [
                    {
                        label: 'MENUITEMS.DISTRIBUTORS.Report.POSStatement.Text',
                        link: '/thuraya/distributor-reports/get-invoice-data',
                        roles: [], 
                        permissions: ['ThdbDistributerReports.View']
                    },
                    {
                        label: 'MENUITEMS.DISTRIBUTORS.Report.FixedTermSalesInvoice.Text',
                        link: '/thuraya/distributor-reports/get-all-bill-details',
                        roles: [], 
                        permissions: ['ThdbDistributerReports.View']
                    },
                    
                ]
            }
               
        ]
    },

    //Sadad Menu
    {
        label: 'MENUITEMS.SADAD.TEXT',
        icon: 'bx-money',
        subItems: [
            {
                label: 'MENUITEMS.SADAD.SadadTransactions.TEXT',
                link: '/sadad/sadad-service-api/get-Transactions',
                roles: ['Accountant'], 
                permissions: []
            },
            {
                label: 'MENUITEMS.SADAD.SadadRequests.TEXT',
                link: '/sadad/sadad-service-api/get-requests',
                roles: ['Accountant'], 
                permissions: []
            },
            {
                label: 'MENUITEMS.SADAD.SadadBalance.TEXT',
                link: '/sadad/sadad-service-api/get-sadad-balance',
                roles: ['Accountant'], 
                permissions: []
            },
            
           
        ]
    },
];

