import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BgDataBillService } from 'src/app/core/services/bigdata/bg-data-bill.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-bill-header',
  templateUrl: './get-bill-header.component.html',
  styleUrls: ['./get-bill-header.component.scss']
})
export class GetBillHeaderComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;


  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Details Table variables
  selectedBillId=null;
  detailsDatasource = []
  detailsPageNumber:number = 1;
  detailsPageSize:number = 10;
  detailsTotalCount:number = 0;
  @ViewChild("detailsGrid ") detailsGrid: ElementRef ;

  isExporting:boolean = false

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private bgDataBillService:BgDataBillService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      billNo: this.f.billNo.value,
      customNo: this.f.customNo.value,
      isSend: this.f.isSend.value,
    }
    this.bgDataBillService.GetBillHeader(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم الفاتورة": data[i].BILL_NO,
              "تاريخ الفاتورة":this.datePipe.transform(data[i].BILL_DATE,this.dateFormatService.dateFormat) ,
              "نوع الفاتورة": data[i].BILL_TYPE,
              "المبلغ الاصلي": data[i].BILL_AMT,
              "عدد الكروت": data[i].totalRcrd_No,
              "عمليه الارسال": data[i].SentStatus,
              "نتيجة الارسال": data[i].Message,
              "اسم المندوب": data[i].Customer_Name,
              "رقم العميل": data[i].AD_U_ID,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "المبلغ الاصلي":Number.parseFloat(data[0].SumTotalCost).toFixed(2),
            "عدد الكروت": Number.parseFloat(data[0].SumTotalPrice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      customNo: new FormControl(""),
      billNo: new FormControl(""),
      isSend: new FormControl("")
    });
  }
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getBillHeader() {
    this.spinner.show();
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      billNo: this.f.billNo.value,
      customNo: this.f.customNo.value,
      isSend: this.f.isSend.value,
    }
    return this.bgDataBillService.GetBillHeader(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getBillDetails(data){
    //if clicked from table change selected bill id and reset pagination/ else we are changing pages for the same bill
    if(data){
      this.selectedBillId = data.Id
      this.detailsPageNumber = 1
      this.detailsTotalCount = 0
      this.detailsDatasource = []
    }
    this.spinner.show(); 
    let body={
      pageNumber: this.detailsPageNumber,
      pageSize: this.detailsPageSize,
      isPaginated: true,
      billId: this.selectedBillId 
    }
    return this.bgDataBillService.GetBillDetails(body).subscribe((result) => {
      

      if(result.IsSuccess && result.Data.resultList.length>0){
        this.detailsDatasource = result.Data.resultList
        this.detailsTotalCount = result.Data.resultList[0].TotalRows;

        let scope = this
        setTimeout(function(){scope.detailsGrid.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }); },100);
      }
      if(result.Data.resultList.length==0){
        this.detailsDatasource = []
        this.detailsTotalCount = 0
        this.detailsPageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.detailsPageNumber = 1
    this.detailsDatasource = []
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getBillHeader()
    } 
  }

  reset(){
    this.initForm()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.datasource =[] 
    this.detailsDatasource =[] 
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.detailsPageNumber = 1
    this.totalCount = 0
    this.detailsTotalCount = 0
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getBillHeader();
  }

  /*----------------------Pagination---------------------- */
  changePageDetails(event){
    this.detailsPageNumber = event
    this.getBillDetails(null);
  }

}
