import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HalaProvidersService } from 'src/app/core/services/hala/hala-providers.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-get-providers',
  templateUrl: './get-providers.component.html',
  styleUrls: ['./get-providers.component.scss']
})
export class GetProvidersComponent implements OnInit {

  //Table Variables
  datasource:[] = [];
  pageNo:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Filter Variables
  filterForm: FormGroup;
  showingFilteredData:boolean = false;
 

  constructor(
    private fb: FormBuilder,
    private halaProvidersService:HalaProvidersService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    //ng pagination on init calls page change so no need to getAllProviders here
    // this.getAllProviders();
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.filterForm = this.fb.group({
      id: new FormControl(""),
    });
  }
 
  // convenience getter for easy access to form fields
  get f() {
  return this.filterForm.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getAllProviders() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNo,
      pageSize: this.pageSize,
      isPaginated: true
    }
    this.halaProvidersService.GetAllProvidersData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows
          // this.ref.detectChanges();
        }
        this.spinner.hide(); 
      },(err)=>{
        this.spinner.hide(); 
      }
    )
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNo = event;
    this.getAllProviders();
    // console.log("pagechange",event)
  }

  /*----------------------Filters---------------------- */
  resetFilters(){
    this.initForm();
    this.showingFilteredData= false;
    //Reset Pagination
    this.pageNo = 1;
    this.getAllProviders();
  }

}
