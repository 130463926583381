import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PackingCardRoutingModule } from './packing-card-routing.module';
import { GetPackingCardsComponent } from './get-packing-cards/get-packing-cards.component';
import { PackingTableComponent } from './get-packing-cards/_components/packing-table/packing-table.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetPackingCardsComponent,
    PackingTableComponent,
  ],
  imports: [
    CommonModule,
    PackingCardRoutingModule,
    CoreModule
  ]
})
export class PackingCardModule { }
