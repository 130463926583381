import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-purchase-target-table',
  templateUrl: './purchase-target-table.component.html',
  styleUrls: ['./purchase-target-table.component.scss']
})
export class PurchaseTargetTableComponent implements OnInit {


  @Input() dataSource;
  
  public toolbarOptions: ToolbarItems[] | object;
  @ViewChild("grid") public grid: GridComponent;
  public aggreagtes: Object;
  public pageSettings: Object;

  constructor(
    public languageService:LanguageService,
  ) { }

  ngOnInit(): void {
    this.toolbarOptions = [
      "ExcelExport",
      "Print"
    ];
    this.pageSettings={
      pageSize:10
    }
    this.aggreagtes = [
      {
        columns: [
          {
            type: "Sum",
            field: "TotalCost",
            format: "N2",

          },
          {
            type: "Sum",
            field: "TotalPrice",
            format: "N2",
          },
        ],
      },
    ];
  }

  /*----------------------Grid Function---------------------- */
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

}
