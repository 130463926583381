import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bill-details-table',
  templateUrl: './bill-details-table.component.html',
  styleUrls: ['./bill-details-table.component.scss']
})
export class BillDetailsTableComponent implements OnInit {

  @Input() datasource;
  @Input() sumQty;
  @Input() sumTotal;

  constructor() { }

  ngOnInit(): void {
  }

}
