import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientEditPricesRoutingModule } from './client-edit-prices-routing.module';
import { GetClientPricesDataComponent } from './get-client-prices-data/get-client-prices-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { ClientPriceTableComponent } from './get-client-prices-data/_components/client-price-table/client-price-table.component';


@NgModule({
  declarations: [
    GetClientPricesDataComponent,
    ClientPriceTableComponent
  ],
  imports: [
    CommonModule,
    ClientEditPricesRoutingModule,
    CoreModule
  ]
})
export class ClientEditPricesModule { }
