<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">

            <!-- Service Id (only if creating)-->
            <div class="col-lg-6 mb-3" *ngIf="!isEditing">
                <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
            </div>

            <!-- Class No (only if creating) -->
            <div class="col-lg-6 mb-3" *ngIf="!isEditing">
                <label for="validationCustom01" class="required"> {{'Common.ClassNo'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="classDetails.class_no"
                    type="text" formControlName="class_no" >
                <!-- <div *ngIf="form.controls.class_no.touched && form.controls.class_no.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div> -->
            </div>
            <!-- Class Name (only if creating) -->
            <div class="col-lg-6 mb-3" *ngIf="!isEditing">
                <label for="validationCustom01" class="required"> {{'Common.ClassName'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="classDetails.class_name"
                    type="text" formControlName="class_name" >
                <!-- <div *ngIf="form.controls.class_name.touched && form.controls.class_name.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div> -->
            </div>

             <!-- Class Type-->
             <div class="col-lg-6 mb-3">
                <label class="required"> {{'Common.ClassType'|translate}}:</label>
                <select formControlName="class_type" class="form-select" [(ngModel)]="classDetails.class_type">
                    <option style="font-size: 16px !important" value="">--{{'Common.Choose'|translate}}--</option>
                    <option style="font-size: 16px !important" value="1"> {{'Common.Data'|translate}}</option>
                    <option style="font-size: 16px !important" value="0"> {{'Common.Voice'|translate}}</option>
                    <!-- <option style="font-size: 16px !important" value="5"> {{'Common.FinalClient'|translate}}</option> -->
                </select>
                <!-- <div *ngIf="form.controls.class_type.touched && form.controls.class_type.errors?.required"
                    class="invalid-input">
                    {{'Common.ClientTypeReq'|translate}}
                </div> -->
            </div>

            <!-- Class Price -->
            <div class="col-lg-6 mb-3">
                <label for="validationCustom01" class="required"> {{'Common.ClassPrice'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="classDetails.class_price"
                    type="text" formControlName="class_price" >
                <!-- <div *ngIf="form.controls.class_price.touched && form.controls.class_price.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div> -->
            </div>

            <!-- Class Cost -->
            <div class="col-lg-6 mb-3">
                <label for="validationCustom01" class="required"> {{'Common.ClassCost'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="classDetails.class_cost"
                    type="text" formControlName="class_cost" >
                <!-- <div *ngIf="form.controls.class_cost.touched && form.controls.class_cost.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div> -->
            </div>
            
           
            <!-- Class Status -->
            <div class="col-lg-6 mb-3">
                <label class="required"> {{'Common.Status'|translate}}:</label>
                <select formControlName="status" class="form-select" [(ngModel)]="classDetails.status">
                    <option style="font-size: 16px !important" value="">--{{'Common.Choose'|translate}}--</option>
                    <option style="font-size: 16px !important" value="1"> {{'Common.Active'|translate}}</option>
                    <option style="font-size: 16px !important" value="2"> {{'Common.Stopped'|translate}}</option>
                </select>
                <!-- <div *ngIf="form.controls.status.touched && form.controls.status.errors?.required"
                    class="invalid-input">
                    {{'Common.ClientStatusReq'|translate}}
                </div> -->
            </div>

             <!-- Product Id -->
             <div class="col-lg-6 mb-3">
                <label for="validationCustom01" class="required"> {{'Common.ProductId'|translate}} :</label>
                <p class="form-control" *ngIf="isEditing" aria-disabled="true">{{classDetails.product_id}} </p>
                <input *ngIf="!isEditing" class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                    type="text" formControlName="product_id" >
                <!-- <div *ngIf="form.controls.product_id.touched && form.controls.product_id.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div> -->
            </div>

            <!-- Action Buttons -->
            <div class="d-flex justify-content-end mt-3">
                <div>
                    <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="form.invalid" (click)="createUpdateServiceClassData()">{{ isEditing ? ('Common.Edit'|translate) : ('Common.Add'|translate) }}</button>
                    <button type="button" class="btn btn-light" (click)="discard()">{{'Common.Discard'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>