<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <!-- First Row -->
                <div class="form-group row">
                    <!-- Client (Signedin name and phone)-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.ClientName'|translate}}:</label>
                        <input class="form-control" disabled formControlName="account_id" [(ngModel)]="authUser">
                    </div>
                    <!-- Clients controlled by the signedin client (subordinbates)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsCompanyPaymentsPage || IsClientPage">
                        <label>{{'Common.Subordinates'|translate}} :</label>
                        <ejs-dropdownlist showClearButton="true" #distributorCombo formControlName="endPointId" id='diacritics' [value]='value' [fields]='posFields'
                        [popupHeight]='height' [dataSource]='posDataList'
                        [allowFiltering]='posDataList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                        filterBarPlaceholder='{{"Common.Search"|translate}}'  #sample
                        (filtering)='onFiltering($event)'>
                        </ejs-dropdownlist>                                                   
                    </div>
                    <!-- Service-->
                    <div class="col-lg-3 mb-3" *ngIf="IsCompanyPage || IsCompanyCardPage || IsCompanyClientPage || IsCompanyCardClientPage">
                        <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                    </div>
                    <!-- Card Name-->
                    <div class="col-lg-3 mb-3" *ngIf="IsCompanyCardPage || IsCompanyCardClientPage">
                        <label>{{'Common.CardName'|translate}}:</label>
                        <select formControlName="class_name" class="form-select">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_name }}">
                            [{{ item.class_no }}] - {{ item.class_name }}
                            </option>
                        </select>
                    </div>
                    <!-- Branch (only in company payments page)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsCompanyPaymentsPage">
                        <label>{{'Common.Branch'|translate}}:</label>
                        <select formControlName="address" class="form-select">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of branchesList" value="{{ item.address }}">
                            {{ item.address }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- Second Row (Times/Dates) -->
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Time From -->
                    <div class="col-lg-2 mb-3" *ngIf="!IsCompanyInvoicePage">
                        <label>{{'Common.TimeFrom'|translate}}:</label>
                        <input class="form-control" formControlName="timeFrom" type="time" />
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Time To -->
                    <div class="col-lg-2 mb-3"  *ngIf="!IsCompanyInvoicePage">
                        <label>{{'Common.TimeTo'|translate}}:</label>
                        <input class="form-control" formControlName="timeTo" type="time" />
                    </div>
                    <!-- Action Buttons -->
                    <div class="col-lg-2 mb-3 d-flex align-items-end">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Excel Export and print report -->
<div class="d-flex justify-content-end">
    <div class="me-3">
        <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
    </div>
    <app-print-report [datasource]="datasource" [isPrinting]="isPrinting" (printReport)="printReport()"></app-print-report>
</div>



<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <!-- First Table -->
    <app-company-table *ngIf="IsCompanyPage || IsCompanyCardPage 
        || IsCompanyClientPage || IsCompanyCardClientPage 
        || IsClientPage " 
        [datasource]="datasource" 
        [IsCompanyPage]="IsCompanyPage" 
        [IsCompanyCardPage]="IsCompanyCardPage" 
        [IsCompanyClientPage]="IsCompanyClientPage" 
        [IsCompanyCardClientPage]="IsCompanyCardClientPage" 
        [IsClientPage]="IsClientPage" >
    </app-company-table>
   
    <!-- Second Table -->
    <app-company-payment-table *ngIf="IsCompanyPaymentsPage || IsBalanceTransferPage || IsCompanyInvoicePage" 
        [datasource]="datasource" 
        [IsCompanyPaymentsPage]="IsCompanyPaymentsPage" 
        [IsBalanceTransferPage]="IsBalanceTransferPage"
        [IsCompanyInvoicePage]="IsCompanyInvoicePage">
    </app-company-payment-table>

    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>