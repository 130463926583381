import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OutstandingClassServicesRoutingModule } from './outstanding-class-services-routing.module';
import { GetOutstandingClassDataComponent } from './get-outstanding-class-data/get-outstanding-class-data.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetOutstandingClassDataComponent
  ],
  imports: [
    CommonModule,
    OutstandingClassServicesRoutingModule,
    CoreModule
  ]
})
export class OutstandingClassServicesModule { }
