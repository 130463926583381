import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private permissionService: PermissionService,
        private router: Router,
    ) { }

    
    canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{
        let token = localStorage.getItem("defaultAppToken");

        if (token) {
            //Check if token expired
            if(!this.authenticationService.tokenExpired(token)){
                //check the permission/Role of the route
                if(this.permissionService.checkRoutePermissions(next) && this.permissionService.checkRouteRole(next)){
                    return true;
                }else{
                    //if does not have permssion for this page redirect to /
                    this.router.navigate(["/"]);
                    return true
                }
            }
        }
        // not logged in so redirect to login page with the return url
        this.authenticationService.logout();
        return false;
    }
}
