<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.RowNo'| translate}}</th>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'Common.CardName'| translate}}</th>
          <th class="align-middle">{{'Common.AddType'| translate}}</th>
          <th class="align-middle">{{'Common.TotalCost'| translate}} </th>
          <th class="align-middle">{{'Common.TotalProfit'| translate}} </th>
          <th class="align-middle">{{'Common.OperationDate'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.row_no}}</a>
          </td>
          <td>{{data.service_id}}</td>
          <td>{{data.class_name}}</td>
          <td>{{data.source_type}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.class_price}}</td>
          <td>{{data.exp_date | date:dateFormatService.dateFormat}}</td>
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.TotalValue'| translate}}</span> 
            <span>{{totalAmount==0 ? '-' :totalAmount | number : '1.0-2'}}</span>
          </td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.TotalCost'| translate}}</span> 
            <span>{{totalCost==0 ? '-' :totalCost | number : '1.0-2'}}</span>
          </td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.Amount'| translate}}</span> 
            <span>{{amount==0 ? '-' :amount | number : '1.0-2'}}</span>
          </td>
        </tr> -->
      </tbody>
    </table>
</div>