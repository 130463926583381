import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPriceSettingsService } from 'src/app/core/services/thuraya/th-price-settings.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThClientEditPricesService } from 'src/app/core/services/thuraya/th-client-edit-prices.service';

@Component({
  selector: 'app-get-client-prices-data',
  templateUrl: './get-client-prices-data.component.html',
  styleUrls: ['./get-client-prices-data.component.scss']
})
export class GetClientPricesDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  dataSource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
    
  isExporting:boolean = false;

  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private thClientEditPricesService: ThClientEditPricesService,
    private msgTranlsateService:MessageTranslateService,
    private excelService:ExcelService,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      service_id: this.f.ServiceId.value,
    }
    this.thClientEditPricesService.GetClientEditPricesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "اسم الفئة": data[i].class_name,
              "التكلفة": data[i].class_cost,
              "سعر المستخدم النهائى": data[i].class_price,
              "السعر": data[i].end_point_amount,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl("",Validators.required),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getClientEditPricesData() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
    }
    this.thClientEditPricesService.GetClientEditPricesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.dataSource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }else{
          this.dataSource = []
          this.totalCount = 0;
          this.pageNumber = 1
          this.toastr.error(this.msgTranlsateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.dataSource = []
        this.totalCount = 0;
        this.pageNumber = 1
        this.spinner.hide(); 
      }
    )
  }

  updatePrice(data){
    Swal.fire({
      input: "text",
      inputLabel: this.msgTranlsateService.msgTranslate('Common.UpdatePrice'),
      inputValue: "",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranlsateService.msgTranslate('Common.Update'),
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        let newValue = parseFloat(value);
        let cost = parseFloat(data.class_cost);
        let price = parseFloat(data.class_price);
        if (!value || isNaN(newValue)) {
          return this.msgTranlsateService.msgTranslate('SwalMessages.PriceReq')
        }
        else if( newValue <= cost || newValue > price){
          return this.msgTranlsateService.msgTranslate('SwalMessages.PriceInvalid')
        }
      },
      preConfirm: (value) => {
        let body = {
          class_no : data.class_no,
          service_id : this.f.ServiceId.value,
          end_point_amount: value,
        }
        this.thClientEditPricesService.updateClientEditPrice(body).subscribe(
          (result)=>{
            if(result.IsSuccess){
              this.dataSource.filter(d=>d.row_no == data.row_no)[0].end_point_amount = value
              this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
                positionClass: 'toast-bottom-left',
              });
            }else{
              this.toastr.success(result.Data, "",{
                positionClass: 'toast-bottom-left',
              });
            }
          },(err)=>{
            this.toastr.error(err.error.title, "",{
              positionClass: 'toast-bottom-left',
            });
          }
        )
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    //as change page get called on init we do not want to get data if no search made
    if(this.dataSource.length!=0){
      this.getClientEditPricesData();
    }
  }

  /*----------------------Filter---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getClientEditPricesData()
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.pageNumber = 1
    this.dataSource = []
    this.totalCount =0
  }


  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    this.form.controls.ServiceId.setValue(event)
  }

}
