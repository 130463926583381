import { Component, OnInit , Input} from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-purchase-table',
  templateUrl: './purchase-table.component.html',
  styleUrls: ['./purchase-table.component.scss']
})
export class PurchaseTableComponent implements OnInit {

  @Input() datasource;
  @Input() totalAmount;
  @Input() totalCost;
  @Input() amount;

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
