import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManagecardsRoutingModule } from './managecards-routing.module';
import { SendCardsComponent } from './send-cards/send-cards.component';
import { CoreModule } from 'src/app/core/core.module';
import { ApproveCardsComponent } from './approve-cards/approve-cards.component';
import { CardsTableComponent } from './approve-cards/_components/cards-table/cards-table.component';
import { SendCardsExcelComponent } from './send-cards-excel/send-cards-excel.component';
import { CardEditDialogComponent } from './approve-cards/_components/card-edit-dialog/card-edit-dialog.component';
import { GetActiveCardsHeaderComponent } from './get-active-cards-header/get-active-cards-header.component';
import { ActiveCardsTableComponent } from './get-active-cards-header/_components/active-cards-table/active-cards-table.component';


@NgModule({
  declarations: [
    SendCardsComponent,
    ApproveCardsComponent,
    CardsTableComponent,
    SendCardsExcelComponent,
    CardEditDialogComponent,
    GetActiveCardsHeaderComponent,
    ActiveCardsTableComponent
  ],
  imports: [
    CommonModule,
    ManagecardsRoutingModule,
    CoreModule
  ]
})
export class ManagecardsModule { }
