import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvancedBulkAccountRoutingModule } from './advanced-bulk-account-routing.module';
import { GetAdvancedBulkDataComponent } from './get-advanced-bulk-data/get-advanced-bulk-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { AdvancedBulkTableComponent } from './get-advanced-bulk-data/_components/advanced-bulk-table/advanced-bulk-table.component';


@NgModule({
  declarations: [
    GetAdvancedBulkDataComponent,
    AdvancedBulkTableComponent
  ],
  imports: [
    CommonModule,
    AdvancedBulkAccountRoutingModule,
    CoreModule
  ]
})
export class AdvancedBulkAccountModule { }
