import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PriceSettingsRoutingModule } from './price-settings-routing.module';
import { GetPriceDataComponent } from './get-price-data/get-price-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { PricesTableComponent } from './get-price-data/_components/prices-table/prices-table.component';
import { CopyDialogComponent } from './get-price-data/_components/copy-dialog/copy-dialog.component';


@NgModule({
  declarations: [
    GetPriceDataComponent,
    PricesTableComponent,
    CopyDialogComponent
  ],
  imports: [
    CommonModule,
    PriceSettingsRoutingModule,
    CoreModule
  ]
})
export class PriceSettingsModule { }
