import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetCardUpdatesComponent } from './get-card-updates/get-card-updates.component';

const routes: Routes = [
  { path: 'get-card-updates', component: GetCardUpdatesComponent,canActivate: [AuthGuard],  data: {permission: ['HalaCardUpdate.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardUpdateRoutingModule { }
