import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { ThCompanySalesService } from 'src/app/core/services/thuraya/th-company-sales.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThSalesRateService } from 'src/app/core/services/thuraya/th-sales-rate.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-get-company-sales',
  templateUrl: './get-company-sales.component.html',
  styleUrls: ['./get-company-sales.component.scss']
})
export class GetCompanySalesComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR Companies Sales Report AND sales rate so we decide from route and with these varibale we change logic
  IsComapniesSalesComponent:boolean = false;
  IsSalesRateComponent:boolean = false;

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp;
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private fb: FormBuilder,
    private thCompanySalesService:ThCompanySalesService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    private excelService:ExcelService,
    private router: Router,
    private thGeneralSystemService:ThGeneralSystemService,
    private thSalesRateService: ThSalesRateService,
    private spinner: NgxSpinnerService
   ) { 

      //[IMPORTANT] This component IS USED FOR Companies Sales Report AND sales rate
      // console.log("route",this.router.url)
      if(this.router.url=="/thuraya/company-sales/get-companies-sales"){
        this.IsComapniesSalesComponent = true;
        this.IsSalesRateComponent = false;
      }else if(this.router.url=="/thuraya/company-sales/get-all-sales-rate"){
        this.IsComapniesSalesComponent = false;
        this.IsSalesRateComponent = true;
      }
    }

  ngOnInit(): void {
    this.initForm();
  } 
    

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    //Export case comapnies sales report page
    if(this.IsComapniesSalesComponent){
      const subscr =  this.thCompanySalesService.GetCompanySales(body).subscribe(
        (result)=>{
          if(result.IsSuccess && result.Data.resultList.length>0){
            let data = result.Data.resultList
            for (let i = 0; i < data.length; i++) {          
              let sheet={ 
                "الشركة": data[i].ServiceId,
                "عدد الكروت": data[i].CardNO,
                "التكلفة": data[i].Cost,
                "سعر الموزع": data[i].SellPrice,
                "الربح": data[i].Profit,
            }
              this.excelService.addToSheet(sheet);
            }
            let total={ 
              "عدد الكروت": Number.parseFloat(result.Data.TotalCardsNo).toFixed(2),
              "التكلفة":Number.parseFloat(result.Data.TotalCost).toFixed(2),
              "سعر الموزع":Number.parseFloat(result.Data.TotalSellPrice).toFixed(2),
              "الربح":Number.parseFloat(result.Data.TotalProfit).toFixed(2),
            }
            this.excelService.addToSheet(total);
            this.excelService.excelExport();
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.subscriptions.push(subscr);
    }
    //Export case sales rate page
    else if(this.IsSalesRateComponent){
      const subscr =  this.thSalesRateService.GetAllSalesRatePerDayData(body).subscribe(
        (result)=>{
          if(result.IsSuccess && result.Data.length>0){
            let data = result.Data
            for (let i = 0; i < data.length; i++) {            
              let sheet={ 
                "مقدم الخدمة": data[i].service_id,
                "اسم الكرت": data[i].note,
                "عدد الكروت": data[i].count,
                "التكلفة": data[i].cost_amount,
                "اجمالي التكلفة":data[i].TotalCost,
                "اجمالي سعر البيع":data[i].TotalSellPrice,
                "اجمالي نقطه البيع":data[i].TotalPointPrice,
                "عدد الايام":data[i].DiffDays,
                "المعدل":data[i].Rate
              }
              this.excelService.addToSheet(sheet);
            }
            let total={ 
              "اجمالي التكلفة":Number.parseFloat(data[0].SumTotalCost).toFixed(2),
              "اجمالي سعر البيع":Number.parseFloat(data[0].SumTotalSellPrice).toFixed(2),
              "اجمالي نقطه البيع": Number.parseFloat(data[0].SumTotalPointPrice).toFixed(2),
            }
            this.excelService.addToSheet(total);
            this.excelService.excelExport();
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.subscriptions.push(subscr);
    }

    
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      //needed for sales rate page
      ServiceId: new FormControl(""),
      class_no: new FormControl(""),
    });
  }

  get f(){
    return this.form.controls;
  }
  /*----------------------API Requests---------------------- */
  getCompanySales() {
    this.spinner.show();
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thCompanySalesService.GetCompanySales(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList;
        this.totalCount = result.Data.resultList[0].TotalRows
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getAllSalesRatePerDayData(){
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thSalesRateService.GetAllSalesRatePerDayData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data;
        this.totalCount = result.Data[0].TotalRows
      }
      else if(result.Data.length==0){
        this.datasource = []
        this.pageNumber = 1;
        this.totalCount = 0;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }else{
        this.datasource = [];
        this.pageNumber = 1;
        this.totalCount = 0;
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    });
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.IsComapniesSalesComponent ? this.getCompanySales() : this.getAllSalesRatePerDayData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.IsComapniesSalesComponent ? this.getCompanySales() : this.getAllSalesRatePerDayData()
    } 
  }

  reset(){
    this.initForm()
    this.IsSalesRateComponent && this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.IsComapniesSalesComponent ? this.getCompanySales() : this.getAllSalesRatePerDayData()
  }

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

}
