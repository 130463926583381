import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReceivedamountsRoutingModule } from './receivedamounts-routing.module';
import { GetAmountsComponent } from './get-amounts/get-amounts.component';
import { AmountsTableComponent } from './get-amounts/_components/amounts-table/amounts-table.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    GetAmountsComponent,
    AmountsTableComponent,
  ],
  imports: [
    CommonModule,
    ReceivedamountsRoutingModule,
    CoreModule
  ]
})
export class ReceivedamountsModule { }
