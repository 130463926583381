import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfitUserRoutingModule } from './profit-user-routing.module';
import { ProfitUserDataComponent } from './profit-user-data/profit-user-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { CompanyTableComponent } from './profit-user-data/_components/company-table/company-table.component';
import { CompanyPaymentTableComponent } from './profit-user-data/_components/company-payment-table/company-payment-table.component';
import { UserSummaryDataComponent } from './user-summary-data/user-summary-data.component';


@NgModule({
  declarations: [
    ProfitUserDataComponent,
    CompanyTableComponent,
    CompanyPaymentTableComponent,
    UserSummaryDataComponent
  ],
  imports: [
    CommonModule,
    ProfitUserRoutingModule,
    CoreModule
  ]
})
export class ProfitUserModule { }
