import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetMonthlyTargetComponent } from './get-monthly-target/get-monthly-target.component';

const routes: Routes = [
  { path: 'get-monthly-target', component: GetMonthlyTargetComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbMonthlyTarget.View'], roles:[]} },
  { path: 'get-monthly-target-level-1', component: GetMonthlyTargetComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbMonthlyTarget.View'], roles:[]} },
  { path: 'get-monthly-target-level-2', component: GetMonthlyTargetComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbMonthlyTarget.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonthlyTargetRoutingModule { }
