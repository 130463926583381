<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.Phone'| translate}} </th>
            <th class="align-middle">{{'Common.POS'| translate}} </th>
            <th class="align-middle">{{'Common.Balance'| translate}}</th>
            <th class="align-middle">{{'Common.SaleSum'| translate}} </th>
            <th class="align-middle">{{'Common.Address'| translate}} </th>
            <th class="align-middle">rt1</th>
            <th class="align-middle">rt2</th>
            <th class="align-middle">{{'Common.TaxNumber'| translate}}</th>
            <th class="align-middle">{{'Common.CommercialNumber'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.CUSTOMER_ID}}</a>
          </td>
          <td>{{data.CUSTOMER_NAME}}</td>
          <td>{{data.balance}}</td>
          <td>{{data.SumPointPrice}}</td>
          <td>{{data.cust_address}}</td>
          <td>{{data.rt1}}</td>
          <td>{{data.rt2}}</td>
          <td>{{data.rt3}}</td>
          <td>{{data.rt4}}</td>
        </tr>
      </tbody>
    </table>
</div>