<form class="form" [formGroup]="dateForm">
    <div class="input-group date">
        <input class="form-control date-Selector" placeholder="" autocomplete="off" name="hidden" type="text" name="dp" 
        ngbDatepicker formControlName="dateSelected" #fd="ngbDatepicker" (click)="fd.toggle()" [readOnly]="true" (dateSelect)="onDateSelect($event)">
        <div class="input-group-append" *ngIf="f.dateSelected.value!=''">
            <span class="input-group-text">
              <i class="fa fa-close" role="button" (click)="clearDate()"></i>
            </span>
        </div>
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-calendar-day" role="button" (click)="fd.toggle()"></i>
          </span>
        </div>
    </div>
</form>