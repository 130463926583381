<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ClientPhone'| translate}}</th>
          <th class="align-middle">{{'Common.Notice'| translate}} </th>
          <th class="align-middle">{{'Common.AddedAmount'| translate}} </th>
          <th class="align-middle">{{'Common.WithdrawnAmount'| translate}} </th>
          <th class="align-middle">{{'Common.AddedBy'| translate}} </th>
          <th class="align-middle">{{'Common.Date'| translate}} </th>
          <th *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Update'])" class="align-middle">{{'Common.Edit'| translate}} </th>
          <th *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Delete'])" class="align-middle">{{'Common.Delete'| translate}} </th>
          <th *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Update']) " class="align-middle">{{'Common.Approve'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.cust_account_id}}</a>
          </td>
          <td>{{data.trans_note}}</td>
          <td>{{data.credit_amount}}</td>
          <td>{{data.debit_amount}}</td>
          <td>{{data.edit_user}}</td>
          <td>{{data.edit_date | date: dateFormatService.dateFormat}}</td>
          <td *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Update'])"> <i class='mdi mdi-pencil font-size-18 text-info' (click)="openEditDialog(data)"></i></td>
          <td *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Delete'])"> <i class='mdi mdi-delete font-size-18 text-danger' (click)="showAlertDelete(data)"></i></td>
          <td *ngIf="!IsIncomingAmountsReportComponent && permissionService.checkPermission(['ThdbManageIncomingAmounts.Update'])"> <i class='fa-regular fa-circle-check font-size-18 text-success' (click)="showAlertSend(data)"></i></td>
        </tr>
      </tbody>
    </table>
</div>