import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThManageAccounts } from 'src/app/core/services/thuraya/th-manage-accounts';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { AddAccountComponent } from './_components/add-account/add-account.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-get-all-created-accounts',
  templateUrl: './get-all-created-accounts.component.html',
  styleUrls: ['./get-all-created-accounts.component.scss']
})
export class GetAllCreatedAccountsComponent implements OnInit {

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Table Variables
  datasource = [];
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("companiesDropDown") companiesDropDown;

  constructor(
    private fb: FormBuilder,
    private thGeneralSystemService:ThGeneralSystemService,
    private thManageAccounts: ThManageAccounts,
    private spinner: NgxSpinnerService,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private modalService: NgbModal,
    public permissionService:PermissionService,
    private datePipe:DatePipe,
    private dateFormatService:DateFormatService,
    ) { }

  ngOnInit(): void {
     this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalBalance')], icon: 'money-bill-wave', iconColor: '#4f0091', amount:[0,1], endText:[this.msgTranslateService.msgTranslate('Common.Rial'),this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalDistributors'),this.msgTranslateService.msgTranslate('Common.DistributorsBalance')], icon: 'user-friends', iconColor: '#1bc5bd', amount:[0,0],endText:[this.msgTranslateService.msgTranslate('Common.Distributor'),this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalAgents'),this.msgTranslateService.msgTranslate('Common.AgentsBalance')], icon: 'user-tie', iconColor: '#181c32',amount:[0,0], endText:[this.msgTranslateService.msgTranslate('Common.Agent'),this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalPOS'),this.msgTranslateService.msgTranslate('Common.POSBalance')], icon: 'cash-register', iconColor: '#181c32',amount:[0,0], endText:[this.msgTranslateService.msgTranslate('Common.POS'),this.msgTranslateService.msgTranslate('Common.Rial')]},
    ];
    this.initForm();
    this.getAcountsDashboard()
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    company_no: new FormControl(""),
    agentAccountId: new FormControl(""),
    distAccountId: new FormControl(""),
    user_type: new FormControl(""),
    status: new FormControl(""),
    accountId: new FormControl(""),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      user_type: this.f.user_type.value,
      status: this.f.status.value,
      agentAccountId:this.f.agentAccountId.value ,
      distAccountId: this.f.distAccountId.value,
      company_no: this.f.company_no.value,
      accountId: ""
    }
    this.thManageAccounts.GetAllCreatedAccountsCompany(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "نوع العميل": data[i].account_type,
              "رقم العميل": data[i].CUSTOMER_ID,
              "اسم العميل	": data[i].CUSTOMER_NAME,
              "تاريخ الانشاء": this.datePipe.transform(data[i].last_login_time,this.dateFormatService.dateFormat),
              // "رقم الهاتف": data[i].PHONE_NO,
              "حاله العميل": data[i].status,
              "العنوان": data[i].cust_address,
              "1العنوان": data[i].rt1,
              "2العنوان": data[i].rt2,
              "البريد الالكترونى": data[i].Email,
              "رقم الشركة": data[i].company_no,
              "رقم المسؤل": data[i].parentNo,
              "اسم المسؤل": data[i].parentName,
              "البطاقة الضريبية": data[i].rt3,
              "السجل التجاري": data[i].rt4,
              "الرصيد": data[i].balance,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------API REQUESTS---------------------- */
  getAcountsDashboard(){
    this.thManageAccounts.GetAcountsDashboard().subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data){
         this.infoList[0].amount[0] = result.Data.totalBalance
         this.infoList[1].amount[0] = result.Data.DistributorsNo
         this.infoList[1].amount[1] = result.Data.DistributorTotal
         this.infoList[2].amount[0] = result.Data.RepresentativesNo
         this.infoList[2].amount[1] = result.Data.RepresentativeTotal
         this.infoList[3].amount[0] = result.Data.PosNo
         this.infoList[3].amount[1] = result.Data.PosTotal
        }else {
        }
      },(err)=>{
      }
    )
  }
  
  getAllCreatedAccountsCompany() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      account_type: this.f.user_type.value,
      status: this.f.status.value,
      agentAccountId:this.f.agentAccountId.value ,
      distAccountId: this.f.distAccountId.value,
      company_no: this.f.company_no.value,
      accountId: this.f.accountId.value
    }
    this.thManageAccounts.GetAllCreatedAccountsCompany(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows
          // this.ref.detectChanges();
        }else if(result.Data.length==0){
          this.datasource = []
          this.totalCount = 0;
          this.pageNumber = 1
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.spinner.hide(); 
      }
    )
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getAllCreatedAccountsCompany();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getAllCreatedAccountsCompany()
  
  }

  reset(){
    this.initForm()
    this.companiesDropDown.reset()
    this.agentsCombo.clear();
    this.distributorCombo.clear();
    this.pageNumber = 1
    this.getAllCreatedAccountsCompany()
  }

  /*----------------------Add modal---------------------- */
  addAccount() {
    let modalRef = this.modalService.open(AddAccountComponent, { centered: true,  size: 'lg', });
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdata",newData)
      if(this.datasource.length==10){
        //drop last element to insert new at top
        this.datasource.pop();
        this.datasource.unshift(newData)
      }
    })
  }

  /*----------------------ng Dropdown list variables ---------------------- */
  //Combox variable
  @ViewChild('distributorCombo') public distributorCombo: ComboBoxComponent
  @ViewChild('agentsCombo') public agentsCombo: ComboBoxComponent
  agentsList:any = []
  public agentFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };
  distributorsList:any = []
  public distrFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };

  /*----------------------Getting Agents for dropdown select---------------------- */
  getAllAgentsByCompany(){
    let body={
      isPaginated: false,
      company_No:this.f.company_no.value,
      accountId: "",
    }
    const subscr = this.thGeneralSystemService.GetAllAgentsByCompany(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.agentsList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------Getting distributors for dropdown select---------------------- */
  getAllDistributorsByAgent(){
    let body={
      isPaginated: false,
      company_No:"",
      accountId: this.f.agentAccountId.value,
    }
    const subscr = this.thGeneralSystemService.GetAllDistributorsByAgent(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.distributorsList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedCompany(event){
    if(event!=""){
      this.form.controls.company_no.setValue(event)
      //Empty selected Agents/distributor from before only if not editing
      this.form.controls.agentAccountId.setValue("")
      this.form.controls.distAccountId.setValue("")
      
      //clear selected value agents/dist
      this.agentsCombo.clear()
      this.distributorCombo.clear()
      //get new agents
      this.getAllAgentsByCompany()
    }else{
      this.form.controls.company_no.setValue("")
      //Empty selected agent/distributor from before
      this.form.controls.agentAccountId.setValue("")
      this.form.controls.distAccountId.setValue("")
      this.agentsList = [];
      this.distributorsList = [];
      //clear selected value
      this.agentsCombo.clear()
      this.distributorCombo.clear()
    }
  }

  /*----------------------when Choosing distributor---------------------- */
  changeSelectedAgnet(event){
    if(event.itemData){
      let distId = event.itemData.CUSTOMER_ID
      this.form.controls.agentAccountId.setValue(distId)
      this.getAllDistributorsByAgent();
      // console.log(this.f.agentAccountId.value)
    }else{
      this.form.controls.agentAccountId.setValue("")
      this.form.controls.distAccountId.setValue("")
      this.distributorsList = []
      // console.log(this.f.agentAccountId.value)
    }
  }

  /*----------------------when Choosing distributor---------------------- */
  changeSelectedDistributor(event){
    if(event.itemData){
      let distId = event.itemData.CUSTOMER_ID
      this.form.controls.distAccountId.setValue(distId)
      // console.log(this.f.distAccountId.value)
    }else{
      this.form.controls.distAccountId.setValue("")
      // console.log(this.f.distAccountId.value)
    }

  }

}
