import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-start-inv-table',
  templateUrl: './start-inv-table.component.html',
  styleUrls: ['./start-inv-table.component.scss']
})
export class StartInvTableComponent implements OnInit {

  @Input() datasource;
  @Input() isDetailsType;

  SumTotalPrice:number = 0
  SumTotalCost:number = 0
  SumTotalCardsNo:number = 0

   
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    // if(this.datasource.length>0){
    //   this.SumTotalPrice = this.datasource[0].SumTotalPrice;
    //   this.SumTotalCost = this.datasource[0].SumTotalCost;
    //   this.SumTotalCardsNo = this.datasource[0].SumTotalCardsNo;
    // }else{
    //   this.SumTotalPrice = 0;
    //   this.SumTotalCost = 0;
    //   this.SumTotalCardsNo = 0;
    // }
  }

}
