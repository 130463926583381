import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComboComponent } from './components/shared-combo/shared-combo.component';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbAlertModule, NgbDatepickerModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ExcelExportComponent } from './components/excel-export/excel-export.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ServicesDropdownComponent } from './components/services-dropdown/services-dropdown.component';
import { ProvidersDropdownComponent } from './components/providers-dropdown/providers-dropdown.component';
import { UsersDropdownComponent } from './components/users-dropdown/users-dropdown.component';
import { CompaniesDropdownComponent } from './components/companies-dropdown/companies-dropdown.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { AllCustomersDropdownComponent } from './components/all-customers-dropdown/all-customers-dropdown.component';
import { CompaniesNotInGroupDropdownComponent } from './components/companies-not-in-group-dropdown/companies-not-in-group-dropdown.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { BanksDropdownComponent } from './components/banks-dropdown/banks-dropdown.component';
import { ServicesMultiselectComponent } from './components/services-multiselect/services-multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClassMultiselectComponent } from './components/class-multiselect/class-multiselect.component';
import { AgentsDropdownComponent } from './components/agents-dropdown/agents-dropdown.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    SharedComboComponent,
    InfoCardComponent,
    ExcelExportComponent,
    PaginatorComponent,
    ServicesDropdownComponent,
    ProvidersDropdownComponent,
    UsersDropdownComponent,
    CompaniesDropdownComponent,
    ActionButtonsComponent,
    AllCustomersDropdownComponent,
    CompaniesNotInGroupDropdownComponent,
    DateSelectorComponent,
    BanksDropdownComponent,
    ServicesMultiselectComponent,
    ClassMultiselectComponent,
    AgentsDropdownComponent,
    PrintReportComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    Ng2SmartTableModule,
    NgbPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    ComboBoxModule,
    DropDownListModule,
    SwitchModule,
    GridModule,
    NgxSpinnerModule,
    MatExpansionModule,
    MatIconModule,
    NgMultiSelectDropDownModule.forRoot(),
    QRCodeModule
  ],
  exports: [
    TranslateModule,
    Ng2SmartTableModule,
    NgbPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    ComboBoxModule,
    DropDownListModule,
    SharedComboComponent,
    SwitchModule,
    GridModule,
    InfoCardComponent,
    ExcelExportComponent,
    NgxSpinnerModule,
    PaginatorComponent,
    ServicesDropdownComponent,
    ProvidersDropdownComponent,
    UsersDropdownComponent,
    CompaniesDropdownComponent,
    ActionButtonsComponent,
    AllCustomersDropdownComponent,
    CompaniesNotInGroupDropdownComponent,
    MatExpansionModule,
    MatIconModule,
    DateSelectorComponent,
    BanksDropdownComponent,
    ServicesMultiselectComponent,
    ClassMultiselectComponent,
    AgentsDropdownComponent,
    PrintReportComponent,
    QRCodeModule
  ],
})
export class CoreModule { }
