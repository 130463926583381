import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThServiceClassSettingsService {

  API_URLGetAllServiceClassData = `${environment.apiUrl}/ThdbServiceClassSetting/GetAllServiceClassData`;
  API_URLCreateUpdateServiceClassData = `${environment.apiUrl}/ThdbServiceClassSetting/CreateUpdateServiceClassData`;

  constructor(private http:HttpClient) { }

  GetAllServiceClassData(body): Observable<any> {
    const url = this.API_URLGetAllServiceClassData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  CreateUpdateServiceClassData(body): Observable<any> {
    const url = this.API_URLCreateUpdateServiceClassData;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
