import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NgbDatepickerConfig, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { GridComponent, PageSettingsModel, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";

import Swal from 'sweetalert2';

import { DatePipe } from "@angular/common";

import { Guid } from 'guid-typescript';

import * as XLSX from 'xlsx';
import { HalaManageCardsService } from "src/app/core/services/hala/hala-manage-cards.service";
import { ThGeneralSystemService } from "src/app/core/services/thuraya/th-general-system.service";
import { HalaProvidersService } from "src/app/core/services/hala/hala-providers.service";
import { ToastrService } from "ngx-toastr";
import { MessageTranslateService } from "src/app/core/services/message-translate.service";
import { LanguageService } from "src/app/core/services/language.service";
@Component({
  selector: 'app-send-cards-excel',
  templateUrl: './send-cards-excel.component.html',
  styleUrls: ['./send-cards-excel.component.scss']
})
export class SendCardsExcelComponent implements OnInit {

  public toolbarOptions: ToolbarItems[] | object;
  public toolbarOptionsDetails: ToolbarItems[] | object;
  @ViewChild("grid")
  public grid: GridComponent;
  public gridDetails: GridComponent;
  @ViewChild("detailgrid") detailGrid: GridComponent;
  dataSource = [];
  private subscriptions: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  form: FormGroup;


  isLoading: boolean;
  public result: any;
  public trxFormat: object;
  public aggreagtes: Object;
  public pageSettings: Object;


  //Date Variables
  @Input() dateDay: string = "";
  @Input() dateTo: string = "";
  fromDateCom: any;
  toDateCom: any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateToSelector") dateToSelector;

  public data: Object[] = [];
  public format = { type: "date", format: "yyyy/MM/dd" };
  public formatTime = { type: "date", format: " h:mm a" };

  ClassNameList:any = []

  //Excel file variables
  GFile: File;
  GFileName: string;
  isActive: boolean = false;
  arrayBuffer:any;
  AllDataList = [];
  cleanDataList = [];
  RepeatedDataList = [];
  cleanSerialPins = [];

  @ViewChild("servicesDropDown") servicesDropDown;
  @ViewChild("providersDropDown") providersDropDown;

  constructor(
    private config: NgbDatepickerConfig,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    public halaManageCardsService: HalaManageCardsService,
    private thGeneralSystemService:ThGeneralSystemService,
    public halaProvidersService: HalaProvidersService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    public languageService:LanguageService,
  ) {
    const current = new Date();
    this.initForm()
  }
  
  /*--------------------------Date for requests-------------------------- */
  pipe = new DatePipe('en-US');
  setSelectedDate(event,type){
    this.fromDateCom = new Date();
    this.fromDateTimeStamp = this.fromDateCom.getTime()
    this.fromDateCom = this.pipe.transform(this.fromDateCom, 'MM/dd/yyyy');
    
    this.toDateCom = event.date
    this.toDateTimeStamp = event.dateTimeStamp   
  }

  //format date to be also exported in excel with this format
  dateFormatter(field: string, data: any, column: Object): string { 
    const date = new Date(data[field]);
    if (date instanceof Date) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    } else {
      return "";
    }
  }
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl(""),
      class_no: new FormControl(""),
      providerId: new FormControl(""),
      orderNo: new FormControl(""),
      dateTo: new FormControl(""),
      file: new FormControl(""),
    });
  }

  get f(){
    return this.form.controls;
  }

  public initialPage: PageSettingsModel;
  ngOnInit() {
    this.initialPage = { pageSize: 250 };
    this.toolbarOptions = [
      "ExcelExport",
      "Print"
    ];
    this.toolbarOptionsDetails = [
      "ExcelExport",
      "Print"
    ];
  }


  /*----------------------Ejs grid functions---------------------- */
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

  clickHandler(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

  /*----------------------Download excel file---------------------- */
  public ExcelName: Guid;
  downloadExcelFormat() {
    // Requiring module
    let excelData = [{
      serialCard: String,
      pinCard: String
    }];
    this.ExcelName = Guid.create();

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.ExcelName.toString() + '.xlsx');

  }

  /*----------------------Upload file lisetner---------------------- */
  changeFileListener($event): void {
    this.GFile = $event.target.files[0];
    this.isActive = false;
    this.GFileName = this.GFile.name;
  }

  /*----------------------Show Uploaded file data---------------------- */
  ShowFileData() {
    //Delete all data from previous  files
    this.RepeatedDataList = [];
    this.dataSource = [];
    this.AllDataList = []
    this.cleanDataList = [];
    this.cleanSerialPins = []
    // this.showSwalAlert();
    this.isActive = false;

    //const sharedAccount_Id = selectEl.options[selectEl.selectedIndex].getAttribute('data-id');

    // check to date is not prvious to from date
    if(this.toDateTimeStamp < this.fromDateTimeStamp){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
      //reset value
      this.toDateCom = "";
      this.toDateTimeStamp = ""
    }
    else if (this.f.ServiceId.value == "" || this.f.class_no.value == "" || this.toDateCom == "" || this.f.providerId.value == "" || this.f.orderNo.value == "") {
      this.CloseSwalAlert();
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InsertData"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
      
    }else if (this.GFileName.split("_")[0] != this.f.class_no.value) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidExcelName"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
    } 
    else {
      try {
        var file = this.GFile;
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          //Data From Excel
          var AllSerialPin:any = XLSX.utils.sheet_to_json(worksheet, { raw: true });

          const removeDuplicates = (array, key) => {
            return array.reduce((arr, item) => {
              const removed = arr.filter(i => i[key] !== item[key]);
              return [item, ...removed,];
            }, []);
          };

          const removeInvalidPins = (array,showToast) => {
            //Remove pin cards less than 5 numbers
            let cleanList2 = array.filter(a=>a.pinCard.toString().length>=5);
            if(cleanList2.length<array.length && showToast){
              this.toastr.info(this.msgTranslateService.msgTranslate("ToastMessages.PinLengthError"), "",{
                positionClass: 'toast-bottom-left',
              })
            }
            return cleanList2;
          };

          let selectedProvider = this.providersDropDown.getProviderFromList(this.f.providerId.value)   
          for (let x = 0; x < AllSerialPin.length; x++) { 
            let newRow = {
              Service_Id : this.f.ServiceId.value,
              Class_Name: this.ClassNameList.filter(c => c.class_no == this.f.class_no.value)[0].class_name,
              AddedDate : this.fromDateCom,
              ExpirationDate : this.toDateCom,
              ProviderName : selectedProvider[0].ProviderName,
              OrderNo: this.f.orderNo.value,
              serialCard: AllSerialPin[x].serialCard,
              pinCard: AllSerialPin[x].pinCard
            };
            this.AllDataList.push(newRow);
          }
          // Remove Duplicate serials
          this.cleanDataList = removeDuplicates(this.AllDataList, 'serialCard');
          if(this.cleanDataList.length<this.AllDataList.length){
            this.toastr.info(this.msgTranslateService.msgTranslate("ToastMessages.RepeatedCards"), "",{
              positionClass: 'toast-bottom-left',
            })
          }
          //Then remove invalid pins (less than 5 digits) and this is the datasource
          this.dataSource = removeInvalidPins(this.cleanDataList,true);
          //The repeated list to show in the problems table
          this.RepeatedDataList = this.AllDataList.filter(x => this.cleanDataList.indexOf(x) === -1);

          //clean the list of serial and pins only to be sent
          this.cleanSerialPins = removeDuplicates(AllSerialPin,'serialCard');
          this.cleanSerialPins = removeInvalidPins(this.cleanSerialPins,false);

          if (this.RepeatedDataList.length == 0) {
            this.isActive = true;
          }

          this.ref.detectChanges();
          this.CloseSwalAlert();
        }
      } catch {
        this.CloseSwalAlert();
      }
    }
  }

  /*----------------------Send Data---------------------- */
  submitData() {
    this.showSwalAlert();
    let selectedProvider = this.providersDropDown.getProviderFromList(this.f.providerId.value)
    const formData = this.form.value;
    let ServiceId = formData.ServiceId;
    let class_no = formData.class_no;
    let class_name = this.ClassNameList.filter(c => c.class_no == this.f.class_no.value)[0].class_name;
    let providerId = formData.providerId;
    let orderNo = formData.orderNo;
    let GListData = this.cleanSerialPins;
    let fileName = this.GFileName;
    let providerName = selectedProvider[0].ProviderName;
    
    if (orderNo == undefined) {
      orderNo = "";
    }
    if (providerId == undefined) {
      providerId = "";
    }
    if (this.toDateCom == "" || this.toDateTimeStamp < this.fromDateTimeStamp) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidDate"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
    } 
    else if (ServiceId == "" || class_no == "" || providerId == "" || providerName == "" || GListData.length == 0 || providerId == "" || orderNo == "" || class_name == "") {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InsertData"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
    } else if (GListData.length == 0) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.EmptyFile"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
      });
    }
    else {
      this.GetInventoryDataDetails(
        ServiceId,
        class_no,
        class_name,
        providerId,
        providerName,
        orderNo,
        fileName,
        GListData,
        this.fromDateCom,
        this.toDateCom,
      );
    }
    //this.grid.dataSource = this.dataSource;
  }

  GetInventoryDataDetails(
    ServiceId: string, class_no: string, class_name: string, providerId: string, providerName: string,
    orderNo: string, fileName: string, GListData: any, dateFrom: string, dateTo: string
  ) {
    var BillSystemImportedData = {
      service_id: ServiceId,
      class_name: class_name,
      class_no: class_no,
      edit_date: this.toDateCom,
      providerId: providerId,
      providerName: providerName,
      orderNo: orderNo,
      serialAndPinCards: GListData
    };

    this.halaManageCardsService.SendExcelCardsToApproveFile(BillSystemImportedData).subscribe((result) => {
      let text = result.Data.errorMessage==null ? 'Success' : result.Data.errorMessage
      this.CloseSwalAlert();
      Swal.fire({
        title: "<h4>" + text + "</h4> <br/><h4>All Cards No :" + result.Data.AllCardCount + " </h4><h4>Added Cards No :" + result.Data.AddedCardsCount + "</h4><h4>Repeated Cards No :" + result.Data.RefusedCardsCount + "</h4> ",
        icon: result.Data.errorMessage==null ? 'success' : "info",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });

      
      let refusedCards = result.Data.refusedCards
      if(refusedCards.length!=0){
        let selectedProvider = this.providersDropDown.getProviderFromList(this.f.providerId.value)
        for (let i = 0; i < refusedCards.length; i++) {
          let newRow = {
            Service_Id : this.f.ServiceId.value,
            Class_Name: this.ClassNameList.filter(c => c.class_no == this.f.class_no.value)[0].class_name,
            AddedDate : this.fromDateCom,
            ExpirationDate : this.toDateCom,
            ProviderName : selectedProvider[0].ProviderName,
            OrderNo: this.f.orderNo.value,
            serialCard: refusedCards[i].serialCard,
            pinCard: refusedCards[i].pinCard
          };
          this.RepeatedDataList.push(newRow);
      }
      this.detailGrid.refresh()
     }
     this.resetValues()
  });
  }


  /*----------------------Swal Functions---------------------- */
  showSwalAlert() {
    Swal.fire({
      title: ' <div class="row"><div class="col-xl-12"><div class="card card-custom gutter-b example example-compact px-8 py-2"><h3 style="text-align: center;">...Please wait</h3><progress style="width: 100%;"></progress></div></div></div>',
      allowOutsideClick: false,
      showCancelButton: false, // There won't be any cancel button
      showConfirmButton: false // There won't be any confirm button

    });
  }

  CloseSwalAlert() {
    Swal.close();
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top-start',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  /*----------------------Empty fields---------------------- */
  resetValues(){
    this.dataSource = [];
    this.initForm();
    this.servicesDropDown.reset()
    this.providersDropDown.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.dateToSelector.reset();
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.GFile = null;
    this.GFileName = ""
    this.f.file.setValue("")
    this.AllDataList = [];
    this.cleanDataList = []
    this.ref.detectChanges();
    this.isActive = false
  }

  /*----------------------Delete From cards with problems---------------------- */
  deleteFromRepeated(data){
    // console.log(data)
    this.RepeatedDataList = this.RepeatedDataList.filter(r=>r.serialCard != data.serialCard)
    //if no more repeated enable send button 
    if(this.RepeatedDataList.length==0 && this.dataSource.length!=0){
      this.isActive = true
    }
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------Select Service from dropdown---------------------- */
   setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------when Choosing Provider---------------------- */
  setSelectedProvider(event){ 
    this.form.controls.providerId.setValue(event)
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

}
