<!-- Card-->
<div class="row">
    <div class="col-xl-4 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>


<ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>

<div class="card py-3 px-3">
    <!-- Table -->
    <app-advanced-bulk-table [datasource]="datasource" [totalAdditions]="infoList[0].amount[0]" [totalWithdraw]="infoList[1].amount[0]"></app-advanced-bulk-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>
