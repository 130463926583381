import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataUpdateRoutingModule } from './data-update-routing.module';
import { GetDataUpdatesComponent } from './get-data-updates/get-data-updates.component';
import { CoreModule } from 'src/app/core/core.module';
import { DataUpdatesTableComponent } from './get-data-updates/_components/data-updates-table/data-updates-table.component';
import { EditDataDialogComponent } from './get-data-updates/_components/edit-data-dialog/edit-data-dialog.component';


@NgModule({
  declarations: [
    GetDataUpdatesComponent,
    DataUpdatesTableComponent,
    EditDataDialogComponent
  ],
  imports: [
    CommonModule,
    DataUpdateRoutingModule,
    CoreModule
  ]
})
export class DataUpdateModule { }
