import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThManageAccounts } from 'src/app/core/services/thuraya/th-manage-accounts';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {
  
  //Form variables
  form: FormGroup;

  @Input() isEditing;
  @Input() accountOldData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  isForAgent:boolean =false;
  isForDistPos:boolean =false;

  accountDetails= {
    user_type:"",
    status: "",
    user_name: "",
    email: "",
    address: "",
    rt1: "",
    rt2: "",
    rt3: "",
    rt4: "",
  }

  private subscriptions: Subscription[] = [];

  @ViewChild("companiesDropDown") companiesDropDown;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private thGeneralSystemService: ThGeneralSystemService,
    private thManageAccounts: ThManageAccounts,
    private toastr: ToastrService,
    private messagTranlsateService:MessageTranslateService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initAccountDetails()
  }

  initAccountDetails(){
    // console.log("init data",this.accountOldData)
    if(this.isEditing){

      let statusValue= "";
      switch (this.accountOldData.status) {
        case "موقوف ":
          statusValue = "0"
          break;
        case "يعمل":
          statusValue = "1"
          break;
        case "معلق":
          statusValue = "5"
          break;
        default:
          break;
      } 

      this.accountDetails= {
        user_type : "",
        status: statusValue,
        user_name: this.accountOldData.CUSTOMER_NAME,
        email: this.accountOldData.Email,
        address: this.accountOldData.cust_address,
        rt1: this.accountOldData.rt1,
        rt2: this.accountOldData.rt2,
        rt3: this.accountOldData.rt3,
        rt4: this.accountOldData.rt4,
      }

      // console.log("details",this.accountDetails)
    }else{
      this.accountDetails= {
        user_type:"3",
        status: "1",
        user_name: "",
        email: "",
        address: "",
        rt1: "",
        rt2: "",
        rt3: "",
        rt4: "",
      }
    }
    
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      user_type: new FormControl("",Validators.required),
      status: new FormControl("",Validators.required),
      company_no: new FormControl("",[Validators.required ]),
      agentAccountId: new FormControl(""),
      distAccountId: new FormControl(""),
      user_name:new FormControl("",[Validators.required ]),
      user_phone: new FormControl("",[Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.minLength(3),
        Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-PhoneNumber-address
      ]),
      user_pass:new FormControl("",[Validators.required,Validators.minLength(6), Validators.maxLength(100)]),
      email:new FormControl("",[Validators.email]),
      address:new FormControl(""),
      //Address 1/2
      rt1:new FormControl(""),
      rt2:new FormControl(""),
      // TaxCard/CommercialRegitser
      rt3:new FormControl(""),
      rt4:new FormControl(""),
    });

    //In case of editing remove validators from uneditable fields
    if(this.isEditing){
      this.form.controls.user_type.clearValidators()
      this.form.controls.user_phone.clearValidators()
      this.form.controls.user_pass.clearValidators()
      this.form.controls.company_no.clearValidators()
    }
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Client Type select Change---------------------- */
  onTypeChange(value){
    //in all cases erase the company selected on change
    this.companiesDropDown.reset()
    this.agentText = "-";
    this.f.company_no.setValue("");
    this.f.agentAccountId.setValue("");
    this.f.distAccountId.setValue("");

    //To get Companies list if client type is Agent, get all companies without associated agent and companies that do have branches
    if(value=="1"){
      this.isForAgent = true;
      this.isForDistPos = false;
    }
     //To get Companies list if client type is Distributor/POS, get all companies with agents
    else if(value=="2" || value=="3"){
      this.isForAgent = false;
      this.isForDistPos = true;
    }//Else get all
    else{
      this.isForAgent = false;
      this.isForDistPos = false;
    }
    this.companiesDropDown.emptyData()

    
    if(value=="3"){
      setTimeout(() => {
        this.distributorCombo.clear()
      }, 100);
    }
    // //if client type is DISTRIBUTOR or POS the Agent is required
    // if(value=="2" || value=="3"){ 
    //   this.form.controls.agentAccountId.setValidators(Validators.required)
    // }else{
    //   this.form.controls.agentAccountId.clearValidators()
    // }
  }

  /*----------------API Requests--------------- */
  addAccount(){
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.messagTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      user_type: this.f.user_type.value,
      status: this.f.status.value,
      company_no: this.f.company_no.value,
      agentAccountId: this.f.agentAccountId.value,
      distAccountId: this.f.distAccountId.value,
      user_name: this.f.user_name.value,
      user_phone: this.f.user_phone.value,
      user_pass: this.f.user_pass.value,
      address: this.f.address.value,
      email: this.f.email.value,
      rt1:this.f.rt1.value,
      rt2:this.f.rt2.value,
      rt3:this.f.rt3.value,
      rt4:this.f.rt4.value,
    }

    this.thManageAccounts.CreateAccountsStored(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.modalService.dismissAll();
          this.toastr.success(this.messagTranlsateService.msgTranslate('ToastMessages.SuccAdd'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.discard();
          //add row in table
          this.updateParent.emit(result.Data[0]);
        }else{
          this.toastr.error(result.Data, "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.title, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    )  
  }

  updateAccount(){
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.messagTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      row_no: this.accountOldData.Row_NO,
      status: this.f.status.value,
      user_name: this.f.user_name.value,
      address: this.f.address.value,
      email: this.f.email.value,
      rt1: this.f.rt1.value,
      rt2: this.f.rt2.value,
      rt3: this.f.rt3.value,
      rt4: this.f.rt4.value,
    }

    this.thManageAccounts.UpdateClientAccount(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.modalService.dismissAll();
          this.toastr.success(this.messagTranlsateService.msgTranslate('ToastMessages.SuccUpdate'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.discard();
          //update row in table
          this.updateParent.emit(result.Data[0]);
        }else{
          this.toastr.error(result.Data, "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.title, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    )  
  }
  /*------------------------ Close Dialog  ------------------------ */
  discard(){
    this.modalService.dismissAll();
    this.initForm();
  }

  /*----------------------Ng dropdown variables---------------------- */
  //Combox variable
  @ViewChild('distributorCombo') public distributorCombo: ComboBoxComponent
  @ViewChild('agentsCombo') public agentsCombo: ComboBoxComponent
  agentText:string = "-"
  distributorsList:any = []
  public distrFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };

  /*----------------------Getting class name for dropdown select---------------------- */

  getAllAgentsByCompany(){
    let body={
      isPaginated: false,
      company_No:this.f.company_no.value,
      accountId: "",
    }
    const subscr = this.thGeneralSystemService.GetAllAgentsByCompany(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.agentText= `[${result.Data[0].CUSTOMER_ID}] - ${result.Data[0].CUSTOMER_NAME} `
          this.f.agentAccountId.setValue(result.Data[0].CUSTOMER_ID)
          this.getAllDistributorsByAgent();
          // this.agentsList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  getAllDistributorsByAgent(){
    let body={
      isPaginated: false,
      company_No:"",
      accountId: this.f.agentAccountId.value,
    }
    const subscr = this.thGeneralSystemService.GetAllDistributorsByAgent(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.distributorsList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing Company---------------------- */
  setSelectedCompany(event){
    if(event!=""){
      this.form.controls.company_no.setValue(event)
      //Empty selected Agents/distributor from before only if not editing
      this.form.controls.agentAccountId.setValue("")
      this.form.controls.distAccountId.setValue("")
      this.agentText="-"
      
      //clear selected value agents/dist
      if(this.form.controls.user_type.value!="1"){
        // this.agentsCombo.clear()
        //get new agents
        this.getAllAgentsByCompany()
      }else if(this.form.controls.user_type.value=="3"){
        this.distributorCombo.clear()
      }
    }else{
      this.form.controls.company_no.setValue("")
      this.agentText="-"
      //Empty selected agent/distributor from before
      this.form.controls.agentAccountId.setValue("")
      this.form.controls.distAccountId.setValue("")
      // this.agentsList = [];
      if(this.form.controls.user_type.value=="3"){
        this.distributorsList = [];
        //clear selected value
        this.distributorCombo.clear()
      }
    }
  }

  /*----------------------when Choosing distributor---------------------- */
  // changeSelectedAgnet(event){
  //   if(event.itemData){
  //     let distId = event.itemData.CUSTOMER_ID
  //     this.form.controls.agentAccountId.setValue(distId)
  //     this.getAllDistributorsByAgent();
  //     console.log(this.f.agentAccountId.value)
  //   }else{
  //     this.form.controls.agentAccountId.setValue("")
  //     this.form.controls.distAccountId.setValue("")
  //     this.distributorsList = []
  //     console.log(this.f.agentAccountId.value)
  //   }
  // }

  /*----------------------when Choosing distributor---------------------- */
  // changeSelectedDistributor(event){
  //   if(event.itemData){
  //     let distId = event.itemData.CUSTOMER_ID
  //     this.form.controls.distAccountId.setValue(distId)
  //     console.log(this.f.distAccountId.value)
  //   }else{
  //     this.form.controls.distAccountId.setValue("")
  //     console.log(this.f.distAccountId.value)
  //   }

  // }
  public value: string = '';
  public height: string = '400px';
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    
    let predicateQuery = query.where(new Predicate('CUSTOMER_NAME', 'contains', e.text, true).or('CUSTOMER_ID', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.distributorsList, query);
  };

}
