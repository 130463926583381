import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThProfitUserService {

  API_URLGetUserAllSummaryData = `${environment.apiUrl}/ThdbProfitUser/GetUserAllSummaryData`;
  API_URLGetProfitUserCompanyData = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyData`;
  API_URLGetProfitUserCompanyCardData = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyCardData`;
  API_URLGetProfitUserCompanyClientData = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyClientData`;
  API_URLGetProfitUserCompanyCardClientData = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyCardClientData`;
  API_URLGetProfitUserCompanyPayments = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyPayments`;
  API_URLGetProfitUserBalanceTransfers = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserBalanceTransfers`;
  API_URLGetProfitUserClientData = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserClientData`;
  API_URLGetProfitUserCompanyInvoice = `${environment.apiUrl}/ThdbProfitUser/GetProfitUserCompanyInvoice`;
  
  constructor(private http:HttpClient) { }

  GetUserAllSummaryData(body): Observable<any> {
    const url = this.API_URLGetUserAllSummaryData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyData(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyCardData(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyCardData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyClientData(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyClientData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyCardClientData(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyCardClientData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyPayments(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyPayments;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserBalanceTransfers(body): Observable<any> {
    const url = this.API_URLGetProfitUserBalanceTransfers;
    //Http request-
    return this.http.post<any>(url,body)
  }
  
  GetProfitUserClientData(body): Observable<any> {
    const url = this.API_URLGetProfitUserClientData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserCompanyInvoice(body): Observable<any> {
    const url = this.API_URLGetProfitUserCompanyInvoice;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
