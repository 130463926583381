import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-get-users',
  templateUrl: './get-users.component.html',
  styleUrls: ['./get-users.component.scss']
})
export class GetUsersComponent implements OnInit {

   //Table Variables
   datasource:[] = [];
   pageNo:number = 1;
   pageSize:number = 10;
   totalCount:number = 0;

   term: any;

  private unsubscribe: Subscription[] = [];
  isExporting:boolean = false;
 
  constructor(
    private halaAccountsService:HalaAccountsService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private spinner: NgxSpinnerService,
    public permissionService:PermissionService) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true;
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      searchString:this.term
    }
    const subscr = this.halaAccountsService.GetUsersData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.users.length){
          let data = result.Data.users
          for (let i = 0; i < data.length; i++) {
            let sheet={  
              "رقم التعريف": data[i].Id,
              "الاسم بالكامل": data[i].FullName,
              "البريد الالكترونى": data[i].Email,
              "رقم الجوال": data[i].PhoneNumber,
              "تاريخ التسجيل":this.datePipe.transform(data[i].DateCreated,this.dateFormatService.dateFormat),
              "فعال": data[i].IsActive ? "فعال" : "غير فعال",
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
          this.isExporting = false
        }
      },(err)=>{
        this.isExporting = false
      }
    )
    this.unsubscribe.push(subscr)
  }

  /*----------------------API REQUESTS---------------------- */
  getAllUsers() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNo,
      pageSize: this.pageSize,
      isPaginated: true,
      searchString:this.term
    }
    const subscr = this.halaAccountsService.GetUsersData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.users.length){
          this.datasource = result.Data.users
          this.totalCount = result.Data.TotalRows
          // this.ref.detectChanges();
        }else{
          this.datasource = []
          this.totalCount = 0
          this.pageNo = 1
        }
        this.spinner.hide(); 
      },
      (err)=>{
        this.spinner.hide(); 
        this.datasource = []
        this.totalCount = 0
        this.pageNo = 1
      }
    )
    this.unsubscribe.push(subscr)
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNo = event;
    this.getAllUsers();
    // console.log("pagechange",event)
  }

   /*----------------------Search Function---------------------- */
  searchChange(){
    this.getAllUsers();
  }

   /*----------------------Component Destroy---------------------- */
   ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
