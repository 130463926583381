<div class="card py-3 px-3 mb-3">
  <div class="d-flex align-items-center justify-content-between">
      <div class="search-box  d-inline-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="term" (input)="searchChange()">
          <i class="bx bx-search-alt search-icon"></i>
        </div>
      </div>
      <!-- Export Excel -->
      <app-excel-export *ngIf="permissionService.checkPermission(['HalaAccounts.View'])" [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
  </div>
  
  <!-- Users Table -->
  
  <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
  <app-users-table [datasource]="datasource"></app-users-table>
  
  <!-- Pagination -->
  <app-paginator [totalCount]="totalCount" [pageNumber]="pageNo" (changePage)="changePage($event)" ></app-paginator> 
</div>