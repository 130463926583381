import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetCompanySalesComponent } from './get-company-sales/get-company-sales.component';

const routes: Routes = [
  { path: 'get-companies-sales', component: GetCompanySalesComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbCompanySales.View'], roles:[]} },
  { path: 'get-all-sales-rate', component: GetCompanySalesComponent,canActivate: [AuthGuard], data: {permission: ['ThdbSalesRatePerDay.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanySalesRoutingModule { }
