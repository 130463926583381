import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-scratch-data-table',
  templateUrl: './scratch-data-table.component.html',
  styleUrls: ['./scratch-data-table.component.scss']
})
export class ScratchDataTableComponent implements OnInit {

  @Input() datasource;
  @Input() sumPrice;

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
