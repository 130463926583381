import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetRequestsComponent } from './get-requests/get-requests.component';
import { GetSadadBalanceComponent } from './get-sadad-balance/get-sadad-balance.component';
import { GetTransactionsComponent } from './get-transactions/get-transactions.component';

const routes: Routes = [
  { path: 'get-Transactions', component: GetTransactionsComponent,canActivate: [AuthGuard],  data: {permission: [], roles:['Accountant']} },
  { path: 'get-requests', component: GetRequestsComponent,canActivate: [AuthGuard],  data: {permission: [], roles:['Accountant']} },
  { path: 'get-sadad-balance', component: GetSadadBalanceComponent,canActivate: [AuthGuard],  data: {permission: [], roles:['Accountant']} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SadadServiceApiRoutingModule { }
