import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThManageIncomingAmountsService } from 'src/app/core/services/thuraya/th-manage-incoming-amounts.service';
import Swal from 'sweetalert2';
import { AddAmountDialogComponent } from './_components/add-amount-dialog/add-amount-dialog.component';

@Component({
  selector: 'app-get-all-incoming-amounts',
  templateUrl: './get-all-incoming-amounts.component.html',
  styleUrls: ['./get-all-incoming-amounts.component.scss']
})
export class GetAllIncomingAmountsComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR Incoming amounts Report AND getallincomingamounts so we decide from route and with these varibale we change logic
  IsIncomingAmountsReportComponent:boolean = false;
  IsGetAllIncomingAmountsComponent:boolean = false;
  
  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;
  
  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Export Variable for loading
  isExporting:boolean = false;

  @ViewChild("agentsDropDown") agentsDropDown;
  @ViewChild("usersDropDown") usersDropDown;
   
  constructor(
    private fb: FormBuilder,
    private thManageIncomingAmountsService:ThManageIncomingAmountsService, 
    private thGeneralSystemService: ThGeneralSystemService,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private router: Router,
    private halaAccountsService:HalaAccountsService,
    public permissionService:PermissionService
  ) { 

    //[IMPORTANT] This component IS USED FOR Companies Sales Report AND sales rate
    // console.log("route",this.router.url)
    if(this.router.url=="/thuraya/manage-incoming-amounts/get-all-incoming-amounts"){
      this.IsGetAllIncomingAmountsComponent = true;
      this.IsIncomingAmountsReportComponent = false;
    }else if(this.router.url=="/thuraya/manage-incoming-amounts/incoming-amounts-report"){
      this.IsGetAllIncomingAmountsComponent = false;
      this.IsIncomingAmountsReportComponent = true;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      customerId: this.f.customerId.value,
      userIdAdded: this.f.userIdAdded.value,
      customerPhone: this.f.customerPhone.value,
      isSend: this.IsIncomingAmountsReportComponent ? true : false,
      isManager : this.IsIncomingAmountsReportComponent ? true : false
    }
    return this.thManageIncomingAmountsService.GetAllIncomingAmountsApprovedOrNot(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data

          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم العميل	": data[i].cust_account_id,
              "البيان": data[i].trans_note,
              "المضاف": data[i].credit_amount,
              "المسحوب": data[i].debit_amount,
              "اضافة بواسطة": data[i].edit_user,
              "التاريخ":  this.datePipe.transform(data[i].edit_date,this.dateFormatService.dateFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      customerId: new FormControl(""),
      userIdAdded: new FormControl(""),
      customerPhone: new FormControl(""),
    });
  }
 
  get f(){
    return this.form.controls;
  }
 
  /*----------------------API Requests---------------------- */
  getAllIncomingAmountsApprovedOrNot() {
    this.spinner.show(); 
    let body={
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      customerId: this.f.customerId.value,
      userIdAdded: this.f.userIdAdded.value,
      customerPhone: this.f.customerPhone.value,
      //for reports component we get all (isSend false to get the one not confirmed. so for "تقييد مبالغ وارده" still to confirm, 
      //isManager true for reports page to get all that are apprved and added by any user not just the signed in user as in get page user can not confirm amount not added by himself )
      isSend: this.IsIncomingAmountsReportComponent ? true : false,
      isManager : this.IsIncomingAmountsReportComponent ? true : false
    }
    return this.thManageIncomingAmountsService.GetAllIncomingAmountsApprovedOrNot(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber = 1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.fromDateCom && this.toDateCom){
      this.getAllIncomingAmountsApprovedOrNot();
    }
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if(this.fromDateCom =="" && this.toDateCom ==""){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getAllIncomingAmountsApprovedOrNot();
    } 
  }

  reset(){
    this.initForm()
    // this.customersNameVal = ""
    this.agentsDropDown.reset()
    this.IsIncomingAmountsReportComponent && this.usersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.getAllIncomingAmountsApprovedOrNot()
  }

  /*----------------------Add modal---------------------- */
  openAddDialog() {
    let modalRef = this.modalService.open(AddAmountDialogComponent, { centered: true,});
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdata",newData)
      if(this.datasource.length==10){
        //drop last element to insert new at top
        this.datasource.pop();
        this.datasource.unshift(newData)
      }else{
        this.datasource.unshift(newData)
      }
    })
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedAgent(event){
    this.form.controls.customerId.setValue(event)
  }

  /*----------------------when Choosing user (for report page)---------------------- */
  setSelectedUser(event){
    this.form.controls.userIdAdded.setValue(event)
  }

}
