<div class="sidenav" [ngClass]="menuExpanded ? 'sidenav-expanded' : ''">
  <div class="side-nav-content">

    <ul class="sidenav-nav">
      <ng-container  *ngFor="let data of menuItems">
        <li class="sidenav-nav-item" [ngClass]="(router.url.includes(data.routeLink) || data|activeSecondaryLinks ) ? 'active' : ''"
        *ngIf="data | rolesPermissionsCheck">
          <!-- If has childrens -->
          <ng-container *ngIf=" data | rolesPermissionsCheck">
            <a class="sidenav-nav-link" #tooltip="matTooltip"
            [matTooltip]="screenWidth>1280 ? '' :data.label|translate"
            matTooltipPosition="right"
            (click)="handleClick(data)" *ngIf="data.childs && data.childs.length > 0"
              [ngClass]="getActiveClass(data)">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center gap-2">
                  <!-- <img [src]="data.icon" alt="icon" /> -->
                  <lucide-icon [name]="data.icon"></lucide-icon>
                  <span class="sidenav-link-text label-regular" @fadeInOut *ngIf="menuExpanded">
                    {{ data.label | translate }}
                  </span>
                </div>
                <i *ngIf="data.childs && menuExpanded" class="menu-collapse-icon" [ngClass]="
                    !data.expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
              </div>
            </a>
          </ng-container>
  
          <!-- If No childrens -->
          <ng-container *ngIf="data | rolesPermissionsCheck">
            <a class="sidenav-nav-link" #tooltip="matTooltip"
            [matTooltip]="screenWidth>1280 ? '' : data.label|translate"
            matTooltipPosition="right" *ngIf="!data.childs || (data.childs && data.childs.length === 0)"
              [routerLink]="[data.routeLink]" [ngClass]="getActiveClass(data)" (click)="shrinkItems(data); closeMobileMenu.emit()">
              <div class="d-flex align-items-center gap-2">
                <!-- <img [src]="data.icon" alt="icon" /> -->
                <lucide-icon [name]="data.icon"></lucide-icon>
                <span class="sidenav-link-text" @fadeInOut *ngIf="menuExpanded">
                  {{ data.label | translate }}
                </span>
              </div>
            </a>
          </ng-container>
          <div *ngIf=" data.childs && (data.childs.length > 0)" class="mt-2 sublevel-menu">
            <app-sublevel-menu [data]="data" [collapsed]="true" (closeMobileMenu)="closeMobileMenu.emit()" [multiple]="multiple"
              [expanded]="data.expanded"></app-sublevel-menu>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

</div>