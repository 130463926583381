<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center;">{{'Common.CopyPrice'| translate}}</h4>
  
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="formCopyPriceSetting">
        <!-- Company Source -->
        <div class="col-lg-12">
            <label> {{'Common.PriceSource'| translate}}:</label>
            <app-companies-not-in-group-dropdown #companiesNotInGroupDropDown (updateParent)="setSelectedCompany($event)"></app-companies-not-in-group-dropdown>
        </div>
  
        <!-- Services -->
        <div class="col-lg-12 mt-4">
            <label> {{'Common.CompaniesToCopyFrom'| translate}} :</label>
            <div style="width: 100%;height: 250px;overflow: auto;border: 1px solid lightgray; overflow-x: hidden;">
                <div class="row p-3">
                    <div class="d-flex align-items-center selectAll py-2 mb-3">
                        <input style="margin-inline-end: 20px;" class="checkbox" (change)="selectDeselectAll($event)" [checked]="allSelectedChecked" type="checkbox"/>
                        <span>{{'Common.SelectAll'|translate}}</span>
                    </div>
                    <div *ngFor="let item of serivceList;let i=index" class="col-lg-5">
                        <input style="margin-inline-end: 20px;" class="checkbox" id="item.service_id" [checked]="allSelected" 
                        (change)="onCheckboxChangeFrom($event)"  value={{item.service_id}}
                        type="checkbox" />{{item.service_id}}
                    </div>
                </div>
            </div>
        </div>

        <!-- Group Select -->
        <div class="col-lg-12 mt-4">
            <label> {{'Common.GroupName'| translate}} :</label>
            <select formControlName="groupId" class="form-control">
            <option value="">--{{'Common.Choose'| translate}} --</option>
            <option *ngFor="let item of AllGroupList" value="{{ item.Id }}">
                [{{ item.Id }}] - {{ item.GroupName }}
            </option>
            </select>
        </div>
  
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success font-weight-bold px-10 mr-2" [disabled]="formCopyPriceSetting.invalid || selectedPriceFrom.length==0" (click)="saveData()">{{'Common.CopyPrice'| translate}}</button>
    <button class="btn btn-danger font-weight-bold px-10 mr-2" aria-label="Close" (click)="reset()">{{'Common.Discard'| translate}}</button>
  </div>