import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageIncomingAmountsRoutingModule } from './manage-incoming-amounts-routing.module';
import { GetAllIncomingAmountsComponent } from './get-all-incoming-amounts/get-all-incoming-amounts.component';
import { CoreModule } from 'src/app/core/core.module';
import { IncAmountsTableComponent } from './get-all-incoming-amounts/_components/inc-amounts-table/inc-amounts-table.component';
import { AddAmountDialogComponent } from './get-all-incoming-amounts/_components/add-amount-dialog/add-amount-dialog.component';


@NgModule({
  declarations: [
    GetAllIncomingAmountsComponent,
    IncAmountsTableComponent,
    AddAmountDialogComponent
  ],
  imports: [
    CommonModule,
    ManageIncomingAmountsRoutingModule,
    CoreModule
  ]
})
export class ManageIncomingAmountsModule { }
