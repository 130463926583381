import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetOutstandCardsComponent } from './get-outstand-cards/get-outstand-cards.component';

const routes: Routes = [
  { path: 'get-outstand-card', component: GetOutstandCardsComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbOutStandingCards.View'], roles:['SuperAdmin','Admin']} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutstandingCardsRoutingModule { }
