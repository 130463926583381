<div class="table-export mb-3">
    <button (click)="export(true)" [disabled]="datasource.length==0 || isExporting">
        <div class="icon-container">
            <i class="fa fa-file-excel"></i>
        </div>
        <span> {{'Common.ExportExcel' | translate}} </span>
        <div *ngIf="isExporting" class="spinner-border spinner-border-sm m-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
</div>  