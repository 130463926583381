import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ToastrService } from 'ngx-toastr';
import { HalaRoleService } from 'src/app/core/services/hala/hala-role.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-roles',
  templateUrl: './get-roles.component.html',
  styleUrls: ['./get-roles.component.scss']
})
export class GetRolesComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  //object for expnasion panel open status for each role ()
  panelOpenState:object={}
  roleOfIndexChange:object={}
  rolesDefaultClaims:object={}

  //Roles result data
  public roles: any;
  public claims: any;

  //Add role form variables
  roleForm: FormGroup;
  showAddForm:boolean = false;
  newRoleClaims:any=[];

  constructor(
    private fb:FormBuilder,
    private halaRoleService:HalaRoleService,
    private ref: ChangeDetectorRef, 
    private toastr: ToastrService,
    public dialog: MatDialog,
    public permissionService:PermissionService) { }

  ngOnInit(): void {
    this.initAddForm();
    this.GetRoles();
    this.getClaims()
  }

  /*------------------------ Form Functions ------------------------ */ 
  initAddForm(){
    //Form Variables
    this.roleForm = new FormGroup({
      roleName: new FormControl('', Validators.required)
    })
  }

  //Getting All roles available
  GetRoles() {
    return this.halaRoleService.GetRoles().subscribe(
      (result) => {
        this.roles = result.Data;
        this.setDefaultClaims();
         this.ref.detectChanges();
      }
    );
  }

  // save default permission for each role to detect if there are chaanges to save or not
  setDefaultClaims(){
    for (let i = 0; i < this.roles.length; i++) {
      let role = this.roles[i];
      let roleName = role.RoleName
      //Using spread operator to copy array without reference
      this.rolesDefaultClaims[roleName] = [...role.Claims];
    }
  }

  //Getting All claims available
  getClaims(){
      return this.halaRoleService.GetClaims().subscribe(
        (result) => {
          this.claims = result.Data;
           this.ref.detectChanges();
        }
      );
  }

  //Check if role has the claim already to have the toggle already checked
  checkClaimExist(role:any,claim:any){
      if(role.Claims.includes(claim)){
        return true;
      }else{
        return false;
      }
    
  }

  //Checks default role claim with the changes
  checkChanges = (newArray, oldArray) => {
    //check that every value in the default array of claims (oldArray) already in new array of claims (newArray) and newarray does not contian others (same length) 
    let nochange= oldArray.every(v => newArray.includes(v)) && oldArray.length==newArray.length;
    return nochange
  } 

  //Toggle Changes
  fieldsChange(event:any, claim:any,role:any,i:any){
     if(event.checked){
      role.Claims.push(claim)
    }else{
      role.Claims = role.Claims.filter(roleClaim =>{ return roleClaim !== claim});
    }

    //Check chages with default claims if there is a change we can save
    let noChanges = this.checkChanges(role.Claims,this.rolesDefaultClaims[role.RoleName],);
      if(noChanges){
         this.roleOfIndexChange[i]=false;
      }else{
        this.roleOfIndexChange[i]=true;
      }
  }
  //Save Role permission changes
  saveChanges(role:any,i:any){
    let body = {
        roleId: role.RoleId,
        roleName: role.RoleName,
        claims: role.Claims
    }
    return this.halaRoleService.UpdateRoleClaims(body).subscribe(
      (result) => {
         //Disabling Save Changes after submit
        this.roleOfIndexChange[i] = false;
        //Update UI
        this.ref.detectChanges()
        //Updating default role claims variable with the updated one for further changes
        this.rolesDefaultClaims[role.RoleName] = [...role.Claims]
        this.toastr.success('تم التعديل بنجاح', "",{
          positionClass: 'toast-bottom-left',
        });
      },
      err => {
        this.toastr.error('خطاء فى العمليه. برجاء اعاده المحاوله', "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

  //Add New Role
  show_AddForm(){
    this.showAddForm = true;
  }

  //Alert on delete
  showAlert(role){
    Swal.fire({
      title: `هل تريد حذف صلاحيه ${role.RoleName}`,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:"الغاء",
      confirmButtonText: 'تاكيد',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteRole(role);
      },
  })
   }

  //deleteRole
  deleteRole(role){
     this.halaRoleService.DeleteRole(role.RoleName).subscribe(
      (result) => {
         this.updateRolesView(role,true)
        this.toastr.success('تم المسح بنجاح', "",{
          positionClass: 'toast-bottom-left',
        });
      },
      err => {
        this.toastr.error('خطاء فى العمليه. برجاء اعاده المحاوله', "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

  //[WHEN USED MATERIAL DIALOG]As this function is called after Adding roles from the dialog (not here) we had to pass the "scope" to the dialog to get the scope in this function when called to be able to access the variables (datasource,ref...etc) of this component using "this"
  updateRolesView(role,isDeleting){
    //Case of deleting Role
    if(isDeleting){
      this.roles = this.roles.filter(r=>r.RoleId!=role.RoleId);
      this.ref.detectChanges();
    }
    //Case of Adding Role
    else{
      this.roles.push(role);
      //Change also default claims with the new added role to disable save if no chenags
      this.setDefaultClaims();
      this.ref.detectChanges();
    }
  }

  /*------------------------ Adding new Roles function ------------------------ */ 
  //Toggle Changes
  addFieldsChange(event:any, claim:any){
    if(event.checked){
      this.newRoleClaims.push(claim)
      // console.log("🚀 ~ file: get-roles.component.ts ~ line 201 ~ GetRolesComponent ~ addFieldsChange ~ this.newRoleClaims", this.newRoleClaims)
      
    }else{
      this.newRoleClaims = this.newRoleClaims.filter(c =>{ return c !== claim});
      // console.log("🚀 ~ file: get-roles.component.ts ~ line 201 ~ GetRolesComponent ~ addFieldsChange ~ this.newRoleClaims", this.newRoleClaims)
    }
  }
  //Discard changes
  cancelChanges(){
    //Close adding form
    this.showAddForm = false;
    //Empty All Selected
    this.newRoleClaims=[];
    this.initAddForm();
  }
  //Submit Changes
  addNewRole(){
  const formData = this.roleForm.value;
  let formRoleName = formData.roleName;
    let body={
      roleName:formRoleName,
      claims:this.newRoleClaims
    }
    this.halaRoleService.UpdateRoleClaims(body).subscribe(
      (result) => {
        //Close Add form and empty roleclaims
        this.cancelChanges();
        //Update role view
        this.updateRolesView(result.Data,false)
        this.toastr.success('تم الاضافه بنجاح', "",{
          positionClass: 'toast-bottom-left',
        });
      },
      err => {
        this.toastr.error('خطاء فى العمليه. برجاء اعاده المحاوله', "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

}
