<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'HALA.GroupsSettings.GroupNumber'| translate}}</th>
          <th class="align-middle">{{'HALA.GroupsSettings.GroupName'| translate}}</th>
          <th class="align-middle">{{'Common.CompanyNo'| translate}}</th>
          <th class="align-middle">{{'Common.CompanyName'| translate}}</th>
          <th class="align-middle">{{'HALA.GroupsSettings.AddedBy'| translate}}</th>
          <th class="align-middle">{{'Common.Date'| translate}} </th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Delete'])">{{'Common.Delete' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.GroupId}}</a>
          </td>
          <td>{{data.GroupName}}</td>
          <td>{{data.CompanyNo}}</td>
          <td>{{data.CompanyName}}</td>
          <td>{{data.CreateByName}}</td>
          <td>{{data.CreateDate | date:dateFormatService.dateFormat}}</td>
          <td *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Delete'])">
            <i class='mdi mdi-delete font-size-18 text-danger' (click)="showAlert(data)"></i> 
          </td>
        </tr>
      </tbody>
    </table>
  </div>