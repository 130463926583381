<!-- Add button -->
<div class="col-12" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Create'])">
    <div class="text-sm-end">
      <button type="button" class="btn btn-success btn-rounded mb-2 me-2" (click)="addUpdateGroup('false',null)"><i class="mdi mdi-plus mr-1"></i>
        {{'HALA.GroupsSettings.AddGroup'| translate}}</button>
    </div>
  </div>

<!-- Table -->
<div class="row" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.View','HalaCompaniesGrouping.Update','HalaCompaniesGrouping.Delete'])">
    <div class="col-xl-12">
        <!-- Spinner -->
        <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
        <!-- Table -->
        <div class="card card-custom gutter-b example example-compact px-8 py-2"> 
            <ejs-grid #grid [enableRtl]="languageService.selectedLanguage=='ar'" [pageSettings]="pageSettings" [toolbar]="toolbarOptions" [allowPaging]="true"
            [allowExcelExport]="true" [dataSource]="dataSource" [allowSelection]="false" (toolbarClick)="toolbarClick($event)">
            <e-columns>

            <e-column field="Id" headerText="{{'HALA.GroupsSettings.GroupNumber'| translate}}"></e-column>
            <e-column field="GroupName" headerText="{{'HALA.GroupsSettings.GroupName'| translate}}"></e-column>
            <e-column headerText="{{'Common.Edit'| translate}}" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Update'])">
                <ng-template #template let-dataSource>
                    <i class='mdi mdi-pencil font-size-22 text-success' style="margin-inline-end: 10px;" (click)="addUpdateGroup('true',dataSource)"></i>
                </ng-template>
            </e-column>

            <e-column headerText="{{'Common.Delete'| translate}}" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Delete'])">
                <ng-template #template let-dataSource>
                    <i *ngIf="dataSource.companiesCount==0" class='mdi mdi-delete font-size-22 text-danger' (click)="showAlert(dataSource)"></i>
                </ng-template>
            </e-column>

            <e-column headerText="{{'Common.Members'| translate}}">
                <ng-template #template let-dataSource>
                    <button class="btn btn-secondary" (click)="getAllCompaniesGroupingData(dataSource)"> {{'Common.Details'| translate}}</button>
                </ng-template>
            </e-column>

            <e-column headerText="{{'Common.AddMembers'| translate}}" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.Update'])">
                <ng-template #template let-dataSource>
                    <i class='mdi mdi-plus font-size-22 text-info' (click)="addCompaniesToGroup(dataSource)"></i>
                </ng-template>
            </e-column>

            </e-columns>
        </ejs-grid>
        </div>
    </div>
</div>

<!-- Details Table And pagination -->
<div class="card" *ngIf="permissionService.checkPermission(['HalaCompaniesGrouping.View','HalaCompaniesGrouping.Update','HalaCompaniesGrouping.Delete'])">
    <div class="card-body">
        <h3 >{{'Common.Members'|translate}}</h3>
        <!--Filter Row-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-custom gutter-b example example-compact px-8 py-2">
                    <form class="form" [formGroup]="form">
                        <div class="card-body">
                            <div class="form-group row">
                                <!-- Companies  -->
                                <div class="col-lg-3 mb-3">
                                    <app-companies-dropdown #companiesDropDown (updateParent)="setSelectedCompany($event)"></app-companies-dropdown>
                                </div>
                                <!-- Action Buttons -->
                                <div class="col-lg-3 d-flex align-items-end mb-3">
                                    <div class="action-btns" style="margin-inline-end: 20px;">
                                        <button type="submit" (click)="search()"
                                        class="btn btn-primary font-weight-bold px-10 mr-2">
                                            {{'Common.Search'|translate}}
                                        </button>
                                    </div>
                                    <div class="action-btns">
                                        <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                            {{'Common.Clear'|translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="mt-3" #groupMembers>
            <app-companies-group-table [datasource]="companiesDataSource"></app-companies-group-table>
         </div>
         <!-- Pagination -->
         <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
    </div>
</div>
