<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">
            <!-- Customer -->
            <div class="col-lg-12">
                <label> <span class="requiredField">*</span> {{'Common.ClientName'|translate}} :</label>
                <app-agents-dropdown #agentsDropDown (updateParent)="setSelectedAgent($event)"></app-agents-dropdown>
            </div>
            <!-- Added Amount -->
            <div class="col-lg-12 mt-3">
                <label> <span class="requiredField">*</span> {{'Common.AddedAmount'|translate}} :</label>
                <input type="number" class="form-control" formControlName="credit_amount" [(ngModel)]="amountDetails.credit_amount" >
            </div>
            <!-- Withdrawn Amount -->
            <div class="col-lg-12 mt-3">
                <label> <span class="requiredField">*</span> {{'Common.WithdrawnAmount'|translate}} :</label>
                <input type="number" class="form-control" formControlName="debit_amount" [(ngModel)]="amountDetails.debit_amount" >
            </div>
            <!-- Note -->
            <div class="col-lg-12 mt-3">
                <label> <span class="requiredField">*</span> {{'Common.Notice'|translate}} :</label>
                <input type="text" class="form-control" formControlName="trans_note" [(ngModel)]="amountDetails.trans_note">
            </div>

            <!-- Action Buttons -->
            <div class="d-flex align-items-end justify-content-end mt-4">
                <div class="action-btns" style="margin-inline-end: 20px;">
                    <button type="submit" [disabled]="form.invalid" (click)="createUpdateIncomingAmounts()"
                    class="btn btn-primary font-weight-bold px-10 mr-2">
                    {{ isEditing ? ('Common.Edit'|translate) : ('Common.Add'|translate) }}</button>
                </div>
                <div class="action-btns">
                    <button (click)="discard()" type="submit" class="btn btn-light font-weight-bold px-10 mr-2">
                        {{'Common.Discard'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>