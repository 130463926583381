import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService, 
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        let authToken = localStorage.getItem("defaultAppToken");
        let lang =  localStorage.getItem("lang")
        if(authToken){
            authToken = authToken.replace(/['"]+/g, '');
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${authToken}`,
                    'accept-language': lang
                }
            });
        }else{
            request = request.clone({
                setHeaders: {
                    'accept-language':lang
                }
            });
        }
        // debugger;
        return next.handle(request).pipe(
            tap(
              event => { 
                if (event instanceof HttpResponse)
                  console.log('request succeeded');
              },
              error => {
                if (error instanceof HttpErrorResponse) {
                    if(error.status==401){
                        this.authService.logout();
                        console.log('Invalid User Permissions will logout',error.status);
                    }
                }
              }
            ));;
    }
}
