import { Component, OnInit, Input,Output, EventEmitter} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { HalaManageCardsService } from 'src/app/core/services/hala/hala-manage-cards.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss']
})
export class CardsTableComponent implements OnInit {

  @Input() datasource;
  @Input() IsApproveComponent;
  @Input() IsMangerReportComponent;
  
  @Output() approveCards = new EventEmitter<any>();
  @Output() returnCards = new EventEmitter<any>();
  @Output() showDetails = new EventEmitter<any>();
  @Output() editCards = new EventEmitter<any>();

  totalCard:number=0;

  constructor(
    public dateFormatService:DateFormatService,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.totalCard = this.datasource[0].SumCardsNo;
    }else{
      this.totalCard = 0;
    }
  }

  approve(data){
    this.approveCards.emit(data)
  }

  return(data){
    this.returnCards.emit(data)
  }

  details(data){
    this.showDetails.emit(data)
  }

  edit(data){
    this.editCards.emit(data)
  }

}
