import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulkCardsRoutingModule } from './bulk-cards-routing.module';
import { GetBulkCardHeaderComponent } from './get-bulk-card-header/get-bulk-card-header.component';
import { CoreModule } from 'src/app/core/core.module';
import { BulkCardsTableComponent } from './get-bulk-card-header/_components/bulk-cards-table/bulk-cards-table.component';
import { BulkDetailsTableComponent } from './get-bulk-card-header/_components/bulk-details-table/bulk-details-table.component';


@NgModule({
  declarations: [
    GetBulkCardHeaderComponent,
    BulkCardsTableComponent,
    BulkDetailsTableComponent
  ],
  imports: [
    CommonModule,
    BulkCardsRoutingModule,
    CoreModule
  ]
})
export class BulkCardsModule { }
