import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HalaCardUpdateService {

  API_URLGetAllCardUpdates = `${environment.apiUrl}/HalaCardUpdate/GetAllCardUpdates`;
  API_URLUpdateCardUpdates = `${environment.apiUrl}/HalaCardUpdate/UpdateCardUpdates`;
  
  constructor(private http:HttpClient) { }

  GetAllCardUpdates(body): Observable<any> {
    const url = this.API_URLGetAllCardUpdates;
    //Http request
    return this.http.post<any>(url,body)
  }

  UpdateCardUpdates(body): Observable<any> {
    const url = this.API_URLUpdateCardUpdates;
    //Http request
    return this.http.post<any>(url,body)
  }
}
