import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardcanActivate } from './shared/gaurds/auth.guard';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) ,
  },

  {
    path: '',
    canActivate: [AuthGuardcanActivate],
    canActivateChild: [AuthGuardcanActivate],
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  { 
    path: 'print', 
    loadChildren: () => import('./pages/print/print.module').then(m => m.PrintModule) ,
  },

  // The wildcard route ('**') must be placed last, so it only gets triggered after Angular has tried all other routes.
  {
    path: '**', 
    redirectTo: '', 
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
