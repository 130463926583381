import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThAdvancedBulkAccountService } from 'src/app/core/services/thuraya/th-advanced-bulk-account.service';
import { ThPosBulkBillService } from 'src/app/core/services/thuraya/th-pos-bulk-bill.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-pos-bulk-data',
  templateUrl: './get-pos-bulk-data.component.html',
  styleUrls: ['./get-pos-bulk-data.component.scss']
})
export class GetPosBulkDataComponent implements OnInit {

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false
  isPrinting:boolean = false

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thPosBulkBillService:ThPosBulkBillService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.Amount')], icon: 'coins', iconColor: '#181c32',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Card')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.OriginalAmount')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Net')], icon: 'dollar-sign', iconColor: '#1bc5bd', amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
    ];
  }

  /*------------------------ Excel Export All data / Print Report ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.thPosBulkBillService.GetPosBulkBillData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "كود الصنف": data[i].I_CODE,
              "اسم الصنف":data[i].Note ,
              "الكمية": data[i].I_QTY,
              "سعر الوحدة": data[i].I_PRICE,
              "الاجمالى": data[i].totalBeforeVAT,
              "الضريبة%": 0.00,
              "الضريبة": 0.00,
              "القيمة": data[i].totalBeforeVAT,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "الكمية": result.Data.SumQty,
            "الاجمالى":Number.parseFloat(result.Data.SumTotalprice).toFixed(2),
            "القيمة": Number.parseFloat(result.Data.SumTotalprice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReport(){
    this.isPrinting =true
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.thPosBulkBillService.GetPosBulkBillData(body).subscribe((result) => {
      this.isPrinting =false
      if(result.IsSuccess && result.Data.resultList.length>0){
        let newWindow = window.open('print/print-pos-bulk-bill','_blank');
        newWindow["result"] = result;
        newWindow["dateFrom"] = this.fromDateCom;
        newWindow["dateTo"] = this.toDateCom;
      }
    })
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      invoiceid: new FormControl(""),
    });
  }
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getPosBulkBillData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thPosBulkBillService.GetPosBulkBillData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.infoList[0].amount[0] = result.Data.SumQty
        this.infoList[1].amount[0] = result.Data.SumPrice
        this.infoList[2].amount[0] = result.Data.SumTotalprice
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0
        this.infoList[1].amount[0] = 0
        this.infoList[2].amount[0] = 0
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if(!this.toDateCom || !this.fromDateCom){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getPosBulkBillData()
    } 
  }

  reset(){
    this.initForm()
    this.datasource =[] 
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.pageNumber = 1
    this.totalCount = 0
    this.infoList[0].amount[0] = 0
    this.infoList[1].amount[0] = 0
    this.infoList[2].amount[0] = 0
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.toDateCom && this.fromDateCom){
      this.getPosBulkBillData();
    }
  }

}
