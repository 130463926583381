import { Component, OnInit,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { HalaProvidersService } from 'src/app/core/services/hala/hala-providers.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import Swal from 'sweetalert2';
import { AddProviderComponent } from '../add-provider/add-provider.component';

@Component({
  selector: 'app-providers-table',
  templateUrl: './providers-table.component.html',
  styleUrls: ['./providers-table.component.scss']
})
export class ProvidersTableComponent implements OnInit {

  @Input() datasource:any;

  isExporting:boolean = false;

  constructor(
    private msgTranlsateService:MessageTranslateService,
    private halaProvidersService:HalaProvidersService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private modalService: NgbModal,
    public permissionService:PermissionService
    ) { }

  ngOnInit(): void {    
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false
    }
    this.halaProvidersService.GetAllProvidersData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم التعريف": data[i].id,
              "إسم المورد": data[i].ProviderName,
              "رقم هاتف المورد": data[i].ProviderPhone,
              "بريد المورد": data[i].ProviderEmail,
              "فعال": data[i].isActive ? "فعال" : "غير فعال",
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------Delete Functions---------------------- */
   //Alert on delete
   showAlert(providerId){
    Swal.fire({
      title: this.msgTranlsateService.msgTranslate('SwalMessages.Delete'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranlsateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteBrand(providerId);
      },
  })
   }

  //delete provider
  deleteBrand(providerId){
     this.halaProvidersService.DeleteProvider(providerId).subscribe(
      (result) => {
        this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccDelete'), "",{
          positionClass: 'toast-bottom-left',
        });
        this.updateView(providerId)
      },
      err => {
        // console.log("err",err)
        this.toastr.error(err.error.errors, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

  updateView(providerId){
    this.datasource = this.datasource.filter(d=>d.id!=providerId);    
  }

  /*----------------------Update Function---------------------- */
  editProvider(providerData){
    let modalRef = this.modalService.open(AddProviderComponent, { centered: true });
    modalRef.componentInstance.isEditing = true;
    modalRef.componentInstance.providerOldData = providerData;
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdata",newData)
      let index = this.datasource.findIndex(d=>d.id==newData.id)
      this.datasource[index] = newData;
    })
  }

  
   /*----------------------Add provider modal---------------------- */
   openModal() {
    let modalRef = this.modalService.open(AddProviderComponent, { centered: true });
    modalRef.componentInstance.isEditing = false;
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdata",newData)
      if(this.datasource.length<10){
        this.datasource.push(newData);
      }
    })
  }


}
