import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';
import { ThAdvancedBulkAccountService } from 'src/app/core/services/thuraya/th-advanced-bulk-account.service';

@Component({
  selector: 'app-get-advanced-bulk-data',
  templateUrl: './get-advanced-bulk-data.component.html',
  styleUrls: ['./get-advanced-bulk-data.component.scss']
})
export class GetAdvancedBulkDataComponent implements OnInit {

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thAdvancedBulkAccountService:ThAdvancedBulkAccountService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCreditAmount')], icon: 'coins', iconColor: '#181c32',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalWithdraw')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCommissions')], icon: 'dollar-sign', iconColor: '#1bc5bd', amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
    ];
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.thAdvancedBulkAccountService.GetAdvancedBulkAccountData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم المستند": data[i].row_no,
              "نوع العملية":data[i].trans_type ,
              "تاريخ العمليه": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateFormat),
              "حساب العميل": data[i].cust_account_id,
              "اسم العميل": data[i].CUSTOMER_NAME,
              "الحساب":data[i].account_id,
              "اضافه": data[i].credit_amount,
              "سحب": data[i].debit_amount,
              "البيان": data[i].trans_note,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "اضافه":Number.parseFloat(result.Data.totalAdditions).toFixed(2),
            "سحب":Number.parseFloat(result.Data.totalWithdraw).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    invoiceid: new FormControl(""),
  });
  }
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAdvancedBulkAccountData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thAdvancedBulkAccountService.GetAdvancedBulkAccountData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.infoList[0].amount[0] = result.Data.totalAdditions
        this.infoList[1].amount[0] = result.Data.totalWithdraw
        this.infoList[2].amount[0] = result.Data.totalCommission
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0
        this.infoList[1].amount[0] = 0
        this.infoList[2].amount[0] = 0
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if(!this.toDateCom || !this.fromDateCom){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getAdvancedBulkAccountData()
    } 
  }

  reset(){
    this.initForm()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.datasource =[] 
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
    this.infoList[0].amount[0] = 0
    this.infoList[1].amount[0] = 0
    this.infoList[2].amount[0] = 0
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.toDateCom && this.fromDateCom){
      this.getAdvancedBulkAccountData();
    }
  }

}
