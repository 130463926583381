import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import Swal from 'sweetalert2';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ThMonthlyTargetService } from 'src/app/core/services/thuraya/th-monthly-target.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-monthly-target',
  templateUrl: './get-monthly-target.component.html',
  styleUrls: ['./get-monthly-target.component.scss']
})
export class GetMonthlyTargetComponent implements OnInit {


  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //[IMPORTANT] This component IS USED FOR MULTIPLE TARGETpages 
  isMainTarget:boolean = false;
  isLevelOne:boolean = false;
  isLevelTwo:boolean = false;

  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Export Variable for loading
  isExporting:boolean = false;
  showStatus:boolean = false;
  showValue = 0;
  isPrinting:boolean = false
  
  //select list variables
  distributorsList:any = []

  private subscriptions: Subscription[] = [];

  @ViewChild("agentsDropDown") agentsDropDown;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thMonthlyTargetService: ThMonthlyTargetService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    //[IMPORTANT] This component IS USED FOR MULTIPLE PAGES
    // console.log("route",this.router.url)
    

    if(this.router.url=="/thuraya/monthly-target/get-monthly-target"){
      this.resetRoute();
      this.isMainTarget = true
    }else if(this.router.url=="/thuraya/monthly-target/get-monthly-target-level-1"){
      this.resetRoute();
      this.isLevelOne = true
    }
    else if(this.router.url=="/thuraya/monthly-target/get-monthly-target-level-2"){
      this.resetRoute();
      this.isLevelTwo = true
    }
  }

  ngOnInit(): void {
    if(this.isMainTarget){
      this.infoList = [
        { headerText: [this.msgTranslateService.msgTranslate('Common.Profit')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
        { headerText: [this.msgTranslateService.msgTranslate('Common.CardAmount')], icon: 'coins',iconColor: '#181c32' , amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Card')]},
        { headerText: [this.msgTranslateService.msgTranslate('Common.TotalSellPrice')], icon: 'dollar-sign',iconColor: '#1bc5bd' ,amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      ];
    } else{
      this.infoList = [
        { headerText: [this.msgTranslateService.msgTranslate('Common.CardAmount')], icon: 'coins',iconColor: '#181c32' , amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Card')]},
        { headerText: [this.msgTranslateService.msgTranslate('Common.TotalSellPrice')], icon: 'dollar-sign',iconColor: '#1bc5bd' ,amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      ];
    }
    this.initForm();
  }

  resetRoute(){
    this.isMainTarget = false;
    this.isLevelOne = false;
    this.isLevelTwo = false;
  }


  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      agentId: this.f.agentId.value,
      distributorsId: this.f.distributorsId.value,
      cardTypeId: this.f.cardTypeId.value,
    }

    //Just like getting data I had to use diff functions as the data returning from every function has different model and some filed names and also here not all column are the same
    if(this.isMainTarget){
      this.exportMainTarget(body);
    }else{
      this.exportLevelsTarget(body);
    }
  }

  exportMainTarget(body){
    const subscr =  this.thMonthlyTargetService.GetMonthlyTarget(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.result.length>0){
          let data = result.Data.result
          for (let i = 0; i < data.length; i++) {   
            let sheet={ 
              "المندوب": data[i].RepresentativeName,
              "رقم المندوب": data[i].RepresentativeNumber,
              "الموزع": data[i].DistributorName,
              "رقم الموزع": data[i].DistributorNumber,
              "نقطة البيع": data[i].PointName,
              "رقم نقطة البيع": data[i].AccountId,
              "ربح الشركة": data[i].CompanyProfit,
              "رقم المنتج": data[i].product_id,
              "اسم الكرت": data[i].class_name,
              "عدد الكروت": data[i].CardsNo,
              "نوع الكرت": data[i].CardType,
              "سعر الموزع": data[i].DistributorPrice,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "ربح الشركة": Number.parseFloat(result.Data.companyProfitSum).toFixed(2),
            "عدد الكروت":Number.parseFloat(result.Data.cardsNo).toFixed(0),
            "سعر الموزع":Number.parseFloat(result.Data.distributorPriceSum).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  exportLevelsTarget(body){

    let url
    if(this.isLevelOne){
      url = this.thMonthlyTargetService.GetMonthlyTarget1(body)
    }else if(this.isLevelTwo){
      url = this.thMonthlyTargetService.GetMonthlyTarget2(body)
    }
    const subscr =  url.subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {   
            let sheet={ 
              "المندوب": data[i].RepresentativeName,
              "رقم المندوب": data[i].RepresentativeNumber,
              "الموزع": data[i].DistributorName,
              "رقم الموزع": data[i].DistributorNumber,
              "نقطة البيع": data[i].PointName,
              "رقم نقطة البيع": data[i].AccountId,
              "عدد الكروت": data[i].CardsNo,
              ...(this.isLevelTwo) && {"نوع الكرت": data[i].CardType},
              "السعر": data[i].DistributorPrice,
              ...(this.showStatus) && {"الحاله":data[i].DistributorPrice >= this.showValue ? "ممتاز" : "ضعيف"}
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "عدد الكروت":Number.parseFloat(result.Data[0].SumCardsNo).toFixed(0),
            "السعر":Number.parseFloat(result.Data[0].SumDistributorPrice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  
  printReport(){
    this.isPrinting =true
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      agentId: this.f.agentId.value,
      distributorsId: this.f.distributorsId.value,
      cardTypeId: this.f.cardTypeId.value,
    }
    let url;
    if(this.isMainTarget){
      url = this.thMonthlyTargetService.GetMonthlyTarget(body)
    }else if(this.isLevelOne){
      url = this.thMonthlyTargetService.GetMonthlyTarget1(body)
    }else if(this.isLevelTwo){
      url = this.thMonthlyTargetService.GetMonthlyTarget2(body)
    }
    url.subscribe((result) => {
      this.isPrinting =false
      if(result.IsSuccess && result.Data.length>0){
        let newWindow = window.open('print/print-monthly-target','_blank');
        newWindow["result"] = result;
        newWindow["isMainTarget"] = this.isMainTarget
        newWindow["isLevelOne"] = this.isLevelOne
        newWindow["isLevelTwo"] = this.isLevelTwo
        newWindow["showStatus"] = this.showStatus
        newWindow["showValue"] = this.showValue
      }
    })
  }

  showStatusInExcel($event){
    this.showStatus = $event.show;
    this.showValue = $event.value
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
    cardTypeId: new FormControl(""),
    distributorsId: new FormControl(""),
    agentId: new FormControl("")
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      agentId: this.f.agentId.value,
      distributorsId: this.f.distributorsId.value,
      cardTypeId: this.f.cardTypeId.value,
    }
    //I had to use diff functions as the data returning from every function has different model and some filed names
    if(this.isMainTarget){
      this.getMonthlyTarget(body) 
    }else{
      this.getLevelsTarget(body) 
    }
  }

  getMonthlyTarget(body){
    return this.thMonthlyTargetService.GetMonthlyTarget(body).subscribe((result) => {
      this.spinner.hide();
      if(result.IsSuccess && result.Data.result.length>0){
        this.datasource = result.Data.result
        this.infoList[0].amount[0] = result.Data.companyProfitSum
        this.infoList[1].amount[0] = result.Data.cardsNo
        this.infoList[2].amount[0] = result.Data.distributorPriceSum
        this.totalCount = result.Data.result[0].TotalRows;
      }
      if(result.Data.result.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0;
        this.infoList[1].amount[0] = 0;
        this.infoList[2].amount[0] = 0;
        this.totalCount = 0;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getLevelsTarget(body){
    let url
    if(this.isLevelOne){
      url = this.thMonthlyTargetService.GetMonthlyTarget1(body)
    }else if(this.isLevelTwo){
      url = this.thMonthlyTargetService.GetMonthlyTarget2(body)
    }
    return url.subscribe((result) => {
      this.spinner.hide();
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.infoList[0].amount[0] = result.Data[0].SumCardsNo
        this.infoList[1].amount[0] = result.Data[0].SumDistributorPrice
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.infoList[0].amount[0] = 0;
        this.infoList[1].amount[0] = 0;
        this.totalCount = 0;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
     
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }


  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.fromDateCom && this.toDateCom ){
      this.getData();
    }
  }

    /*----------------------Search and reset search functions---------------------- */
    search(){
      //reset page number
      this.pageNumber = 1;
      if(this.fromDateCom =="" || this.toDateCom ==""){
        Swal.fire({
          title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
          icon: 'error',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton:false
        });
      }
      else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
        Swal.fire({
          title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
          icon: 'error',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton:false
        });
      }else{
       this.getData()
      } 
    }
  
    reset(){
      this.initForm()
      this.agentsDropDown.reset()
      this.distributorCombo.clear()
      this.dateFromSelector.reset()
      this.dateToSelector.reset();
      this.toDateCom = ""
      this.toDateTimeStamp = ""
      this.fromDateCom = ""
      this.fromDateTimeStamp = ""
      this.pageNumber = 1
      this.datasource = [];
      this.totalCount = 0;
      // this.getData()
    }

  /*----------------------Ng dropdown variables---------------------- */
  //Combox variable
  @ViewChild('distributorCombo') public distributorCombo: ComboBoxComponent
  public distrFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };

  /*----------------------Getting class name for dropdown select---------------------- */

  getAllDistributorsByAgent(){
    let body={
      isPaginated: false,
      company_No:"",
      accountId: this.f.agentId.value,
    }
    const subscr = this.thGeneralSystemService.GetAllDistributorsByAgent(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.distributorsList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedAgent(event){
    if(event!=""){
      this.form.controls.agentId.setValue(event)
      //Empty selected distributor from before only if not editing
      this.form.controls.distributorsId.setValue("")
      //clear selected value
      this.distributorCombo.clear()
      //get new cards
      this.getAllDistributorsByAgent()
    }else{
      this.form.controls.agentId.setValue("")
      //Empty selected distributor from before
      this.form.controls.distributorsId.setValue("")
      this.distributorsList = [];
      //clear selected value
      this.distributorCombo.clear()
    }
  }
  /*----------------------when Choosing distributor---------------------- */
  changeSelectedDistributor(event){
    if(event.itemData){
      let distId = event.itemData.CUSTOMER_ID
      this.form.controls.distributorsId.setValue(distId)
      // console.log(this.f.distributorsId.value)
    }else{
      this.form.controls.distributorsId.setValue("")
      // console.log(this.f.distributorsId.value)
    }

  }

}
