import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HalaProvidersService } from 'src/app/core/services/hala/hala-providers.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit {

  // Form Variables
  public addForm: FormGroup;

  // Editing Variables
  @Input() public isEditing;
  @Input() public providerOldData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();
  providerDetails= {
    id: "",
    providerName: "",
    providerEmail: "",
    providerPhone:"",
    IsActive:""
  }

  constructor(
    private fb: FormBuilder,
    private halaProvidersService:HalaProvidersService,
    private toastr: ToastrService,
    private messagTranlsateService:MessageTranslateService, 
    private router:Router,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    // console.log("data",this.providerOldData)
    this.initForm()
    this.initProviderDetails()
  }

  /*----------------Form Functions--------------- */
  initForm(){
    this.addForm = this.fb.group({
      id: ['', [Validators.required]],
      providerName: ['', [Validators.required]],
      providerEmail:['', Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      providerPhone: [
        '',
        Validators.compose([
          Validators.pattern("^[0-9]*$"),
          Validators.required,
          Validators.minLength(7), // The shortest international phone numbers in use contain seven digits.
          Validators.maxLength(15), // (Search internet max phone length) the international phone numbering plan (ITU-T E. 164), phone numbers cannot contain more than 15 digits. 
        ]),
      ],
      isActive: ['', Validators.required],
    })

    //In case of editing provider remove validators from the ID
    if(this.isEditing){
      this.addForm.controls.id.clearValidators()
    }
  }

  get f() {
    return this.addForm.controls;
  }

  initProviderDetails(){
    if(this.isEditing){
      this.providerDetails= {
        id: this.providerOldData.id,
        providerName: this.providerOldData.ProviderName,
        providerEmail: this.providerOldData.ProviderEmail,
        providerPhone:this.providerOldData.ProviderPhone,
        IsActive:this.providerOldData.isActive
      }
    }else{
      this.providerDetails= {
        id: "",
        providerName: "",
        providerEmail: "",
        providerPhone:"",
        IsActive:""
      }
    }
    
  }

  /*----------------API Requests--------------- */
  addProvider(){
    //Check fields 
    if (this.addForm.invalid){
      Swal.fire({
        title: this.messagTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      id: this.f.id.value,
      ProviderName: this.f.providerName.value,
      ProviderPhone: this.f.providerPhone.value,
      ProviderEmail: this.f.providerEmail.value,
      isActive: this.f.isActive.value
    }

    this.halaProvidersService.InsertProvider(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.modalService.dismissAll();
          this.toastr.success(this.messagTranlsateService.msgTranslate('ToastMessages.SuccAdd'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.discard();
          //add new row to table
          this.updateParent.emit(body);
        }else{
          this.toastr.error(result.Data, "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.title, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    )  
  }

  editProvider(){
    //Check fields 
    if (this.addForm.invalid){
      Swal.fire({
        title: this.messagTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      id: this.providerOldData.id,
      ProviderName: this.f.providerName.value,
      ProviderPhone: this.f.providerPhone.value,
      ProviderEmail: this.f.providerEmail.value,
      isActive: this.f.isActive.value
    }

    this.halaProvidersService.UpdateProvider(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.modalService.dismissAll();
          this.toastr.success(this.messagTranlsateService.msgTranslate('ToastMessages.SuccUpdate'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.discard();
          //add row in table
          this.updateParent.emit(body);
        }else{
          this.toastr.error(result.Data, "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.title, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    )  
  }

  /*------------------------ Add/Remove Photos Validation depending on editing/Adding  ------------------------ */
  discard(){
    this.initForm();
    this.initProviderDetails()
    this.modalService.dismissAll();
  }
}
