import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetRolesComponent } from './get-roles/get-roles.component';

const routes: Routes = [
  { path: 'get-roles', component:GetRolesComponent,canActivate: [AuthGuard],  data: {permission: ['HalaRole.View','HalaRole.Create','HalaRole.Update','HalaRole.Delete'], roles:['SuperAdmin']} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule { }
