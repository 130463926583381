<div class="table-responsive mb-0">
    <table class="table table-centered ">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.RowNo'| translate}}</th>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'Common.ClassNo'| translate}}</th>
          <th class="align-middle">{{'Common.ClassName'| translate}}</th>
          <th class="align-middle">{{'Common.ClassType'| translate}}</th>
          <th class="align-middle">{{'Common.ClassPrice'| translate}}</th>
          <th class="align-middle">{{'Common.ClassCost'| translate}}</th>
          <th class="align-middle">{{'Common.Status'| translate}}</th>
          <th class="align-middle">{{'Common.ProductId'| translate}}</th>
          <!-- <th class="align-middle">{{'Common.Request_Code'| translate}}</th>
          <th class="align-middle">{{'Common.auto_min_qty'| translate}}</th>
          <th class="align-middle">{{'Common.auto_reorder_count'| translate}}</th>
          <th class="align-middle">{{'Common.auto_order_status'| translate}}</th> -->
          <th class="align-middle" *ngIf="permissionService.checkPermission(['ThdbServiceClassSetting.Update'])">{{'Common.Edit'|translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.row_no}}</a>
          </td>
          <td>{{data.service_id}}</td>
          <td>{{data.class_no}}</td>
          <td>{{data.class_name}}</td>
          <td>{{data.class_type}}</td>
          <td>{{data.class_price}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.status}}</td>
          <td>{{data.product_id}}</td>
          <!-- <td>{{data.request_code}}</td>
          <td>{{data.auto_min_qty}}</td>
          <td>{{data.auto_reorder_count}}</td>
          <td>{{data.auto_order_status}}</td> -->
          <!-- Update button only available if not showing excel data  -->
          <td *ngIf="permissionService.checkPermission(['ThdbServiceClassSetting.Update']) ">
            <button class="btn btn-info" (click)="openUpdateDialog(data)">{{'Common.Edit' | translate}}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>