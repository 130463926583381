<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{'HALA.Providers.AddProvider'|translate}}</h5>
                </div>
                <div class="card-body">
                    <div class="row product-adding">
                         <!-- Add Form -->
                        <form class="needs-validation" [formGroup]="addForm">
                            <div class="add-product-form row">
                                <div class="form-group col-lg-8" *ngIf="!isEditing">
                                    <label for="validationCustom01" class="mb-0">{{'HALA.Providers.OracleId'|translate}} :</label>
                                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                                        type="number" formControlName="id"  [(ngModel)]="providerDetails.id" min="0">
                                    <div *ngIf="addForm.controls.id.touched && addForm.controls.id.errors?.required"
                                        class="invalid-input">
                                        {{'Common.IdReq'|translate}}
                                    </div>
                                </div>

                                <div class="form-group col-lg-8 mt-3">
                                    <label for="validationCustom01" class="mb-0">{{'HALA.Providers.ProviderName'|translate}} :</label>
                                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                                        type="text" formControlName="providerName"  [(ngModel)]="providerDetails.providerName">
                                    <div *ngIf="addForm.controls.providerName.touched && addForm.controls.providerName.errors?.required"
                                        class="invalid-input">
                                        {{'HALA.Providers.ProviderNameReq'|translate}}
                                    </div>
                                </div>
                                <div class="form-group col-lg-8 mt-3">
                                    <label for="validationCustom02" class="mb-0">{{'HALA.Providers.ProviderPhone'|translate}} :</label>
                                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom02"
                                        type="text" formControlName="providerPhone" [(ngModel)]="providerDetails.providerPhone">
                                    <div *ngIf="addForm.controls.providerPhone.touched && addForm.controls.providerPhone.errors?.required || addForm.controls.providerPhone.hasError('pattern')"
                                        class="invalid-input">
                                        {{'HALA.Providers.ProviderPhoneReq'|translate}}
                                    </div>
                                    <div *ngIf="addForm.controls.providerPhone.touched && addForm.controls.providerPhone.hasError('minlength')"
                                        class="invalid-input">
                                        {{'Register.PhoneSmall'|translate}}
                                    </div>
                                    <div *ngIf="addForm.controls.providerPhone.touched && addForm.controls.providerPhone.hasError('maxlength')"
                                        class="invalid-input">
                                        {{'Register.PhoneHuge'|translate}}
                                    </div>
                                </div>
                                <div class="form-group col-lg-8 mt-3">
                                    <label for="validationCustom02" class="mb-0">{{'HALA.Providers.ProviderEmail'|translate}} :</label>
                                    <input class="form-control col-xl-8 col-sm-7" id="validationCustom02"
                                        type="text" formControlName="providerEmail" [(ngModel)]="providerDetails.providerEmail">
                                    <div *ngIf="addForm.controls.providerEmail.touched && addForm.controls.providerEmail.errors?.required"
                                        class="invalid-input">
                                        {{'HALA.Providers.ProviderEmailReq'|translate}}
                                    </div>
                                    <div *ngIf="addForm.controls.providerEmail.touched && addForm.controls.providerEmail.errors?.email"
                                        class="invalid-input">
                                        {{'Login.EmailInvalid'|translate}}
                                    </div>
                                </div>
                                <div class="form-group col-lg-8 mt-3">
                                    <label for="exampleFormControlSelect1" class="mb-0">{{'Common.Active'|translate}}</label>
                                    <select class="form-select digits col-xl-8 col-sm-7" formControlName="isActive"  [(ngModel)]="providerDetails.IsActive"
                                        id="exampleFormControlSelect1">
                                        <option value="" [selected]>{{'Common.ChooseStatus'|translate}}</option>
                                        <option  [ngValue]="true">{{'Common.Active'|translate}}</option>
                                        <option  [ngValue]="false">{{'Common.NotActive'|translate}}</option>
                                    </select>
                                    <div *ngIf="addForm.controls.isActive.touched && addForm.controls.isActive.errors?.required"
                                    class="invalid-input">
                                    {{'Common.ActiveReq'|translate}}
                                </div>
                                </div>
                            </div>
                        </form>
                        <div class="d-flex justify-content-end mt-3">
                            <div>
                                <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="addForm.invalid" (click)="isEditing ? editProvider() : addProvider()">{{ isEditing ? ('Common.Edit'|translate) : ('Common.Add'|translate) }}</button>
                                <button type="button" class="btn btn-light" (click)="discard()">{{'Common.Discard'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->