import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-companies-sales-table',
  templateUrl: './companies-sales-table.component.html',
  styleUrls: ['./companies-sales-table.component.scss']
})
export class CompaniesSalesTableComponent implements OnInit {

  @Input() datasource;
  TotalCardsNo:number = 0;
  TotalCost:number = 0;
  TotalSellPrice:number = 0;
  TotalProfit:number = 0;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.TotalCardsNo = this.datasource[0].TotalCardsNo;
      this.TotalProfit = this.datasource[0].TotalProfit;
      this.TotalSellPrice = this.datasource[0].TotalSellPrice;
      this.TotalCost = this.datasource[0].TotalCost;
    }else{
      this.TotalCardsNo = 0;
      this.TotalProfit = 0;
      this.TotalSellPrice = 0;
      this.TotalCost = 0;
    }
  }

}
