<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.ServiceId'| translate}} </th>
            <th class="align-middle">{{'Common.ClassName'| translate}} </th>
            <th class="align-middle">{{'Common.TransDate'| translate}}</th>
            <th class="align-middle">{{'Common.Account'| translate}} </th>
            <th class="align-middle">{{'Common.SellPrice'| translate}} </th>
            <th class="align-middle">{{'Common.Serial'| translate}} </th>
            <th class="align-middle">{{'Common.Pin'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.note}}</td>
          <td>{{data.trans_date | date:dateFormatService.dateFormat}}</td>
          <td>{{data.account_id}}</td>
          <td>{{data.point_price}}</td>
          <td>{{data.card_serial}}</td>
          <td>{{data.card_scratch}}</td>
        </tr>
        <tr >
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <div class="d-flex flex-column">
                  <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                  <span>{{sumPrice==0 ? '-' :sumPrice | number : '1.0-2'}}</span>
                </div>
            </td>
            <td></td>
            <td></td>
          </tr>
      </tbody>
    </table>
</div>