import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectivePosReportRoutingModule } from './effective-pos-report-routing.module';
import { GetEffectivePosDataComponent } from './get-effective-pos-data/get-effective-pos-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { EffectivePosTableComponent } from './get-effective-pos-data/_components/effective-pos-table/effective-pos-table.component';


@NgModule({
  declarations: [
    GetEffectivePosDataComponent,
    EffectivePosTableComponent
  ],
  imports: [
    CommonModule,
    EffectivePosReportRoutingModule,
    CoreModule
  ]
})
export class EffectivePosReportModule { }
