import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThManageIncomingAmountsService } from 'src/app/core/services/thuraya/th-manage-incoming-amounts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-amount-dialog',
  templateUrl: './add-amount-dialog.component.html',
  styleUrls: ['./add-amount-dialog.component.scss']
})
export class AddAmountDialogComponent implements OnInit {

  @Input() isEditing;
  @Input() amountOldData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  amountDetails= {
    cust_account_id: "",
    credit_amount: "",
    debit_amount: "",
    trans_note: "",
  }

  //Form variables
  form: FormGroup;

  @ViewChild("agentsDropDown") agentsDropDown;
  
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private thGeneralSystemService:ThGeneralSystemService,
    private thManageIncomingAmountsService: ThManageIncomingAmountsService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    ) { }

  ngOnInit(): void {
    this.initForm();
    //a little time to be able to see the combox and set the value in case of edit
    setTimeout(() => {
      this.initAmountDetails()
    }, 100);
  }

  initAmountDetails(){
    // console.log("init data",this.amountOldData)
    if(this.isEditing){
      this.f.cust_account_id.setValue(this.amountOldData.cust_account_id)
      //we must put it in [] as we select the id later from between the []
      this.agentsDropDown.setValue( `[${this.amountOldData.cust_account_id}]`)
      this.amountDetails= {
        cust_account_id: this.amountOldData.cust_account_id,
        credit_amount: this.amountOldData.credit_amount,
        debit_amount: this.amountOldData.debit_amount,
        trans_note: this.amountOldData.trans_note,
      }
    }else{
      this.amountDetails= {
        cust_account_id: "",
        credit_amount: "",
        debit_amount: "",
        trans_note: "تعبئة رصيد",
      }
    }
    
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      cust_account_id: new FormControl("",Validators.required),
      credit_amount: new FormControl("",Validators.required),
      debit_amount: new FormControl("",Validators.required),
      trans_note: new FormControl("تعبئة رصيد",Validators.required),
    });
  }
 
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  createUpdateIncomingAmounts() {
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      row_no: this.isEditing ? this.amountOldData.row_no : "",
      trans_note: this.f.trans_note.value,
      cust_account_id: this.f.cust_account_id.value,
      credit_amount: this.f.credit_amount.value,
      debit_amount: this.f.debit_amount.value
    }
    return this.thManageIncomingAmountsService.CreateUpdateIncomingAmounts(body).subscribe((result) => {
      if(result.IsSuccess ){
        this.modalService.dismissAll();
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccAdd'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.discard();
          //add row in table
          this.updateParent.emit(result.Data[0]);
      }
      else{
        this.toastr.error(result.Data, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    },(err)=>{
      this.toastr.error(err.error.title, "", {
        positionClass: 'toast-bottom-left',
      });
    }
  );
  }
  
  /*----------------------Close Dialog---------------------- */
  discard(){
    this.modalService.dismissAll()
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedAgent(event){
    this.form.controls.cust_account_id.setValue(event)
  }

}
