<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.BillNo'| translate}}</th>
          <th class="align-middle">{{'Common.BillDate'| translate}}</th>
          <th class="align-middle">{{'Common.BillType'| translate}}</th>
          <th class="align-middle">{{'Common.OriginalAmount'| translate}}</th>
          <th class="align-middle">{{'Common.CardAmount'| translate}} </th>
          <th class="align-middle">{{'Common.SendOperation'| translate}} </th>
          <th class="align-middle">{{'Common.SendResult'| translate}} </th>
          <th class="align-middle">{{'Common.AgentName'| translate}} </th>
          <th class="align-middle"> {{'Common.ClientPhone'| translate}}</th>
          <th class="align-middle"> {{'Common.Details'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.BILL_NO}}</a>
          </td>
            <td>
                <p> {{data.BILL_DATE | date:dateFormatService.dateOnlyFormat}} </p>
                <p> {{data.BILL_TIME | date:dateFormatService.timeOnlyFormat}} </p>
            </td>
          <td>{{data.BILL_TYPE}}</td>
          <td>{{data.BILL_AMT}}</td>
          <td>{{data.totalRcrd_No}}</td>
          <td>{{data.SentStatus}}</td>
          <td>{{data.Message}}</td>
          <td>{{data.Customer_Name}}</td>
          <td>{{data.AD_U_ID}}</td>
          <td class="text-center"><i class="fa-solid fa-circle-info" style="font-size: 18px;" (click)="details(data)"></i></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalValue'| translate}}</span> 
              <span>{{totalAmount==0 ? '-' :totalAmount | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalCost'| translate}}</span> 
              <span>{{totalCost==0 ? '-' :totalCost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>