import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PurchasesRoutingModule } from './purchases-routing.module';
import { GetAllPurchasesDataComponent } from './get-all-purchases-data/get-all-purchases-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { PurchaseTableComponent } from './get-all-purchases-data/_components/purchase-table/purchase-table.component';
import { PurchaseRateTableComponent } from './get-all-purchases-data/_components/purchase-rate-table/purchase-rate-table.component';
import { PurchaseTargetTableComponent } from './get-all-purchases-data/_components/purchase-target-table/purchase-target-table.component';


@NgModule({
  declarations: [
    GetAllPurchasesDataComponent,
    PurchaseTableComponent,
    PurchaseRateTableComponent,
    PurchaseTargetTableComponent,
  ],
  imports: [
    CommonModule,
    PurchasesRoutingModule,
    CoreModule
  ]
})
export class PurchasesModule { }
