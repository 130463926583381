import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HalaBankService } from '../../services/hala/hala-bank.service';

@Component({
  selector: 'app-banks-dropdown',
  templateUrl: './banks-dropdown.component.html',
  styleUrls: ['./banks-dropdown.component.scss']
})
export class BanksDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private halaBankService:HalaBankService,
  ) { }

  ngOnInit(): void {
    this.initComboSharedInput()
  }

  /*----------------------Functions Called from parent---------------------- */
  reset(){
    this.bankNameVal = ""
  }
  
  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public bankscomboName="banks";
  public bankNameVal: string= "";
  shrdInpt= {}

  initComboSharedInput(){
    this.shrdInpt = {
      banks:{},
    };
    //Made these outside initComboSharedInputValues() because we need to have the refrence of combox and do not reset it in any case
    this.shrdInpt[this.bankscomboName].comboboxref =null

    this.initComboSharedInputValues(this.bankscomboName);
    this.getBanks()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting banks for dropdown select---------------------- */

  getBanksBody(){
    let body={}
    if(this.shrdInpt[this.bankscomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.bankscomboName].pageNumber,
        pageSize: this.shrdInpt[this.bankscomboName].pageSize,
        // searchString: this.shrdInpt[this._comboName].searchText,
        isPaginated: true,
      }
    }else{
      this.shrdInpt[this.bankscomboName].pageNumberBeforeSearch = this.shrdInpt[this.bankscomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.bankscomboName].pageNumber,
        pageSize: this.shrdInpt[this.bankscomboName].pageSize,
        isPaginated: true,
      }
    } 
    return body;
  }

  getBanks() {
    let body = this.getBanksBody();
    this.shrdInpt[this.bankscomboName].isLoading = true;
    const subscr = this.halaBankService.GetAllBank(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let data = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.bankscomboName].isSearching){
            this.shrdInpt[this.bankscomboName].comboBoxList = []
              if(data.length){
                this.shrdInpt[this.bankscomboName].endData = false;
                for (let i = 0; i < data.length; i++) {
                  this.shrdInpt[this.bankscomboName].comboBoxList.push(`[${data[i].Id}] - ${data[i].BankName}`);
                  this.shrdInpt[this.bankscomboName].loadedList.push(data[i]);
                 
                }
                this.shrdInpt[this.bankscomboName].comboboxref.addItem(this.shrdInpt[this.bankscomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.bankscomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.bankscomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.bankscomboName].endData = true;
              }
          }else{
            if(data.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.bankscomboName].oldSearchText!=this.shrdInpt[this.bankscomboName].searchText){
                this.shrdInpt[this.bankscomboName].searchList= [];
                //update old value
                this.shrdInpt[this.bankscomboName].oldSearchText = this.shrdInpt[this.bankscomboName].searchText;
              }
              this.shrdInpt[this.bankscomboName].endData = false;
              for (let i = 0; i < data.length; i++) {
                this.shrdInpt[this.bankscomboName].searchList.push(`[${data[i].Id}] - ${data[i].BankName}`);
              }
              this.shrdInpt[this.bankscomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.bankscomboName].combBoxEvent.updateData(this.shrdInpt[this.bankscomboName].searchList);
            }else{
              this.shrdInpt[this.bankscomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.bankscomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.bankscomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.bankscomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */
  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }

  /*----------------------when Choosing Bank---------------------- */
  changeSelectedBank(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let indexofendId = selectedValue.indexOf(']')
      let bankSelected = selectedValue.substring(1,indexofendId)
      this.bankNameVal = selectedValue
      this.updateParent.emit(bankSelected);
    }else{
      this.updateParent.emit("");
    }
  }
  
}
