<div class="card">
    <div class="card-body">
        <h4 class="card-title">{{'HALA.BANK.BANKLIST'|translate}}</h4>
        <!-- <p class="card-sub-title"> Example of editable table.</p> -->
        <!-- Id Filter -->
        <div class="my-4" *ngIf="permissionService.checkPermission(['HalaBank.View'])">
            <form [formGroup]="filterForm">
              <div class="row d-flex justify-content-start">
                <div class="form-group col-lg-4 idFilter">
                  <label for="validationCustom01" class="">{{'Common.Id'|translate}}:</label>
                  <input class="form-control" id="validationCustom01" type="text" formControlName="id">
                </div>
                  
                <div class="col-lg-4 d-flex align-items-end">
                  <button class="btn btn-primary" (click)="getBankById()" [disabled]="f.id.value==''"> {{'Common.Show'|translate}}</button>
                  <button class="btn btn-danger mx-3" (click)="resetFilters()" [disabled]="!showingFilteredData"> {{'Common.Clear'|translate}}</button>
                </div>
              </div>
          </form> 
        </div>
        <!-- Export Excel -->
        <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)" *ngIf="permissionService.checkPermission(['HalaBank.View'])"></app-excel-export>
        <!-- Table -->
        <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
        <div class="tableContainer">
          <ng2-smart-table [settings]="settings" [source]="datasource" 
          (editConfirm)="editBankConfirm($event)" 
          (createConfirm)="createBankConfirm($event)"
          (deleteConfirm)="showAlert($event)"
          ></ng2-smart-table>
          <!-- Pagination -->
          <app-paginator [totalCount]="totalCount" [pageNumber]="pageNo" (changePage)="changePage($event)" ></app-paginator>
        </div>
        
    </div>
</div>