import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThBulkCardsService {
  
  API_URLGetAllBulkCardsHeader = `${environment.apiUrl}/ThdbBulkCards/GetAllBulkCardsHeader`;
  API_URLGetAllBulkCardsDetails = `${environment.apiUrl}/ThdbBulkCards/GetAllBulkCardsDetails`;
  API_URLActiveIsPrintedFlag = `${environment.apiUrl}/ThdbBulkCards/ActiveIsPrintedFlag`;

  constructor(private http:HttpClient) { }

  GetAllBulkCardsHeader(body): Observable<any> {
    const url = this.API_URLGetAllBulkCardsHeader;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllBulkCardsDetails(body): Observable<any> {
    const url = this.API_URLGetAllBulkCardsDetails;
    //Http request-
    return this.http.post<any>(url,body)
  }

  ActiveIsPrintedFlag(Id): Observable<any> {
    const url = this.API_URLActiveIsPrintedFlag;
    //Http request-
    return this.http.post<any>(`${url}?invoiceId=${Id}`, {})
  }
}
