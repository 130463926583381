<div class="col-12" *ngIf="permissionService.checkPermission(['HalaProviders.Create'])">
  <div class="text-sm-end">
    <button type="button" class="btn btn-success btn-rounded mb-2 me-2" (click)="openModal()"><i class="mdi mdi-plus mr-1"></i>
      {{'HALA.Providers.AddProvider'| translate}}</button>
  </div>
</div>

<!-- Export Excel -->
<app-excel-export *ngIf="permissionService.checkPermission(['HalaProviders.View'])" [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)" ></app-excel-export>

<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <!-- <th style="width: 20px;">
            <div class="form-check font-size-16 align-middle">
              <input class="form-check-input" type="checkbox" id="transactionCheck01">
              <label class="form-check-label" for="transactionCheck01"></label>
            </div>
          </th> -->
          <th class="align-middle">{{'HALA.Providers.OracleId'| translate}}</th>
          <th class="align-middle">{{'HALA.Providers.ProviderName'| translate}}</th>
          <th class="align-middle">{{'HALA.Providers.ProviderPhone'| translate}}</th>
          <th class="align-middle">{{'HALA.Providers.ProviderEmail'| translate}}</th>
          <th class="align-middle">{{'Common.Active'| translate}} </th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaProviders.Update','HalaProviders.Delete'])" ></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <!-- <td>
            <div class="form-check font-size-16">
              <input class="form-check-input" type="checkbox" id="transactionCheck{{data.index}}">
              <label class="form-check-label" for="transactionCheck{{data.index}}"></label>
            </div>
          </td> -->
          <td>
            <a class="text-body fw-bold">{{data.id}}</a>
          </td>
          <td>{{data.ProviderName}}</td>
          <td>{{data.ProviderPhone}}</td>
          <td>{{data.ProviderEmail}}</td>
          <td>
            <span *ngIf="data.isActive">{{'Common.Active'| translate}}</span>
            <span *ngIf="!data.isActive">{{'Common.NotActive'| translate}}</span>
          </td>
          <td *ngIf="permissionService.checkPermission(['HalaProviders.Update','HalaProviders.Delete'])">
            <i *ngIf="permissionService.checkPermission(['HalaProviders.Update'])" class='mdi mdi-pencil font-size-18 text-success' style="margin-inline-end: 10px;" (click)="editProvider(data)"></i>
            <i *ngIf="permissionService.checkPermission(['HalaProviders.Delete'])" class='mdi mdi-delete font-size-18 text-danger' (click)="showAlert(data.id)"></i>
          </td>

          <!-- <td>
            <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]=" { 'badge-soft-danger': data.status === 'Chargeback',
                          'badge-soft-warning':data.status === 'Refund' }">{{data.status}}</span>
          </td>
          <td>
            <i :class="`fab ${data.payment[0]} mr-1`"></i>
            {{data.payment[1]}}
          </td>
          <td> -->
            <!-- Button trigger modal -->
            <!-- <button type="button" class="btn btn-primary btn-sm btn-rounded" (click)="openModal(content)">View
              Details</button>
          </td> -->
        </tr>
      </tbody>
    </table>
  
    <!-- <ng-template #content role="document" let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Order Details</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <p class="mb-2">
          Product id:
          <span class="text-primary">#SK2540</span>
        </p>
        <p class="mb-4">
          Billing Name:
          <span class="text-primary">Neal Matthews</span>
        </p>
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Product Name</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div>
                    <img src="assets/images/product/img-7.png" alt class="avatar-sm" />
                  </div>
                </th>
                <td>
                  <div>
                    <h5 class="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                    <p class="text-muted mb-0">$ 225 x 1</p>
                  </div>
                </td>
                <td>$ 255</td>
              </tr>
              <tr>
                <th scope="row">
                  <div>
                    <img src="assets/images/product/img-4.png" alt class="avatar-sm" />
                  </div>
                </th>
                <td>
                  <div>
                    <h5 class="text-truncate font-size-14">Phone patterned cases</h5>
                    <p class="text-muted mb-0">$ 145 x 1</p>
                  </div>
                </td>
                <td>$ 145</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-end">Sub Total:</h6>
                </td>
                <td>$ 400</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-end">Shipping:</h6>
                </td>
                <td>Free</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-end">Total:</h6>
                </td>
                <td>$ 400</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template> -->
  </div>