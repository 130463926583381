<!-- Card-->
<div class="row" *ngIf="permissionService.checkPermission(['ThdbManageAccounts.View'])">
    <div class="col-xl-3 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!--Filter Row-->
<div class="row"  *ngIf="permissionService.checkPermission(['ThdbManageAccounts.View'])">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact px-8 py-2">
            <form class="form" [formGroup]="form">
                <div class="card-body">
                    <div class="form-group row">
                        <!-- Client Type -->
                        <div class="col-lg-3 mb-3 ">
                            <label>{{'Common.ClientType'|translate}}:</label>
                            <select formControlName="user_type" class="form-select">
                                <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                                <option style="font-size: 16px !important" value="1"> {{'Common.Agent'|translate}}</option>
                                <option style="font-size: 16px !important" value="2"> {{'Common.Distributor'|translate}}</option>
                                <option style="font-size: 16px !important" value="3"> {{'Common.POS'|translate}}</option>
                                <option style="font-size: 16px !important" value="5"> {{'Common.FinalClient'|translate}}</option>
                            </select>
                        </div>
                        <!-- Client Status -->
                        <div class="col-lg-3 mb-3 ">
                            <label>{{'Common.ClientStatus'|translate}}:</label>
                            <select formControlName="status" class="form-select">
                                <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                                <option style="font-size: 16px !important" value="0"> {{'Common.Stopped'|translate}}</option>
                                <option style="font-size: 16px !important" value="1"> {{'Common.Working'|translate}}</option>
                                <option style="font-size: 16px !important" value="5"> {{'Common.Hold'|translate}}</option>
                            </select>
                        </div>
                        <!-- Companies  -->
                        <div class="col-lg-3 mb-3">
                            <app-companies-dropdown #companiesDropDown (updateParent)="setSelectedCompany($event)"></app-companies-dropdown>
                        </div>
                        <!-- Agents  -->
                        <div class="col-lg-3 mb-3">
                            <label>{{'Common.Agents'|translate}} :</label>
                            <ejs-combobox #agentsCombo [dataSource]="agentsList"  [fields]="agentFields" (change)="changeSelectedAgnet($event)" ></ejs-combobox>
                        </div>
                        <!-- Distributors  -->
                        <div class="col-lg-3 mb-3">
                            <label>{{'Common.Distributors'|translate}} :</label>
                            <ejs-combobox #distributorCombo [dataSource]="distributorsList"  [fields]="distrFields" (change)="changeSelectedDistributor($event)" ></ejs-combobox>
                        </div>
                        <!-- Distributors  -->
                        <div class="col-lg-3 mb-3">
                            <label>{{'Common.ClientPhone'|translate}} :</label>
                            <input class="form-control" formControlName="accountId" >
                        </div>
                    
                        <!-- Action Buttons -->
                        <div class="col-lg-3 mb-3 d-flex align-items-end">
                            <div class="action-btns" style="margin-inline-end: 20px;">
                                <button type="submit" (click)="search()"
                                class="btn btn-primary font-weight-bold px-10 mr-2">
                                    {{'Common.Search'|translate}}
                                </button>
                            </div>
                            <div class="action-btns">
                                <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                    {{'Common.Clear'|translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Add Account -->
<div class="col-12 d-flex align-items-center justify-content-between">
    <div class="text-sm-end"  *ngIf="permissionService.checkPermission(['ThdbManageAccounts.Create'])">
      <button type="button" class="btn btn-success btn-rounded  me-2" (click)="addAccount()"><i class="mdi mdi-plus mr-1"></i>
        {{'SYSTEMSETTINGS.CreateAccounts.AddAccount'| translate}}</button>
    </div>
     <!-- Export Excel -->
    <app-excel-export  *ngIf="permissionService.checkPermission(['ThdbManageAccounts.View'])" [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
</div>



<!-- Table/Paginator -->
<div class="card" *ngIf="permissionService.checkPermission(['ThdbManageAccounts.View','ThdbManageAccounts.Update'])">
    <div class="card-body">
         <div class="mt-3">
            <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
            <app-accounts-table [datasource]="datasource"></app-accounts-table>
         </div>
        <!-- Pagination -->
        <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
    </div>
</div>