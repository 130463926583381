import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardUpdateRoutingModule } from './card-update-routing.module';
import { GetCardUpdatesComponent } from './get-card-updates/get-card-updates.component';
import { CoreModule } from 'src/app/core/core.module';
import { CardUpdatesTableComponent } from './get-card-updates/_components/card-updates-table/card-updates-table.component';
import { EditDataDialogComponent } from './get-card-updates/_components/edit-data-dialog/edit-data-dialog.component';


@NgModule({
  declarations: [
    GetCardUpdatesComponent,
    CardUpdatesTableComponent,
    EditDataDialogComponent
  ],
  imports: [
    CommonModule,
    CardUpdateRoutingModule,
    CoreModule
  ]
})
export class CardUpdateModule { }
