<!--begin::Stats Widget 29-->
<div class="card card-custom bgi-no-repeat card-stretch gutter-b"
style="background-position: left top; background-size: 30% auto; background-image: url(assets/images/abstract-11.svg); background-repeat: no-repeat;">
<!--begin::Body-->
<div class="card-body">
    <i class="info-icon d-block" [className]="'fa fa-' + icon" [ngStyle]="{'color': iconColor}"  style="margin-bottom: 10px !important;"></i>
    <div *ngFor="let header of headerText let i = index">
        <h5 class="font-weight-bold d-inline-block mx-1">{{header}}:</h5>
        <span class="card-title font-weight-bolder font-size-h1 mb-0 " [ngStyle]="{'color': iconColor}">{{ amount[i] }}</span>
        <span class="font-weight-bold text-muted font-size-sm mx-1">{{endText[i]}}</span>
    </div>
</div>
<!--end::Body-->
</div>
<!--end::Stats Widget 29-->
