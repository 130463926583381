<!-- Card-->
<div class="row">
    <div class="col-xl-4 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- BillNo -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.BillNo'|translate}}:</label>
                        <input type="text" class="form-control" formControlName="invoiceid">
                    </div>
                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel -->
<!-- <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export> -->


<ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>

<div class="card py-3 px-3">
    <!-- Table -->
    <app-bulk-cards-table [datasource]="datasource" (getDetails)="getAllBulkCardsDetails($event)"></app-bulk-cards-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>


<!-- Export Excel -->
<div class="d-flex justify-content-end align-items-center" *ngIf="detailsDatasource.length>0">
    <div class="form-check me-3">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="changeExportExcelWithClassName($event)">
        <label class="form-check-label" for="flexCheckDefault">
          {{'Common.ExportExcelWithName'|translate}}
        </label>
    </div>
    <app-excel-export [datasource]="detailsDatasource" [isExporting]="isExportingDetails" (exportExcel)="checkBillIsPrinted()"></app-excel-export>
</div>

<div #detailsGrid>
    <div class="card py-3 px-3" *ngIf="detailsDatasource.length>0">
        <h3>{{'HALA.ApproveCards.CardDetails'| translate}}</h3>
        <hr>
        <!-- Table -->
        <app-bulk-details-table [datasource]="detailsDatasource" [detailsTotalCount]="detailsTotalCount"></app-bulk-details-table>
        
        <!-- Pagination -->
        <app-paginator [totalCount]="detailsTotalCount" [pageNumber]="detailsPageNumber" (changePage)="changePageDetails($event)" ></app-paginator>
    </div>
</div>



