import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPriceSettingsService } from 'src/app/core/services/thuraya/th-price-settings.service';
import Swal from 'sweetalert2';
import { CopyDialogComponent } from './_components/copy-dialog/copy-dialog.component';
import * as XLSX from 'xlsx';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-get-price-data',
  templateUrl: './get-price-data.component.html',
  styleUrls: ['./get-price-data.component.scss']
})
export class GetPriceDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  dataSource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  // Updating price with excel variables
  @ViewChild("excelFile") excelFile: ElementRef;
  isActive: boolean = false;
  showingExcelData:boolean = false;
  GFile: File;
  GFileName: string;
  GDataFileList = [];
  arrayBuffer: any;
    
  isExporting:boolean = false;

  @ViewChild("servicesDropDown") servicesDropDown;
  @ViewChild("companiesDropDown") companiesDropDown;

  constructor(
    private thGeneralSystemService:ThGeneralSystemService,
    private thPriceSettingsService: ThPriceSettingsService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private msgTranlsateService:MessageTranslateService,
    private excelService:ExcelService,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      company_no: this.f.company_no.value,
      service_id: this.f.ServiceId.value,
    }
    this.thPriceSettingsService.GetAllPriceData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "row_no": data[i].row_no,
              "group_no": this.f.company_no.value,
              "class_no": data[i].class_no,
              "service_id": data[i].service_id,
              "class_name": data[i].class_name,
              "product_id": data[i].product_id,
              "class_cost": data[i].class_cost,
              "class_price": data[i].class_price,
              "agent_amount": data[i].agent_amount,
              // "dest_amount": data[i].dest_amount,
              // "end_point_amount": data[i].end_point_amount
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    company_no: new FormControl("",Validators.required),
    ServiceId: new FormControl("",Validators.required),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getAllPriceData() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      company_no: this.f.company_no.value,
      service_id: this.f.ServiceId.value,
    }
    this.thPriceSettingsService.GetAllPriceData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.dataSource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }else{
          this.dataSource = []
          this.totalCount = 0;
          this.pageNumber = 1
          this.toastr.error(this.msgTranlsateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.dataSource = []
        this.totalCount = 0;
        this.pageNumber = 1
        this.spinner.hide(); 
      }
    )
  }

  updatePrice(data){
    Swal.fire({
      input: "text",
      inputLabel: this.msgTranlsateService.msgTranslate('Common.UpdatePrice'),
      inputValue: "",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranlsateService.msgTranslate('Common.Update'),
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        let newValue = parseFloat(value);
        let cost = parseFloat(data.class_cost);
        let price = parseFloat(data.class_price);
        if (!value || isNaN(newValue)) {
          return this.msgTranlsateService.msgTranslate('SwalMessages.PriceReq')
        }
        else if( newValue <= cost || newValue > price){
          return this.msgTranlsateService.msgTranslate('SwalMessages.PriceInvalid')
        }
      },
      preConfirm: (value) => {
        let body = {}
        // new companies has row_no == 0 so we must add new in database (backend logic) so we send more data
        if(parseInt(data.row_no)==0){
          body = {
            row_no: data.row_no,
            agent_amount: value,
            dest_amount: value,
            end_point_amount: value,
            service_id : data.service_id,
            group_no : this.f.company_no.value,
            class_no : data.class_no,
          }
        }else{
          body = {
            row_no: data.row_no,
            agent_amount: value,
            dest_amount: value,
            end_point_amount: value,
            service_id : "",
            group_no : "",
            class_no : "",
          }
        }
        this.thPriceSettingsService.UpdatePriceData(body).subscribe(
          (result)=>{
            if(result.IsSuccess){
              this.dataSource.filter(d=>d.row_no == data.row_no)[0].agent_amount = value
              this.dataSource.filter(d=>d.row_no == data.row_no)[0].dest_amount = value
              this.dataSource.filter(d=>d.row_no == data.row_no)[0].end_point_amount = value
              this.dataSource.filter(d=>d.row_no == data.row_no)[0].group_no = this.f.company_no.value
              this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
                positionClass: 'toast-bottom-left',
              });
            }else{
              this.toastr.success(result.Data, "",{
                positionClass: 'toast-bottom-left',
              });
            }
          },(err)=>{
            this.toastr.error(err.error.title, "",{
              positionClass: 'toast-bottom-left',
            });
          }
        )
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    //as change page get called on init we do not want to get data if no search made
    if(this.dataSource.length!=0){
      this.getAllPriceData();
    }
  }

  /*----------------------Filter---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    //Cancel excel data if shown before 
    this.showingExcelData = false;
    this.isActive = false
    this.GFile = null;
    this.GFileName = null;
    if(this.dataSource.length!=0){
      this.excelFile.nativeElement.value = "";
    }
    this.getAllPriceData()
  
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.companiesDropDown.reset()
    this.pageNumber = 1
    this.dataSource = []
    this.totalCount =0
  }

  /*----------------------Open copy price dialog---------------------- */
  copyPrices(){
    let modalRef = this.modalService.open(CopyDialogComponent, { centered: true });
    // modalRef.componentInstance.groupData = data;
    // modalRef.componentInstance.updateParent.subscribe(() => {
    //   this.dataSource.filter(d=>d.Id== data.Id)[0].companiesCount++;
    //   this.grid.refresh();
    // })
  }

  /*----------------------Updating prices with excel functions---------------------- */
  changeFileListener($event): void {
    this.GFile = $event.target.files[0];
    this.isActive = false;
    this.GFileName = this.GFile.name;
  }

  ShowFileData() {
    //when show file data disable pagination as the loaded data from excel can not be paginated
    this.pageNumber=1
    this.totalCount = 0;
    //Enable expot button
    this.isActive = true;
    this.showingExcelData = true;
    try {
      var file = this.GFile;
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];

        var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.dataSource = arraylist;
        this.GDataFileList = this.dataSource;
      }
    }catch{}
  }

  copyPriceFromExcel() {
    this.spinner.show
    let GListData = this.GDataFileList;
    this.thPriceSettingsService.CopyPriceFromExcel(GListData).subscribe(
      (result) => {
         this.GFile = null;
         this.GFileName = null;
         this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccUpdate'), "", {
          positionClass: 'toast-bottom-left',
        });
      },
    (err)=>{
      this.toastr.error(err.error.title, "", {
        positionClass: 'toast-bottom-left',
      });
    }
    );
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    this.form.controls.ServiceId.setValue(event)
  }

   /*----------------------when Choosing Company---------------------- */
   setSelectedCompany(event){
    if(event!=""){
      this.form.controls.company_no.setValue(event)
    }else{
      this.form.controls.company_no.setValue("")
      //Empty data because we can not update prices if we do not have company_no so this way we are secured
      this.dataSource= [];
      this.totalCount = 0;
      this.pageNumber = 1
    }
  }
}
