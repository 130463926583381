import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';


import { fadeInOut } from './sublevel-menu/sublevel-menu.component';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { INavbarData, menuItemsData } from './nav-data';
import { SideNavToggle } from '../../../shared/models/side-nav-toggle';
import { PermissionService } from '../../../shared/services/permission.service';
import { NavService } from '../../../shared/services/nav.service';
import { ActiveSecondaryLinksPipe } from '../../../shared/pipes/active-secondary-links.pipe';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    fadeInOut,
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SideNavComponent {
  _unsubscribe: Subject<boolean> = new Subject();
  menuExpanded: boolean = true;
  menuItems = menuItemsData;
  screenWidth = 0;
  multiple: boolean = false;
  profileImage = '';
  isMobileView$ = this.navService.isMobileView$
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  @Output() closeMobileMenu: EventEmitter<any> = new EventEmitter();

  constructor(
    public authService: AuthService,
    public router: Router,
    public permissionService:PermissionService,
    private navService:NavService,
    private activeSecondaryLinksPipe:ActiveSecondaryLinksPipe
  ) {

    // On logout shrink all items
    // this.authService.user.subscribe((res) => {
    //   if (res == false) {
    //     this.shrinkAll();
    //   }
    // });
  }

  @HostListener('window: resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1280 && this.screenWidth > 576) {
      this.menuExpanded = false;
      this.onToggleSideNav.emit({
        expanded: this.menuExpanded,
        screenWidth: this.screenWidth,
      });
    }
    if (this.screenWidth >= 1280) {
      this.menuExpanded = true;
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth >= 1280) {
      this.menuExpanded = true;
    }
    for (let modelItem of this.menuItems) {
      if (window.location.href.includes(modelItem.routeLink) || this.checkOtherLinksIthWindow(modelItem)) {
        // console.log("includes");
        modelItem.expanded = true;
        break;
      }
    }
  }

  goToProfile() {
    this.router.navigateByUrl('/user/details');
  }

  toggleCollapse() {
    this.menuExpanded = !this.menuExpanded;
    this.onToggleSideNav.emit({
      expanded: this.menuExpanded,
      screenWidth: this.screenWidth,
    });
  }
  closeSideNav() {
    this.menuExpanded = false;
    this.onToggleSideNav.emit({
      expanded: this.menuExpanded,
      screenWidth: this.screenWidth,
    });
  }
  handleClick(item: INavbarData): void {
    if (this.menuExpanded == false) {
      // if(this.screenWidth > 1280){
      //   this.menuExpanded = true;
      // }
      this.onToggleSideNav.emit({
        expanded: this.menuExpanded,
        screenWidth: this.screenWidth,
      });
    }
    this.shrinkItems(item);
    item.expanded = !item.expanded;
  }
  getActiveClass(data: INavbarData): string {
    return (this.router.url.includes(data.routeLink) || this.activeSecondaryLinksPipe.transform(data)) ? 'active' : '';
  }

  checkOtherLinksIthWindow(data:INavbarData){
    return data.routeLinkSecondary ?  data.routeLinkSecondary!.some((i:any)=>window.location.href.includes(i)) : false
  }

  shrinkItems(item: INavbarData): void {
    if (!this.multiple) {
      for (let modelItem of this.menuItems) {
        if (item !== modelItem && modelItem.expanded) {
          modelItem.expanded = false;
        }
      }
    }
  }
  shrinkAll(): void {
    for (let modelItem of this.menuItems) {
      modelItem.expanded = false;
    }
  }
  ngOnDestroy(): void {
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
    // Collapse all on destroy 
    this.menuItems.map((i)=>{
      i.expanded=false
      if(i.childs){
        i.childs!.map((j)=>j.expanded=false)
      }
    })
  }
}
