import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-profit-table',
  templateUrl: './company-profit-table.component.html',
  styleUrls: ['./company-profit-table.component.scss']
})
export class CompanyProfitTableComponent implements OnInit {

  @Input() datasource
  SumAvg_Cost:number = 0 
  SumTot_Cost:number = 0 
  SumTot_Price:number = 0 
  classNoSum:number = 0 
  SumProfitMargin:number = 0 
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.datasource.length>0){
      this.classNoSum = this.datasource[0].classNoSum
      this.SumAvg_Cost = this.datasource[0].SumAvg_Cost
      this.SumTot_Cost = this.datasource[0].SumTot_Cost
      this.SumTot_Price = this.datasource[0].SumTot_Price
      this.SumProfitMargin = this.datasource[0].SumProfitMargin
    }else{
      this.classNoSum = 0
      this.SumAvg_Cost = 0
      this.SumTot_Cost = 0
      this.SumTot_Price = 0
      this.SumProfitMargin = 0
    }
  }

}
