import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';
import { ThBulkCardsService } from 'src/app/core/services/thuraya/th-bulk-cards.service';

@Component({
  selector: 'app-get-bulk-card-header',
  templateUrl: './get-bulk-card-header.component.html',
  styleUrls: ['./get-bulk-card-header.component.scss']
})
export class GetBulkCardHeaderComponent implements OnInit {

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;


  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Details Table variables
  selectedBillId=null;
  detailsDatasource = []
  firstLoad:boolean=true;
  detailsPageNumber:number = 1;
  detailsPageSize:number = 10;
  detailsTotalCount:number = 0;
  @ViewChild("detailsGrid ") detailsGrid: ElementRef ;


  isExporting:boolean = false
  isExportingDetails:boolean = false

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thBulkCardsService:ThBulkCardsService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.Phone'), this.msgTranslateService.msgTranslate('Common.Name')], icon: 'coins', iconColor: '#181c32',amount:['-','-'], endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.OrdersNumber'),this.msgTranslateService.msgTranslate('Common.TotalPrice')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0,0], endText:[this.msgTranslateService.msgTranslate('Common.Order'),this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Balance')], icon: 'dollar-sign', iconColor: '#1bc5bd', amount:[0],endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
    ];
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      invoiceid: this.f.invoiceid.value,
    }
    this.thBulkCardsService.GetAllBulkCardsHeader(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length>0){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم الفاتورة": data[i].Id,
              "كود الفاتورة":data[i].InvoiceGuid ,
              "التاريخ": this.datePipe.transform(data[i].InvoiceDate,this.dateFormatService.dateFormat),
              "رقم العميل": data[i].CustomerPhone,
              "عدد المطلوب": data[i].CardsNoRequested,
              "العدد الفعلى":data[i].CardsNoExecuted,
              "الاجمالى": data[i].totalInvoice,
              "تمت الطباعه": data[i].IsPrinted,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  checkBillIsPrinted(){
    let index = this.datasource.findIndex(d=>d.InvoiceGuid == this.selectedBillId)
    //Bill already printed then will export excel only
    if(this.datasource[index].IsPrinted=='True'){
      this.exportExcelDetails();
    }
    //else will print the bill and export
    else{
      this.showPrintAlert();
    }
  }
  showPrintAlert(){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.ConfirmPrint'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.activeIsPrintedFlag()
        this.exportExcelDetails();
      },
    })
  }

  // checkbox to determine if excel export eith class name or not
  exportWithName:boolean = false; 
  changeExportExcelWithClassName(event){
    // console.log(event.target.checked)
    this.exportWithName = event.target.checked
  }

  exportExcelDetails(){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      invoiceid: this.selectedBillId,
    }
    this.thBulkCardsService.GetAllBulkCardsDetails(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {  
            let sheet = {}
            if(this.exportWithName){
              sheet={ 
                "card name": data[i].note,
                "serial no.": data[i].card_serial,
                "pin no.": data[i].card_scratch,
                "date": this.datePipe.transform(data[i].ExpiredDate,this.dateFormatService.dateOnlyFormat),
              }
            } else{
              sheet={ 
                "serial no.": data[i].card_serial,
                "pin no.": data[i].card_scratch,
                "date": this.datePipe.transform(data[i].ExpiredDate,this.dateFormatService.dateOnlyFormat),
              }
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport(data[0].note);
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      invoiceid: new FormControl(""),
    });
  }
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAllBulkCardsHeader() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      invoiceid: this.f.invoiceid.value,
    }
    return this.thBulkCardsService.GetAllBulkCardsHeader(body).subscribe((result) => {
      this.infoList[0].amount[0] = result.Data.customerPhone
      this.infoList[0].amount[1] = result.Data.customerName
      this.infoList[1].amount[0] = result.Data.ordersNo
      this.infoList[1].amount[1] = result.Data.totalBalance
      this.infoList[2].amount[0] = result.Data.customerBalance

      if(result.IsSuccess){
        if(result.Data.resultData.length>0){
          this.datasource = result.Data.resultData
          this.totalCount = result.Data.resultData[0].TotalRows;
        }else{
          this.datasource = []
          this.totalCount = 0
          this.pageNumber =1;
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
      }else{
        this.infoList[0].amount[0] = '-'
        this.infoList[0].amount[1] = '-'
        this.infoList[1].amount[0] = 0
        this.infoList[1].amount[1] = 0
        this.infoList[2].amount[0] = 0
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getAllBulkCardsDetails(data) {
    if(data){
      this.selectedBillId = data.InvoiceGuid
    }
    this.spinner.show(); 
    let body={
      pageNumber: this.detailsPageNumber,
      pageSize: this.detailsPageSize,
      isPaginated: true,
      invoiceid: this.selectedBillId,
    }
    return this.thBulkCardsService.GetAllBulkCardsDetails(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.detailsDatasource = result.Data
        this.detailsTotalCount = result.Data[0].TotalRows;
        let scope = this
        setTimeout(function(){scope.detailsGrid.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }); },100);
      }
      if(result.Data.length==0){
        this.detailsDatasource = []
        this.selectedBillId = null;
        this.detailsTotalCount = 0
        this.detailsPageNumber = 1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  activeIsPrintedFlag() {
    return this.thBulkCardsService.ActiveIsPrintedFlag(this.selectedBillId).subscribe((result) => {
      if(result.IsSuccess ){
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccPrint'), "", {
          positionClass: 'toast-bottom-left',
        });
        let index = this.datasource.findIndex(d=>d.InvoiceGuid == this.selectedBillId)
        this.datasource[index].IsPrinted = "True"
      }else{
        this.toastr.error(result.Data, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    },(err)=>{
      this.toastr.error(err.Message, "", {
        positionClass: 'toast-bottom-left',
      });
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.resetDetails()
    if(!this.toDateCom || !this.fromDateCom){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getAllBulkCardsHeader()
    } 
  }

  reset(){
    this.initForm()
    this.resetDetails()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.datasource =[] 
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
    // this.infoList[0].amount[0] = 0
    // this.infoList[0].amount[1] = 0
    this.infoList[1].amount[0] = 0
    this.infoList[1].amount[1] = 0
    this.infoList[2].amount[0] = 0
  }

  resetDetails(){
    this.detailsDatasource = []
    this.selectedBillId = null
    this.detailsTotalCount = 0
    this.detailsPageNumber = 1
    this.detailsPageSize = 10
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    // if(this.toDateCom && this.fromDateCom){
      this.getAllBulkCardsHeader();
    // }
  }

   /*----------------------Pagination Details---------------------- */
   changePageDetails(event){
    if(event==1 && this.firstLoad){
      return;
    }else{
      this.firstLoad = false
      this.detailsPageNumber = event
      this.getAllBulkCardsDetails(null);
    }  
  }
}
