import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThServiceClassSettingsService } from 'src/app/core/services/thuraya/th-service-class-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-update-class-dialog',
  templateUrl: './create-update-class-dialog.component.html',
  styleUrls: ['./create-update-class-dialog.component.scss']
})
export class CreateUpdateClassDialogComponent implements OnInit {

  //Form variables
  form: FormGroup;

  @Input() isEditing;
  @Input() classData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  classDetails= {
    class_no:"",
    class_name:"",
    class_price:"",
    class_cost:"",
    class_type:"",
    status:"",
    product_id:"",
    request_code:"",
    auto_min_qty:"",
    auto_reorder_count:"",
    auto_order_status:"",
  }

  constructor(
    private fb: FormBuilder,
    private modalService:NgbModal,
    private thServiceClassSettingsService:ThServiceClassSettingsService,
    private toastr: ToastrService,
    private msgTranlsateService:MessageTranslateService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initClassDetails();
  }

  initClassDetails(){
    // console.log("init data",this.classData)
    if(this.isEditing){
      this.classDetails = this.classData
    }else{
      this.classDetails= {
        class_no:"",
        class_name:"",
        class_price:"",
        class_cost:"",
        class_type:"",
        status:"",
        product_id:"",
        request_code:"",
        auto_min_qty:"",
        auto_reorder_count:"",
        auto_order_status:"",
      }
    }
  }


  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      service_id: new FormControl("",Validators.required),
      class_no: new FormControl("",Validators.required),
      class_name: new FormControl("",Validators.required),
      class_type: new FormControl("",Validators.required),
      class_price: new FormControl("",Validators.required),
      class_cost: new FormControl("",Validators.required),
      status: new FormControl("",Validators.required),
      product_id: new FormControl("",Validators.required),
      // request_code: new FormControl("",Validators.required),
      // auto_min_qty: new FormControl("",Validators.required),
      // auto_reorder_count: new FormControl("",Validators.required),
      // auto_order_status: new FormControl("",Validators.required)
    });

    // In case of editing remove validators from uneditable fields
    if(this.isEditing){
      this.form.controls.service_id.clearValidators()
      this.form.controls.class_no.clearValidators()
      this.form.controls.class_name.clearValidators()
      this.form.controls.product_id.clearValidators()
    }
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  createUpdateServiceClassData(){
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.msgTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body = {};
    if(parseInt(this.f.class_price.value)<parseInt(this.f.class_cost.value)){
      this.toastr.warning(this.msgTranlsateService.msgTranslate("ToastMessages.PriceLessCost"), "", {
        positionClass: 'toast-bottom-left',
      });
    }else{
      if(this.isEditing){
        body = {
          //Values to keep without editing
          row_no: this.classData.row_no,
          class_no: this.classData.class_no,
          service_id: this.classData.service_id,
          class_name: this.classData.class_name,
          product_id: this.classData.product_id,
          request_code: this.classData.request_code,
          auto_min_qty: this.classData.auto_min_qty,
          auto_reorder_count: this.classData.auto_reorder_count,
          auto_order_status: this.classData.auto_order_status,
          // New Values
          class_type: this.f.class_type.value,
          class_price: this.f.class_price.value,
          class_cost: this.f.class_cost.value,
          status: this.f.status.value,
        }
      }else{
        body = {
          service_id: this.f.service_id.value,
          class_no: this.f.class_no.value,
          class_name: this.f.class_name.value,
          class_type: this.f.class_type.value,
          class_price: this.f.class_price.value,
          class_cost: this.f.class_cost.value,
          product_id: this.f.product_id.value,
          status: this.f.status.value,
          request_code: "",
          auto_min_qty: "",
          auto_reorder_count: "",
          auto_order_status: "",
        }
      }
      this.thServiceClassSettingsService.CreateUpdateServiceClassData(body).subscribe(
        (result)=>{
          if(result.IsSuccess){
            let text = this.isEditing ? this.msgTranlsateService.msgTranslate("ToastMessages.SuccUpdate") : this.msgTranlsateService.msgTranslate("ToastMessages.SuccAdd")
            this.toastr.success(text, "", {
              positionClass: 'toast-bottom-left',
            });
            if(!this.isEditing){
              this.updateParent.emit(result.Data)
            }
            this.modalService.dismissAll();
          }else{
            // console.log("res",result)
            this.toastr.error(result.Message, "", {
              positionClass: 'toast-bottom-left',
            });
          }
         
        },(err)=>{
          // console.log("err",err)
          this.toastr.error(err.error.Message, "", {
            positionClass: 'toast-bottom-left',
          });
        }
      )
    }
  }
  
  discard(){
    this.initForm()
    this.modalService.dismissAll();
  }

  /*----------------------Select from dropdown---------------------- */
  setSelectedService(event){
    this.form.controls.service_id.setValue(event)
  }

}
