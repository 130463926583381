<!--begin::Entry-->
<div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!-- Roles Table Row-->
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b example example-compact px-5 py-2">
            <div class="buttonContainer my-4" *ngIf="permissionService.checkPermission(['HalaRole.Create'])">
              <button type="button" (click)="show_AddForm()" class="btn btn-success addBtn">
                <mat-icon>add</mat-icon>
                <span>{{'Common.Add'|translate}}</span>
              </button>
            </div>
             <!-- Expansion Tile to Add role -->
             <mat-accordion class="mb-4" *ngIf="showAddForm">
              <mat-expansion-panel (closed)="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                   <span style="color: red; font-weight: bold;">{{'Common.AddNewRole'|translate}}</span>  
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- New Role Name input field -->
                <form class="form mb-3" [formGroup]="roleForm">
                  <div class="">
                    <div class="form-group row">
                      <div class="col-lg-6">
                        <label style="font-size: 16px;">{{'Common.RoleName'|translate}}</label>
                        <input type="text" formControlName="roleName"  class="form-control" placeholder="" />
                        <span class="invalid-input" *ngIf="roleForm.controls.roleName?.invalid && roleForm.controls.roleName?.touched">{{'Common.RoleNameReq'|translate}}</span>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- New Role Permissions select -->
                <div class="row mt-3">
                    <div class="claimsConatiner mb-4 col-lg-4 col-md-6" *ngFor="let claimCategory of claims">
                        <h3>{{claimCategory.ControllerName}}</h3>
                        <hr/>
                            <div *ngFor="let claim of claimCategory.ControllerMethods">
                                <div class="d-flex align-items-center mb-3">
                                    <ejs-switch cssClass="e-small" [enableRtl]="true" (change)='addFieldsChange($event,claim)'></ejs-switch>
                                    <span style="margin-inline-start: 15px; font-size: 16px;">{{claim.substring(claim.indexOf(".")+1)}}</span>
                                </div>
                            </div>
                    </div>
                </div>
                <!--Action buttons -->
                <div class="d-flex justify-content-end">
                    <button type="button" class=" btn btn-primary px-4 me-3" style="font-size: 16px" (click)="addNewRole()" [disabled]="roleForm.invalid">{{'Common.Save'|translate}}</button>
                    <button type="button" class="btn btn-secondary px-4" (click)="cancelChanges()">{{'Common.Discard'|translate}}</button>
              </div>
              </mat-expansion-panel>
            </mat-accordion>
            <!-- Expansion Tile for each role -->
            <mat-accordion *ngFor="let role of roles; let i = index" class="mb-4">
                <mat-expansion-panel (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false" *ngIf="permissionService.checkPermission(['HalaRole.View','HalaRole.Update','HalaRole.Delete'])">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="expansionTitle">
                        <span>{{role.RoleName}}</span>
                        <button *ngIf="permissionService.checkPermission(['HalaRole.Delete'])" type="button" (click)="showAlert(role)" class="btn btn-danger removeBtn">
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </div>
                        
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description> -->
                  </mat-expansion-panel-header>
                  <!-- lazy load content as it is request data -->
                  <ng-template [ngIf]="panelOpenState[i]">
                    <div class="row mt-3">
                        <div class="claimsConatiner mb-4 col-lg-4 col-md-6" *ngFor="let claimCategory of claims">
                            <h4>{{claimCategory.ControllerName}}</h4>
                            <hr/>
                            
                                <div *ngFor="let claim of claimCategory.ControllerMethods">
                                    <div class="d-flex align-items-center mb-3">
                                        <ejs-switch [checked]='checkClaimExist(role,claim)' cssClass="e-small" [enableRtl]="true" (change)='fieldsChange($event,claim,role,i)'></ejs-switch>
                                        <span style="margin-inline-start: 15px; font-size: 16px;">{{claim.substring(claim.indexOf(".")+1)}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="button" *ngIf="permissionService.checkPermission(['HalaRole.Update'])" class=" btn btn-primary px-14" style="font-size: 16px" [disabled]="!roleOfIndexChange[i]" (click)="saveChanges(role,i)">{{'Common.Save'|translate}}</button>
                    </div>
                  </ng-template>
                 
                </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
  
      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->