import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetGamePurchaseDataComponent } from './get-game-purchase-data/get-game-purchase-data.component';

const routes: Routes = [
  { path: 'get-game-purchase-data', component: GetGamePurchaseDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbGamePurchaseInvoices.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GamePurchaseInvoiceRoutingModule { }
