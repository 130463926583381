import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HalaProvidersService } from '../../services/hala/hala-providers.service';

@Component({
  selector: 'app-providers-dropdown',
  templateUrl: './providers-dropdown.component.html',
  styleUrls: ['./providers-dropdown.component.scss']
})
export class ProvidersDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    public halaProvidersService: HalaProvidersService,
  ) { }

  ngOnInit(): void {
    this.initComboSharedInput()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data){
    this.providerIdVal = `[${data.ProviderId}] - ${data.ProviderName}`;
  }

  getProviderFromList(providerId){
    return this.shrdInpt[this.providerscomboName].loadedList.filter(x => x.id == providerId)
  }
  reset(){
    this.providerIdVal = ""
  }

  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public providerscomboName="providers";
  providerIdVal: string="";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      providers:{}
    };

    //Made these outside initComboSharedInputValues() because we need to have the refrence of combox and do not reset it in any case
    // this.shrdInpt[this.providerscomboName].comboboxref =null

    this.initComboSharedInputValues(this.providerscomboName);
    
    this.getProviders()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting providers for dropdown select---------------------- */

  getProvidersBody(){
    let body={}
    if(this.shrdInpt[this.providerscomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.providerscomboName].pageNumber,
        pageSize: this.shrdInpt[this.providerscomboName].pageSize,
        // searchString: this.shrdInpt[this._comboName].searchText,
        isPaginated: true,
      }
    }else{
      this.shrdInpt[this.providerscomboName].pageNumberBeforeSearch = this.shrdInpt[this.providerscomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.providerscomboName].pageNumber,
        pageSize: this.shrdInpt[this.providerscomboName].pageSize,
        isPaginated: true,
      }
    } 
    return body;
  }

  getProviders() {
    let body = this.getProvidersBody();
    this.shrdInpt[this.providerscomboName].isLoading = true;
    const subscr = this.halaProvidersService.GetAllProvidersActiveData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let data = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.providerscomboName].isSearching){
            this.shrdInpt[this.providerscomboName].comboBoxList = []
              if(data.length){
                this.shrdInpt[this.providerscomboName].endData = false;
                for (let i = 0; i < data.length; i++) {
                  this.shrdInpt[this.providerscomboName].comboBoxList.push(`[${data[i].id}] - ${data[i].ProviderName}`);
                  this.shrdInpt[this.providerscomboName].loadedList.push(data[i]);
                 
                }
                this.shrdInpt[this.providerscomboName].comboboxref.addItem(this.shrdInpt[this.providerscomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.providerscomboName].endData = true;
              }
          }else{
            if(data.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.providerscomboName].oldSearchText!=this.shrdInpt[this.providerscomboName].searchText){
                this.shrdInpt[this.providerscomboName].searchList= [];
                //update old value
                this.shrdInpt[this.providerscomboName].oldSearchText = this.shrdInpt[this.providerscomboName].searchText;
              }
              this.shrdInpt[this.providerscomboName].endData = false;
              for (let i = 0; i < data.length; i++) {
                this.shrdInpt[this.providerscomboName].searchList.push(`[${data[i].id}] - ${data[i].ProviderName}`);
              }
              this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.providerscomboName].combBoxEvent.updateData(this.shrdInpt[this.providerscomboName].searchList);
            }else{
              this.shrdInpt[this.providerscomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.providerscomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.providerscomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.providerscomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }
  

  /*----------------------when Choosing Provider---------------------- */
  changeSelectedProvider(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let indexofendId = selectedValue.indexOf(']')
      let providerSelected = selectedValue.substring(1,indexofendId)
      this.providerIdVal = selectedValue;
      this.updateParent.emit(providerSelected);   
    }else{
      this.updateParent.emit("");   
    }
  }

}
