import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAdvancedBulkDataComponent } from './get-advanced-bulk-data/get-advanced-bulk-data.component';

const routes: Routes = [
   { path: 'get-advanced-bulk-data', component: GetAdvancedBulkDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbAdvancedBulkAccount.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvancedBulkAccountRoutingModule { }
