import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetCompanyProfitComponent } from './get-company-profit/get-company-profit.component';

const routes: Routes = [
  { path: 'get-company-profit', component: GetCompanyProfitComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbCompanyProfit.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyProfitRoutingModule { }
