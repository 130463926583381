import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetPosBulkDataComponent } from './get-pos-bulk-data/get-pos-bulk-data.component';

const routes: Routes = [
  { path: 'get-pos-bulk-data', component: GetPosBulkDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbPosBulkBill.View'], roles:[]}  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PosBulkBillRoutingModule { }
