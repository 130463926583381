<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="align-middle"> {{'Common.RowNumber'| translate}}</th>
          <th class="align-middle" *ngIf="IsAdvancedAccountReportDataComponent">{{'Common.TransType'| translate}}</th>
          <th class="align-middle">{{'Common.TransDate'| translate}}</th>
          <th class="align-middle">{{'Common.AccountId'| translate}} </th>
          <th class="align-middle">{{'Common.ClientName'| translate}}</th>
          <th class="align-middle">{{'Common.SystemAdmin'| translate}}</th>
          <th class="align-middle">{{'Common.Add'| translate}}</th>
          <th class="align-middle">{{'Common.Withdraw'| translate}} </th>
          <th class="align-middle" style="width: 100px;">{{'Common.Notice'| translate}} </th>
          <!-- <th class="align-middle">{{'Common.Date'| translate}}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.row_no}}</a> </td>
          <td *ngIf="IsAdvancedAccountReportDataComponent">{{data.trans_type}}</td>
          <td>{{data.trans_date | date: dateFormatService.dateFormat }}</td>
          <td>{{data.cust_account_id}}</td>
          <td>{{data.CUSTOMER_NAME}}</td>
          <td>{{data.account_id}}</td>
          <td>{{data.credit_amount}} </td>
          <td>{{data.debit_amount}}</td>
          <td  style="width: 100px;">{{data.trans_note}}</td>
        </tr>
        <tr  *ngIf="IsAdvancedAccountReportDataComponent">
          <td></td>
          <td *ngIf="IsAdvancedAccountReportDataComponent"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{totalAdditions==0 ? '-' :totalAdditions | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{totalWithdraw==0 ? '-' :totalWithdraw | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>