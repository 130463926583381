import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';

@Component({
  selector: 'app-agents-dropdown',
  templateUrl: './agents-dropdown.component.html',
  styleUrls: ['./agents-dropdown.component.scss']
})
export class AgentsDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private thGeneralSystemService:ThGeneralSystemService,
  ) { }

  ngOnInit(): void {
    this.initComboSharedInput()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data){
    // console.log("eventdata",data)
    this.agentsNameVal = data;
  }

  reset(){
    this.agentsNameVal = ""
  }

  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public agentscomboName="agents";
  agentsNameVal: string="";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      agents:{},
    };

    this.initComboSharedInputValues(this.agentscomboName);
    
    this.getAgents()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting agents for dropdown select---------------------- */
  getAgentsBody(){
    let body={}
    if(this.shrdInpt[this.agentscomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.agentscomboName].pageNumber,
        pageSize: this.shrdInpt[this.agentscomboName].pageSize,
        searchStrings: this.shrdInpt[this.agentscomboName].searchText,
        isPaginated: true,
        company_No: "",
        accountId:"", 
      }
    }else{
      this.shrdInpt[this.agentscomboName].pageNumberBeforeSearch = this.shrdInpt[this.agentscomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.agentscomboName].pageNumber,
        pageSize: this.shrdInpt[this.agentscomboName].pageSize,
        isPaginated: true,
        company_No: "",
        accountId:"", 
      }
    } 
    return body;
  }

  getAgents() {
    let body = this.getAgentsBody();
    this.shrdInpt[this.agentscomboName].isLoading = true;
    const subscr = this.thGeneralSystemService.GetAllAgentsByCompany(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let resp = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.agentscomboName].isSearching){
            this.shrdInpt[this.agentscomboName].comboBoxList = []
              if(resp.length){
                this.shrdInpt[this.agentscomboName].endData = false;
                for (let i = 0; i < resp.length; i++) {
                  this.shrdInpt[this.agentscomboName].comboBoxList.push(`[${resp[i].CUSTOMER_ID}] - ${resp[i].CUSTOMER_NAME}`);
                  this.shrdInpt[this.agentscomboName].loadedList.push(resp[i]);
                 
                }
                this.shrdInpt[this.agentscomboName].comboboxref.addItem(this.shrdInpt[this.agentscomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.agentscomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.agentscomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.agentscomboName].endData = true;
              }
          }else{
            if(resp.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.agentscomboName].oldSearchText!=this.shrdInpt[this.agentscomboName].searchText){
                this.shrdInpt[this.agentscomboName].searchList= [];
                //update old value
                this.shrdInpt[this.agentscomboName].oldSearchText = this.shrdInpt[this.agentscomboName].searchText;
              }
              this.shrdInpt[this.agentscomboName].endData = false;
              for (let i = 0; i < resp.length; i++) {
                this.shrdInpt[this.agentscomboName].searchList.push(`[${resp[i].CUSTOMER_ID}] - ${resp[i].CUSTOMER_NAME}`);
              }
              this.shrdInpt[this.agentscomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.agentscomboName].combBoxEvent.updateData(this.shrdInpt[this.agentscomboName].searchList);
            }else{
              this.shrdInpt[this.agentscomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.agentscomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.agentscomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.agentscomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }

  /*----------------------when Choosing customer---------------------- */
  changeSelectedAgent(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      //Getting id of selected customer and setting value in for combobox and in form
      let indexofendId = selectedValue.indexOf(']')
      let agentselected = selectedValue.substring(1,indexofendId)
      this.agentsNameVal = selectedValue;
      this.updateParent.emit(agentselected);   
    }else{
      this.updateParent.emit("");
    }
  }

}
