<div class="table-responsive mb-0">
  <h3 class="mb-3">{{IsApproveComponent ? ('HALA.ApproveCards.CardToApprove'|translate) : ('HALA.ApproveCards.Cards'| translate)}}</h3>
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'HALA.ApproveCards.ServiceId'| translate}}</th>
          <th class="align-middle">{{'HALA.ApproveCards.CardName'| translate}}</th>
          <th class="align-middle">{{'HALA.ApproveCards.CardAmount'| translate}}</th>
          <th class="align-middle">{{'Common.DateFrom'| translate}}</th>
          <th class="align-middle">{{'Common.DateTo'| translate}} </th>
          <th class="align-middle"> {{'Common.UserId'| translate}}</th>
          <th class="align-middle"> {{'HALA.ApproveCards.ProviderId'| translate}}</th>
          <th class="align-middle"> {{'HALA.ApproveCards.OrderNo'| translate}}</th>
          <th *ngIf="IsMangerReportComponent" class="align-middle"> {{'HALA.ApproveCards.ReceiveNo'| translate}}</th>
          <th *ngIf="IsMangerReportComponent" class="align-middle"> {{'HALA.ApproveCards.TransferNo'| translate}}</th>
          <!-- For approve page -->
          <th *ngIf="IsApproveComponent" class="align-middle"> {{'Common.Approve'| translate}}</th>
          <th *ngIf="IsApproveComponent" class="align-middle"> {{'Common.Retutrn'| translate}}</th>
          <!-- For employee report page -->
          <th *ngIf="!IsApproveComponent" class="align-middle"> {{'Common.Approved'| translate}}</th>
          <th *ngIf="!IsApproveComponent" class="align-middle"> {{'Common.Sent'| translate}}</th>
          <th class="align-middle"> {{'Common.Details'| translate}}</th>
          <!-- For manger report page -->
          <th *ngIf="IsMangerReportComponent && permissionService.checkPermission(['HalaManageHalaCards.Update'])" class="align-middle"> {{'Common.Edit'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.Service_Id}}</a>
          </td>
          <td>{{data.Class_Name}}</td>
          <td>{{data.cardsNo}}</td>
          <td>
            <div>
              <p>{{data.AddedDate | date:dateFormatService.dateOnlyFormat}}</p>
              <p>{{data.AddedDate | date:"hh:mm a"}}</p>
            </div>
          </td>
          <td>
            <div>
              <p>{{data.ExpirationDate | date:dateFormatService.dateOnlyFormat}}</p>
              <p>{{data.ExpirationDate | date:"hh:mm a"}}</p>
            </div>
          <td>{{data.UserName}}</td>
          <td>{{data.ProviderName}}</td>
          <td>{{data.OrderNo}}</td>
          <!-- For manger report page (display 2 additional columns) -->
          <td *ngIf="IsMangerReportComponent">{{data.oracleNo}}</td>
          <td *ngIf="IsMangerReportComponent">{{data.transNo}}</td>
          <!-- For approve page (display approve and return buttons)-->
          <td *ngIf="IsApproveComponent" class="text-center"><i class="fa-regular fa-circle-check text-success" role="button" style="font-size: 18px;" (click)="approve(data)"></i></td>
          <td *ngIf="IsApproveComponent" class="text-center"><i class="fa-solid fa-rotate-left text-danger" role="button" style="font-size: 18px;" (click)="return(data)"></i></td>
          <!-- For employee report page (display approved status)-->
          <td *ngIf="!IsApproveComponent" class="text-center"> 
            <i *ngIf="!data.IsApproved" class="fa-solid fa-xmark text-danger" style="font-size: 18px;"></i>
            <i *ngIf="data.IsApproved" class="fa-solid fa-check text-success" style="font-size: 18px;"></i>
          </td>
          <!-- For employee report page (display sent status)-->
          <td *ngIf="!IsApproveComponent" class="text-center">
            <i *ngIf="!data.IsSendToManager" class="fa-solid fa-xmark text-danger" style="font-size: 18px;"></i>
            <i *ngIf="data.IsSendToManager" class="fa-solid fa-check text-success" style="font-size: 18px;"></i>
          </td>
          <td class="text-center"><i class="fa-solid fa-circle-info" role="button" style="font-size: 18px;" (click)="details(data)"></i></td>
          <!-- For manger report page (dispaly edit button) -->
          <td *ngIf="IsMangerReportComponent && permissionService.checkPermission(['HalaManageHalaCards.Update'])" >
            <i class='mdi mdi-pencil font-size-18 text-info' role="button" (click)="edit(data)"></i>
          </td>
        </tr>
        <tr *ngIf="IsMangerReportComponent">
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'HALA.ApproveCards.TotalNo'| translate}}</span> 
              <span>{{totalCard==0 ? '-' :totalCard}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>
