<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Report type not shown in avilable report with expiry date -->
                    <div class="col-lg-3">
                        <label>{{'Common.ReportType'| translate}}:</label>
                        <select formControlName="reportType" class="form-select"
                            (change)="reportTypeChanged($event)">
                            <option [selected]="true" value=""> ----{{'Common.Choose'| translate}}---- </option>
                            <option value="1"> {{'Common.Overall'| translate}} </option>
                            <option value="2"> {{'Common.Detailed'| translate}} </option>
                        </select>
                    </div>
                    <!-- Service -->
                    <div class="col-lg-3 mb-3">
                        <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                    </div>
                    <!-- Card Name -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                        <select formControlName="class_no" class="form-select">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                            [{{ item.class_no }}] - {{ item.class_name }}
                            </option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                    <!-- Add Type -->
                    <div class="col-lg-3 mb-3" *ngIf="isDetailsType">
                        <label>{{'Common.AddType'|translate }}:</label>
                        <select formControlName="source_type" class="form-select">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                            <option style="font-size: 16px !important" value="0"> {{'REPORTS.PurchaseReport.Offline'|translate}}</option>
                            <option style="font-size: 16px !important" value="1"> {{'REPORTS.PurchaseReport.Online'|translate}}</option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div>

                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>


<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-start-inv-table [datasource]="datasource" [isDetailsType]="isDetailsType"></app-start-inv-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>