import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SadadServiceApiRoutingModule } from './sadad-service-api-routing.module';
import { GetTransactionsComponent } from './get-transactions/get-transactions.component';
import { CoreModule } from 'src/app/core/core.module';
import { SadadTransactionsTableComponent } from './get-transactions/_components/sadad-transactions-table/sadad-transactions-table.component';
import { GetRequestsComponent } from './get-requests/get-requests.component';
import { SadadRequestsTableComponent } from './get-requests/_component/sadad-requests-table/sadad-requests-table.component';
import { GetSadadBalanceComponent } from './get-sadad-balance/get-sadad-balance.component';
import { SadadBalanceTableComponent } from './get-sadad-balance/_components/sadad-balance-table/sadad-balance-table.component';


@NgModule({
  declarations: [
    GetTransactionsComponent,
    SadadTransactionsTableComponent,
    GetRequestsComponent,
    SadadRequestsTableComponent,
    GetSadadBalanceComponent,
    SadadBalanceTableComponent
  ],
  imports: [
    CommonModule,
    SadadServiceApiRoutingModule,
    CoreModule
  ]
})
export class SadadServiceApiModule { }
