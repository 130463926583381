<div class="table-responsive mb-0">
    <table class="table table-centered ">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ClassNo'| translate}}</th>
          <th class="align-middle">{{'Common.CompanyNo'| translate}}</th>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'Common.ClassName'| translate}}</th>
          <th class="align-middle">{{'Common.ProductId'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}} </th>
          <th class="align-middle">{{'Common.EndUserPrice' | translate}}</th>
          <th class="align-middle">{{'Common.Price' | translate}}</th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['ThdbPriceSettings.Update'])"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.class_no}}</a>
          </td>
          <td>{{data.group_no}}</td>
          <td>{{data.service_id}}</td>
          <td>{{data.class_name}}</td>
          <td>{{data.product_id}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.class_price}}</td>
          <!-- we can use agent_amount, dest_amount or end_point_amount the all the same-->
          <td>{{data.agent_amount}}</td>
          <!-- Update price button only available if not showing excel data  -->
          <td *ngIf="!showingExcelData && permissionService.checkPermission(['ThdbPriceSettings.Update'])">
            <button class="btn btn-info" (click)="update(data)">{{'Common.UpdatePrice' | translate}}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>