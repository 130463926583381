import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulkOrderCardRoutingModule } from './bulk-order-card-routing.module';
import { GetInsertedOrderDataComponent } from './get-inserted-order-data/get-inserted-order-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { InsertedOrdersTableComponent } from './get-inserted-order-data/_components/inserted-orders-table/inserted-orders-table.component';


@NgModule({
  declarations: [
    GetInsertedOrderDataComponent,
    InsertedOrdersTableComponent
  ],
  imports: [
    CommonModule,
    BulkOrderCardRoutingModule,
    CoreModule
  ]
})
export class BulkOrderCardModule { }
