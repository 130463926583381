<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light table-nowrap">
        <tr>
            <th class="align-middle">{{'Common.ClientPhone' | translate}}</th>
            <th class="align-middle">{{'Common.Notice' | translate}}</th>
            <th class="align-middle">{{'Common.POSPhone' | translate}}</th>
            <th class="align-middle">{{'Common.POSName' | translate}}</th>
            <th class="align-middle">{{'Common.Branch' | translate}}</th>
            <th class="align-middle">{{'Common.WithdrawnAmount' | translate}}</th>
            <th class="align-middle">{{'Common.AddedAmount' | translate}}</th>
            <th class="align-middle">{{'Common.Date' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.cust_account_id}}</a>
          </td>
          <td>{{data.trans_note}}</td>
          <td>{{data.PosPhone}}</td>
          <td>{{data.PosName}}</td>
          <td>{{data.address}}</td>
          <td>{{data.debit_amount}}</td>
          <td>{{data.credit_amount }}</td>
          <td>{{data.trans_date | date:dateFormatService.dateOnlyFormat}}</td>
        </tr>
        <tr >
          <td></td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{TotalDebitAmount==0 ? '-' :TotalDebitAmount | number : '1.0-2'}}</span>
              </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalCreditAmount==0 ? '-' :TotalCreditAmount | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>