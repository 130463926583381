import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetEffectivePosDataComponent } from './get-effective-pos-data/get-effective-pos-data.component';

const routes: Routes = [
  { path: 'get-effective-pos-data', component: GetEffectivePosDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbEffectivePosReport.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EffectivePosReportRoutingModule { }
