<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'REPORTS.PackCardReport.BatchNumber'| translate}}</th>
          <th class="align-middle">{{'Common.ServiceId'| translate}} </th>
          <th class="align-middle">{{'Common.File'| translate}} </th>
          <th class="align-middle">{{'Common.Date'| translate}} </th>
          <th class="align-middle">{{'Common.CardName'| translate}} </th>
          <th class="align-middle">{{'THURAYA.CardBatches.OriginalNumber'| translate}}</th>
          <th class="align-middle">{{'Common.TotalUpload'| translate}}</th>
          <th class="align-middle">{{'THURAYA.CardBatches.Disability'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.batch_no}}</a>
          </td>
          <td>{{data.service_id}}</td>
          <td>{{data.file_name==null ? '-' : data.file_name.substring(0,5)}} <a *ngIf="data.file_name!=null" (click)="showMore(data.file_name)" class="showMore">{{'Common.More'| translate}} </a></td>
          <td>
            <div>
                {{data.batch_date | date:dateFormatService.dateOnlyFormat}}
            </div>
            <div>
                {{data.batch_date | date:dateFormatService.timeOnlyFormat}}
            </div>
          </td>
          <td>{{data.class_name}}</td>
          <td>{{data.batch_count}}</td>
          <td>{{data.realBatchCount}}</td>
          <td>{{data.differenceBatchCount}}</td>
        </tr>

        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{SumBatchesCount==0 ? '-' :SumBatchesCount | number : '1.0-2'}}</span>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{SumRealBatchCount==0 ? '-' :SumRealBatchCount | number : '1.0-2'}}</span>
              </div>
            </td>
            <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{SumDifferenceBatchCount==0 ? '-' :SumDifferenceBatchCount | number : '1.0-2'}}</span>
              </div>
            </td>
          </tr>
      </tbody>
    </table>
</div>