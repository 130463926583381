import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PosBulkBillRoutingModule } from './pos-bulk-bill-routing.module';
import { GetPosBulkDataComponent } from './get-pos-bulk-data/get-pos-bulk-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { BillModule } from '../../big-data/bill/bill.module';


@NgModule({
  declarations: [
    GetPosBulkDataComponent
  ],
  imports: [
    CommonModule,
    PosBulkBillRoutingModule,
    CoreModule,
    BillModule
  ]
})
export class PosBulkBillModule { }
