<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">
            <div class="form-group mb-3">
                <label class="required" for="name">{{'Common.AmountRequired'|translate}}</label>
                <input type="text" class="form-control" formControlName="requiredBalance" id="name" [(ngModel)]="oldData.RequiredBalance">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="phone">{{'Common.TradeName'|translate}}</label>
                <input type="text" class="form-control" formControlName="commercialName" id="phone" [(ngModel)]="oldData.CommercialName">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="surename">{{'Common.CommercialRegistrationNo'|translate}}</label>
                <input type="text" class="form-control" formControlName="commercialRegistrationNo" id="surename" [(ngModel)]="oldData.CommercialRegistrationNo">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="email">{{'Common.POSUserNumber'|translate}}</label>
                <input type="text" class="form-control" formControlName="posUserNumber" id="email" [(ngModel)]="oldData.PosUserNumber">
            </div>
            <div class="form-group mb-3">
                <label for="subject">{{'Common.PersonAuthorizedToUser'|translate}}</label>
                <input formControlName="userName" type="text" class="form-control" id="area" [(ngModel)]="oldData.UserName">
            </div>
            <div class="form-group mb-3">
                <label for="subject">{{'Common.SimSerialNumber'|translate}}</label>
                <input formControlName="companyNumber" type="text" class="form-control" id="city" [(ngModel)]="oldData.CompanyNumber">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="subject">{{'Common.MachineSerial'|translate}}</label>
                <input type="text" class="form-control" formControlName="serialNumber" id="city" [(ngModel)]="oldData.SerialNumber">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="subject">{{'Common.Email'|translate}}</label>
                <input type="text" class="form-control" formControlName="email"  id="city" [(ngModel)]="oldData.Email">
            </div>
            <div class="form-group mb-3">
                <label class="required" for="subject">{{'Common.Address'|translate}}</label>
                <input type="text" class="form-control"   formControlName="address" id="city" [(ngModel)]="oldData.Address" >
            </div>

            <div class="form-group mb-3">
                <label class="required" for="subject">{{'Common.MachineType'|translate}}</label>
                <div class="col-lg-8 d-flex align-items-end">
                    <div class="form-check" style="margin-inline-end: 24px;">
                        <input class="form-check-input" type="radio" name="deviceType" id="1" value="1" (click)="changeDeviceType($event)" [checked]="oldData.DeviceType=='1'">
                        <label class="form-check-label" for="1" style="margin-inline-start: 8px;">{{'Common.Machine'|translate}}</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="deviceType" id="2" value="2" (click)="changeDeviceType($event)" [checked]="oldData.DeviceType=='2'">
                        <label class="form-check-label" for="2" style="margin-inline-start: 8px;">{{'Common.PrinterOnly'|translate}}</label>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="required">{{'Common.Status'|translate}}</label>
                <select formControlName="isAction" [(ngModel)]="oldData.IsAction" class="form-select">
                    <option style="font-size: 16px;" [ngValue]="true">{{'Common.ActionsTaken'|translate}}</option>
                    <option style="font-size: 16px;" [ngValue]="false">{{'Common.ActionsNotTaken'|translate}}</option>
                </select>
                <!-- <p class="invalid-input" *ngIf="f.email.invalid && f.email.touched">{{'Form.MustEnter'|translate}} {{'Form.Email'|translate}}</p> -->
            </div>
        
            <!-- Action Buttons -->
            <div class="d-flex justify-content-end mb-3">
                <div>
                    <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="form.invalid" (click)="saveChanges()">{{ 'Common.Edit'|translate }}</button>
                    <button type="button" class="btn btn-light" (click)="cancelChanges()">{{'Common.Discard'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>