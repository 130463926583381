import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ThAccountReportService } from 'src/app/core/services/thuraya/th-account-report.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';
import { ThDistributorReportsService } from 'src/app/core/services/thuraya/th-distributor-reports.service';

@Component({
  selector: 'app-get-all-bill-details',
  templateUrl: './get-all-bill-details.component.html',
  styleUrls: ['./get-all-bill-details.component.scss']
})
export class GetAllBillDetailsComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  SumQty = 0;
  SumTotalprice = 0;

  isExporting:boolean = false
  isPrinting:boolean = false

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thDistributorReportsService:ThDistributorReportsService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export/Report All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.thDistributorReportsService.GetAllBillDetails(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "كود الصنف": data[i].I_CODE,
              "اسم الصنف":data[i].Note ,
              "الكمية": data[i].I_QTY,
              "سعر الوحدة": data[i].I_PRICE,
              "الاجمالى": data[i].totalBeforeVAT,
              "الضريبة%": 0.00,
              "الضريبة": 0.00,
              "القيمة": data[i].totalBeforeVAT,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "الكمية": result.Data.SumQty,
            "الاجمالى":Number.parseFloat(result.Data.SumTotalprice).toFixed(2),
            "القيمة": Number.parseFloat(result.Data.SumTotalprice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  
  printReport(){
  this.isPrinting =true;
  let body={
    isPaginated: false,
    dateFrom: this.fromDateCom,
    dateTo: this.toDateCom,
  }
  this.thDistributorReportsService.GetAllBillDetails(body).subscribe((result) => {
    this.isPrinting = false
    let newWindow = window.open('print/print-distributor-report-all-bill','_blank');
    if(result.IsSuccess && result.Data.resultList.length>0){
      newWindow["result"] = result;
      newWindow["dateFrom"] = this.fromDateCom;
      newWindow["dateTo"] = this.toDateCom;
    }
  },(err)=>{
    this.isPrinting = false
  }
  )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 

  /*----------------------API Requests---------------------- */
  getAllBillDetails() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thDistributorReportsService.GetAllBillDetails(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.totalCount = result.Data.resultList[0].TotalRows;
        this.SumQty = result.Data.SumQty
        this.SumTotalprice = result.Data.SumTotalprice
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.totalCount = 0
        this.SumQty = 0
        this.SumTotalprice = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if(!this.toDateCom || !this.fromDateCom){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getAllBillDetails()
    } 
  }

  reset(){
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.datasource =[] 
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.toDateCom && this.fromDateCom){
      this.getAllBillDetails();
    }
  }

}
