import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-outstanding-table',
  templateUrl: './outstanding-table.component.html',
  styleUrls: ['./outstanding-table.component.scss']
})
export class OutstandingTableComponent implements OnInit {

  @Input() datasource
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
