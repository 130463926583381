 <!--begin::Row-->
 <div class="row">
    <div class="col-xl-12">
      <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <div class="table-striped table-responsive">
          <ejs-grid #grid id="Grid" [enableRtl]="languageService.selectedLanguage=='ar'" [dataSource]="datasource" [pageSettings]="pageSettings"
            [toolbar]="toolbarOptions" [allowPaging]="true" [allowExcelExport]="true" [aggregates]="aggreagtes"
            (toolbarClick)="toolbarClick($event)">
            <e-columns>
              <e-column field="account_id" headerText=" {{'Common.ClientPhone' | translate}} "></e-column>
              <e-column field="Customer_Name" headerText="{{'Common.ClientName' | translate}}"></e-column>
              <e-column field="service_id" headerText="{{'Common.CompanyName' | translate}}"></e-column>
              <e-column field="note" headerText="{{'Common.CardName' | translate}}"></e-column>
              <e-column field="point_price" headerText="{{'Common.CardPrice' | translate}}"></e-column>
              <e-column field="card_serial" headerText="{{'Common.Serial' | translate}}"></e-column>
              <e-column field="card_scratch" headerText="{{'Common.Pin' | translate}}"></e-column>
              <e-column field="trans_date" type='date' [format]="format" headerText="{{'Common.Date' | translate}}"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  <!--end::Row-->