<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          
          <th class="align-middle"> {{'Common.ClientName'| translate}}</th>
          <th class="align-middle">{{'Common.SimOldSerialNumber'| translate}} </th>
          <th class="align-middle">{{'Common.ClientPhone'| translate}}</th>
          <th class="align-middle">{{'Common.SimNewSerialNumber'| translate}}</th>
          <th class="align-middle"> {{'Common.Date'| translate}}</th>
          <th class="align-middle" style="width: 50px;">{{'Common.ActionsTaken'| translate}}</th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaCardUpdate.Update'])">{{'Common.Edit'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.AgentName}}</a> </td>
          <!-- <td>{{data.CreateDate | date: dateFormatService.dateOnlyFormat}}</td> -->
          <td>{{data.OldSerial}}</td>
          <td> {{data.AgentPhoneNo}} </td>
          <td>{{data.NewSerial}}</td>
          <td>{{data.createDate}}</td>
          <td class="text-center" style="width: 50px;">
            <i *ngIf="!data.IsAction" class="fa-solid fa-xmark text-danger" style="font-size: 18px;"></i>
            <i *ngIf="data.IsAction" class="fa-solid fa-check text-success" style="font-size: 18px;"></i>
          </td>
          <td style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaCardUpdate.Update'])">
            <i class='mdi mdi-pencil font-size-18 text-success' role="button" (click)="openEditDialog(data)"></i>
          </td>
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{totalSellPrice==0 ? '-' :totalSellPrice}}</span>
            </div>
          </td>
        </tr> -->
      </tbody>
    </table>
</div>