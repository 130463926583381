import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ThInventorMovementService } from 'src/app/core/services/thuraya/th-inventor-movement.service';
import Swal from 'sweetalert2';
import { ThStartInvBalanceService } from 'src/app/core/services/thuraya/th-start-inv-balance.service';

@Component({
  selector: 'app-get-inventory-data',
  templateUrl: './get-inventory-data.component.html',
  styleUrls: ['./get-inventory-data.component.scss']
})
export class GetInventoryDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any;
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []
  isDetailsType:boolean = false;

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thStartInvBalanceService:ThStartInvBalanceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value,
      sourceType: this.f.source_type.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      isForDetails: this.isDetailsType
    }
    let sumTotalCost:string = ""
    let sumTotal:string = ""
    const subscr =  this.thStartInvBalanceService.GetInventoryDataOverallAndDetails(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          let sheet = {}
          if(!this.isDetailsType){
            for (let i = 0; i < data.length; i++) {     
              sheet={ 
                "مقدم الخدمة": data[i].service_id,
                "اسم الكرت": data[i].class_name,
                "عدد الكروت": data[i].totalCardsNo,
                "اجمالي التكلفة": data[i].totalCost,
                "اجمالى الربح": data[i].totalPrice,
              }
              this.excelService.addToSheet(sheet);  
            }
          }else{
            for (let i = 0; i < data.length; i++) {     
              sheet={ 
                "مقدم الخدمة": data[i].service_id,
                "اسم الكرت": data[i].class_name,
                "عدد الكروت": data[i].totalCardsNo,
                "القيمة": data[i].class_price,
                "التكلفة": data[i].class_cost,
                "اجمالي التكلفة": data[i].totalCost,
                "اجمالى الربح": data[i].totalPrice,
                "طريقة الاضافة": data[i].source_type,
                "تاريخ الادخال": this.datePipe.transform(data[i].edit_date,this.dateFormatService.dateFormat),
                "تاريخ البيع": this.datePipe.transform(data[i].po_date,this.dateFormatService.dateFormat),
              }
              this.excelService.addToSheet(sheet);   
            }
          }
          // sumTotal = Number.parseFloat(data[0].SumTotal).toFixed(2)
          // sumTotalCost = Number.parseFloat(data[0].SumTotalCostAmount).toFixed(2)
          // let total={ 
          //   "الاجمالى":sumTotal,
          //   "اجمالي التكلفة": sumTotalCost,
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
    
    
  }


  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    ServiceId: new FormControl(""),
    class_no: new FormControl(""),
    source_type : new FormControl(""),
    reportType: new FormControl("")
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getInventoryData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
      sourceType: this.f.source_type.value,
      class_no: this.f.class_no.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      isForDetails: this.isDetailsType
    }
    return this.thStartInvBalanceService.GetInventoryDataOverallAndDetails(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getInventoryData();
  }

  
  /*----------------------Search and reset search functions---------------------- */
  search(){
    if(this.f.reportType.value==""){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.ReportTypeReq"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      //reset page number
      this.pageNumber = 1;
      if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
        Swal.fire({
          title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
          icon: 'error',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton:false
        });
      }else{
        this.getInventoryData()
      } 
    }
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0;
    this.getInventoryData()
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------Report type change event---------------------- */
  reportTypeChanged($event) {
    this.datasource=[];
    this.totalCount=0;
    this.f.reportType.setValue($event.target.value)
    if(this.f.reportType.value=="2"){
      this.isDetailsType = true;
    }else{
      this.isDetailsType = false
      this.f.source_type.setValue("")
    }
    // this.reportTypeGrid = reportType;
    // this.dataSource = [];
  }

}
