import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetDataUpdatesComponent } from './get-data-updates/get-data-updates.component';

const routes: Routes = [
  { path: 'get-data-updates', component: GetDataUpdatesComponent,canActivate: [AuthGuard],  data: {permission: ['HalaDataUpdate.View'], roles:[]} },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataUpdateRoutingModule { }
