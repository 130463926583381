<!-- Add Account -->
<div class="col-12 d-flex align-items-center justify-content-between">
    <div class="text-sm-end"  *ngIf="permissionService.checkPermission(['ThdbMoveBalanceBetweenAccounts.Update'])">
      <button type="button" class="btn btn-success btn-rounded  me-2" (click)="transferBalance()" ><i class="mdi mdi-plus mr-1"></i>
        {{'Common.BalanceTransferForClients'| translate}}</button>
    </div>
     <!-- Export Excel -->
    <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
</div>


<div class="card py-3 px-3">
    <!-- Filters Row-->
    <form class="form" [formGroup]="form">
        <div class="card-body">
            <!-- First Row -->
            <div class="form-group row">
                <!-- Date From -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateFrom'|translate}}</label>
                    <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                </div>
                <!-- Date to -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateTo'|translate}}</label>
                    <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                </div>
                <!-- Branch-->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.Branch'|translate}}:</label>
                    <select formControlName="address" class="form-select" (change)="changeSelectedBranch()">
                        <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                        <option style="font-size: 16px !important" *ngFor="let item of branchesList" value="{{ item.address }}">
                        {{ item.address }}
                        </option>
                    </select>
                </div>
                <!-- Clients under branch-->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.ClientName'|translate}} :</label>
                    <ejs-dropdownlist showClearButton="true" formControlName="customerId" id='' [value]='value' [fields]='customerFields'
                    [popupHeight]='height' [dataSource]='customersList'
                    [allowFiltering]='customersList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                    filterBarPlaceholder='{{"Common.Search"|translate}}' 
                    (filtering)='onFiltering($event)'>
                    </ejs-dropdownlist>                                                   
                </div>
                <!-- Action Buttons -->
                <div class="col-lg-2 mb-3 d-flex align-items-end">
                    <div class="action-btns" style="margin-inline-end: 20px;">
                        <button type="submit" (click)="search()"
                        class="btn btn-primary font-weight-bold px-10 mr-2">
                            {{'Common.Search'|translate}}
                        </button>
                    </div>
                    <div class="action-btns">
                        <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                            {{'Common.Clear'|translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <!-- First Table -->
    <app-movement-table [datasource]="datasource"></app-movement-table>
   

    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>