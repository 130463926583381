import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdditionsRoutingModule } from './additions-routing.module';
import { GetAdditionsComponent } from './get-additions/get-additions.component';
import { CoreModule } from 'src/app/core/core.module';
import { AdditionsTableComponent } from './get-additions/_components/additions-table/additions-table.component';


@NgModule({
  declarations: [
    GetAdditionsComponent,
    AdditionsTableComponent
  ],
  imports: [
    CommonModule,
    AdditionsRoutingModule,
    CoreModule
  ]
})
export class AdditionsModule { }
