<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.CardName'| translate}} </th>
            <th class="align-middle">{{'Common.Serial'| translate}}</th>
            <th class="align-middle">{{'Common.Pin'| translate}}</th>
            <th class="align-middle">{{'Common.Date'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
            <td> <a class="text-body fw-bold">{{data.note}}</a> </td>
            <td>{{data.card_serial}}</td>
            <td>{{data.card_scratch}}</td>
            <td> <p> {{data.ExpiredDate | date:dateFormatService.dateOnlyFormat}} </p></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td> 
            <div class="d-flex">
              <span style="font-weight: 800; margin-inline-end: 20px;">{{'Common.Total'| translate}}</span> 
              <span>{{detailsTotalCount==0 ? '-' :detailsTotalCount}} {{'Common.Card'| translate}}</span>
            </div>
          </td>
          
      </tr>
      </tbody>
    </table>
</div>