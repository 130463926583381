<div class="table-responsive mb-0">
    <table class="table table-centered ">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.Company'| translate}}</th>
          <th class="align-middle">{{'Common.Class'| translate}}</th>
          <th class="align-middle">{{'Common.ClassType'| translate}}</th>
          <th class="align-middle">{{'Common.POS'| translate}}</th>
          <th class="align-middle">{{'Common.Distributor'| translate}}</th>
          <th class="align-middle">{{'Common.Agent'| translate}} </th>
          <th class="align-middle">{{'Common.CardAmount'| translate}} </th>
          <th class="align-middle"> {{'Common.AvgCost'| translate}}</th>
          <th class="align-middle"> {{'Common.TotalCost'| translate}}</th>
          <th class="align-middle"> {{'Common.TotalPrice'| translate}}</th>
          <th class="align-middle"> {{'Common.ProfitMargin'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.service_id}}</a> </td>
          <td> {{data.class_name}} </td>
          <td>{{data.class_type}}</td>
          <td>{{data.account_id}}</td>
          <td>{{data.dest_account_id}}</td>
          <td>{{data.agent_account_id}}</td>
          <td>{{data.class_no}}</td>
          <td>{{data.Avg_Cost}}</td>
          <td>{{data.Tot_Cost}}</td>
          <td>{{data.Tot_Price}}</td>
          <td>{{data.Profit_Margin}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{classNoSum==0 ? '-' :classNoSum}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumAvg_Cost==0 ? '-' :SumAvg_Cost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTot_Cost==0 ? '-' :SumTot_Cost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTot_Price==0 ? '-' :SumTot_Price | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumProfitMargin==0 ? '-' :SumProfitMargin | number : '1.0-2'}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>