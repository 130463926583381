import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminMovementRoutingModule } from './admin-movement-routing.module';
import { GetAdminMovementDataComponent } from './get-admin-movement-data/get-admin-movement-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { AdminMovementTableComponent } from './get-admin-movement-data/_components/admin-movement-table/admin-movement-table.component';


@NgModule({
  declarations: [
    GetAdminMovementDataComponent,
    AdminMovementTableComponent
  ],
  imports: [
    CommonModule,
    AdminMovementRoutingModule,
    CoreModule
  ]
})
export class AdminMovementModule { }
