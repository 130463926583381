import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAdditionsComponent } from './get-additions/get-additions.component';

const routes: Routes = [
  { path: 'getadditions', component: GetAdditionsComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbHalaAdditions.View'], roles:[]}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdditionsRoutingModule { }
