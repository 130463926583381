import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-admin-movement-table',
  templateUrl: './admin-movement-table.component.html',
  styleUrls: ['./admin-movement-table.component.scss']
})
export class AdminMovementTableComponent implements OnInit {

  @Input() datasource
  @Input() IsAdvancedAccountReportDataComponent
  
  totalAdditions:number = 0
  totalWithdraw:number = 0

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.datasource.length>0){
      this.totalAdditions = this.datasource[0].totalAdditions
      this.totalWithdraw = this.datasource[0].totalWithdraw
    }else{
      this.totalAdditions = 0
      this.totalWithdraw = 0
    }
  }

}
