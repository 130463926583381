import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { HalaBankService } from 'src/app/core/services/hala/hala-bank.service';
import { NgxSpinnerService } from "ngx-spinner";

import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-get-banks',
  templateUrl: './get-banks.component.html',
  styleUrls: ['./get-banks.component.scss']
})
export class GetBanksComponent implements OnInit {

  private unsubscribe: Subscription[] = [];
  
  //Table Permissions
  canDelete:boolean = this.permissionService.checkPermission(['HalaBank.Delete']);
  canAdd:boolean = this.permissionService.checkPermission(['HalaBank.Create']);
  canEdit:boolean = this.permissionService.checkPermission(['HalaBank.Update']);

  //Table Settings
  settings = {
    pager:{
      display:false,
      perPage:10
    },
    actions:{
      columnTitle :this.msgTranslateService.msgTranslate('Common.Actions'),
      add:this.canAdd,
      edit:this.canEdit,
      delete:this.canDelete,
    },
    add:{
      addButtonContent:"<i class='mdi  mdi-plus font-size-18 text-success m-3'> <span>" + this.msgTranslateService.msgTranslate('Common.Add') +"</span> </i>",
      createButtonContent:"<i class='mdi  mdi-plus font-size-18 text-success m-3'></i>",
      cancelButtonContent:"<i class='mdi mdi-close font-size-18 text-danger m-3'></i>",
      confirmCreate: true,
      mode:'inline',

    },
    edit: {
      editButtonContent:"<i class='mdi mdi-pencil font-size-18 text-success m-3'></i>",
      cancelButtonContent:"<i class='mdi mdi-close font-size-18 text-danger m-3'></i>",
      saveButtonContent:"<i class='mdi mdi-check font-size-18 text-success m-3'></i>",
      confirmSave: true,
      mode:'inline',
    },
    delete:{
      deleteButtonContent:"<i class='mdi mdi-delete font-size-18 text-danger'></i>",
      confirmDelete:true,
    },
    columns: {
      Id: {
        filter:false,
        // filterFunction: (cell?: any, search?: string) => {    
        //   return this.getBankById(search)           
        // },
        title: this.msgTranslateService.msgTranslate('HALA.BANK.ID'),
        editable:false,
        addable: false,
      },
      BankName: {
        filter:false,
        title: this.msgTranslateService.msgTranslate('HALA.BANK.NAME'),
      },
    },
  };

  //Table Variables
  datasource:[] = [];
  pageNo:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Filter Variables
  filterForm: FormGroup;
  showingFilteredData:boolean = false;

  isExporting:boolean = false;

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService, 
    private halabankService:HalaBankService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    public permissionService:PermissionService,
     ) { }

  ngOnInit(): void {
    //ng pagination on init calls page change so no need to getallbanks here
    // this.getAllBanks();
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false
    }
    if(this.f.id.value=="" && !this.showingFilteredData){
      const subscr = this.halabankService.GetAllBank(body).subscribe(
        (result)=>{
          if(result.IsSuccess){
            let data = result.Data
            for (let i = 0; i < data.length; i++) {
              let sheet={ 
                "رقم التعريف": data[i].Id,
                "إسم البنك": data[i].BankName,
              }
              this.excelService.addToSheet(sheet);
            }
            this.excelService.excelExport();
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.unsubscribe.push(subscr);
    }else{
      const subscr = this.halabankService.GetBankById(parseInt(this.f.id.value)).subscribe(
        (result:any)=>{
          // console.log(result);
          if(result.IsSuccess){
            let data = [result.Data] 
            for (let i = 0; i < data.length; i++) {
              let sheet={ 
                "رقم التعريف": data[i].Id,
                "إسم البنك": data[i].BankName,
              }
              this.excelService.addToSheet(sheet);
            }
            this.excelService.excelExport();      
          }
          this.isExporting = false;
        },
        (err)=>{
          this.isExporting = false
        }
      )
      this.unsubscribe.push(subscr);   
    }
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.filterForm = this.fb.group({
      id: new FormControl(""),
    });
  }
 
  // convenience getter for easy access to form fields
  get f() {
  return this.filterForm.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getAllBanks() {
    this.spinner.show();
    // console.log("called");
    let body = {
      pageNumber: this.pageNo,
      pageSize: this.pageSize,
      isPaginated: true
    }
    const subscr = this.halabankService.GetAllBank(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }
        this.spinner.hide();
      },(err)=>{
        this.spinner.hide();
      }
    )
    this.unsubscribe.push(subscr);
  }

  getBankById(){
    this.spinner.show();
    //reset pagination
    this.pageNo= 1
    const subscr = this.halabankService.GetBankById(parseInt(this.f.id.value)).subscribe(
      (result:any)=>{
        // console.log(result);
        if(result.IsSuccess){
          this.showingFilteredData = true;
          let bank:any = [result.Data]
          this.datasource=bank;
          this.totalCount = 1

        }else{
          
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide();
      },(err)=>{
        this.spinner.hide();
      }
    )
    this.unsubscribe.push(subscr);
  }

  createBankConfirm(event){
    if(event.newData.BankName==""){
      this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyName'), "",{
        positionClass: 'toast-bottom-left',
      });
    }else{
      let body = {
        bankName: event.newData.BankName    
      }
      const subscr = this.halabankService.AddUpdateBank(body).subscribe(
        (result)=>{
          if(result.IsSuccess){
            if(this.datasource.length<10){
              event.source.append(result.Data);
            }
            event.confirm.resolve.skipAdd = true;
            event.confirm.resolve();
            this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccAdd'), "",{
              positionClass: 'toast-bottom-left',
            });
          }else{
            this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
              positionClass: 'toast-bottom-left',
            });
          }
        },
        (err)=>{
          // console.log("err",err)
          this.toastr.error(err.error.errors.BankName[0], "",{
            positionClass: 'toast-bottom-left',
          });
        }
      )
      this.unsubscribe.push(subscr);
    }
   
  }

  editBankConfirm(event){
    // console.log(event);
    if(event.newData.BankName==""){
      this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyName'), "",{
        positionClass: 'toast-bottom-left',
      });
    }else{
      let body = {
        id: event.data.Id,
        bankName: event.newData.BankName    
      }
      const subscr = this.halabankService.AddUpdateBank(body).subscribe(
        (result)=>{
          if(result.IsSuccess){
            event.confirm.resolve();
            this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
              positionClass: 'toast-bottom-left',
            });
          }else{
            this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
              positionClass: 'toast-bottom-left',
            });
          }
        },
        (err)=>{
          // console.log("err",err)
          this.toastr.error(err.error.errors.BankName[0], "",{
            positionClass: 'toast-bottom-left',
          });
        }
      )
      this.unsubscribe.push(subscr);
    }
    //event.confirm.reject();
  }

  /*----------------------Delete Functions---------------------- */
  //Alert on delete
  showAlert(event){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.Delete'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteBankConfirm(event);
      },
    })
  }

  deleteBankConfirm(event){
    // console.log("delete event",event)
    const subscr = this.halabankService.DeleteBank(event.data.Id).subscribe(
      (result)=>{
        if(result.IsSuccess){
          event.source.remove(event.data)
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccDelete'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.errors, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    )
    this.unsubscribe.push(subscr);
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNo = event;
    this.getAllBanks();
    // console.log("pagechange",event)
  }

  resetFilters(){
    this.initForm();
    this.showingFilteredData= false;
    //Reset Pagination
    this.pageNo = 1;
    this.getAllBanks();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
