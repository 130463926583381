import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardBatchesRoutingModule } from './card-batches-routing.module';
import { GetBatchesDataComponent } from './get-batches-data/get-batches-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { CardBatchesTableComponent } from './get-batches-data/_components/card-batches-table/card-batches-table.component';


@NgModule({
  declarations: [
    GetBatchesDataComponent,
    CardBatchesTableComponent
  ],
  imports: [
    CommonModule,
    CardBatchesRoutingModule,
    CoreModule
  ]
})
export class CardBatchesModule { }
