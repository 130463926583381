import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';


import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { AuthenticationService } from './core/services/auth.service';
import { LanguageService } from './core/services/language.service';
import { ToastrModule } from 'ngx-toastr';
import { BankModule } from './pages/hala/bank/bank.module';
import { ProvidersModule } from './pages/hala/providers/providers.module';
import { AccountsModule } from './pages/hala/accounts/accounts.module';
import { ReceivedamountsModule } from './pages/hala/receivedamounts/receivedamounts.module';
import { DatePipe } from '@angular/common';
import { ManagecardsModule } from './pages/hala/managecards/managecards.module';
import { AdditionsModule } from './pages/hala/additions/additions.module';
import { AggregateService, ExcelExportService, PageService, PdfExportService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import {MatDialogModule} from '@angular/material/dialog';
import { ThurayaModule } from './pages/thuraya/thuraya.module';
import { TrxRefundModule } from './pages/thuraya/trx-refund/trx-refund.module';
import { CardBatchesModule } from './pages/thuraya/card-batches/card-batches.module';
import { OutstandingCardsModule } from './pages/thuraya/outstanding-cards/outstanding-cards.module';
import { InventoryMovementModule } from './pages/thuraya/inventory-movement/inventory-movement.module';
import { PurchasesModule } from './pages/thuraya/purchases/purchases.module';
import { CompanySalesModule } from './pages/thuraya/company-sales/company-sales.module';
import { MonthlyTargetModule } from './pages/thuraya/monthly-target/monthly-target.module';
import { PackingCardModule } from './pages/thuraya/packing-card/packing-card.module';
import { ManageAccountsModule } from './pages/thuraya/manage-accounts/manage-accounts.module';
import { CompaniesGroupingModule } from './pages/hala/companies-grouping/companies-grouping.module';
import { PriceSettingsModule } from './pages/thuraya/price-settings/price-settings.module';
import { ManageIncomingAmountsModule } from './pages/thuraya/manage-incoming-amounts/manage-incoming-amounts.module';
import { BigDataModule } from './pages/big-data/big-data.module';
import { BillModule } from './pages/big-data/bill/bill.module';
import { AccountReportModule } from './pages/thuraya/account-report/account-report.module';
import { PosScratchReportModule } from './pages/thuraya/pos-scratch-report/pos-scratch-report.module';
import { EffectivePosReportModule } from './pages/thuraya/effective-pos-report/effective-pos-report.module';
import { BulkCardsModule } from './pages/thuraya/bulk-cards/bulk-cards.module';
import { AdvancedBulkAccountModule } from './pages/thuraya/advanced-bulk-account/advanced-bulk-account.module';
import { PosBulkBillModule } from './pages/thuraya/pos-bulk-bill/pos-bulk-bill.module';
import { ServiceClassSettingsModule } from './pages/thuraya/service-class-settings/service-class-settings.module';
import { CompanyProfitModule } from './pages/thuraya/company-profit/company-profit.module';
import { CustomerSalesDetailsModule } from './pages/thuraya/customer-sales-details/customer-sales-details.module';
import { AdminMovementModule } from './pages/thuraya/admin-movement/admin-movement.module';
import { StartInvBalanceModule } from './pages/thuraya/start-inv-balance/start-inv-balance.module';
import { RolesModule } from './pages/hala/roles/roles.module';
import { CurrentStockBalanceModule } from './pages/thuraya/current-stock-balance/current-stock-balance.module';
import { OutstandingClassServicesModule } from './pages/thuraya/outstanding-class-services/outstanding-class-services.module';
import { GamePurchaseInvoiceModule } from './pages/thuraya/game-purchase-invoice/game-purchase-invoice.module';
import { BulkOrderCardModule } from './pages/thuraya/bulk-order-card/bulk-order-card.module';
import { ProfitUserModule } from './pages/thuraya/profit-user/profit-user.module';
import { ClientEditPricesModule } from './pages/thuraya/client-edit-prices/client-edit-prices.module';
import { OtpModule } from './pages/thuraya/otp/otp.module';
import { DistributorPagesModule } from './pages/thuraya/distributor-pages/distributor-pages.module';
import { MoveBalanceBetweenAccountsModule } from './pages/thuraya/move-balance-between-accounts/move-balance-between-accounts.module';
import { DistributorReportsModule } from './pages/thuraya/distributor-reports/distributor-reports.module';
import { CardUpdateModule } from './pages/hala/card-update/card-update.module';
import { DataUpdateModule } from './pages/hala/data-update/data-update.module';
import { JobApplicationModule } from './pages/hala/job-application/job-application.module';
import { SadadModule } from './pages/sadad/sadad.module';
import { SadadServiceApiModule } from './pages/sadad/sadad-service-api/sadad-service-api.module';

// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// } else {
//   // tslint:disable-next-line: no-unused-expression
//   FakeBackendInterceptor;
// }

//Check User already logged in first thing in the app and selected language for layout change
function appInitializer(authService: AuthenticationService,languageService:LanguageService) {
  languageService.checkLanguage();
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    BankModule,
    ProvidersModule,
    AccountsModule,
    ReceivedamountsModule,
    ManagecardsModule,
    AdditionsModule,
    MatDialogModule,
    ThurayaModule,
    TrxRefundModule,
    CardBatchesModule,
    OutstandingCardsModule,
    InventoryMovementModule,
    PurchasesModule,
    CompanySalesModule,
    MonthlyTargetModule,
    PackingCardModule,
    ManageAccountsModule,
    CompaniesGroupingModule,
    PriceSettingsModule,
    ManageIncomingAmountsModule,
    BigDataModule,
    BillModule,
    AccountReportModule,
    PosScratchReportModule,
    EffectivePosReportModule,
    BulkCardsModule,
    AdvancedBulkAccountModule,
    PosBulkBillModule,
    ServiceClassSettingsModule,
    CompanyProfitModule,
    CustomerSalesDetailsModule,
    AdminMovementModule,
    StartInvBalanceModule,
    RolesModule,
    CurrentStockBalanceModule,
    OutstandingClassServicesModule,
    GamePurchaseInvoiceModule,
    BulkOrderCardModule,
    ProfitUserModule,
    ClientEditPricesModule,
    OtpModule,
    DistributorPagesModule,
    MoveBalanceBetweenAccountsModule,
    DistributorReportsModule,
    CardUpdateModule,
    DataUpdateModule,
    JobApplicationModule,
    SadadModule,
    SadadServiceApiModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService,LanguageService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe,
    PdfExportService, ExcelExportService, ToolbarService, AggregateService,PageService,
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
})
export class AppModule { }
