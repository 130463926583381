import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { HalaCompaniesSettingsService } from 'src/app/core/services/hala/hala-companies-settings.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-companies-group-table',
  templateUrl: './companies-group-table.component.html',
  styleUrls: ['./companies-group-table.component.scss']
})
export class CompaniesGroupTableComponent implements OnInit {

  @Input() datasource
  constructor(
    public dateFormatService:DateFormatService,
    private msgTranlsateService:MessageTranslateService,
    private toastr: ToastrService,
    private halaCompaniesSettingsService:HalaCompaniesSettingsService,
    public permissionService:PermissionService) { }

  ngOnInit(): void {
  }

  showAlert(data){
    Swal.fire({
      title: this.msgTranlsateService.msgTranslate('SwalMessages.Delete'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranlsateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteCompaniesGrouping(data);
      },
    })
  }
  
  deleteCompaniesGrouping(data){
    this.halaCompaniesSettingsService.DeleteCompaniesGrouping(data.Id).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.datasource = this.datasource.filter(d=> d.Id != data.Id);
          this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccDelete'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },(err)=>{
        this.toastr.error(err.error.title, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    )
  }

}
