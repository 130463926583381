import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, ToolbarItems, } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-inserted-orders-table',
  templateUrl: './inserted-orders-table.component.html',
  styleUrls: ['./inserted-orders-table.component.scss']
})
export class InsertedOrdersTableComponent implements OnInit {

  @Input() datasource
  //Ejs grid variables
  public toolbarOptions: ToolbarItems[] | object;
  @ViewChild("grid") public grid: GridComponent;
  dataSource = [];
  public format = { type: "date", format: "yyyy/MM/dd" };
  public aggreagtes: Object;
  public pageSettings: Object;
 

  constructor(
    public languageService:LanguageService,
  ) { }

  ngOnInit(): void {
    this.toolbarOptions = ['ExcelExport', 'Print'];
    this.aggreagtes = [
      {
        columns: [
          {
            type: "Sum",
            field: "point_price",
            format: "N2",

          }
        ],
      },
    ];
  }

  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

}
