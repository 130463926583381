import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-app-table',
  templateUrl: './job-app-table.component.html',
  styleUrls: ['./job-app-table.component.scss']
})
export class JobAppTableComponent implements OnInit {

  @Input() datasource;
  
  constructor() { }

  ngOnInit(): void {
  }

  downloadAttachment(url){
    window.open(url, "_blank");
  }

}
