import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BgDataBillService {

  //API URLS
  API_URLGetBillHeader = `${environment.apiUrl}/BigDataBill/GetBillHeader`;  
  API_URLGetBillDetails = `${environment.apiUrl}/BigDataBill/GetBillDetails`;  

  constructor( private http: HttpClient ) {
  }

  GetBillHeader(body):Observable<any> {
    const url = this.API_URLGetBillHeader;    
    return this.http.post<any>(url,body)
  }

  GetBillDetails(body):Observable<any> {
    const url = this.API_URLGetBillDetails;    
    return this.http.post<any>(url,body)
  }
}
