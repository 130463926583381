<!--Filter Row-->
<div class="row" *ngIf="permissionService.checkPermission(['ThdbManageIncomingAmounts.View','ThdbManageIncomingAmounts.Update', 'ThdbManageIncomingAmounts.Delete'])">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Customer -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.AgentName'|translate}} :</label>
                        <app-agents-dropdown #agentsDropDown (updateParent)="setSelectedAgent($event)"></app-agents-dropdown>
                    </div>

                    <!-- Added By (only for report component) -->
                    <div class="col-lg-3 mb-3" *ngIf="IsIncomingAmountsReportComponent">
                        <app-users-dropdown  #usersDropDown (updateParent)="setSelectedUser($event)"></app-users-dropdown>
                    </div>
                    <!-- Customer Phone-->
                    <!-- <div class="col-lg-3">
                        <label>{{'Common.ClientPhone'|translate}} :</label>
                        <input class="form-control" formControlName="customerPhone" >
                    </div> -->

                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel -->
<div class="col-12 d-flex align-items-center mb-3" [ngClass]="IsGetAllIncomingAmountsComponent ? 'justify-content-between' : 'justify-content-end'">
    <!-- Add amount(Only if not report page) -->
    <div class="text-sm-end" *ngIf="IsGetAllIncomingAmountsComponent">
      <button *ngIf="permissionService.checkPermission(['ThdbManageIncomingAmounts.Create'])" type="button" class="btn btn-success btn-rounded  me-2" (click)="openAddDialog()"><i class="mdi mdi-plus mr-1"></i>
        {{'SYSTEMSETTINGS.IcomingAmounts.TEXT'| translate}}</button>
    </div>
     <!-- Export Excel -->
    <app-excel-export *ngIf="permissionService.checkPermission(['ThdbManageIncomingAmounts.View'])" [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
</div>


<div class="card py-3 px-3" *ngIf="permissionService.checkPermission(['ThdbManageIncomingAmounts.View', 'ThdbManageIncomingAmounts.Update', 'ThdbManageIncomingAmounts.Delete'])">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-inc-amounts-table [datasource]="datasource" [IsIncomingAmountsReportComponent]="IsIncomingAmountsReportComponent"></app-inc-amounts-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>