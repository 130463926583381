
<div class="py-3 px-3">
    <h3 class="card-title">{{'Common.AddMembers'| translate}}</h3>
    <hr>
</div>


<ngx-spinner type="ball-scale-pulse" [fullScreen]="false" color="white" size="large"></ngx-spinner>

<form class="form" [formGroup]="formCreateUpdate">
    <div class="card-body">
      <div class="form-group row">
        <div style="width: 100%;height: 300px;overflow: auto;">
            <div  *ngFor="let item of CompaniesData;let i=index" class="companiesContainer">
                <input class="checkbox checkbox-lg checkbox-inline"  style="margin-inline-end: 20px;" (change)="onCheckboxChangeTo($event)" 
                value="{{item.company_no}}" type="checkbox" />[{{item.company_no}}] - {{item.company_name}}
              
            </div>
        </div>

        <div class="col-lg-12 action-btns d-flex justify-content-end" style="margin-top: 25px;">
          <button class="btn btn-primary font-weight-bold" style="margin-inline-end: 20px;" 
          [disabled]="formCreateUpdate.controls.selectedcompanyList.value.length==0" (click)="addNewCompaniesGrouping()">{{'Common.Add'| translate}}</button>
          <button class="btn btn-secondary font-weight-bold" (click)="reset()">{{'Common.Discard'| translate}}</button>
        </div>
      </div>
    </div>
  </form>