import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];
  selectedLanguage:string = null

  constructor(public translate: TranslateService) {
    this.selectedLanguage =  localStorage.getItem("lang")
    //if no language in local storage set default to en
    if(!this.selectedLanguage){
      this.selectedLanguage = "en";
      localStorage.setItem("lang","en")
    }
    this.translate.use(this.selectedLanguage);
  }

  //Check language at init of app (app module) and when change language
  checkLanguage(){
    if(this.selectedLanguage == 'ar') {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
    }
    this.translate.use(this.selectedLanguage);
  }

  //set selected langauge
  public setLanguage(lang) {
    // this.translate.use(lang);
    localStorage.setItem("lang", lang)
    this.selectedLanguage = lang;
    this.checkLanguage();
    // this.cookieService.set('lang', lang);
  }

}
