import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThMoveBlanaceBetweenAccountsService {

  API_URLGetMoveBalanceBetweenAccountsData = `${environment.apiUrl}/ThdbMoveBalanceBetweenAccounts/GetMoveBalanceBetweenAccountsData`;
  API_URLGetProfitUserBranches = `${environment.apiUrl}/ThdbMoveBalanceBetweenAccounts/GetProfitUserBranches`;
  API_URLGetAllCustomerAccountData = `${environment.apiUrl}/ThdbMoveBalanceBetweenAccounts/GetAllCustomerAccountData`;
  API_URLMoveBalanceBetweenAccounts = `${environment.apiUrl}/ThdbMoveBalanceBetweenAccounts/MoveBalanceBetweenAccounts`;

  constructor(private http:HttpClient) { }

  GetMoveBalanceBetweenAccountsData(body): Observable<any> {
    const url = this.API_URLGetMoveBalanceBetweenAccountsData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetProfitUserBranches(body): Observable<any> {
    const url = this.API_URLGetProfitUserBranches;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllCustomerAccountData(body): Observable<any> {
    const url = this.API_URLGetAllCustomerAccountData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  MoveBalanceBetweenAccounts(body): Observable<any> {
    const url = this.API_URLMoveBalanceBetweenAccounts;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
