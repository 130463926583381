import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ThEffectivePosReportService } from 'src/app/core/services/thuraya/th-effective-pos-report.service';

@Component({
  selector: 'app-get-effective-pos-data',
  templateUrl: './get-effective-pos-data.component.html',
  styleUrls: ['./get-effective-pos-data.component.scss']
})
export class GetEffectivePosDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  @ViewChild("usersDropDown") usersDropDown;


  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thEffectivePosReportService:ThEffectivePosReportService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      customeR_ID: this.f.customeR_ID.value,
    }
    this.thEffectivePosReportService.GetEffectivePosReportData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {            
            let sheet={ 
              "رقم الهاتف": data[i].CUSTOMER_ID,
              "نقطة بيع	": data[i].CUSTOMER_NAME,
              "الرصيد": data[i].balance,
              "مجموع المبيعات": data[i].SumPointPrice,
              "العنوان":data[i].cust_address,
              "rt1":data[i].rt1,
              "rt2":data[i].rt2,
              "الرقم الضريبي":data[i].rt3,
              "الرقم التجاري":data[i].rt4,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    customeR_ID: new FormControl(""),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getEffectivePosReportData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      customeR_ID: this.f.customeR_ID.value,
    }
    return this.thEffectivePosReportService.GetEffectivePosReportData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );  
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getEffectivePosReportData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getEffectivePosReportData() 
    } 
  }

  reset(){
    this.initForm()
    this.usersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0;
    this.datasource = []
    // this.getEffectivePosReportData()
  }

  /*----------------------when Choosing User---------------------- */
  setSelectedUser(event){
    this.form.controls.customeR_ID.setValue(event)
  }

}
