import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetJobApplicationsComponent } from './get-job-applications/get-job-applications.component';

const routes: Routes = [
  { path: 'get-all-job-applications', component:GetJobApplicationsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaJobApplication.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobApplicationRoutingModule { }
