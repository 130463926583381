import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {


  dateForm: FormGroup;

  dateSelctedValue = null
  dateSelctedTimeStamp = null

  @Output() setSelectedDate: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm()
  }
  /*----------------------Functions Called from parent---------------------- */
  reset(){
    this.initForm()
  }

  /*--------------------------Form Functions-------------------------- */
  initForm(){
    this.dateForm = this.fb.group({
      dateSelected: new FormControl(""),
    });
  }

  get f() {
    return this.dateForm.controls;
  }

  /*--------------------------On Date Select-------------------------- */
  onDateSelect(event){
    this.getDate()
    let body = {
      date: this.dateSelctedValue,
      dateTimeStamp: this.dateSelctedTimeStamp
    }
    this.setSelectedDate.emit(body)
  }

  /*--------------------------Clear Date-------------------------- */
  clearDate(){
    this.initForm()
    this.getDate();
    let body = {
      date: this.dateSelctedValue,
      dateTimeStamp: this.dateSelctedTimeStamp
    }
    this.setSelectedDate.emit(body)
  }

  
  /*--------------------------Format date for requests-------------------------- */
  pipe = new DatePipe('en-US');
  getDate() {
    let dateSelcted = this.f.dateSelected.value;
    if (dateSelcted) {
      this.dateSelctedValue = new Date(
        dateSelcted.year,
        dateSelcted.month - 1,
        dateSelcted.day
      );
      this.dateSelctedTimeStamp = this.dateSelctedValue.getTime()
      this.dateSelctedValue = this.pipe.transform(this.dateSelctedValue, 'MM/dd/yyyy');
    } else {
      this.dateSelctedValue = "";
      this.dateSelctedTimeStamp  = "";
    }
  }

}
