import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThManageIncomingAmountsService } from 'src/app/core/services/thuraya/th-manage-incoming-amounts.service';
import Swal from 'sweetalert2';
import { AddAmountDialogComponent } from '../add-amount-dialog/add-amount-dialog.component';

@Component({
  selector: 'app-inc-amounts-table',
  templateUrl: './inc-amounts-table.component.html',
  styleUrls: ['./inc-amounts-table.component.scss']
})
export class IncAmountsTableComponent implements OnInit {

  @Input() datasource;
  @Input() IsIncomingAmountsReportComponent;

  constructor(
    public dateFormatService:DateFormatService,
    private modalService:NgbModal,
    private msgTranslateService:MessageTranslateService, 
    private thManageIncomingAmountsService:ThManageIncomingAmountsService, 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public permissionService:PermissionService
    ) { }

  ngOnInit(): void {
  }

  
  /*----------------------Edit Dialog---------------------- */
  openEditDialog(data) {
    let modalRef = this.modalService.open(AddAmountDialogComponent, { centered: true,});
    modalRef.componentInstance.isEditing = true;
    modalRef.componentInstance.amountOldData = data;
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdataedit",newData)
      let index = this.datasource.findIndex(d=>d.row_no==newData.row_no)
      this.datasource[index] = newData;
      //these do not change so we set them from olddata
      this.datasource[index].edit_user = data.edit_user
      this.datasource[index].edit_date = data.edit_date
      
    })
  }

  /*----------------------Delete Functions---------------------- */
  //Alert on delete
  showAlertDelete(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.Delete'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteIncomingAmountsData(data);
      },
    })
  }

  deleteIncomingAmountsData(data){
    this.thManageIncomingAmountsService.DeleteIncomingAmounts(data.row_no).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.updateTableView(data,'delete')
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccDelete'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.errors, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    )
  }

  /*----------------------Send Functions---------------------- */
  //Alert on Send
  showAlertSend(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.ApproveAmount'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.sendIncomingAmountsData(data);
      },
    })
  }

  sendIncomingAmountsData(data){
    this.thManageIncomingAmountsService.SendIncomingAmounts(data.row_no).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccSend'), "",{
            positionClass: 'toast-bottom-left',
          });
          this.updateTableView(data,'send')
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.errors, "",{
          positionClass: 'toast-bottom-left',
        });
      }
    )
  }

  /*----------------------Update table View after edit/delete or send---------------------- */
  updateTableView(data,status){
    if(status=="delete" || status=="send" ){
      this.datasource =  this.datasource.filter(d=>d.row_no != data.row_no);
    }else if (status=="update"){
      let index = this.datasource.findIndex(d=>d.row_no == data.row_no)
      this.datasource[index].ResponseStatus = "200"
      this.datasource[index].ResponseMessage = "Transaction successful"
    }
  }

}
