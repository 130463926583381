import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThCompanyProfitService } from 'src/app/core/services/thuraya/th-company-profit.service';
import { ThCustomerSalesDetailsService } from 'src/app/core/services/thuraya/th-customer-sales-details.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-cust-sales-details',
  templateUrl: './get-cust-sales-details.component.html',
  styleUrls: ['./get-cust-sales-details.component.scss']
})
export class GetCustSalesDetailsComponent implements OnInit {

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false

  constructor(
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thCustomerSalesDetailsService:ThCustomerSalesDetailsService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.thCustomerSalesDetailsService.GetCustomerSalesDetails(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "اسم العميل": data[i].CUSTOMER_NAME,
              "رقم الحساب": data[i].account_id,
              "الشركة": data[i].service_id,
              "اسم الكود": data[i].code_name,
              "الفئة": data[i].note,
              "العدد": data[i].cardsNo,
              "سعر الموزع": data[i].sell_price,
              "الاجمالى": data[i].TotalSellPrice,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "الاجمالى": Number.parseFloat(result.Data.SumTotalSellPrice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  /*----------------------API Requests---------------------- */
  getCustomerSalesDetails() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.thCustomerSalesDetailsService.GetCustomerSalesDetails(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    // if(!this.toDateCom || !this.fromDateCom){
    //   Swal.fire({
    //     title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
    //     icon: 'error',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false
    //   });
    // }
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getCustomerSalesDetails()
    } 
  }

  reset(){
    this.datasource =[] 
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
    this.getCustomerSalesDetails()
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    // if(this.toDateCom && this.fromDateCom){
      this.getCustomerSalesDetails();
    // }
  }

}
