import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-movement-table',
  templateUrl: './movement-table.component.html',
  styleUrls: ['./movement-table.component.scss']
})
export class MovementTableComponent implements OnInit {
  
  @Input() datasource;
  
  TotalCreditAmount:number = 0;
  TotalDebitAmount:number = 0;

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.TotalCreditAmount = this.datasource[0].TotalCreditAmount;
      this.TotalDebitAmount = this.datasource[0].TotalDebitAmount;
    }else{
      this.TotalCreditAmount = 0;
      this.TotalDebitAmount = 0
    }
  }

}
