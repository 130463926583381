import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GamePurchaseInvoiceRoutingModule } from './game-purchase-invoice-routing.module';
import { GetGamePurchaseDataComponent } from './get-game-purchase-data/get-game-purchase-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { GameInvoiceTableComponent } from './get-game-purchase-data/_components/game-invoice-table/game-invoice-table.component';


@NgModule({
  declarations: [
    GetGamePurchaseDataComponent,
    GameInvoiceTableComponent
  ],
  imports: [
    CommonModule,
    GamePurchaseInvoiceRoutingModule,
    CoreModule
  ]
})
export class GamePurchaseInvoiceModule { }
