import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.scss'
})
export class NavHeaderComponent {

	@Output() onToggleSideNav: EventEmitter<boolean> = new EventEmitter();

  	toggleChat: boolean = true;
	toggleSingle: boolean = true;

	// Language Variables
	element:any;
	

	constructor(
	) { }
	
	ngOnInit(): void {
	
	}
	
	openMobileNav(){
		this.onToggleSideNav.emit(true)
	}
	
	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}

}
