
<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>


<div class="card py-3 px-3">
    <!-- Filters Row-->
    <form class="form" [formGroup]="form">
        <div class="card-body">
            <!-- First Row -->
            <div class="form-group row">
                <!-- Date From -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateFrom'|translate}}</label>
                    <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                </div>
                <!-- Date to -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateTo'|translate}}</label>
                    <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                </div>
                <!-- Clients list (Agents/Dist list only as these are the list of clients asked for amount)-->
                <!-- <div class="col-lg-3 mb-3">
                    <label>{{'Common.RequestExecuter'|translate}} :</label>
                    <ejs-dropdownlist showClearButton="true" formControlName="userPhone" id='' [value]='value' [fields]='agentsDistribFields'
                    [popupHeight]='height' [dataSource]='agentsDistribList'
                    [allowFiltering]='agentsDistribList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                    filterBarPlaceholder='{{"Common.Search"|translate}}' 
                    (filtering)='onFiltering($event)'>
                    </ejs-dropdownlist>                                                   
                </div> -->
                <!-- Clients list (all Agents/Dist/pos list)-->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.Requestor'|translate}} :</label>
                    <app-all-customers-dropdown #allCustomersDropDown (updateParent)="setSelectedCustomer($event)"></app-all-customers-dropdown>
                </div>
                <!-- Settled -->
                <div class="col-lg-3  mb-3">
                    <label>{{'Common.SettledStatus'| translate}}:</label>
                    <select formControlName="isSetteled" class="form-select">
                        <option [selected]="true" value=""> ----{{'Common.Choose'| translate}}---- </option>
                        <option [ngValue]="true"> {{'Common.Settled'| translate}} </option>
                        <option [ngValue]="false"> {{'Common.NotSettled'| translate}} </option>
                    </select>
                </div>
                <!-- Action Buttons -->
                <div class="col-lg-2 mb-3 d-flex align-items-end">
                    <div class="action-btns" style="margin-inline-end: 20px;">
                        <button type="submit" (click)="search()"
                        class="btn btn-primary font-weight-bold px-10 mr-2">
                            {{'Common.Search'|translate}}
                        </button>
                    </div>
                    <div class="action-btns">
                        <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                            {{'Common.Clear'|translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <!-- First Table -->
    <app-sadad-transactions-table [datasource]="datasource" (requestByTransId)="getRequestsByTransactionId($event)" (transChildren)="getTransactionchildrens($event)"></app-sadad-transactions-table>   

    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>

<div class="row mt-5">
    <div class="col-xl-12">
      <div class="card card-custom gutter-b example example-compact px-2 py-4">
        <ejs-grid #grid id="Grid" [enableRtl]="languageService.selectedLanguage=='ar'" [dataSource]="transactionChildrenData" [pageSettings]="pageSettings"
        [toolbar]="toolbarOptions" [pageSettings]='pageSettings' [allowPaging]="true" [allowExcelExport]="true"
        [aggregates]="aggreagtes" (toolbarClick)="toolbarClick($event)">
          <e-columns>
              <e-column field="Amount" isPrimaryKey='true' headerText="{{ 'Common.Amount2' | translate}}"></e-column>
              <e-column field="RequestorUser.UserFullName" isPrimaryKey='true' headerText="{{ 'Common.RequestorName' | translate}}"></e-column>
              <e-column field="RequestorUser.UserName" isPrimaryKey='true' headerText="{{ 'Common.RequestorPhone' | translate}}"></e-column>
              <e-column field="ApprovalUser.UserFullName" isPrimaryKey='true' headerText="{{ 'Common.RequestExecuterName' | translate}}"></e-column>
              <e-column field="ApprovalUser.UserName" isPrimaryKey='true' headerText="{{ 'Common.RequestExecuterPhone' | translate}}"></e-column>
              <e-column field="SetteledAmount" isPrimaryKey='true' headerText="{{ 'Common.SettledAmount' | translate}}"></e-column>
              <e-column field="RemainAmount" headerText="{{ 'HALA.Withdrawals.RemainValue' | translate}}" [valueAccessor]='calculateRemaining'>
                  <ng-template #template let-data>
                  <span>{{data.Amount - data.SetteledAmount}}</span>
                  </ng-template>
              </e-column>
              <e-column  headerText="{{ 'Common.Details' | translate}}">
                <ng-template #template let-data>
                    <button class="btn btn-primary" (click)="getRequestsByTransactionId(data)">{{ 'Common.Details' | translate}}</button>
                </ng-template>
            </e-column>

          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>