
<!-- Filters -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label"> {{'HALA.SendExcel.AddNewFile'|translate}} </h3>
        </div>
      </div>

      <!--begin::Form-->
      <div class="form-group">
        <form class="form" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
          <div class="card-body">

            <!--Fields Row  -->
            <div class="form-group row">
                  <!-- Date to -->
                  <div class="col-lg-4 mb-3">
                      <label>{{'Common.DateTo'|translate}}</label>
                      <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                  </div>
                  <!-- Service -->
                  <div class="col-lg-4 mb-3">
                    <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                  </div>
                  <!-- Card Name -->
                  <div class="col-lg-4 mb-3">
                      <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                      <select formControlName="class_no" class="form-control">
                          <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                          <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                          [{{ item.class_no }}] - {{ item.class_name }}
                          </option>
                      </select>
                      <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                  </div> 
                  <!-- Provider -->
                  <div class="col-lg-4 mb-3">
                    <app-providers-dropdown #providersDropDown (updateParent)="setSelectedProvider($event)"></app-providers-dropdown>
                  </div>
                  <!-- Order no -->
                  <div class="col-lg-4 mb-3">
                      <label>{{'HALA.SendCardsEmployee.OrderNo'|translate}}:</label>
                      <input type="text" class="form-control" placeholder=""
                          formControlName="orderNo" />
                  </div>
            </div>

             <!--Upload/download/view file Row  -->
            <div class="row mt-2">
              <!-- Upload File Excel -->
              <div class='loadExcelInput col-lg-6 mb-3'>
                <label>{{'HALA.SendExcel.OpenFile'|translate}}:</label>
                <input type="file" formControlName="file" class="form-control" (change)="changeFileListener($event)" placeholder="Upload file"
                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              </div>

              <!-- Action buttons -->
              <div class="col-lg-6 d-flex align-items-end mb-3">
                <button type="submit" (click)="ShowFileData()" class="btn btn-primary font-weight-bold px-3" style="margin-inline-end: 20px;"> {{'Common.Show'|translate}} </button>
                <button type="submit" (click)="downloadExcelFormat()" class="btn btn-success font-weight-bold px-3">{{'HALA.SendExcel.DownloadExcel'|translate}}</button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <!--end::Form-->
    </div>
  </div>
</div>

<!-- Card Table -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
      <div class="table-striped table-responsive">
        <ejs-grid #grid id="Grid" [enableRtl]="languageService.selectedLanguage=='ar'" [dataSource]="dataSource" [pageSettings]="pageSettings"
          [toolbar]="toolbarOptions" [allowPaging]="true" [allowExcelExport]="true" [aggregates]="aggreagtes"
          (toolbarClick)="toolbarClick($event)">

          <e-columns>
            <e-column field="Service_Id" headerText=" {{'HALA.SendCardsEmployee.ServiceId'|translate}}  " width="120"></e-column>
            <e-column field="Class_Name" headerText="  {{'HALA.SendCardsEmployee.CardName'|translate}} " width="150"></e-column>
            <e-column field="AddedDate" type="date" [format]="format" headerText="{{'Common.DateFrom'|translate}}" [valueAccessor]='dateFormatter' width="120"></e-column>
            <e-column field="ExpirationDate" type="date" [format]="format" headerText="{{'Common.DateTo'|translate}}" [valueAccessor]='dateFormatter'width="120"></e-column>
            <e-column field="ProviderName" headerText=" {{'HALA.SendCardsEmployee.ProviderId'|translate}} "width="200"></e-column>
            <e-column field="OrderNo" headerText=" {{'HALA.SendCardsEmployee.OrderNo'|translate}} "width="120"></e-column>
            <e-column field="serialCard" headerText=" {{'Common.Serial'|translate}}  "></e-column>
            <e-column field="pinCard" headerText=" {{'Common.Pin'|translate}}"></e-column>
          </e-columns>
        </ejs-grid>

      </div>
    </div>
  </div>
</div>

<!-- Card With Problems Table -->
<div class="row">
  <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-2 py-2">
      <h3 style="color: rgb(196, 82, 82);">{{'HALA.SendExcel.CardsProblem'|translate}}</h3>
      <div class="table-striped table-responsive">
        <ejs-grid #detailgrid [enableRtl]="languageService.selectedLanguage=='ar'" [pageSettings]="pageSettings" [toolbar]="toolbarOptionsDetails"
          [allowPaging]="true" (toolbarClick)='clickHandler($event)' [allowExcelExport]="true"
          [aggregates]="aggreagtes" [dataSource]="RepeatedDataList" [allowSelection]="false">

          <e-columns>
            <e-column field="Service_Id" headerText=" {{'HALA.SendCardsEmployee.ServiceId'|translate}}  " width="120"></e-column>
            <e-column field="Class_Name" headerText="  {{'HALA.SendCardsEmployee.CardName'|translate}} " width="150"></e-column>
            <e-column field="AddedDate" type="date" [format]="format" headerText="{{'Common.DateFrom'|translate}}" [valueAccessor]='dateFormatter'width="120"></e-column>
            <e-column field="ExpirationDate" type="date" [format]="format" headerText="{{'Common.DateTo'|translate}}" [valueAccessor]='dateFormatter'width="120"></e-column>
            <e-column field="ProviderName" headerText=" {{'HALA.SendCardsEmployee.ProviderId'|translate}} "width="200"></e-column>
            <e-column field="OrderNo" headerText=" {{'HALA.SendCardsEmployee.OrderNo'|translate}} "width="120"></e-column>
            <e-column field="serialCard" headerText=" {{'Common.Serial'|translate}}  "></e-column>
            <e-column field="pinCard" headerText=" {{'Common.Pin'|translate}}"></e-column>
            <e-column headerText="{{'Common.Delete'|translate}}">
              <ng-template #template let-data>
                  <i class='mdi mdi-delete font-size-24 text-danger' (click)="deleteFromRepeated(data)"></i>
             
              </ng-template>
          </e-column>
          </e-columns>
        </ejs-grid>

      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center mb-3">
  <button [disabled]="!isActive" type="submit" (click)="submitData()"
      class="btn btn-success font-weight-bold px-5">
      {{'Common.Send'|translate}}
  </button>

</div>
