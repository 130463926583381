import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThManageAccounts {

 
  API_URLGetAcountsDashboard = `${environment.apiUrl}/ThdbManageAccounts/GetAcountsDashboard`;
  API_URLGetAllCreatedAccountsCompany = `${environment.apiUrl}/ThdbManageAccounts/GetAllCreatedAccountsCompany`;
  API_URLCreateAccountsStored = `${environment.apiUrl}/ThdbManageAccounts/CreateAccountsStored`;
  API_URLUpdateClientAccount = `${environment.apiUrl}/ThdbManageAccounts/UpdateClientAccount`;

  constructor(private http:HttpClient) { }

  GetAcountsDashboard(): Observable<any> {
    const url = this.API_URLGetAcountsDashboard;
    //Http request-
    return this.http.get<any>(url)
  }

  GetAllCreatedAccountsCompany(body): Observable<any> {
    const url = this.API_URLGetAllCreatedAccountsCompany;
    //Http request-
    return this.http.post<any>(url,body)
  }

  CreateAccountsStored(body): Observable<any> {
    const url = this.API_URLCreateAccountsStored;
    //Http request-
    return this.http.post<any>(url,body)
  }

  UpdateClientAccount(body): Observable<any> {
    const url = this.API_URLUpdateClientAccount;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
