import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPackingCardService } from 'src/app/core/services/thuraya/th-packing-card.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-packing-cards',
  templateUrl: './get-packing-cards.component.html',
  styleUrls: ['./get-packing-cards.component.scss']
})
export class GetPackingCardsComponent implements OnInit {

  
  //Form variables
  form: FormGroup;

  //Date Variables
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  fromDateCom:any = ""
  toDateCom:any = ""

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  cardStatusList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thPackingCardService:ThPackingCardService,
    private excelService: ExcelService,
    private datePipe:DatePipe,
    public dateFormatService:DateFormatService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      packingCardId: this.f.packingCardId.value,
      batch_no:this.f.batch_no.value
    }

    const subscr =  this.thPackingCardService.GetpackingCards(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) { 
            let sheet={ 
              "رقم الدفعة":data[i].batch_no,
              "مقدم الخدمة": data[i].service_id,
              "وصف الفئة": data[i].class_name,
              "المصدر": data[i].cardsource,
              "حاله الكرت": data[i].code_name,
              "سعر البيع": data[i].class_price,
              "التكلفة": data[i].class_cost,
              "السريال": data[i].card_serial,
              "الخدش": data[i].card_scratch,
              "تاريخ البيع": this.datePipe.transform(data[i].po_date,this.dateFormatService.dateFormat),
              "الملف": data[i].file_name,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      packingCardId: new FormControl(""),
      batch_no: new FormControl("")
    });
  }
  
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getpackingCards(){
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      packingCardId: this.f.packingCardId.value,
      batch_no:this.f.batch_no.value
    }
    const subscr = this.thPackingCardService.GetpackingCards(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    //do not call api if no filter (as it get alot of data and sometimes it timeout) 
    if(this.f.packingCardId.value!="" || this.f.batch_no.value!=""){
      this.getpackingCards();
    }
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    if (this.f.packingCardId.value=="" && this.f.batch_no.value ==""){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    //reset page number
    this.pageNumber = 1;
    this.getpackingCards()
  }

  reset(){
    this.initForm()
    this.pageNumber = 1
    this.totalCount = 0;
    this.datasource = [];
  }

}
