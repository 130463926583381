
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ThOtpService } from "src/app/core/services/thuraya/th-otp.service";
import { DateFormatService } from "src/app/core/services/date-format.service";



@Component({
  selector: 'app-validation-code',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss']
})
export class ValidationCodeComponent implements OnInit {


  dataSource = null;
  form: FormGroup;
  
  constructor(
    public thOtpService: ThOtpService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public dateFormatService:DateFormatService
  ) {
    this.form = this.fb.group({
      phone: new FormControl("")
    });
  }
  
  ngOnInit() {   }

  /*----------------------API Requests---------------------- */
  getOtpReal() {
    const formData = this.form.value;
    let phone = formData.phone;
    this.spinner.show(); 

    return this.thOtpService.OtpReal(phone).subscribe(
      (result) => {
        this.spinner.hide(); 
        this.dataSource = result.Data;
        this.ref.detectChanges();
      },(err)=>{
        this.spinner.hide(); 
      }
    );
  }

}
