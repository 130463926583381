import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThManageIncomingAmountsService } from 'src/app/core/services/thuraya/th-manage-incoming-amounts.service';
import Swal from 'sweetalert2';
import { AddAccountComponent } from '../add-account/add-account.component';

@Component({
  selector: 'app-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss']
})
export class AccountsTableComponent implements OnInit {

  @Input() datasource;
  constructor( 
    private modalService: NgbModal,
    private msgTranslateService:MessageTranslateService,
    private thManageIncomingAmountsService:ThManageIncomingAmountsService,
    public permissionService:PermissionService,
    public dateFormatService:DateFormatService
    ) { }

  ngOnInit(): void {
  }

  editAccount(data){
    let modalRef = this.modalService.open(AddAccountComponent, { centered: true , size: 'lg'});
    modalRef.componentInstance.isEditing = true;
    modalRef.componentInstance.accountOldData = data;
    modalRef.componentInstance.updateParent.subscribe((newData) => {
      // console.log("newdata",newData)
      let index = this.datasource.findIndex(d=>d.Row_NO==newData.Row_NO)
      this.datasource[index] = newData;
    })
  }

  // createManageIncomingAmountData(data){
  //   Swal.fire({
  //     title: this.msgTranslateService.msgTranslate('SYSTEMSETTINGS.CreateAccounts.MaxTransfers'),
  //     html:
  //     '<div>'+
  //       '<label>'+ this.msgTranslateService.msgTranslate('SYSTEMSETTINGS.CreateAccounts.AddAmount')+'</label>' +
  //       '<input type="number" id="addAmount" class="swal2-input">'+
  //     '</div>'+
  //     '<div>'+
  //       '<label>'+this.msgTranslateService.msgTranslate('SYSTEMSETTINGS.CreateAccounts.RemoveAmount')+'</label>' +
  //       '<input type="number" id="removeAmount" class="swal2-input"' +
  //     '</div>',
  //     focusConfirm: false,
  //     showCancelButton: true,
  //     cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
  //     confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       let addAmount = (document.getElementById('addAmount')  as HTMLInputElement).value
  //       let removeAmount = (document.getElementById('removeAmount')  as HTMLInputElement).value
  //       if(addAmount=="" && removeAmount==""){
  //         Swal.fire({
  //           title:this.msgTranslateService.msgTranslate('SwalMessages.AddRemoveAmountReq'),
  //           icon:"error",
  //           showCloseButton:true,
  //           showConfirmButton:false
  //         })
  //       }else{
  //         let params= {
  //           userId: data.CUSTOMER_ID,
  //           addAmount: addAmount,
  //           removeAmount:  removeAmount
  //         }
  //         this.thManageIncomingAmountsService.CreateManageIncomingAmountData(params).subscribe(
  //           (result)=>{
  //             if(result.IsSucces){
                
  //             }
  //           }
  //         )
  //       }
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   })
  // }

}
