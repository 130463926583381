<div class="table-responsive mb-0">
    <div>
      <!-- Taregt evalution based on dist price -->
      <form class="form" [formGroup]="form" *ngIf="datasource.length && !isMainTarget">
        <div class="card-body">
            <div class="form-group row">
                <!-- Status Evaluation amount  -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.TargetValue'|translate}} :</label>
                    <input type="number" formControlName="statusValue" class="form-control" min="0" (input)="inputChange($event)" >
                </div>
            </div>
        </div>
    </form>
    </div>
    <table class="table table-centered ">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'REPORTS.TargetReport.Agent'| translate}}</th>
          <th class="align-middle">{{'REPORTS.TargetReport.AgentPhone'| translate}}</th>
          <th class="align-middle">{{'REPORTS.TargetReport.Distributor'| translate}}</th>
          <th class="align-middle">{{'REPORTS.TargetReport.DistrPhone'| translate}}</th>
          <th class="align-middle">{{'REPORTS.TargetReport.POS'| translate}}</th>
          <th class="align-middle">{{'REPORTS.TargetReport.POSPhone'| translate}}</th>
          <th class="align-middle" *ngIf="isMainTarget">{{'REPORTS.TargetReport.CompanyProfit'| translate}}</th>
          <th class="align-middle" *ngIf="isMainTarget">{{'REPORTS.TargetReport.ProductId'| translate}}</th>
          <th class="align-middle" *ngIf="isMainTarget">{{'Common.CardName'| translate}} </th>
          <th class="align-middle">{{'Common.CardAmount'| translate}} </th>
          <th class="align-middle" *ngIf="!isLevelOne">{{'Common.CardType'| translate}} </th>
          <th class="align-middle"> {{ isMainTarget ? ('REPORTS.TargetReport.DistributorPrice'| translate) : ('Common.Price'| translate) }}</th>
          <th class="align-middle" *ngIf="statusShown"> {{ 'Common.Status'| translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.RepresentativeName}}</a>
          </td>
          <td>{{data.RepresentativeNumber}}</td>
          <td>{{data.DistributorName}}</td>
          <td>{{data.DistributorNumber}}</td>
          <td>{{data.PointName}}</td>
          <td>{{data.AccountId}}</td>
          <td *ngIf="isMainTarget">{{data.CompanyProfit}}</td>
          <td *ngIf="isMainTarget">{{data.product_id}}</td>
          <td *ngIf="isMainTarget">{{data.class_name}}</td>
          <td>{{data.CardsNo}}</td>
          <td *ngIf="!isLevelOne">{{data.CardType}}</td>
          <td>{{data.DistributorPrice}}</td>
          <td *ngIf="statusShown">
            <span [ngClass]=" data.DistributorPrice >= f.statusValue.value ? 'goodStatus': 'poorStatus' ">{{data.DistributorPrice >= f.statusValue.value ? ('Common.Excellent'| translate) : 'Common.Poor'| translate }} </span>
          </td>
        </tr>
        <tr >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td *ngIf="isMainTarget">
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumCompanyProfit==0 ? '-' :SumCompanyProfit | number : '1.0-2'}}</span>
            </div>
          </td>
          <td *ngIf="isMainTarget"></td>
          <td *ngIf="isMainTarget"></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumCardsNo==0 ? '-' :SumCardsNo}}</span>
            </div>
          </td>
          <td *ngIf="!isLevelOne"></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumDistributorPrice==0 ? '-' :SumDistributorPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td *ngIf="statusShown"></td>
        </tr>
      </tbody>
    </table>
</div>