import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetPriceDataComponent } from './get-price-data/get-price-data.component';

const routes: Routes = [
  { path: 'get-all-price-data', component: GetPriceDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbPriceSettings.View','ThdbPriceSettings.Create','ThdbPriceSettings.Update'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PriceSettingsRoutingModule { }
