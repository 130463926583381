  
  
<div class="table-responsive mb-0">
    <table class="table table-centered">
    <thead class="table-light">
        <tr>
            <!-- <th style="width: 20px;">
                <div class="form-check font-size-16 align-middle">
                <input class="form-check-input" type="checkbox" id="transactionCheck01">
                <label class="form-check-label" for="transactionCheck01"></label>
                </div>
            </th> -->
            <!-- <th class="align-middle">{{'Common.Id'| translate}}</th> -->
            <th class="align-middle">{{'HALA.Users.FullName'| translate}}</th>
            <th class="align-middle">{{'HALA.Users.Email'| translate}}</th>
            <th class="align-middle">{{'HALA.Users.PhoneNumber'| translate}}</th>
            <th class="align-middle">{{'HALA.Users.DateCreated'| translate}}</th>
            <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">{{'Common.Active'| translate}} </th>
            <th class="align-middle" style="max-width: 150px;">{{'Common.Roles'| translate}} </th>
            <th style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">{{'Common.EditRole'| translate}}</th>
            <th style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">{{'Common.Edit'| translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of datasource">
        <!-- <td>
            <div class="form-check font-size-16">
            <input class="form-check-input" type="checkbox" id="transactionCheck{{data.index}}">
            <label class="form-check-label" for="transactionCheck{{data.index}}"></label>
            </div>
        </td> -->
        <!-- <td>
            <a class="text-body fw-bold">{{data.Id}}</a>
        </td> -->
        <td>
            <a class="text-body fw-bold">{{data.FullName}}</a>
        </td>
        <td>{{data.Email}}</td>
        <td>{{data.PhoneNumber}}</td>
        <td>{{data.DateCreated | date:dateFormatService.dateFormat}}</td>
        <td *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">
            <ejs-switch [checked]='data.IsActive' cssClass="e-small" [enableRtl]="true" (change)='changeUserStatus($event,data)'></ejs-switch>
        </td>
        <td style="max-width: 150px;">{{data.Roles}}</td>
        <td style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">
            <i class='mdi mdi-pencil font-size-18 text-success' role="button" (click)="editUserRoles(data)"></i>
        </td>
        <td style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaAccounts.Update'])">
            <i class='mdi mdi-pencil font-size-18 text-success' role="button" (click)="openEditDialog(data)"></i>
        </td>
        </tr>
    </tbody>
    </table>
</div>