import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAllCreatedAccountsComponent } from './get-all-created-accounts/get-all-created-accounts.component';

const routes: Routes = [
  { path: 'get-all-created-accounts', component:GetAllCreatedAccountsComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbManageAccounts.View','ThdbManageAccounts.Create','ThdbManageAccounts.Update'], roles:[]}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageAccountsRoutingModule { }
