import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-stock-table',
  templateUrl: './current-stock-table.component.html',
  styleUrls: ['./current-stock-table.component.scss']
})
export class CurrentStockTableComponent implements OnInit {

  @Input() datasource;

  SumTotalClassPrice:number = 0
  SumTotalClassCost:number = 0
  SumTotalCount:number = 0

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumTotalClassPrice = this.datasource[0].SumTotalClassPrice;
      this.SumTotalClassCost = this.datasource[0].SumTotalClassCost;
      this.SumTotalCount = this.datasource[0].SumTotalCount;
    }else{
      this.SumTotalClassPrice = 0;
      this.SumTotalClassCost = 0;
      this.SumTotalCount = 0;
    }
  }

}
