import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CurrentStockBalanceRoutingModule } from './current-stock-balance-routing.module';
import { GetCurrentStockDataComponent } from './get-current-stock-data/get-current-stock-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { CurrentStockTableComponent } from './get-current-stock-data/_components/current-stock-table/current-stock-table.component';


@NgModule({
  declarations: [
    GetCurrentStockDataComponent,
    CurrentStockTableComponent
  ],
  imports: [
    CommonModule,
    CurrentStockBalanceRoutingModule,
    CoreModule
  ]
})
export class CurrentStockBalanceModule { }
