import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThOtpService {

  API_URLOtpReal = `${environment.apiUrl}/ThdbOtp/OtpReal`;

  constructor(private http:HttpClient) { }

  OtpReal(body): Observable<any> {
    const url = this.API_URLOtpReal;
    //Http request-
    return this.http.get<any>(`${url}?phone=${body}`)
  }

}
