import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtpRoutingModule } from './otp-routing.module';
import { ValidationCodeComponent } from './validation-code/validation-code.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    ValidationCodeComponent
  ],
  imports: [
    CommonModule,
    OtpRoutingModule,
    CoreModule
  ]
})
export class OtpModule { }
