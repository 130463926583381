<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'REPORTS.PackCardReport.BatchNumber'| translate}}</th>
          <th class="align-middle">{{'Common.ServiceId'| translate}} </th>
          <th class="align-middle">{{'REPORTS.PackCardReport.CategoryDescription'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PackCardReport.Source'| translate}}</th>
          <th class="align-middle">{{'Common.CardStatus'| translate}} </th>
          <th class="align-middle">{{'Common.SellPrice'| translate}} </th>
          <th class="align-middle">{{'Common.Cost'| translate}} </th>
          <th class="align-middle">{{'Common.Serial'| translate}} </th>
          <th class="align-middle">{{'Common.Pin'| translate}} </th>
          <th class="align-middle">{{'Common.SellDate'| translate}} </th>
          <th class="align-middle">{{'Common.File'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.batch_no}}</a>
          </td>
          <td>{{data.service_id}}</td>
          <td>{{data.class_name}}</td>
          <td>{{data.cardsource}}</td>
          <td>{{data.code_name}}</td>
          <td>{{data.class_price}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.card_serial}}</td>
          <td>{{data.card_scratch}}</td>
          <td>
            <div>
                {{data.po_date | date:dateFormatService.dateOnlyFormat}}
            </div>
            <div>
                {{data.po_date | date:dateFormatService.timeOnlyFormat}}
            </div>
          </td>
          <td>{{data.file_name==null ? '-' : data.file_name.substring(0,5)}} <a *ngIf="data.file_name!=null" (click)="showMore(data.file_name)" class="showMore">{{'Common.More'| translate}} </a></td>
        </tr>
      </tbody>
    </table>
</div>