<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'HALA.Additions.RowNo'| translate}}</th>
          <th class="align-middle">{{'HALA.Additions.TransDate'| translate}}</th>
          <th class="align-middle">{{'HALA.Additions.CustomerAccount'| translate}}</th>
          <th class="align-middle">{{'HALA.Additions.AccountId'| translate}}</th>
          <th class="align-middle">{{'HALA.Additions.CreditAmount'| translate}} </th>
          <th class="align-middle"> {{'HALA.Additions.Note'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.row_no}}</a>
          </td>
          <td>{{data.trans_date | date:dateFormatService.dateFormat}}</td>
          <td>{{data.cust_account_id}}</td>
          <td>{{data.account_id}}</td>
          <td>{{data.credit_amount}}</td>
          <td>{{data.trans_note}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'HALA.Additions.TotalCreditAmount'| translate}}</span> 
            <span>{{totalCreditAmount==0 ? '-' :totalCreditAmount | number : '1.0-2'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
</div>