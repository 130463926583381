import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThGeneralSystemService {

  API_URLGetClassNameList = `${environment.apiUrl}/ThdbGeneralSystemActions/GetClassNameData`;
  API_URLGetServicesList = `${environment.apiUrl}/ThdbGeneralSystemActions/GetServicesData`;
  API_URLGetAllAgentsByCompany = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllAgentsByCompany`;
  API_URLGetAllDistributorsByAgent = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllDistributorsByAgent`;
  API_URLGetAllPosByDistributer = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllPosByDistributer`;
  API_URLGetAllCompanies = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllCompanies`;
  API_URLGetAllCustomerAccountData = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllCustomerAccountData`;
  API_URLGetAllAccountsByCompany = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllAccountsByCompany`;

  constructor(private http:HttpClient) { }

  GetClassNameData(body): Observable<any> {
    const url = this.API_URLGetClassNameList;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetServicesData(body): Observable<any> {
    const url = this.API_URLGetServicesList;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllCompanies(body): Observable<any> {
    const url = this.API_URLGetAllCompanies;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllAgentsByCompany(body): Observable<any> {
    const url = this.API_URLGetAllAgentsByCompany;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllDistributorsByAgent(body): Observable<any> {
    const url = this.API_URLGetAllDistributorsByAgent;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllPosByDistributer(body): Observable<any> {
    const url = this.API_URLGetAllPosByDistributer;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllCustomerAccountData(body): Observable<any> {
    const url = this.API_URLGetAllCustomerAccountData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllAccountsByCompany(body): Observable<any> {
    const url = this.API_URLGetAllAccountsByCompany;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
