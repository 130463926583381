import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThCompanySalesService {


  API_URLGetCompanySales = `${environment.apiUrl}/ThdbCompanySales/GetCompanySales`;

  constructor(private http:HttpClient) { }

  GetCompanySales(body): Observable<any> {
    const url = this.API_URLGetCompanySales;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
