<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light table-nowrap">
        <tr>
            <th class="align-middle">{{'Common.ClientPhone' | translate}}</th>
            <th class="align-middle">{{'Common.ClientName' | translate}}</th>
            <th class="align-middle" *ngIf="!IsClientOfUserPage">{{'Common.Agent' | translate}}</th>
            <th class="align-middle">{{'Common.Balance' | translate}}</th>
            <th class="align-middle">{{'Common.Address' | translate}}</th>

            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.ServiceId' | translate}}</th>
            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.ClassName' | translate}}</th>
            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.Date' | translate}}</th>
            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.POSPrice' | translate}}</th>
            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.SellPrice' | translate}}</th>
            <th class="align-middle" *ngIf="IsClientWithdrawalsPage">{{'Common.Status' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.CUSTOMER_ID}}</a>
          </td>
          <td>{{data.CUSTOMER_NAME}}</td>
          <td *ngIf="!IsClientOfUserPage">{{data.shared_account_id}}</td>
          <td>{{data.balance}}</td>
          <td>{{data.cust_address}}</td>

          <td *ngIf="IsClientWithdrawalsPage">{{data.service_id}}</td>
          <td *ngIf="IsClientWithdrawalsPage">{{data.note}}</td>
          <td *ngIf="IsClientWithdrawalsPage">{{data.trans_date | date: dateFormatService.dateOnlyFormat}}</td>
          <td *ngIf="IsClientWithdrawalsPage">{{data.point_price}}</td>
          <td *ngIf="IsClientWithdrawalsPage">{{data.sell_price}}</td>
          <td *ngIf="IsClientWithdrawalsPage">{{data.trans_status}}</td>
        </tr>
        <tr >
          <td></td>
          <td></td>
          <td *ngIf="!IsClientOfUserPage"></td>
          <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{TotalBalance==0 ? '-' :TotalBalance | number : '1.0-2'}}</span>
              </div>
          </td>
          <td></td>
          <td *ngIf="IsClientWithdrawalsPage"></td>
          <td *ngIf="IsClientWithdrawalsPage"></td>
          <td *ngIf="IsClientWithdrawalsPage"></td>
          <td *ngIf="IsClientWithdrawalsPage">
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalPointPrice==0 ? '-' :TotalPointPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td *ngIf="IsClientWithdrawalsPage">
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalSellPrice==0 ? '-' :TotalSellPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td *ngIf="IsClientWithdrawalsPage"></td>
        </tr>
      </tbody>
    </table>
  </div>