import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThAccountReportService {

  API_URLGetAccounBillData = `${environment.apiUrl}/ThdbAccountReport/GetAccounBillData`;
  
  constructor(private http:HttpClient) { }

  GetAccounBillData(body): Observable<any> {
    const url = this.API_URLGetAccounBillData;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
