<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="align-middle"> {{'Common.AmountRequired'| translate}}</th>
          <th class="align-middle"> {{'Common.Date'| translate}}</th>
          <th class="align-middle">{{'Common.TradeName'| translate}} </th>
          <th class="align-middle">{{'Common.CommercialRegistrationNo'| translate}}</th>
          <th class="align-middle">{{'Common.POSUserNumber'| translate}}</th>
          <th class="align-middle">{{'Common.PersonAuthorizedToUser'| translate}}</th>
          <th class="align-middle">{{'Common.SimSerialNumber'| translate}}</th>
          <th class="align-middle">{{'Common.MachineSerial'| translate}}</th>
          <th class="align-middle">{{'Common.Email'| translate}}</th>
          <th class="align-middle">{{'Common.Address'| translate}}</th>
          <th class="align-middle">{{'Common.MachineType'| translate}}</th>
          <th class="align-middle" style="width: 50px;">{{'Common.ActionsTaken'| translate}}</th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaDataUpdate.Update'])">{{'Common.Edit'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.RequiredBalance}}</a> </td>
          <!-- <td>{{data.CreateDate | date: dateFormatService.dateOnlyFormat}}</td> -->
          <td>{{data.createDate}}</td>
          <td>{{data.CommercialName}}</td>
          <td> {{data.CommercialRegistrationNo}} </td>
          <td>{{data.PosUserNumber}}</td>
          <td>{{data.UserName}}</td>
          <td>{{data.CompanyNumber}}</td>
          <td>{{data.SerialNumber}}</td>
          <td>{{data.Email}}</td>
          <td>{{data.Address}}</td>
          <td>
            <span *ngIf="data.DeviceType==1"> {{'Common.Machine'| translate}}</span>
            <span *ngIf="data.DeviceType==2"> {{'Common.PrinterOnly'| translate}} </span>
          </td>
          <td class="text-center" style="width: 50px;">
            <i *ngIf="!data.IsAction" class="fa-solid fa-xmark text-danger" style="font-size: 18px;"></i>
            <i *ngIf="data.IsAction" class="fa-solid fa-check text-success" style="font-size: 18px;"></i>
          </td>
          <td style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaDataUpdate.Update'])">
            <i class='mdi mdi-pencil font-size-18 text-success' role="button" (click)="openEditDialog(data)"></i>
          </td>
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{totalSellPrice==0 ? '-' :totalSellPrice}}</span>
            </div>
          </td>
        </tr> -->
      </tbody>
    </table>
</div>