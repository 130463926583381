import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ThDistributorReportsService {

  API_URLGetInvoiceData = `${environment.apiUrl}/ThdbDistributerReports/GetInvoiceData`;
  API_URLGetAllBillDetails = `${environment.apiUrl}/ThdbDistributerReports/GetAllBillDetails`;

  constructor(private http:HttpClient) { }

  GetInvoiceData(body): Observable<any> {
    const url = this.API_URLGetInvoiceData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllBillDetails(body): Observable<any> {
    const url = this.API_URLGetAllBillDetails;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
