import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HalaManageCardsService {

  API_URLGetAllHeadersNotSentForThisUser = `${environment.apiUrl}/HalaManageHalaCards/GetAllHeadersNotSentForThisUser`;
  API_URLGetCardsHeaderById = `${environment.apiUrl}/HalaManageHalaCards/GetCardsHeaderById`;
  API_URLGetThisHeaderDetails = `${environment.apiUrl}/HalaManageHalaCards/GetThisHeaderDetails`;
  API_URLDeleteCardDetails = `${environment.apiUrl}/HalaManageHalaCards/DeleteCardDetails`;
  API_URLAddNewCard = `${environment.apiUrl}/HalaManageHalaCards/AddNewCard`;
  // API_URLGetCardHeaderExisted = `${environment.apiUrl}/HalaManageHalaCards/GetCardHeaderExisted`;
  API_URLSendCards = `${environment.apiUrl}/HalaManageHalaCards/SendCards`;
  API_URLGetCardsHeaderReportManager = `${environment.apiUrl}/HalaManageHalaCards/GetCardsHeaderReportManager`;
  API_URLApproveCards = `${environment.apiUrl}/HalaManageHalaCards/ApproveCards`;
  API_URLReturnCards = `${environment.apiUrl}/HalaManageHalaCards/ReturnCards`;
  API_URLSendExcelCardsToApproveFile = `${environment.apiUrl}/HalaManageHalaCards/SendExcelCardsToApproveFile`;
  API_URLUpdateCardHeaderManager = `${environment.apiUrl}/HalaManageHalaCards/UpdateCardHeaderManager`;
  API_URLDeleteCardHeader = `${environment.apiUrl}/HalaManageHalaCards/DeleteCardHeader`;
  API_URLGetActiveCardsHeaderReport = `${environment.apiUrl}/HalaManageHalaCards/GetActiveCardsHeaderReport`;
  API_URLGetActiveCardsDetailsReport = `${environment.apiUrl}/HalaManageHalaCards/GetActiveCardsDetailsReport`;
  API_URLActiveThisBatchCards = `${environment.apiUrl}/HalaManageHalaCards/ActiveThisBatchCards`;
      
  constructor(private http:HttpClient) {
  }

  GetAllHeadersNotSentForThisUser(body): Observable<any> {
    const url = this.API_URLGetAllHeadersNotSentForThisUser;
    //Http request-
    return this.http.post(url, body);
  }

  GetCardsHeaderById(cardHeaderId: string): Observable<any> {
    const url = this.API_URLGetCardsHeaderById;
    //Http request-
    return this.http.get<any>(url + `?cardHeaderId=${cardHeaderId}`)
  }

  GetThisHeaderDetails(body): Observable<any> {
    const url = this.API_URLGetThisHeaderDetails;
    //Http request-
    return this.http.post<any>(url,body)
  }
  
  DeleteCardDetails(cardDetailsId: string): Observable<any> {
    const url = this.API_URLDeleteCardDetails;
    //Http request-
    return this.http.get<any>(url + `?cardDetailsId=${cardDetailsId}`)
  }

  AddNewCard(body): Observable<any> {
    const url = this.API_URLAddNewCard;
    return this.http.post(url, body);
  }

  SendCards(rowId: string): Observable<any> {
    const url = this.API_URLSendCards;
    //Http request-
    return this.http.get<any>(url + `?rowId=${rowId}`)
  }

  GetCardsHeaderReportManager(body): Observable<any> {
    const url = this.API_URLGetCardsHeaderReportManager;
    //Http request-
    return this.http.post<any>(url,body)
  }

  ApproveCards(param): Observable<any> {
    const url = this.API_URLApproveCards;
    //Http request-
    return this.http.get<any>(url,{params:param})
  }

  ReturnCards(id): Observable<any> {
    const url = this.API_URLReturnCards;
    //Http request-
    return this.http.get<any>(`${url}?cardHeaderId=${id}`)
  }

  SendExcelCardsToApproveFile(body): Observable<any> {
    const url = this.API_URLSendExcelCardsToApproveFile;
    //Http request-
    return this.http.post<any>(url,body)
  }

  UpdateCardHeaderManager(body): Observable<any> {
    const url = this.API_URLUpdateCardHeaderManager;
    //Http request-
    return this.http.post<any>(url,body)
  }

  DeleteCardHeader(id): Observable<any> {
    const url = this.API_URLDeleteCardHeader;
    //Http request-
    return this.http.get<any>(`${url}?cardHeaderId=${id}`)
  }

  GetActiveCardsHeaderReport(body): Observable<any> {
    const url = this.API_URLGetActiveCardsHeaderReport;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetActiveCardsDetailsReport(body): Observable<any> {
    const url = this.API_URLGetActiveCardsDetailsReport;
    //Http request-
    return this.http.post<any>(url,body)
  }

  ActiveThisBatchCards(batchNo): Observable<any> {
    const url = this.API_URLActiveThisBatchCards;
    //Http request-
    return this.http.get<any>(`${url}?batch_no=${batchNo}`)
  }
}
