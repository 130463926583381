import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageTranslateService {

  constructor(private translate:TranslateService) { }

  msg:string;
  msgTranslate(Msg:string)
  {
    this.translate.get([Msg] )
    .subscribe((translations:string) => {
      this.msg = translations[Msg];
    });
    return this.msg ;
  }
}
