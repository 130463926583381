<!-- Export Excel -->
<div class="col-12 d-flex align-items-center justify-content-between">
    <div class="text-sm-end">
        <button *ngIf="permissionService.checkPermission(['ThdbServiceClassSetting.Create'])" type="button" class="btn btn-success btn-rounded mb-2 me-2 px-4" (click)="openAddDialog()">
            <i class="mdi mdi-plus mr-1"></i>
            {{'Common.Add'| translate}}</button>
    </div>
    <app-excel-export *ngIf="permissionService.checkPermission(['ThdbServiceClassSetting.View'])" [datasource]="dataSource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

</div>

<!--Filter / Table-->
<div class="row" *ngIf="permissionService.checkPermission(['ThdbServiceClassSetting.View', 'ThdbServiceClassSetting.Update'])">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact  py-2">
            <!-- Filter -->
            <form class="form" [formGroup]="form">
                <div class="card-body">
                    <div class="form-group row">
                        <!-- Service -->
                        <div class="col-lg-3">
                            <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                        </div>

                        <!-- Action Buttons -->
                        <div class="col-lg-3 d-flex align-items-end">
                            <div class="action-btns" style="margin-inline-end: 20px;">
                                <button type="submit" (click)="search()" [disabled]="form.invalid"
                                class="btn btn-primary font-weight-bold px-10 mr-2">
                                    {{'Common.Search'|translate}}
                                </button>
                            </div>
                            <div class="action-btns">
                                <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                    {{'Common.Clear'|translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
            <!-- Table -->
            <div class="mt-3"> 
                <!-- Spinner -->
               <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
               <app-class-data-table [datasource]="dataSource"></app-class-data-table>
            </div>

            <!-- Pagination -->
            <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
        </div>
       
    </div>
</div>
