import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanyProfitRoutingModule } from './company-profit-routing.module';
import { GetCompanyProfitComponent } from './get-company-profit/get-company-profit.component';
import { CoreModule } from 'src/app/core/core.module';
import { CompanyProfitTableComponent } from './get-company-profit/_components/company-profit-table/company-profit-table.component';


@NgModule({
  declarations: [
    GetCompanyProfitComponent,
    CompanyProfitTableComponent
  ],
  imports: [
    CommonModule,
    CompanyProfitRoutingModule,
    CoreModule
  ]
})
export class CompanyProfitModule { }
