import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  dateFormat = "yyyy-MM-dd - hh:mm a"
  dateOnlyFormat = "yyyy-MM-dd"
  timeOnlyFormat = "hh:mm a"
  
  constructor() { }


}
