import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LanguageService } from '../../services/language.service';
import { ThPurchaseTargetService } from '../../services/thuraya/th-purchase-target.service';

@Component({
  selector: 'app-class-multiselect',
  templateUrl: './class-multiselect.component.html',
  styleUrls: ['./class-multiselect.component.scss']
})
export class ClassMultiselectComponent implements OnInit {

    //customer multi select variables
    classDropDownList = []
    classDropDownListFiltered = []
    dropdownSettings:IDropdownSettings = {};
    SelectedValue
  
    @Output() addToList: EventEmitter<any> = new EventEmitter();
    @Output() removeFromList: EventEmitter<any> = new EventEmitter();
  
    constructor(
      private thPurchaseTargetService: ThPurchaseTargetService,
      public languageService:LanguageService,
      private ref: ChangeDetectorRef
    ) { }
  
    ngOnInit(): void {
      // services multiselect settings
      this.dropdownSettings = {
        singleSelection: false,
        enableCheckAll:false,
        idField: 'class_no',
        textField: 'class_name',
        searchPlaceholderText:"بحث",
        selectAllText: 'تحديد الكل',
        unSelectAllText: 'الغاء تحديد الكل',
        itemsShowLimit: 2,
        allowSearchFilter: true
      };
    }

    // Called from parent on reset
    resetSelected(){
      this.SelectedValue = null
    }
  
    getClassData(list) {
      //empty any selected before when selecting/deselecting services
      this.SelectedValue = null
      this.thPurchaseTargetService.GetClassNameData(list).subscribe(
        (result) => {
          this.classDropDownList = this.classDropDownListFiltered = result.resultList;
        }
      );
    }
  
    onFilterChange(e){
      if(!e)
         this.classDropDownListFiltered=this.classDropDownList;
      else
      {
         e=e.toLowerCase();
         this.classDropDownListFiltered=this.classDropDownListFiltered.filter(x=>x.class_no.toLowerCase()
            .indexOf(e)==0)
  
            //If no result show all else you get no data and no search bar
            if(this.classDropDownListFiltered.length==0){
              this.classDropDownListFiltered = this.classDropDownList
            }
      }
      
    }
  
    onItemSelect(item: any) {
      this.addToList.emit(item.class_no)
    }
    onItemDeSelect(item:any){
      this.removeFromList.emit(item.class_no)
    }
  

}
