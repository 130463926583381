<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
            <th class="align-middle">{{'Common.CardName'| translate}}</th>
            <th class="align-middle">{{'Common.CardAmount'| translate}}</th>
            <th class="align-middle">{{'Common.TotalPrice'| translate}}</th>
            <th class="align-middle">{{'Common.TotalCost'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.class_name}}</td>
          <td>{{data.TotalCount}}</td>
          <td>{{data.TotalClassPrice}}</td>
          <td>{{data.TotalClassCost}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalCount==0 ? '-' :SumTotalCount}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalClassPrice==0 ? '-' :SumTotalClassPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalClassCost==0 ? '-' :SumTotalClassCost | number : '1.0-2'}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>