  <div class="table-responsive mb-0">
      <table class="table table-centered table-nowrap">
        <thead class="table-light">
          <tr>
            <th class="align-middle">{{'HALA.RecAmounts.TrxRef'| translate}}</th>
            <th class="align-middle">{{'HALA.RecAmounts.TrxDate'| translate}}</th>
            <th class="align-middle">{{'HALA.RecAmounts.Amount'| translate}}</th>
            <th class="align-middle">{{'HALA.RecAmounts.UserName'| translate}}</th>
            <th class="align-middle">{{'HALA.RecAmounts.MobileNumber'| translate}} </th>
            <th class="align-middle">{{'HALA.RecAmounts.ThurayaNumber'| translate}} </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of datasource">
            <td>
              <a class="text-body fw-bold">{{data.TrxRef}}</a>
            </td>
            <td>{{data.TrxDate | date:dateFormatService.dateFormat}}</td>
            <td>{{data.Amount}}</td>
            <td>{{data.user_name}}</td>
            <td>{{data.MobileNumber}}</td>
            <td>{{data.ThurayaNumber}}</td>
          </tr>
        </tbody>
      </table>
</div>