import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-packing-table',
  templateUrl: './packing-table.component.html',
  styleUrls: ['./packing-table.component.scss']
})
export class PackingTableComponent implements OnInit {

  @Input() datasource;
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  /*------------------------ Show more file name ------------------------ */
  showMore(filename) {
    Swal.fire({
      title: filename,
      // showCloseButton: true,
      confirmButtonText: "اغلاق"
    });
  }

}
