import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MoveBalanceBetweenAccountsRoutingModule } from './move-balance-between-accounts-routing.module';
import { GetMovementDataComponent } from './get-movement-data/get-movement-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { MovementTableComponent } from './get-movement-data/_components/movement-table/movement-table.component';
import { BalanceTransferDialogComponent } from './get-movement-data/_components/balance-transfer-dialog/balance-transfer-dialog.component';


@NgModule({
  declarations: [
    GetMovementDataComponent,
    MovementTableComponent,
    BalanceTransferDialogComponent
  ],
  imports: [
    CommonModule,
    MoveBalanceBetweenAccountsRoutingModule,
    CoreModule
  ]
})
export class MoveBalanceBetweenAccountsModule { }
