import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HalaBankService {

  API_URLGetAllBank = `${environment.apiUrl}/HalaBank/GetAllBank`;
  API_URLGetBankById = `${environment.apiUrl}/HalaBank/GetBankById`;
  API_URLAddUpdateBank = `${environment.apiUrl}/HalaBank/AddUpdateBank`;
  API_URLDeleteBank = `${environment.apiUrl}/HalaBank/DeleteBank`;
  
  constructor(private http:HttpClient) { }

  GetAllBank(body): Observable<any> {
    const url = this.API_URLGetAllBank;
    //Http request
    return this.http.post<any>(url,body)
  }

  GetBankById(bankId): Observable<any> {
    const url = this.API_URLGetBankById;
    //Http request
    let param = {id: bankId};
    // return this.http.get<any>(url,{params:param})
    return this.http.get<any>(`${url}/${bankId}`)
  }

  AddUpdateBank(body): Observable<any> {
    const url = this.API_URLAddUpdateBank;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteBank(bankId): Observable<any> {
    const url = this.API_URLDeleteBank;
    //Http request
    let param = {id: bankId};
    // return this.http.delete<any>(url,{params:param})
    return this.http.delete<any>(`${url}/${bankId}`)

  }
}
