import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-bulk-cards-table',
  templateUrl: './bulk-cards-table.component.html',
  styleUrls: ['./bulk-cards-table.component.scss']
})
export class BulkCardsTableComponent implements OnInit {

  @Input() datasource;
  @Output() getDetails: EventEmitter<any> = new EventEmitter();

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  details(data){
    this.getDetails.emit(data)
  }

}
