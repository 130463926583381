<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">
            <!-- Client Type -->
            <div class="col-lg-6 mb-3" *ngIf="!isEditing">
                <label> <span class="requiredField">*</span> {{'Common.ClientType'|translate}}:</label>
                <select formControlName="user_type" class="form-select" (change)="onTypeChange($event.target.value)" [(ngModel)]="accountDetails.user_type">
                    <option style="font-size: 16px !important" value="">--{{'Common.Choose'|translate}}--</option>
                    <option style="font-size: 16px !important" value="1"> {{'Common.Agent'|translate}}</option>
                    <option style="font-size: 16px !important" value="2"> {{'Common.Distributor'|translate}}</option>
                    <option style="font-size: 16px !important" value="3"> {{'Common.POS'|translate}}</option>
                    <!-- <option style="font-size: 16px !important" value="5"> {{'Common.FinalClient'|translate}}</option> -->
                </select>
                <div *ngIf="form.controls.user_type.touched && form.controls.user_type.errors?.required"
                    class="invalid-input">
                    {{'Common.ClientTypeReq'|translate}}
                </div>
            </div>
            <!-- Client Status -->
            <div class="col-lg-6 mb-3">
                <label> <span class="requiredField">*</span> {{'Common.ClientStatus'|translate}}:</label>
                <select formControlName="status" class="form-select" [(ngModel)]="accountDetails.status">
                    <option style="font-size: 16px !important" value="">--{{'Common.Choose'|translate}}--</option>
                    <option style="font-size: 16px !important" value="0"> {{'Common.Stopped'|translate}}</option>
                    <option style="font-size: 16px !important" value="1"> {{'Common.Working'|translate}}</option>
                    <option style="font-size: 16px !important" value="5"> {{'Common.Hold'|translate}}</option>
                </select>
                <div *ngIf="form.controls.status.touched && form.controls.status.errors?.required"
                    class="invalid-input">
                    {{'Common.ClientStatusReq'|translate}}
                </div>
            </div>
            <!-- Client Name -->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0"> <span class="requiredField">*</span> {{'Common.ClientName'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.user_name"
                    type="text" formControlName="user_name" >
                <div *ngIf="form.controls.user_name.touched && form.controls.user_name.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div>
            </div>
            <!-- Client UserName (Main phone) -->
            <div class="col-lg-4 mb-3" *ngIf="!isEditing">
                <label for="validationCustom02" class="mb-0"> <span class="requiredField">*</span> {{'Common.UserName'|translate}} ({{'Common.ClientPhone'|translate}}) :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                type="text" formControlName="user_phone" >
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.errors?.required"
                    class="invalid-input">
                    {{'Common.PhoneReq'|translate}}
                </div>
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('pattern')"
                    class="invalid-input">
                    {{'Common.PhoneFormat'|translate}}
                </div>
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('minlength')"
                    class="invalid-input">
                    {{'Common.PhoneSmall'|translate}}
                </div>
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('maxLength')"
                    class="invalid-input">
                    {{'Common.PhoneHuge'|translate}}
                </div>
            </div>
            <!-- password -->
            <div class="col-lg-4 mb-3" *ngIf="!isEditing">
                <label for="validationCustom02" class="mb-0"> <span class="requiredField">*</span> {{'Common.Password'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                type="text" formControlName="user_pass" >
                <div *ngIf="form.controls.user_pass.touched && form.controls.user_pass.errors?.required"
                    class="invalid-input">
                    {{'Common.PasswordRequired'|translate}}
                </div>
                <div *ngIf="form.controls.user_pass.touched && form.controls.user_pass.hasError('minlength')"
                    class="invalid-input">
                    {{'Common.PasswordSmall'|translate}}
                </div>
                <div *ngIf="form.controls.user_pass.touched && form.controls.user_pass.hasError('maxlength')"
                    class="invalid-input">
                    {{'Common.PasswordHuge'|translate}}
                </div>
            </div>
            <!-- Companies  -->
            <div class="col-lg-12 mb-3" *ngIf="!isEditing">
                <app-companies-dropdown #companiesDropDown (updateParent)="setSelectedCompany($event)"></app-companies-dropdown>
            </div>
            <!-- Agents -->
            <div class="col-lg-12 mb-3" *ngIf="!isEditing && form.controls.user_type.value!='1' ">
                <label> <span class="requiredField" *ngIf="form.controls.user_type.value=='2' || form.controls.user_type.value=='3'">*</span> {{'Common.Agents'|translate}} :</label>
                <!-- <ejs-combobox #agentsCombo [dataSource]="agentsList"  [fields]="agentFields" (change)="changeSelectedAgnet($event)" ></ejs-combobox> -->
                <p class="form-control" disabled="true">{{agentText}}</p>
            </div>
            <!-- Distributors (not visible if client type ==3 POS) -->
            <div class="col-lg-12 mb-3" *ngIf="!isEditing && form.controls.user_type.value!='2' && form.controls.user_type.value!='1'">
                <label>{{'Common.Distributors'|translate}} :</label>
                <!-- <ejs-combobox #distributorCombo [dataSource]="distributorsList"  [fields]="distrFields" (change)="changeSelectedDistributor($event)" ></ejs-combobox> -->
                <ejs-dropdownlist #distributorCombo formControlName="distAccountId" id='diacritics' [value]='value' [fields]='distrFields'
                    [popupHeight]='height' [dataSource]='distributorsList'
                    [allowFiltering]='distributorsList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                    filterBarPlaceholder='{{"Common.Search"|translate}}'  #sample
                    (filtering)='onFiltering($event)'></ejs-dropdownlist>
            </div>

            <!-- Phone (optional) -->
            <!-- <div class="col-lg-4 mb-3" *ngIf="!isEditing">
                <label for="validationCustom02" class="mb-0">{{'Common.Phone'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"
                type="text" formControlName="user_phone" >
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('pattern')"
                    class="invalid-input">
                    {{'Common.PhoneFormat'|translate}}
                </div>
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('minlength')"
                    class="invalid-input">
                    {{'Common.PhoneSmall'|translate}}
                </div>
                <div *ngIf="form.controls.user_phone.touched && form.controls.user_phone.hasError('maxLength')"
                    class="invalid-input">
                    {{'Common.PhoneHuge'|translate}}
                </div>
            </div> -->
            
            <!-- Address (optional)-->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0">{{'Common.Address'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.address"
                    type="text" formControlName="address">
                <div *ngIf="form.controls.address.touched && form.controls.address.errors?.required"
                    class="invalid-input">
                    {{'Common.AddressReq'|translate}}
                </div>
            </div>
            <!-- Address 1 (optional)-->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0">{{'Common.Address'|translate}}1 :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.rt1"
                    type="text" formControlName="rt1">
                <div *ngIf="form.controls.rt1.touched && form.controls.rt1.errors?.required"
                    class="invalid-input">
                    {{'Common.AddressReq'|translate}}
                </div>
            </div>
            <!-- Address 2(optional)-->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0">{{'Common.Address'|translate}}2 :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.rt2"
                    type="text" formControlName="rt2">
                <div *ngIf="form.controls.rt2.touched && form.controls.rt2.errors?.required"
                    class="invalid-input">
                    {{'Common.AddressReq'|translate}}
                </div>
            </div>
            <!-- TaxCard(optional)-->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0">{{'Common.TaxCard'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.rt3"
                    type="text" formControlName="rt3">
            </div>
            <!-- CommercialRegister(optional)-->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom01" class="mb-0">{{'Common.CommercialRegister'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.rt4"
                    type="text" formControlName="rt4">
            </div>
            <!-- email -->
            <div class="col-lg-4 mb-3">
                <label for="validationCustom02" class="mb-0">{{'Common.Email'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" [(ngModel)]="accountDetails.email"
                type="text" formControlName="email">
                <div *ngIf="form.controls.email.touched && form.controls.email.errors?.required ||  form.controls.email.hasError('email') "
                    class="invalid-input">
                    {{'Common.EmailReq'|translate}}
                </div>
            </div>
        
            <!-- Action Buttons -->
            <div class="d-flex justify-content-end mb-3">
                <div>
                    <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="form.invalid" (click)="isEditing ? updateAccount() : addAccount()">{{ isEditing ? ('Common.Edit'|translate) : ('Common.Add'|translate) }}</button>
                    <button type="button" class="btn btn-light" (click)="discard()">{{'Common.Discard'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>