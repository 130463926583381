import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThPriceSettingsService {

  API_URLGetAllPriceData = `${environment.apiUrl}/ThdbPriceSettings/GetAllPriceData`;
  API_URLUpdatePriceData = `${environment.apiUrl}/ThdbPriceSettings/UpdatePriceData`;
  API_URLCopyPriceFromTo = `${environment.apiUrl}/ThdbPriceSettings/CopyPriceFromTo`;
  API_URLCopyPriceFromExcel = `${environment.apiUrl}/ThdbPriceSettings/CopyPriceFromExcel`;

  constructor(private http:HttpClient) { }

  GetAllPriceData(body): Observable<any> {
    const url = this.API_URLGetAllPriceData;
    //Http request-
    return this.http.post<any>(url,body)
  }
  UpdatePriceData(body): Observable<any> {
    const url = this.API_URLUpdatePriceData;
    //Http request-
    return this.http.post<any>(url,body)
  }
  CopyPriceFromTo(body): Observable<any> {
    const url = this.API_URLCopyPriceFromTo;
    //Http request-
    return this.http.post<any>(url,body)
  }
  CopyPriceFromExcel(body): Observable<any> {
    const url = this.API_URLCopyPriceFromExcel;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
