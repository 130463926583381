import { Component, OnInit, Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';

@Component({
  selector: 'app-amounts-table',
  templateUrl: './amounts-table.component.html',
  styleUrls: ['./amounts-table.component.scss']
})
export class AmountsTableComponent implements OnInit {

  @Input() datasource:any;

  constructor(
    public dateFormatService:DateFormatService) { }

  ngOnInit(): void {    
  }

}
