import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sadad-transactions-table',
  templateUrl: './sadad-transactions-table.component.html',
  styleUrls: ['./sadad-transactions-table.component.scss']
})
export class SadadTransactionsTableComponent implements OnInit {

  @Input() datasource;
  @Output() requestByTransId = new EventEmitter<any>();
  @Output() transChildren = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  getDetails(data){
    this.requestByTransId.emit(data)
  }

  rowSelect(data){
    this.transChildren.emit(data)
  }

}
