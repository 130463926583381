export interface INavbarData {
  routeLink: string;
  routeLinkSecondary?: string[];
  icon?: string;
  label: string;
  roles?: any;
  permissions: any;
  expanded?: boolean;
  childs?: INavbarData[];
}

export const menuItemsData: INavbarData[] = [
  {
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'house',
    routeLink: '/dashboard',
    roles: [],
    permissions: [],
  },
  //Hala Menu
  {
    label: 'MENUITEMS.HALA.TEXT',
    icon: 'menu',
    routeLink: '/hala/',
    roles: [], 
    permissions: [
        'HalaReceivedAmounts.View',
        'HalaBank.View',
        'HalaBank.Create',
        'HalaBank.Update',
        'HalaBank.Delete',
        'HalaProviders.View',
        'HalaProviders.Create',
        'HalaProviders.Update',
        'HalaProviders.Delete',
        'HalaAccounts.Create',
        'HalaAccounts.View',
        'HalaAccounts.Update',
        'ThdbHalaAdditions.View',
        'HalaWithdrawals.View',
        'HalaWithdrawals.Create'
    ],
    childs: [
        // HalaReceivedamount menu
        {
            label: 'MENUITEMS.HALA.RecievedAmounts.TEXT',
            icon:'dot',
            routeLink: '/hala/halareceivedamounts/getamounts',
            roles: [], 
            permissions: ['HalaReceivedAmounts.View']
        },
        // HalaBank Menu
        {
            label: 'MENUITEMS.HALA.BANK.TEXT',
            icon:'dot',
            routeLink: '/hala/halabank/getbanks',
            roles: [], 
            permissions: ['HalaBank.View','HalaBank.Create','HalaBank.Update','HalaBank.Delete']
        },
        //HalaProviders Menu
        {
            label: 'MENUITEMS.HALA.Providers.TEXT',
            icon:'dot',
            routeLink: '/hala/halaproviders/getproviders',
            roles: [], 
            permissions: ['HalaProviders.View','HalaProviders.Create','HalaProviders.Update','HalaProviders.Delete']
        },
        //HalaUsers Menu
        {
            label: 'MENUITEMS.HALA.Accounts.Users',
            icon:'dot',
            routeLink: '/hala/halaaccounts/getusers',
            roles: [], 
            permissions: ['HalaAccounts.Create','HalaAccounts.View','HalaAccounts.Update']
        },
        
        // //HalaAdditions Menu
        {
            label: 'MENUITEMS.HALA.Additions.TEXT',
            icon:'dot',
            routeLink: '/hala/halaadditions/getadditions',
            roles: [], 
            permissions: ['ThdbHalaAdditions.View']
        },
        // //HalaWithdrawals Menu
        {
            label: 'MENUITEMS.HALA.Withdrawals.TEXT',
            icon:'dot',
            routeLink: '/hala/halawithdrawals/getwithdrawals',
            roles: [], 
            permissions: ['HalaWithdrawals.View','HalaWithdrawals.Create']
        },
    ]
  },
  //Cards Menu
  {
    label: 'MENUITEMS.CARDS.TEXT',
    icon: 'credit-card',
    routeLink: 'hala/halamanagecards/',
    routeLinkSecondary:['thuraya/cards/'],
    roles: [],
    permissions: [
      'HalaManageHalaCards.Create',
      'HalaManageHalaCards.Update',
      'HalaManageHalaCards.Delete',
      'HalaManageHalaCards.View',
      'HalaManageHalaCards.Create',
      'HalaManageHalaCards.Update',
      'HalaManageHalaCards.View',
      'ThdbTrxRefund.View',
      'ThdbCardBatches.View',
      'ThdbOutStandingCards.View',
      'ThdbInventoryMovement.View'
    ],
    childs: [
      //HalaCardsEmployee Menu
      {
          label: 'MENUITEMS.CARDS.EmployeeCards.TEXT',
          icon: 'dot',
          routeLink: 'hala/halamanagecards/employee',
          roles: [],
          permissions:[
            'HalaManageHalaCards.Create',
            'HalaManageHalaCards.Update',
            'HalaManageHalaCards.Delete',
            'HalaManageHalaCards.View'
          ],
          childs: [
              {
                label: 'MENUITEMS.CARDS.EmployeeCards.AddCard',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/employee/sendcards',
                roles: [], 
                permissions: ['HalaManageHalaCards.Create','HalaManageHalaCards.Update','HalaManageHalaCards.Delete']
              },
              {
                label: 'MENUITEMS.CARDS.EmployeeCards.CardReport',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/employee/cardsreport',
                roles: [], 
                permissions: ['HalaManageHalaCards.View']
              },
          ]        
      },
       //HalaCardsManger Menu
       {
        label: 'MENUITEMS.CARDS.MangerCards.TEXT',
        icon: 'dot',
        routeLink: 'hala/halamanagecards/manger',
        routeLinkSecondary:['thuraya/cards/'],
        roles: [],
        permissions:[
          'HalaManageHalaCards.Create',
          'HalaManageHalaCards.Update',
          'HalaManageHalaCards.View',
          'ThdbTrxRefund.View',
          'ThdbCardBatches.View',
          'ThdbOutStandingCards.View',
          'ThdbInventoryMovement.View'
        ],
        childs: [
            {
                label: 'MENUITEMS.CARDS.MangerCards.AddCardFile',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/manger/send-cards-excel',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['HalaManageHalaCards.Create']
            },
            {
                label: 'MENUITEMS.CARDS.MangerCards.ApproveCard',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/manger/approvecards',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['HalaManageHalaCards.Update']
            },
            {
                label: 'MENUITEMS.CARDS.MangerCards.MangerCardReport',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/manger/cards-report-manger',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['HalaManageHalaCards.View','HalaManageHalaCards.Update']
            },
            {
                label: 'MENUITEMS.CARDS.MangerCards.ActivateBatchCards',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/manger/active-batch-cards',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['HalaManageHalaCards.Update']
            },
            {
                label: 'MENUITEMS.CARDS.MangerCards.CardsActivatedReports',
                icon: 'dot',
                routeLink: '/hala/halamanagecards/manger/get-active-cards',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['HalaManageHalaCards.View']
            },
            {
                label: 'MENUITEMS.CARDS.TrxRefund.TEXT',
                icon: 'dot',
                routeLink: '/thuraya/cards/trx-refund/get-refunds',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['ThdbTrxRefund.View']
            },
            {
                label: 'MENUITEMS.CARDS.CardBatches.TEXT',
                icon: 'dot',
                routeLink: '/thuraya/cards/card-batches/get-batches',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['ThdbCardBatches.View']
            },
            {
                label: 'MENUITEMS.CARDS.OutstandingCards.TEXT',
                icon: 'dot',
                routeLink: '/thuraya/cards/outstanding-card/get-outstand-card',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['ThdbOutStandingCards.View']
            },
            {
                label: 'MENUITEMS.CARDS.InventoryMovements.TEXT',
                icon: 'dot',
                routeLink: '/thuraya/cards/inventory-movement/get-movements',
                roles: ['SuperAdmin','Admin'], 
                permissions: ['ThdbInventoryMovement.View']
            },
        ]       
    },
    ]
  },
  //Reports Menu
  {
    label: 'MENUITEMS.REPORTS.TEXT',
    icon: 'file-text',
    routeLink: '/thuraya/reports/',
    routeLinkSecondary:['hala/reports','big-data/reports'],
    roles: [],
    permissions: [
      'ThdbPurchases.View',
      'ThdbPurchasesRatePerDay.View',
      'ThdbPurchasesTarget.View',
      'ThdbCompanySales.View',
      'ThdbSalesRatePerDay.View'
    ],
    childs: [
      //Purchase Report Menu
      {
          label: 'MENUITEMS.REPORTS.PurchaseReport.TEXT',
          icon: 'dot',
          routeLink: '/thuraya/reports/purchases/',
          roles: [],
          permissions:['ThdbPurchases.View','ThdbPurchasesRatePerDay.View','ThdbPurchasesTarget.View'],
          childs: [
              {
                label: 'MENUITEMS.REPORTS.PurchaseReport.Purchase.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/purchases/get-all-purchases-data',
                roles: [], 
                permissions: ['ThdbPurchases.View']
              },
              {
                label: 'MENUITEMS.REPORTS.PurchaseReport.PurchaseRate.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/purchases/get-all-purchases-rate-data',
                roles: [], 
                permissions: ['ThdbPurchasesRatePerDay.View']
              },
              {
                label: 'MENUITEMS.REPORTS.PurchaseReport.PurchaseTarget.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/purchases/get-all-purchases-target-data',
                roles: [], 
                permissions: ['ThdbPurchasesTarget.View']
              },
          ]        
      },
      //Sales Report Menu
      {
        label: 'MENUITEMS.REPORTS.SalesReport.TEXT',
        icon: 'dot',
        routeLink: '/thuraya/reports/company-sales/',
        roles: [],
        permissions:['ThdbCompanySales.View','ThdbSalesRatePerDay.View'],
        childs: [
            {
                label: 'MENUITEMS.REPORTS.SalesReport.CompaniesSales.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/company-sales/get-companies-sales',
                roles: [], 
                permissions: ['ThdbCompanySales.View']
            },
            {
                label: 'MENUITEMS.REPORTS.SalesReport.SalesRate.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/company-sales/get-all-sales-rate',
                roles: [], 
                permissions: ['ThdbSalesRatePerDay.View']
            },
        ]        
      },
      //Other Report Menu
      {
        label: 'MENUITEMS.REPORTS.Others.TEXT',
        icon: 'dot',
        routeLink: 'thuraya/reports/others',
        routeLinkSecondary:['hala/reports/others','big-data/reports/others'],
        roles: [],
        permissions:[
          'ThdbMonthlyTarget.View',
          'ThdbPackingCard.View',
          'BigDataBill.View',
          'ThdbAccountReport.View',
          'ThdbPosScratchReport.View',
          'ThdbEffectivePosReport.View',
          'ThdbAdvancedBulkAccount.View',
          'ThdbCompanyProfit.View',
          'ThdbCustomerSalesDetails.View',
          'ThdbAdminMovement.View',
          'ThdbAdvancedAccountReport.View',
          'ThdbStartInventoryBalance.View',
          'ThdbCurrentStockBalance.View',
          'ThdbOutstandingClassServices.View',
          'ThdbOutstandingClassServices.Update',
          'ThdbGamePurchaseInvoices.View',
          'HalaCardUpdate.View',
          'HalaDataUpdate.View',
          'HalaJobApplication.View'
        ],
        childs: [
            {
                label: 'MENUITEMS.REPORTS.Others.TargetReport.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/monthly-target/monthly-target',
                roles: [], 
                permissions: ['ThdbMonthlyTarget.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.TargetReport1.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/monthly-target/target-level-1',
                roles: [], 
                permissions: ['ThdbMonthlyTarget.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.TargetReport2.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/monthly-target/target-level-2',
                roles: [], 
                permissions: ['ThdbMonthlyTarget.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.PackCardReport.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/packing-card/get-packing-cards',
                roles: [], 
                permissions: ['ThdbPackingCard.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.BillHeader.Text',
                icon: 'dot',
                routeLink: '/big-data/reports/others/bill/get-bill-header',
                roles: [], 
                permissions: ['BigDataBill.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.AccountReport.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/account-report/get-account-bill-data',
                roles: [], 
                permissions: ['ThdbAccountReport.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.POSScratchReport.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/pos-scratch-report/get-pos-scratch-data',
                roles: [], 
                permissions: ['ThdbPosScratchReport.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.EffectivePosReport.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/effective-pos-report/get-effective-pos-data',
                roles: [], 
                permissions: ['ThdbEffectivePosReport.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.AdvancedBulkAccount.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/advanced-bulk-account/get-advanced-bulk-data',
                roles: [], 
                permissions: ['ThdbAdvancedBulkAccount.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.CompanyProfit.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/company-profit/get-company-profit',
                roles: [], 
                permissions: ['ThdbCompanyProfit.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.CustomerSalesDetails.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/customer-sales-details/get-customer-sales-details',
                roles: [], 
                permissions: ['ThdbCustomerSalesDetails.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.AdminMovement.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/admin-movement/get-admin-data',
                roles: [], 
                permissions: ['ThdbAdminMovement.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.GetAdvancedAccountReportData.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/advanced-account-report-data/get-advanced-account-report-data',
                roles: [], 
                permissions: ['ThdbAdvancedAccountReport.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.StartInventoryBalance.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/start-inv-balance/get-inventory-data',
                roles: [], 
                permissions: ['ThdbStartInventoryBalance.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.CurrentStockBalance.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/current-stock-balance/get-current-stock-data',
                roles: [], 
                permissions: ['ThdbCurrentStockBalance.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.OutstandingClassServices.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/outstanding-class-services/get-outstanding-class-data',
                roles: [], 
                permissions: ['ThdbOutstandingClassServices.View','ThdbOutstandingClassServices.Update']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.GamePurchaseInvoice.Text',
                icon: 'dot',
                routeLink: '/thuraya/reports/others/game-purchase-invoice/get-game-purchase-data',
                roles: [], 
                permissions: ['ThdbGamePurchaseInvoices.View',]
            },
            {
                label: 'MENUITEMS.REPORTS.Others.CardUpdates.Text',
                icon: 'dot',
                routeLink: '/hala/reports/others/card-update/get-card-updates',
                roles: [], 
                permissions: ['HalaCardUpdate.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.DataUpdates.Text',
                icon: 'dot',
                routeLink: '/hala/reports/others/data-update/get-data-updates',
                roles: [], 
                permissions: ['HalaDataUpdate.View']
            },
            {
                label: 'MENUITEMS.REPORTS.Others.JobApplications.Text',
                icon: 'dot',
                routeLink: '/hala/reports/others/job-application/get-all-job-applications',
                roles: [], 
                permissions: ['HalaJobApplication.View']
            },
            
            //TODO:Continue this page
            // {
            //     label: 'MENUITEMS.REPORTS.Others.InventoryReport.Text',
            //     link: '/reports/other-reports/inventory-report',
            //     roles: [], 
            //     permissions: []
            // },
        ]        
    },
    ]
  },
  //System Settings Menu
  {
    label: 'MENUITEMS.SYSTEMSETTINGS.TEXT',
    icon: 'settings',
    routeLink: 'thuraya/system-settings/',
    routeLinkSecondary:['hala/system-settings/'],
    roles: [],
    permissions: [
     'ThdbCompaniesSettings.View',
     'ThdbCompaniesSettings.Create',
     'ThdbCompaniesSettings.Update',
     'ThdbCompaniesSettings.Delete',
     'ThdbManageAccounts.View',
     'ThdbManageAccounts.Create',
     'ThdbManageAccounts.Update',
     'HalaCompaniesGrouping.View',
     'HalaCompaniesGrouping.Create',
     'HalaCompaniesGrouping.Update',
     'HalaCompaniesGrouping.Delete',
     'ThdbPriceSettings.View',
     'ThdbPriceSettings.Create',
     'ThdbPriceSettings.Update',
     'ThdbManageIncomingAmounts.View',
     'ThdbManageIncomingAmounts.Create',
     'ThdbManageIncomingAmounts.Update',
     'ThdbManageIncomingAmounts.Delete',
     'ThdbManageIncomingAmounts.View',
      'ThdbServiceClassSetting.View',
      'ThdbServiceClassSetting.Create',
      'ThdbServiceClassSetting.Update',
      'HalaRole.View',
      'HalaRole.Create',
      'HalaRole.Update',
      'HalaRole.Delete',
      'ThdbOtp.View'
    ],
    childs: [
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.CompaniesSettings.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/companies-settings/get-all-companies',
            roles: [], 
            permissions: ['ThdbCompaniesSettings.View','ThdbCompaniesSettings.Create','ThdbCompaniesSettings.Update','ThdbCompaniesSettings.Delete']
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.CreateAccounts.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/manage-accounts/get-all-created-accounts',
            roles: [], 
            permissions: ['ThdbManageAccounts.View','ThdbManageAccounts.Create','ThdbManageAccounts.Update']
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.GroupsSettings.TEXT',
            icon: 'dot',
            routeLink: '/hala/system-settings/halacompaniesgrouping/get-all-groups',
            roles: [], 
            permissions: ['HalaCompaniesGrouping.View','HalaCompaniesGrouping.Create','HalaCompaniesGrouping.Update','HalaCompaniesGrouping.Delete',]
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.PriceSettings.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/price-settings/get-all-price-data',
            roles: [], 
            permissions: ['ThdbPriceSettings.View','ThdbPriceSettings.Create','ThdbPriceSettings.Update']
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.IcomingAmounts.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/manage-incoming-amounts/get-all-incoming-amounts',
            roles: [], 
            permissions: ['ThdbManageIncomingAmounts.View','ThdbManageIncomingAmounts.Create','ThdbManageIncomingAmounts.Update','ThdbManageIncomingAmounts.Delete']
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.IcomingAmountsReport.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/manage-incoming-amounts/incoming-amounts-report',
            roles: ['SuperAdmin','Admin'],
            permissions: ['ThdbManageIncomingAmounts.View',]
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.SystemClassSettings.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/system-settings/service-class-settings/get-service-class-data',
            roles: [], 
            permissions: ['ThdbServiceClassSetting.View','ThdbServiceClassSetting.Create','ThdbServiceClassSetting.Update']
        },
        {
            label: 'MENUITEMS.SYSTEMSETTINGS.RolesSettings.TEXT',
            icon: 'dot',
            routeLink: '/hala/system-settings/roles/get-roles',
            roles: ['SuperAdmin'], 
            permissions: ['HalaRole.View','HalaRole.Create','HalaRole.Update','HalaRole.Delete']
        },
        // {
        //     label: 'MENUITEMS.SYSTEMSETTINGS.ValidationCode.TEXT',
        //     icon: 'dot',
        //     routeLink: '/thuraya/otp/validation-code',
        //     roles: [], 
        //     permissions: ['ThdbOtp.View']
        // },
    ]
  },
  //Profit User Menu
  {
    label: 'MENUITEMS.PROFITUSER.TEXT',
    icon: 'chart-spline',
    routeLink: 'thuraya/profit-user/',
    roles: [], 
    permissions: ['ThdbProfitUser.View'],
    childs: [
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompany.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/company-profit-margin',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyCard.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/company-card-profit-margin',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyClient.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/client-company-profit-margin',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyCardClient.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/client-company-card-profit-margin',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserClient.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/client-profit-margin',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyPayments.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/company-payments',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserBalanceTransfer.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/balance-transfers',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },
        {
            label: 'MENUITEMS.PROFITUSER.ProfitUserCompanyInvoice.TEXT',
            icon: 'dot',
            routeLink: '/thuraya/profit-user/company-invoice',
            roles: [], 
            permissions: ['ThdbProfitUser.View']
        },           
    ]
  },
  // Pins
  {
    label: 'MENUITEMS.PINS.TEXT',
    icon: 'barcode',
    routeLink: '/thuraya/pins/',
    roles: [],
    permissions: ['ThdbBulkOrderCard.Create','ThdbBulkCards.View','ThdbPosBulkBill.View','ThdbClientEditPrices.View','ThdbClientEditPrices.Update'],
    childs: [
      {
        label: 'MENUITEMS.PINS.BulkOrderCard.TEXT',
        routeLink: '/thuraya/pins/bulk-order-card/get-bulk-order-cards-data',
        icon: 'dot',
        roles: [],
        permissions: ['ThdbBulkOrderCard.Create'],
      },
      {
        label: 'MENUITEMS.PINS.BulkCards.Text',
        routeLink: '/thuraya/pins/bulk-cards/get-bulk-card-header',
        icon: 'dot',
        roles: [],
        permissions: ['ThdbBulkCards.View'],
      },
      {
        label: 'MENUITEMS.PINS.PosBulkBill.Text',
        routeLink: '/thuraya/pins/pos-bulk-bill/get-pos-bulk-data',
        icon: 'dot',
        roles: [],
        permissions: ['ThdbPosBulkBill.View'],
      },
      {
        label: 'MENUITEMS.PINS.ClientEditPrices.Text',
        routeLink: '/thuraya/pins/client-edit-prices/get-client-prices-data',
        icon: 'dot',
        roles: [],
        permissions: ['ThdbClientEditPrices.View','ThdbClientEditPrices.Update'],
      },
    ],
  },
  //Sadad Menu
  {
    label: 'MENUITEMS.SADAD.TEXT',
    icon: 'banknote',
    routeLink: '/sadad/sadad-service-api/',
    roles: ['Accountant'],
    permissions: [],
    childs: [
        {
            label: 'MENUITEMS.SADAD.SadadTransactions.TEXT',
            icon: 'dot',
            routeLink: '/sadad/sadad-service-api/get-Transactions',
            roles: ['Accountant'], 
            permissions: []
        },
        {
            label: 'MENUITEMS.SADAD.SadadRequests.TEXT',
            icon: 'dot',
            routeLink: '/sadad/sadad-service-api/get-requests',
            roles: ['Accountant'], 
            permissions: []
        },
        {
            label: 'MENUITEMS.SADAD.SadadBalance.TEXT',
            icon: 'dot',
            routeLink: '/sadad/sadad-service-api/get-sadad-balance',
            roles: ['Accountant'], 
            permissions: []
        },
        
       
    ]
},
];