import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { HalaJobApplicationsService } from 'src/app/core/services/hala/hala-job-applications.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-job-applications',
  templateUrl: './get-job-applications.component.html',
  styleUrls: ['./get-job-applications.component.scss']
})
export class GetJobApplicationsComponent implements OnInit {

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false

  constructor(
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private halaJobApplicationsService:HalaJobApplicationsService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    this.halaJobApplicationsService.GetAllJobApplications(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {  
            let sheet={ 
              "الاسم رباعي":data[i].FullName,
              "الجنسية":data[i].NationalityType==1 ? "سعودى" : "وافد",
              "جنسية الوافد":data[i].ForeignerNationality,
              "رقم الهويه/الاقامه":data[i].IdentityId,
              "رقم الجوال المباشر":data[i].PhoneNumber,
              "صلاحية الاقامة":data[i].ResidenceValidity,
              "المهنة بالاقامة":data[i].ResidenceJob,
              "مسمى الوظيفة":data[i].JobTitle,
              "البريد الالكترونى":data[i].Email,
              "العنوان":data[i].CurrentAdress,
              "اخر شركة عملت بها":data[i].LastCompany,
              "سبب ترك العمل":data[i].ReasonLeftCompany,
              "اسم وجوال الشخص المسئول":data[i].NameAndNumberForResponsable,
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "الاجمالى": Number.parseFloat(result.Data.SumTotalSellPrice).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  /*----------------------API Requests---------------------- */
  
  // this api get data filled from the form of thuraya website (thuraya/update-data) IMPORTANT this page in thuraya website is referred only with url does not exist in nav bar
  getAllJobApplications() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      // isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
    }
    return this.halaJobApplicationsService.GetAllJobApplications(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.Data.length>0){
        this.datasource = result.Data.Data
        this.totalCount = result.Data.TotalCount;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getAllJobApplications()
    } 
  }

  reset(){
    this.datasource =[] 
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
    this.getAllJobApplications()
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    // if(this.toDateCom && this.fromDateCom){
      this.getAllJobApplications();
    // }
  }

}
