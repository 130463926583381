<div class="table-export mb-3">
    <button (click)="print()" [disabled]="datasource.length==0 || isPrinting">
        <div class="icon-container-print">
            <i class="fa fa-print"></i>
        </div>
        <span> {{'Common.PrintReport' | translate}} </span>
        <div *ngIf="isPrinting" class="spinner-border spinner-border-sm m-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
</div>  