import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HalaCompaniesSettingsService } from 'src/app/core/services/hala/hala-companies-settings.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-companies-dialog',
  templateUrl: './add-companies-dialog.component.html',
  styleUrls: ['./add-companies-dialog.component.scss']
})
export class AddCompaniesDialogComponent implements OnInit {

  @Input() groupData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  formCreateUpdate: FormGroup;
  
  CompaniesData: any[];
  public SelectCompaniesList: any[];

  constructor( 
    private fb: FormBuilder,
    private halaCompaniesSettingsService:HalaCompaniesSettingsService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private msgTranlsateService:MessageTranslateService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllCompaniesDataNotInGrouping()
  }

  initForm(){
    this.formCreateUpdate = this.fb.group({
      groupId: new FormControl(""),
      selectedcompanyList: new FormArray([]),
    });
  } 

  
  /*----------------------API REQUESTS---------------------- */
  getAllCompaniesDataNotInGrouping() {
    this.spinner.show()
    let body={
      isPaginated:false
    }
    return this.halaCompaniesSettingsService.GetAllCompaniesDataNotInGrouping(body).subscribe(
      (result) => {
      this.CompaniesData = result.Data;
      this.spinner.hide()
    },(err)=>{
      this.spinner.hide()
    }
    );
  }

  addNewCompaniesGrouping(){
    //Check fields 
    if (this.formCreateUpdate.controls.selectedcompanyList.value.length==0){
      Swal.fire({
        title: this.msgTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    
    let body = {
      groupId: this.groupData.Id,
      groupName: this.groupData.GroupName,
      companiesList: this.formCreateUpdate.controls.selectedcompanyList.value
    }
    return this.halaCompaniesSettingsService.AddNewCompaniesGrouping(body).subscribe(
      (result) => {
      if(result.IsSuccess){
        this.modalService.dismissAll();
        this.toastr.success(this.msgTranlsateService.msgTranslate("ToastMessages.SuccAdd"), "",{
          positionClass: 'toast-bottom-left',
        })
        this.updateParent.emit()
      }else{
        this.toastr.error(result.Data, "",{
          positionClass: 'toast-bottom-left',
        })
      }
    },(err)=>{
      this.toastr.error(err.error.title, "",{
        positionClass: 'toast-bottom-left',
      })
    }
    );
  }
  
  /*-----------------------Selecting/Deselecting company-------------------- */
  onCheckboxChangeTo(event: any) {
    const selectedPriceTo = (this.formCreateUpdate.controls.selectedcompanyList as FormArray);
    if (event.target.checked) {
      let selc = {
        company_no: event.target.value,
        company_name: this.CompaniesData.filter(a => a.company_no == event.target.value)[0].company_name

      };
      selectedPriceTo.push(new FormControl(selc));
      // console.log("add",this.formCreateUpdate.controls.selectedcompanyList.value)
    } else {
      const index = selectedPriceTo.controls
        .findIndex(x => x.value === event.target.value);
      selectedPriceTo.removeAt(index);
      // console.log("remove",this.formCreateUpdate.controls.selectedcompanyList.value)

    }
  }

  reset(){
    this.CompaniesData=[];
    this.SelectCompaniesList = [];
    this.modalService.dismissAll();
  }

}
