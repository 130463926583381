import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThProfitUserService } from 'src/app/core/services/thuraya/th-profit-user.service';

@Component({
  selector: 'app-user-summary-data',
  templateUrl: './user-summary-data.component.html',
  styleUrls: ['./user-summary-data.component.scss']
})
export class UserSummaryDataComponent implements OnInit {

  //Cards list variable
  infoList

  constructor(
    private msgTranslateService:MessageTranslateService,
    private thProfitUserService:ThProfitUserService,
    private authService:AuthenticationService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show();
    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.Name')], icon: 'tag', iconColor: '#181c32',amount:['-'], endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Phone'),], icon: 'phone', iconColor: '#181c32',amount:['-'], endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Address'),], icon: 'home', iconColor: '#4f0091', amount:['-'], endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TaxNumber')], icon: 'file-invoice', iconColor: '#4f0091', amount:['-'], endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.POSNumber')], icon: 'store', iconColor: '#1bc5bd', amount:['-'],endText:[]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.Balance')], icon: 'wallet', iconColor: '#1bc5bd', amount:['-'],endText:[]},
    ];
    this.getUserAllSummaryData()
    
  }
  /*----------------------API Requests---------------------- */
  getUserAllSummaryData(){
    let body = {
      accountId: this.authService.authUser.PhoneNumber
    }

    return this.thProfitUserService.GetUserAllSummaryData(body).subscribe(
      (result) => {
        this.spinner.hide()
        if(result.IsSuccess && result.Data){
          this.infoList[0].amount[0] = result.Data.UserName
          this.infoList[1].amount[0] = result.Data.AccountId
          this.infoList[2].amount[0] = result.Data.Address
          this.infoList[3].amount[0] = result.Data.TaxNumber
          this.infoList[4].amount[0] = result.Data.myClientsCount
          this.infoList[5].amount[0] = result.Data.balance
        }
        else{
          this.infoList[0].amount[0] = '-'
          this.infoList[1].amount[0] = '-'
          this.infoList[2].amount[0] = '-'
          this.infoList[3].amount[0] = '-'
          this.infoList[4].amount[0] = '-'
          this.infoList[5].amount[0] = '-'
        }
      },(err)=>{
        this.spinner.hide()
        // this.datasource = []
        // this.totalCount = 0;
      }
    );
  }

}
