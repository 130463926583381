import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ThStartInvBalanceService } from 'src/app/core/services/thuraya/th-start-inv-balance.service';
import { ThCurrentStockBalanceService } from 'src/app/core/services/thuraya/th-current-stock-balance.service';

@Component({
  selector: 'app-get-current-stock-data',
  templateUrl: './get-current-stock-data.component.html',
  styleUrls: ['./get-current-stock-data.component.scss']
})
export class GetCurrentStockDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("servicesDropDown") servicesDropDown;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thCurrentStockBalanceService:ThCurrentStockBalanceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value,
      class_name: this.f.class_name.value,
    }
    const subscr =  this.thCurrentStockBalanceService.GetCurrentStockBalanceData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          let sheet = {}
          for (let i = 0; i < data.length; i++) {     
            sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "اسم الكرت": data[i].class_name,
              "عدد الكروت": data[i].TotalCount,
              "اجمالي السعر": data[i].TotalClassPrice,
              "اجمالي التكلفة": data[i].TotalClassCost,
            }
            this.excelService.addToSheet(sheet);
          }

          let total={ 
            "عدد الكروت": data[0].SumTotalCount,
            "اجمالي السعر":  Number.parseFloat(data[0].SumTotalClassPrice).toFixed(2),
            "اجمالي التكلفة": Number.parseFloat(data[0].SumTotalClassCost).toFixed(2), 
          }

          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
    
    
  }  
  
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    ServiceId: new FormControl(""),
    class_name: new FormControl(""),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getCurrentStockBalanceData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      service_id: this.f.ServiceId.value,
      class_name: this.f.class_name.value,
    }
    return this.thCurrentStockBalanceService.GetCurrentStockBalanceData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getCurrentStockBalanceData();
  }

  
  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getCurrentStockBalanceData()
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.pageNumber = 1
    this.totalCount = 0;
    this.getCurrentStockBalanceData()
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_name.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_name.setValue("")
      this.ClassNameList = [];
    }
  }

}
