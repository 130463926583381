import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillRoutingModule } from './bill-routing.module';
import { GetBillHeaderComponent } from './get-bill-header/get-bill-header.component';
import { CoreModule } from 'src/app/core/core.module';
import { BillHeaderTableComponent } from './get-bill-header/_components/bill-header-table/bill-header-table.component';
import { BillDetailsTableComponent } from './get-bill-header/_components/bill-details-table/bill-details-table.component';


@NgModule({
  declarations: [
    GetBillHeaderComponent,
    BillHeaderTableComponent,
    BillDetailsTableComponent
  ],
  imports: [
    CommonModule,
    BillRoutingModule,
    CoreModule
  ],
  exports:[
    BillDetailsTableComponent
  ]
})
export class BillModule { }
