import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-sales-rate-table',
  templateUrl: './sales-rate-table.component.html',
  styleUrls: ['./sales-rate-table.component.scss']
})
export class SalesRateTableComponent implements OnInit {

  @Input() datasource;

  sumTotalCost:number = 0;
  sumTotalSellPrice:number = 0;
  sumTotalPointPrice:number = 0;
 
  constructor() { }

  ngOnInit(): void {
  }

  
  ngOnChanges() { 
    if(this.datasource.length>0){
      this.sumTotalCost = this.datasource[0].SumTotalCost;
      this.sumTotalSellPrice = this.datasource[0].SumTotalSellPrice;
      this.sumTotalPointPrice = this.datasource[0].SumTotalPointPrice;
    }else{
      this.sumTotalCost = 0;
      this.sumTotalSellPrice = 0;
      this.sumTotalPointPrice = 0;
    }
  }

}
