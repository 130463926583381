import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetBatchesDataComponent } from './get-batches-data/get-batches-data.component';

const routes: Routes = [
  { path: 'get-batches', component: GetBatchesDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbCardBatches.View'], roles:['SuperAdmin','Admin']} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardBatchesRoutingModule { }
