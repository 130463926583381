import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inv-movements-table',
  templateUrl: './inv-movements-table.component.html',
  styleUrls: ['./inv-movements-table.component.scss']
})
export class InvMovementsTableComponent implements OnInit {

  @Input() datasource; 

  SumTotal:number = 0
  SumTotalCostAmount:number = 0

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumTotal = this.datasource[0].SumTotal;
      this.SumTotalCostAmount = this.datasource[0].SumTotalCostAmount;
    }else{
      this.SumTotal = 0;
      this.SumTotalCostAmount = 0;
    }
  }


}
