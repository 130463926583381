import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetOutstandingClassDataComponent } from './get-outstanding-class-data/get-outstanding-class-data.component';

const routes: Routes = [
  { path: 'get-outstanding-class-data', component: GetOutstandingClassDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbOutstandingClassServices.View','ThdbOutstandingClassServices.Update'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutstandingClassServicesRoutingModule { }
