<!--Filter Row-->
<div class="row" *ngIf="permissionService.checkPermission(['ThdbPriceSettings.View','ThdbPriceSettings.Update'])">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact  py-2">
            <form class="form" [formGroup]="form">
                <div class="card-body">
                    <div class="form-group row">
                        <!-- Companies  -->
                        <div class="col-lg-3 mb-3">
                            <app-companies-dropdown #companiesDropDown (updateParent)="setSelectedCompany($event)"></app-companies-dropdown>
                        </div>
                        <!-- Service -->
                        <div class="col-lg-3 mb-3">
                            <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                        </div>

                        <!-- Action Buttons -->
                        <div class="col-lg-3 mb-3 d-flex align-items-end">
                            <div class="action-btns" style="margin-inline-end: 20px;">
                                <button type="submit" (click)="search()" [disabled]="form.invalid"
                                class="btn btn-primary font-weight-bold px-10 mr-2">
                                    {{'Common.Search'|translate}}
                                </button>
                            </div>
                            <div class="action-btns">
                                <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                    {{'Common.Clear'|translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
       
    </div>
</div>

<!-- Update Price with excel -->
<div class="row" *ngIf="dataSource.length && permissionService.checkPermission(['ThdbPriceSettings.Update'])">
    <div class="col-xl-12">
        <div class="card card-custom gutter-b example example-compact py-4 px-3">
            <div class="row d-flex align-items-end">
                <h4 class="mb-4" style="color: rgb(196, 82, 82);">{{'SYSTEMSETTINGS.PriceSettings.UpdatePriceExcel'| translate}}</h4>
                <!-- Open File -->
                <div class='col-lg-4 mb-3'>
                    <p>{{'HALA.SendExcel.OpenFile'|translate}} </p>
                    <input #excelFile type="file" class="form-control" (change)="changeFileListener($event)"
                      placeholder="Upload file"
                      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </div>
                <!-- Show -->
                <div class="col-lg-1 col-2 mb-3 action-btns">
                    <button type="submit" (click)="ShowFileData()" [disabled]="GFile==null"
                        class="btn btn-primary font-weight-bold px-10 mr-2">{{'Common.Show'|translate}}</button>
                </div>
                <!-- Update -->
                <div class="col-lg-1 col-2 mb-3 action-btns">
                    <button [disabled]="!isActive" type="submit" (click)="copyPriceFromExcel()" class="btn btn-success font-weight-bold px-10 mr-2">{{'Common.Export'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Export Excel/Copy Prices -->
<div class="col-12 d-flex justify-content-between align-items-center">
     <!-- Copy Price button -->
    <div class="text-sm-end" *ngIf="permissionService.checkPermission(['ThdbPriceSettings.Create'])">
        <button type="button" class="btn btn-success" (click)="copyPrices()" >
            {{'SYSTEMSETTINGS.PriceSettings.CopyPrices'| translate}}</button>
    </div>
    <div>
        <app-excel-export *ngIf="permissionService.checkPermission(['ThdbPriceSettings.View'])" [datasource]="dataSource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
    </div>
</div>


<!-- Table And pagination -->
<div class="card" *ngIf="permissionService.checkPermission(['ThdbPriceSettings.View','ThdbPriceSettings.Update'])">
    <div class="card-body">
        <div class="mt-3">
             <!-- Spinner -->
            <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
            <app-prices-table [datasource]="dataSource" (updatePrice)="updatePrice($event)" [showingExcelData]="showingExcelData"></app-prices-table>
         </div>
        
        <!-- Pagination -->
        <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
    </div>
</div>