<div>
    <label> {{'Common.CardName'|translate}}:</label>
    <ng-multiselect-dropdown
    [placeholder]="languageService.selectedLanguage=='ar' ? 'اختار' : ''"
    [settings]="dropdownSettings"
    [data]="classDropDownListFiltered"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="onItemDeSelect($event)"
    (onFilterChange)="onFilterChange($event)"
    [(ngModel)]="SelectedValue"
    >
    </ng-multiselect-dropdown>
</div>