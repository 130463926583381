import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetServiceClassDataComponent } from './get-service-class-data/get-service-class-data.component';

const routes: Routes = [
  { path: 'get-service-class-data', component: GetServiceClassDataComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbServiceClassSetting.View','ThdbServiceClassSetting.Create','ThdbServiceClassSetting.Update'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceClassSettingsRoutingModule { }
