import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAmountsComponent } from './get-amounts/get-amounts.component';


const routes: Routes = [
  { path: 'getamounts', component:GetAmountsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaReceivedAmounts.View'], roles:[]}, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceivedamountsRoutingModule { }
