import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LanguageService } from '../../services/language.service';
import { ThPurchaseTargetService } from '../../services/thuraya/th-purchase-target.service';

@Component({
  selector: 'app-services-multiselect',
  templateUrl: './services-multiselect.component.html',
  styleUrls: ['./services-multiselect.component.scss']
})
export class ServicesMultiselectComponent implements OnInit {

  //customer multi select variables
  servicesDropDownList = []
  servicesDropDownListFiltered = []
  dropdownSettings:IDropdownSettings = {};
  SelectedValue

  @Output() addToList: EventEmitter<any> = new EventEmitter();
  @Output() removeFromList: EventEmitter<any> = new EventEmitter();

  constructor(
    private thPurchaseTargetService: ThPurchaseTargetService,
    public languageService:LanguageService,
  ) { }

  
  // Called from parent on reset
  resetSelected(){
    this.SelectedValue = null
  }

  ngOnInit(): void {
    // services multiselect settings
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll:false,
      idField: 'service_id',
      textField: 'fullName',
      searchPlaceholderText:"بحث",
      selectAllText: 'تحديد الكل',
      unSelectAllText: 'الغاء تحديد الكل',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.getServicesData()
  }

  getServicesData() {
    this.thPurchaseTargetService.GetServicesData().subscribe(
      (result) => {
        this.servicesDropDownList = this.servicesDropDownListFiltered = result.Data;
      }
    );
  }

  onFilterChange(e){
    if(!e)
       this.servicesDropDownListFiltered=this.servicesDropDownList;
    else
    {
       e=e.toLowerCase();
       this.servicesDropDownListFiltered=this.servicesDropDownListFiltered.filter(x=>x.fullName.toLowerCase()
          .indexOf(e)==0)

          //If no result show all else you get no data and no search bar
          if(this.servicesDropDownListFiltered.length==0){
            this.servicesDropDownListFiltered = this.servicesDropDownList
          }
    }
    
  }

  onItemSelect(item: any) {
    this.addToList.emit(item.service_id)
  }
  onItemDeSelect(item:any){
    this.removeFromList.emit(item.service_id)
  }

}
