<div class="table-responsive mb-0">
      <table class="table table-centered">
        <thead class="table-light">
          <tr>
            <th class="align-middle">{{'Common.BatchNumber'| translate}}</th>
            <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
            <th class="align-middle">{{'Common.CardName'| translate}}</th>
            <th class="align-middle">{{'Common.FileName'| translate}}</th>
            <th class="align-middle">{{'Common.BatchDate'| translate}}</th>
            <th class="align-middle">{{'Common.ExpiryDate'| translate}} </th>
            <th class="align-middle">{{'Common.CardAmount'| translate}}</th>
            <th class="align-middle"> {{'Common.UserId'| translate}}</th>
            <th class="align-middle"> {{'Common.Status'| translate}}</th>
            <th class="align-middle"> {{'Common.Details'| translate}}</th>
            <!-- Only for activating cards page show button -->
            <th class="align-middle" *ngIf="IsActivateComponent"> {{'Common.Activate'| translate}}</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of datasource">
            <td>
              <a class="text-body fw-bold">{{data.batch_no}}</a>
            </td>
            <td>{{data.service_id}}</td>
            <td style="max-width: 150px;">{{data.class_name}}</td>
            <td style="max-width: 200px;">{{data.file_name}}</td>
            <td>
              <div>
                <p>{{data.batch_date | date:dateFormatService.dateOnlyFormat}}</p>
                <p>{{data.batch_date | date:dateFormatService.timeOnlyFormat}}</p>
              </div>
            </td>
            <td>
              <div>
                <p>{{data.exp_date | date:dateFormatService.dateOnlyFormat}}</p>
                <p>{{data.exp_date | date:dateFormatService.timeOnlyFormat}}</p>
              </div>
            <td>{{data.batch_count}}</td>
            <td>{{data.edit_user}}</td>
            <td>{{data.batch_statustext}}</td>
            <td class="text-center"><i class="fa-solid fa-circle-info" role="button" style="font-size: 18px;" (click)="details(data)"></i></td>
             <!-- Only for activating cards page show button and not alrady active (status==1)-->
            <td class="text-center" *ngIf="IsActivateComponent">
                <span *ngIf="data.batch_status=='1'">-</span>
                <button class="btn btn-success" *ngIf="data.batch_status!='1'" (click)="showActivateConfirmBox(data)">{{'Common.Activate'| translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  