import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DistributorReportsRoutingModule } from './distributor-reports-routing.module';
import { GetInvoiceDataComponent } from './get-invoice-data/get-invoice-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { InvoiceTableComponent } from './get-invoice-data/_components/invoice-table/invoice-table.component';
import { GetAllBillDetailsComponent } from './get-all-bill-details/get-all-bill-details.component';
import { BillModule } from '../../big-data/bill/bill.module';


@NgModule({
  declarations: [
    GetInvoiceDataComponent,
    InvoiceTableComponent,
    GetAllBillDetailsComponent
  ],
  imports: [
    CommonModule,
    DistributorReportsRoutingModule,
    CoreModule,
    BillModule
  ]
})
export class DistributorReportsModule { }
