import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventoryMovementRoutingModule } from './inventory-movement-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { GetInventoryMovementComponent } from './get-inventory-movement/get-inventory-movement.component';
import { InvMovementsTableComponent } from './get-inventory-movement/_components/inv-movements-table/inv-movements-table.component';


@NgModule({
  declarations: [
    GetInventoryMovementComponent,
    InvMovementsTableComponent
  ],
  imports: [
    CommonModule,
    InventoryMovementRoutingModule,
    CoreModule
  ]
})
export class InventoryMovementModule { }
