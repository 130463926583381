import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HalaReceivedAmountsService } from 'src/app/core/services/hala/hala-received-amounts.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DeclarationHelper } from 'src/app/core/helpers/declaration-helper';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-get-amounts',
  templateUrl: './get-amounts.component.html',
  styleUrls: ['./get-amounts.component.scss']
})
export class GetAmountsComponent implements OnInit {

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  //Cards list variable
  infoList
  //Dates variables
  fromDateCom:any = null
  toDateCom:any = null
  public formatOptions: object;

  //Table variables
  dataSource = [];
  public data: any;
  public result: any;
  pageNo:number = 1
  pageSize:number = 10
  totalCount:number = 0;

  //Form Variables
  form: FormGroup;

  
  transfers = 0;
  transfersCount = 0;
  unpaid = 0;
  paid = 0; 
  trxNumber: string;
  thurayaNumber: string;
  isLoading: boolean;
  public trxFormat: object;

  public isExporting:boolean = false;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private halaReceivedService: HalaReceivedAmountsService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private spinner: NgxSpinnerService

    ) { 
     this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('HALA.RecAmounts.TransacAmount')], icon: 'comments-dollar', iconColor: '#4f0091', amount:[0], endText:[this.msgTranslateService.msgTranslate('HALA.RecAmounts.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('HALA.RecAmounts.Paid')], icon: 'check-circle', iconColor: '#1bc5bd', amount:[0],endText:[this.msgTranslateService.msgTranslate('HALA.RecAmounts.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('HALA.RecAmounts.Unpaid')], icon: 'exclamation-circle', iconColor: '#f64e60',amount:[0], endText:[this.msgTranslateService.msgTranslate('HALA.RecAmounts.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('HALA.RecAmounts.TransacCount')], icon: 'coins', iconColor: '#181c32',amount:[0], endText:[this.msgTranslateService.msgTranslate('HALA.RecAmounts.Transaction')]},
    ];
    this.initForm()
    // this.initComboSharedInput();
  }

  // angular lifecircle hooks
  ngOnInit() {
    //ng pagination on init calls page change so no need to getReceivedAmounts here
    // this.getReceivedAmounts();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      trxNumber: this.f.trxNumber.value,
      user_Id: this.f.user_Id.value,
      user_Phone: this.f.User_Phone.value 
    }

    const subscr = this.halaReceivedService.GetReceivedAmounts(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length>0){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم التحويل": data[i].TrxRef,
              "تاريخ التحويل": this.datePipe.transform(data[i].TrxDate,this.dateFormatService.dateFormat),
              "قيمة التحويل": data[i].Amount,
              "اسم العميل": data[i].user_name,
              "رقم الجوال	": data[i].MobileNumber,
              "حساب الثرايا": data[i].ThurayaNumber,
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
          this.isExporting = false;
        }
      },(err)=>{
        this.isExporting = false
      }
    )
    this.unsubscribe.push(subscr);
  }

  /*--------------------------Form Functions-------------------------- */
  initForm(){
    this.form = this.fb.group({
      trxNumber: new FormControl(null),
      // thurayaNumber: new FormControl(null),
      User_Phone: new FormControl(null),
      user_Id: new FormControl(null),
    });
  }

  get f() {
    return this.form.controls;
  }
  
  /*--------------------------Api Request-------------------------- */
  getReceivedAmounts() {
    this.spinner.show();
    let body={
      pageNumber: this.pageNo,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      trxNumber: this.f.trxNumber.value,
      user_Id: this.f.user_Id.value,
      user_Phone: this.f.User_Phone.value 
    }
    const subscr = this.halaReceivedService.GetReceivedAmounts(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data.resultData.length>0){
          // console.log("here")
          this.dataSource = result.Data.resultData;
          this.infoList[0].amount[0] = result.Data.total
          this.infoList[1].amount[0] = result.Data.paid
          this.infoList[2].amount[0] = result.Data.unpaid
          this.infoList[3].amount[0] = result.Data.resultCount;
          this.totalCount = result.Data.resultCount
          // this.transfers = data.total;
          // this.transfersCount = data.resultCount;
          // this.unpaid = data.unpaid;
          // this.paid = data.paid;
        }else{
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
          this.infoList[0].amount[0] =0;
          this.infoList[1].amount[0] =0;
          this.infoList[2].amount[0] =0;
          this.infoList[3].amount[0] =0;
          this.totalCount = 0;
          this.pageNo = 1;
          this.dataSource=[]
        }
        this.spinner.hide();
    },(err)=>{
      this.spinner.hide();
    }
    );
    this.unsubscribe.push(subscr)
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
    }else if(type=="to"){
      this.toDateCom = event.date
    }
  }

  /*--------------------------Filter-------------------------- */
  submit() {
    //reset paginatoin
    this.pageNo = 1
    this.getReceivedAmounts();
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNo = event;
    this.getReceivedAmounts();
    // console.log("pagechange",event)
  }

  /*----------------------Combobox FUNCTIONS---------------------- */
  
  // //Combox variable
  // public comboFiled: Object ={};
  // public comboData =[];
  // public userscomboName="users";
  // selectedUserId = null;

  // initComboSharedInput(){
  //   this.shrdInpt = {
  //     users:{}
  //   };
  //   this.shrdInpt.users.comboboxref =null
  //   this.shrdInpt.users.combBoxEvent = null;
  //   this.shrdInpt.users.comboBoxList = [];
  //   this.shrdInpt.users.loadedList = [];
  //   this.shrdInpt.users.searchList = [];
  //   this.shrdInpt.users.scrollNewData= [];
  //   this.shrdInpt.users.endData = false;
  //   this.shrdInpt.users.selectedData= null;
  //   this.shrdInpt.users.isSearching = false;
  //   this.shrdInpt.users.searchText = "";
  //   this.shrdInpt.users.oldSearchText = "";
  //   this.shrdInpt.users.pageNumberBeforeSearch=1;
  //   this.shrdInpt.users.pageNumber=1;
  //   this.shrdInpt.users.pageSize=10;
  //   this.shrdInpt.users.isLoading = false;
  //   this.shrdInpt.users.submitted = false;

  //   let body={
  //     pageNumber: 1,
  //     pageSize: 10,
  //     isPaginated: true,
  //     searchString: ""
  //   }
  //   this.getData(body)
  // }

  // getData(body) {
  //   this.shrdInpt.users.isLoading = true;
  //   const subscr = this.halaAccountsService.GetUsersDataActive(body).subscribe(
  //     (result) => {
  //       if(result.IsSuccess && result.Data){
  //         let users = result.Data;
  //         // case not Searching
  //         if(!this.shrdInpt.users.isSearching){
  //           this.shrdInpt.users.comboBoxList = []
  //             // if(this.shrdInpt.users.pageNumber==1){
  //             //   this.shrdInpt.users.loadedList = [];
  //             // }
  //             if(users.length){
  //               this.shrdInpt.users.endData = false;
  //               // this.shrdInpt.users.comboBoxList=[]
  //               for (let i = 0; i < users.length; i++) {
  //                 this.shrdInpt.users.comboBoxList.push(`[${users[i].Id.substring(0,8)}] ${users[i].FullName}-${users[i].PhoneNumber}`);
  //                 this.shrdInpt.users.loadedList.push(users[i]);
  //               }
  //               //if there are previous data append to the array
  //               // if(this.comboData.length>0){
  //               //   this.comboData= Array.prototype.push.apply(this.comboData,this.shrdInpt.users.comboBoxList)
  //               // }else{
  //               //   this.comboData = this.shrdInpt.users.comboBoxList
  //               // }
  //               this.shrdInpt.users.comboboxref.addItem(this.shrdInpt.users.comboBoxList  as { [key: string]: Object }[])
  //               this.shrdInpt.users.comboboxref.hideSpinner();
  //               // this.comboFiled = { text: 'FullName', value: 'Id' };
  //             }else{
  //               this.shrdInpt.users.comboboxref.hideSpinner();
  //               //To detect when no more data avaialbale 
  //               this.shrdInpt.users.endData = true;
  //             }
  //         }else{
  //           if(users.length){
  //             //only empty the search list if search string changed [so that if search not changed we have paginated results] 
  //             if(this.shrdInpt.users.oldSearchText!=this.shrdInpt.users.searchText){
  //               this.shrdInpt.users.searchList= [];
  //               //update old value
  //               this.shrdInpt.users.oldSearchText = this.shrdInpt.users.searchText;
  //             }
  //             this.shrdInpt.users.endData = false;
  //             for (let i = 0; i < users.length; i++) {
  //               // this.shrdInpt.users.searchList.push(users[i]);
  //               this.shrdInpt.users.searchList.push(`[${users[i].Id.substring(0,8)}] ${users[i].FullName}-${users[i].PhoneNumber}`);
  //               // this.shrdInpt.users.loadedList.push(users[i]);
  //             }
  //             this.shrdInpt.users.comboboxref.hideSpinner();
  //             this.shrdInpt.users.combBoxEvent.updateData(this.shrdInpt.users.searchList);
  //           }else{
  //             this.shrdInpt.users.comboboxref.hideSpinner();
  //             //To detect when no more data avaialbale 
  //             this.shrdInpt.users.endData = true;
  //           }
  //         }
  //       }
  //       this.shrdInpt.users.isLoading = false;
  //       // this.ref.detectChanges();
  //     },(err)=>{
  //       this.shrdInpt.users.isLoading = false;
  //     }
  //   );
  //   this.unsubscribe.push(subscr)
  // }

  // updateSharedData(event){
  //   let key=event.key;
  //   let subkey=event.subkey;
  //   this.shrdInpt[key][subkey] = event.value
  //   // console.log(this.shrdInpt)
  // }

  // changeSelectedUser(selectedId){ 
  //   if(selectedId){
  //     let list = this.shrdInpt.users.loadedList;
  //     for (let i = 0; i < list.length; i++) {
  //       if(list[i].Id.substring(0,8) == selectedId ){
  //         this.selectedUserId = list[i].Id;
  //         break;
  //       }
  //     }
  //   }else{
  //     this.selectedUserId = null
  //   }
  //   console.log(this.selectedUserId)
  // }

  /*----------------------Component Destroy---------------------- */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
