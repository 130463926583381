<div class="card">
    <div class="card-body">
        <h4 class="card-title">{{'HALA.Providers.ProvidersList'|translate}}</h4>
         <div class="mt-3">
            <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
            <app-providers-table [datasource]="datasource"></app-providers-table>
         </div>
        <!-- Pagination -->
        <app-paginator [totalCount]="totalCount" [pageNumber]="pageNo" (changePage)="changePage($event)" ></app-paginator> 
    </div>
</div>