import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from 'src/app/core/services/permission.service';
import { CreateUpdateClassDialogComponent } from '../create-update-class-dialog/create-update-class-dialog.component';

@Component({
  selector: 'app-class-data-table',
  templateUrl: './class-data-table.component.html',
  styleUrls: ['./class-data-table.component.scss']
})
export class ClassDataTableComponent implements OnInit {

  @Input() datasource 
  
  constructor(
    private modalService: NgbModal,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
  }

  openUpdateDialog(data){
    let modalRef = this.modalService.open(CreateUpdateClassDialogComponent, { centered: true });
    modalRef.componentInstance.isEditing = true;
    modalRef.componentInstance.classData = data;
    // modalRef.componentInstance.updateParent.subscribe(() => {
    //   this.dataSource.filter(d=>d.Id== data.Id)[0].companiesCount++;
    //   this.grid.refresh();
    // })
  }

}
