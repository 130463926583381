import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ApproveCardsComponent } from './approve-cards/approve-cards.component';
import { GetActiveCardsHeaderComponent } from './get-active-cards-header/get-active-cards-header.component';
import { SendCardsExcelComponent } from './send-cards-excel/send-cards-excel.component';
import { SendCardsComponent } from './send-cards/send-cards.component';

const routes: Routes = [
  { path: 'sendcards', component: SendCardsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.Create','HalaManageHalaCards.Update','HalaManageHalaCards.Delete'], roles:[]} },
  { path: 'cardsreport', component: ApproveCardsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.View'], roles:[]} },
  
  { path: 'sendcardsexcel', component: SendCardsExcelComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.Create'], roles:['SuperAdmin','Admin']} },
  { path: 'approvecards', component: ApproveCardsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.Update'], roles:['SuperAdmin','Admin']} },
  { path: 'cardsreportmanger', component: ApproveCardsComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.View','HalaManageHalaCards.Update'], roles:['SuperAdmin','Admin']} },
  { path: 'active-batch-cards', component: GetActiveCardsHeaderComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.Update'], roles:['SuperAdmin','Admin']} },
  { path: 'get-active-cards', component: GetActiveCardsHeaderComponent,canActivate: [AuthGuard],  data: {permission: ['HalaManageHalaCards.View'], roles:['SuperAdmin','Admin']} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManagecardsRoutingModule { }
