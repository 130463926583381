import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  signupForm: FormGroup;
  submitted = false;
  error = '';
  successmsg = false;

  //register Result variable
  registerResult:boolean = null;
  registerResultMessage:string = "";

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private msgTranlsateService:MessageTranslateService, ) { }

  ngOnInit() {
    this.initSignupForm();
  }
  
  initSignupForm(){
    this.signupForm = this.formBuilder.group({
      fullName: ['', 
        Validators.compose([
          Validators.required,
        ]),],
      email: ['', [Validators.required, Validators.email]],
      password: new FormControl( 
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
        ]),
      ),
      phoneNumber:new FormControl( 
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),            // The shortest international phone numbers in use contain seven digits.
          Validators.maxLength(15),          // (Search internet max phone length) the international phone numbering plan (ITU-T E. 164), phone numbers cannot contain more than 15 digits. 
          Validators.pattern("^[0-9]*$")
        ]),
      ),
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }
  /*-------------------------API REQUESTS------------------------- */
  onSubmit() {
    //Check fields 
    if (this.signupForm.invalid){
      Swal.fire({
        title: this.msgTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    this.submitted = true;
    this.registerResult=null;
    this.registerResultMessage=""
    let body={
      fullName: this.f.fullName.value,
      email: this.f.email.value,
      password: this.f.password.value,
      phoneNumber: this.f.phoneNumber.value
    }
    const subscr = this.authService.register(body)
    .subscribe(
      (result) => {
        // console.log("register result",result)
          if(result.IsSuccess){
            this.registerResult = true;
            this.registerResultMessage = result.Data;
          }else{
            this.registerResult = false;
            //error when mail already exist reposne return in array
            if(isArray(result.Data)){
              this.registerResultMessage = result.Data[0];
            }
            //error when phone already exist response retun normal string
            else{
              this.registerResultMessage = result.Data
            }
            
            // this.toastrService.error(result.Data[0], "",{
            //   positionClass: 'toast-bottom-right',
            // });
          }
          this.submitted = false
      },
      err=>{
        this.submitted = false;
        this.registerResult = false;
        this.toastrService.error(err.error.Message, "",{
          positionClass: 'toast-bottom-right',
        });
        // console.log("register error",err)
      }
    );
    this.unsubscribe.push(subscr);
  }

}
