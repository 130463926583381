import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-advanced-bulk-table',
  templateUrl: './advanced-bulk-table.component.html',
  styleUrls: ['./advanced-bulk-table.component.scss']
})
export class AdvancedBulkTableComponent implements OnInit {

  @Input() datasource;
  @Input() totalAdditions;
  @Input() totalWithdraw;

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
