import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sadad-balance-table',
  templateUrl: './sadad-balance-table.component.html',
  styleUrls: ['./sadad-balance-table.component.scss']
})
export class SadadBalanceTableComponent implements OnInit {

  @Input() datasource;

  constructor() { }

  ngOnInit(): void {
  }

}
