import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {  map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PermissionService } from './permission.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    //API URLS
    API_URLLogin = `${environment.apiUrl}/HalaAccounts/Login`;
    API_URLRegister = `${environment.apiUrl}/HalaAccounts/RegisterOrUpdate`;
    
    user: User;
    
    //Variables
    authUser:any= null;

    constructor( 
        private router: Router,
        private http: HttpClient,
        private permissionService:PermissionService ) {
    }

    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    login(body):Observable<any> {
        const url = this.API_URLLogin;    
        return this.http.post<any>(url,body)
    }

    register(body):Observable<any> {
        const url = this.API_URLRegister;    
        return this.http.post<any>(url,body)
    }

    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    logout() {
        localStorage.removeItem("defaultAppToken");
        localStorage.removeItem("defaultAppUserData");
        this.authUser = null;
        this.router.navigate(["/auth/login"]);
    }

    public tokenExpired(token: string) {
        // const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        // return (Math.floor((new Date).getTime() / 1000)) >= expiry;
        return false;
    }

    //Get logged in user at init of app (app module)
    getUserByToken(): Observable<any> {
        let userToken = localStorage.getItem("defaultAppToken");
        let userData = JSON.parse(localStorage.getItem("defaultAppUserData"));
        //Set user permission in the permission service (here case of refresh or access page without login as token not expired)
        this.permissionService.setPermissionsFromToken()
        if (!userToken || !userData) {
        return of(undefined);
        }else{
        //Check if token expired first
        if(this.tokenExpired(userToken)){
            return of(undefined);
        
        }else{
            return this.getDecodedToken(userToken).pipe(
            map((tokenData: any) => {
                if (tokenData) {
                this.authUser = {...tokenData,...userData};
                // console.log("Authed user",this.authUser)
                } else {
                // this.logout();
                }
                return this.authUser;
            }),
            )
        }
        }
    }

    getDecodedToken(token: string): Observable<any> {
        return of(jwt_decode(token));
    }

    //Used after Login /Register
    setAuthedUser(tokenData:any,userData:any){
        this.authUser =  {...tokenData, ...userData};
        console.log("AuthUser",this.authUser)
    }
}

