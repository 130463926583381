import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { HalaAdditionsService } from 'src/app/core/services/hala/hala-additions.service';
import { ToastrService } from 'ngx-toastr';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-get-additions',
  templateUrl: './get-additions.component.html',
  styleUrls: ['./get-additions.component.scss']
})
export class GetAdditionsComponent implements OnInit {

  dataSource = [];
  private subscriptions: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  
  //Form Variables
  form: FormGroup;
  fromDateCom: any;
  toDateCom: any;

  //Tables variables
  pageSize: number=10;
  pageNumber: number=1;
  totalCount:number = 0;
  totalCreditAmount:number = 0;
  
  public data: Object[] = [];
  public format = { type: "date", format: "yyyy/MM/dd" };
  
  isExporting:boolean = false;

  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  constructor(
    public halaAdditionsService: HalaAdditionsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    private excelService:ExcelService,
    private datePipe:DatePipe,
    public dateFormatService:DateFormatService,
    private spinner: NgxSpinnerService
  ) {
   this.initForm()
  }    

  /*------------------------ Form Functions ------------------------ */
  initForm(){
    this.form = this.fb.group({
      cust_account_id: new FormControl(""),
    });
  }
  get f(){
    return this.form.controls
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
    }else if(type=="to"){
      this.toDateCom = event.date
    }
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
     let body={
      isPaginated: false,
      cust_account_id: this.f.cust_account_id.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    }
    const subscr = this.halaAdditionsService.GetHalaAdditionsData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {            
            let sheet={ 
              "رقم المستند": data[i].row_no,
              "تاريخ العملية": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateFormat),
              "حساب العميل": data[i].cust_account_id,
              "مدير النظام": data[i].account_id,
              "اضافة": data[i].credit_amount,
              "البيان":data[i].trans_note
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "اضافة":  Number.parseFloat(data[0].TotalCreditAmount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  ngOnInit() {
   //change page gets called firsy so we get data there
  }
  
  submitSearch() {
    this.pageNumber = 1
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      cust_account_id:this.f.cust_account_id.value ,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    }
    this.getHalaAdditionsData(body);
  }

  /*------------------------ API Requests ------------------------ */
  getHalaAdditionsData(body) {
    this.spinner.show(); 
    const subscr = this.halaAdditionsService.GetHalaAdditionsData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length){
        this.dataSource = result.Data;
        this.totalCount = result.Data[0].TotalRows;
        this.totalCreditAmount = result.Data[0].TotalCreditAmount;
      }else{
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
        this.totalCount = 0;
        this.pageNumber = 1;
        this.dataSource=[];
        this.totalCreditAmount = 0;
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
    this.subscriptions.push(subscr)
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      cust_account_id: this.f.cust_account_id.value,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    }
    this.getHalaAdditionsData(body);
  }

  /*----------------------Reset Filters---------------------- */
  reset() {
    this.initForm();
    this.dateFromSelector.reset();
    this.dateToSelector.reset();
    this.pageNumber = 1
    this.fromDateCom = "";
    this.toDateCom = "";
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      cust_account_id:"" ,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    }
    this.getHalaAdditionsData(body);
  }

  /*----------------------Components destroy---------------------- */
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

}
