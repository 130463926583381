<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
            <th class="align-middle">{{'Common.CardName'| translate}}</th>
            <th class="align-middle">{{'Common.CardAmount'| translate}}</th>
            <th *ngIf="isDetailsType" class="align-middle">{{'Common.Value'| translate}} </th>
            <th *ngIf="isDetailsType" class="align-middle">{{'Common.Cost'| translate}} </th>
            <th class="align-middle">{{'Common.TotalCost'| translate}}</th>
            <th class="align-middle">{{'Common.TotalProfit'| translate}}</th>
            <th *ngIf="isDetailsType" class="align-middle">{{'Common.AddType'| translate}} </th>
            <th *ngIf="isDetailsType" class="align-middle">{{'Common.CreateDate'| translate}} </th>
            <th *ngIf="isDetailsType" class="align-middle">{{'Common.SellDate'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.class_name}}</td>
          <td>{{data.totalCardsNo}}</td>
          <td *ngIf="isDetailsType">{{data.class_price}}</td>
          <td *ngIf="isDetailsType">{{data.class_cost}}</td>
          <td>{{data.totalCost}}</td>
          <td>{{data.totalPrice}}</td>
          <td *ngIf="isDetailsType">{{data.source_type}}</td>
          <td *ngIf="isDetailsType">{{data.edit_date | date:dateFormatService.dateFormat}}</td>
          <td *ngIf="isDetailsType">{{data.po_date | date:dateFormatService.dateFormat}}</td>
          <!-- <td>{{data.sourceType}}</td> -->
        </tr>
        <!-- <tr>
          <td></td>
          <td></td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.Total'| translate}}</span> 
            <span>{{SumTotal==0 ? '-' :SumTotal | number : '1.0-2'}}</span>
          </td>
          <td></td>
          <td>
            <span style="margin-inline-end: 20px; font-weight: 800;">{{'Common.Total'| translate}}</span> 
            <span>{{SumTotalCostAmount==0 ? '-' :SumTotalCostAmount | number : '1.0-2'}}</span>
          </td>
          <td></td>
        </tr> -->
      </tbody>
    </table>
</div>