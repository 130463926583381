import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompaniesGroupingRoutingModule } from './companies-grouping-routing.module';
import { GetAllGroupsComponent } from './get-all-groups/get-all-groups.component';
import { CoreModule } from 'src/app/core/core.module';
import { CompaniesGroupTableComponent } from './get-all-groups/_components/companies-group-table/companies-group-table.component';
import { AddCompaniesDialogComponent } from './get-all-groups/_components/add-companies-dialog/add-companies-dialog.component';


@NgModule({
  declarations: [
    GetAllGroupsComponent,
    CompaniesGroupTableComponent,
    AddCompaniesDialogComponent
  ],
  imports: [
    CommonModule,
    CompaniesGroupingRoutingModule,
    CoreModule
  ]
})
export class CompaniesGroupingModule { }
