<div class="account-pages">
    <div class="container">
      <div class="row justify-content-center align-items-start">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">{{'Register.Register'|translate}}</h5>
                    <!-- <p>Get your free Skote account now.</p> -->
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <a>
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                    </span>
                  </div>
                </a>
              </div>
              <div class="p-2">
  
                <form class="needs-validation" name="signupForm" [formGroup]="signupForm" (ngSubmit)="onSubmit()"
                  novalidate>
  
                  <ngb-alert type="success" *ngIf="registerResult" [dismissible]="false">{{ registerResultMessage }}
                  </ngb-alert>
  
                  <ngb-alert type="danger" *ngIf="registerResult==false" [dismissible]="false">{{ registerResultMessage }}</ngb-alert>
  
                  <!-- <div class="mb-3">
                    <label for="username" class="form-label">Username</label>
  
                    <input type="text" formControlName="username" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="username"
                      placeholder="Enter username" />
  
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Userame is required</div>
                    </div>
                  </div> -->
  
                  <!-- FullName Field -->
                  <div class="mb-3">
                    <label for="fullName" class="form-label">{{'Register.FullName'|translate}}</label>
                    <input type="text" formControlName="fullName" class="form-control" [ngClass]="{ 'is-invalid': f.fullName.errors  && f.fullName.touched }" id="fullName" placeholder="" />
                    <div *ngIf="f.fullName.errors?.required && f.fullName.touched" class="invalid-feedback">{{'Register.FullNameRequired'|translate}}</div>
                    <div *ngIf="f.fullName.hasError('minlength') && f.email.touched" class="invalid-feedback">{{'Register.FullNameSmall'|translate}}</div>
                  </div>
  
                  <!-- Email Field -->
                  <div class="mb-3">
                    <label for="email" class="form-label">{{'Login.Email'|translate}}</label>
                    <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': f.email.errors  && f.email.touched }" id="email" placeholder="" />
                    <div *ngIf="f.email.errors?.required && f.email.touched" class="invalid-feedback">{{'Login.EmailRequired'|translate}}</div>
                    <div *ngIf="f.email.errors?.email && f.email.touched" class="invalid-feedback">{{'Login.EmailInvalid'|translate}}</div>
                  </div>
  
                  <!-- Password Field -->
                  <div class="mb-3">
                    <label for="password" class="form-label">{{'Login.Password'|translate}}</label>
                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.errors && f.password.touched }" id="password" placeholder="" />
                    <div *ngIf="f.password.errors?.required && f.password.touched " class="invalid-feedback"> {{'Login.PasswordRequired'|translate}} </div>
                    <div *ngIf="f.password.hasError('minlength') && f.password.touched " class="invalid-feedback"> {{'Login.PasswordSmall'|translate}} </div>
                    <div *ngIf="f.password.hasError('maxLength') && f.password.touched " class="invalid-feedback"> {{'Login.PasswordHuge'|translate}} </div>
                  </div>
  
                  <!-- Phone Field -->
                  <div class="mb-3">
                    <label for="phone" class="form-label">{{'Register.Phone'|translate}}</label>
                    <input type="text" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': f.phoneNumber.errors && f.phoneNumber.touched }" id="password" placeholder="" />
                    <div *ngIf="f.phoneNumber.errors?.required && f.phoneNumber.touched " class="invalid-feedback"> {{'Register.PhoneRequired'|translate}} </div>
                    <div *ngIf="f.phoneNumber.hasError('minlength') && f.phoneNumber.touched " class="invalid-feedback"> {{'Register.PhoneSmall'|translate}} </div>
                    <div *ngIf="f.phoneNumber.hasError('maxLength') && f.phoneNumber.touched " class="invalid-feedback"> {{'Register.PhoneHuge'|translate}} </div>
                  </div>
  
                  <!-- Submit button -->
                  <div class="mt-4 d-grid">
                    <button class="btn btn-primary" type="submit" [disabled]="signupForm.invalid || submitted">{{'Register.Register'|translate}}</button>
                  </div>
                  <!-- Social Signup -->
                  <!-- <div class="mt-4 text-center">
                    <h5 class="font-size-14 mb-3">Sign in with</h5>
  
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div> -->
                  
                  <!-- Terms and conditions -->
                  <!-- <div class="mt-4 text-center">
                    <p class="mb-0">{{'Register.AgreeTerms'|translate}} <a href="javascript: void(0);"
                        class="text-primary">{{'Register.Terms'|translate}}</a></p>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
          <!-- end card -->
  
          <!-- <div class="mt-5 text-center">
            <p>{{'Register.AlreadyRegistered'|translate}} <a routerLink="/auth/login" class="fw-medium text-primary"> {{'Login.Login'|translate}}</a>
            </p>
            <p>© {{year}} Skote. Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesbrand</p>
          </div>
        </div> -->
         <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>