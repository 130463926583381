import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetDistributorDataComponent } from './get-distributor-data/get-distributor-data.component';

const routes: Routes = [
  { path: 'client-balances', component: GetDistributorDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerPages.View'], roles:[]} },
  { path: 'clients-of-clients', component: GetDistributorDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerPages.View'], roles:[]} },
  { path: 'clients-of-user', component: GetDistributorDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerPages.View'], roles:[]} },
  { path: 'clients-withdrawals', component: GetDistributorDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerPages.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DistributorPagesRoutingModule { }
