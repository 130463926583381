import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetPackingCardsComponent } from './get-packing-cards/get-packing-cards.component';

const routes: Routes = [
  { path: 'get-packing-cards', component: GetPackingCardsComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbPackingCard.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackingCardRoutingModule { }
