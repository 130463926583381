import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThDistributorsPagesService {

  API_URLGetDistributerPagesData = `${environment.apiUrl}/ThdbDistributerPages/GetDistributerPagesData`;
  API_URLGetDistributerPagesDatesWithdrawals = `${environment.apiUrl}/ThdbDistributerPages/GetDistributerPagesDatesWithdrawals`;

  constructor(private http:HttpClient) { }

  GetDistributerPagesData(body): Observable<any> {
    const url = this.API_URLGetDistributerPagesData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetDistributerPagesDatesWithdrawals(body): Observable<any> {
    const url = this.API_URLGetDistributerPagesDatesWithdrawals;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
