import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'purchases', loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule)},
  { path: 'company-sales', loadChildren: () => import('./company-sales/company-sales.module').then(m => m.CompanySalesModule)},
  { path: 'monthly-target', loadChildren: () => import('./monthly-target/monthly-target.module').then(m => m.MonthlyTargetModule)},
  { path: 'packing-card', loadChildren: () => import('./packing-card/packing-card.module').then(m => m.PackingCardModule)},
  { path: 'companies-settings', loadChildren: () => import('./companies-settings/companies-settings.module').then(m => m.CompaniesSettingsModule)},
  { path: 'manage-accounts', loadChildren: () => import('./manage-accounts/manage-accounts.module').then(m => m.ManageAccountsModule)},
  { path: 'trx-refund', loadChildren: () => import('./trx-refund/trx-refund.module').then(m => m.TrxRefundModule)},
  { path: 'card-batches', loadChildren: () => import('./card-batches/card-batches.module').then(m => m.CardBatchesModule)},
  { path: 'outstanding-card', loadChildren: () => import('./outstanding-cards/outstanding-cards.module').then(m => m.OutstandingCardsModule)},
  { path: 'inventory-movement', loadChildren: () => import('./inventory-movement/inventory-movement.module').then(m => m.InventoryMovementModule)},
  { path: 'price-settings', loadChildren: () => import('./price-settings/price-settings.module').then(m => m.PriceSettingsModule)},
  { path: 'manage-incoming-amounts', loadChildren: () => import('./manage-incoming-amounts/manage-incoming-amounts.module').then(m => m.ManageIncomingAmountsModule)},
  { path: 'account-report', loadChildren: () => import('./account-report/account-report.module').then(m => m.AccountReportModule)},
  { path: 'pos-scratch-report', loadChildren: () => import('./pos-scratch-report/pos-scratch-report.module').then(m => m.PosScratchReportModule)},
  { path: 'effective-pos-report', loadChildren: () => import('./effective-pos-report/effective-pos-report.module').then(m => m.EffectivePosReportModule)},
  { path: 'bulk-cards', loadChildren: () => import('./bulk-cards/bulk-cards.module').then(m => m.BulkCardsModule)},
  { path: 'advanced-bulk-account', loadChildren: () => import('./advanced-bulk-account/advanced-bulk-account.module').then(m => m.AdvancedBulkAccountModule)},
  { path: 'pos-bulk-bill', loadChildren: () => import('./pos-bulk-bill/pos-bulk-bill.module').then(m => m.PosBulkBillModule)},
  { path: 'service-class-settings', loadChildren: () => import('./service-class-settings/service-class-settings.module').then(m => m.ServiceClassSettingsModule)},
  { path: 'company-profit', loadChildren: () => import('./company-profit/company-profit.module').then(m => m.CompanyProfitModule)},
  { path: 'customer-sales-details', loadChildren: () => import('./customer-sales-details/customer-sales-details.module').then(m => m.CustomerSalesDetailsModule)},
  { path: 'admin-movement', loadChildren: () => import('./admin-movement/admin-movement.module').then(m => m.AdminMovementModule)},
  { path: 'advanced-account-report-data', loadChildren: () => import('./admin-movement/admin-movement.module').then(m => m.AdminMovementModule)},
  { path: 'start-inv-balance', loadChildren: () => import('./start-inv-balance/start-inv-balance.module').then(m => m.StartInvBalanceModule)},
  { path: 'current-stock-balance', loadChildren: () => import('./current-stock-balance/current-stock-balance.module').then(m => m.CurrentStockBalanceModule)},
  { path: 'outstanding-class-services', loadChildren: () => import('./outstanding-class-services/outstanding-class-services.module').then(m => m.OutstandingClassServicesModule)},
  { path: 'game-purchase-invoice', loadChildren: () => import('./game-purchase-invoice/game-purchase-invoice.module').then(m => m.GamePurchaseInvoiceModule)},
  { path: 'bulk-order-card', loadChildren: () => import('./bulk-order-card/bulk-order-card.module').then(m => m.BulkOrderCardModule)},
  { path: 'profit-user', loadChildren: () => import('./profit-user/profit-user.module').then(m => m.ProfitUserModule)},
  { path: 'client-edit-prices', loadChildren: () => import('./client-edit-prices/client-edit-prices.module').then(m => m.ClientEditPricesModule)},
  { path: 'otp', loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule)},
  { path: 'distributor-pages', loadChildren: () => import('./distributor-pages/distributor-pages.module').then(m => m.DistributorPagesModule)},
  { path: 'move-balance-between-accounts', loadChildren: () => import('./move-balance-between-accounts/move-balance-between-accounts.module').then(m => m.MoveBalanceBetweenAccountsModule)},
  { path: 'distributor-reports', loadChildren: () => import('./distributor-reports/distributor-reports.module').then(m => m.DistributorReportsModule)},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThurayaRoutingModule { }
