import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetBanksComponent } from './get-banks/get-banks.component';

const routes: Routes = [

  { path: 'getbanks', component: GetBanksComponent,canActivate: [AuthGuard],  data: {permission: ['HalaBank.View','HalaBank.Create','HalaBank.Update','HalaBank.Delete'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankRoutingModule { }
