
<div class="d-flex flex-column-fluid  col-xl-12">
    <div class="card card-custom gutter-b example example-compact py-2  col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label"> {{'Common.ValidationCode'|translate}}</h3>
              </div>
            </div>
            <!--begin::Form-->
            <form class="form" [formGroup]="form">
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-lg-3 mb-3">
                    <label> {{'Common.Phone'|translate}} :</label>
                    <input formControlName="phone" type="text" class="form-control" placeholder="" />
                  </div>
  
  
                  <div class="col-lg-3 mb-3 action-btns d-flex align-items-end">
                    <button (click)="getOtpReal()" type="submit"
                      class="btn btn-primary font-weight-bold">{{'Common.Validate'|translate}}</button>
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-12">
  
          <div class="card card-custom gutter-b example example-compact px-8 py-2  col-xl-12">
            <div class="table-striped table-responsive  col-xl-12 px-3">
                <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
                <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style="width: 120px;">{{'Common.ValidationCode'|translate}}</th>
                    <th>{{'Common.ValidationMessage'|translate}}</th>
                    <th>{{'Common.Date'|translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 120px;">{{dataSource ? dataSource.verificationCode : ''}}</td>
                    <td>{{dataSource ? dataSource.MESSAGE : ''}}</td>
                    <td>{{dataSource ? (dataSource.OtpDate | date:dateFormatService.dateFormat) : ''}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
