import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss']
})
export class InvoiceTableComponent implements OnInit {

  @Input() datasource;
  SumSellPrice= 0

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumSellPrice= this.datasource[0].SumSellPrice
    }else{
      this.SumSellPrice= 0;
    }
  }

}
