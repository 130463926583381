import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HalaAccountsService } from '../../services/hala/hala-accounts.service';

@Component({
  selector: 'app-users-dropdown',
  templateUrl: './users-dropdown.component.html',
  styleUrls: ['./users-dropdown.component.scss']
})
export class UsersDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private halaAccountsService: HalaAccountsService,
  ) { }

  ngOnInit(): void {
    this.initComboSharedInput();
  }

  /*----------------------Functions Called from parent---------------------- */
  // setValue(data){
  //   // console.log("eventdata",data)
  //   this.userVal = `[${data.Service_Id}] - ${data.Service_Id}`;
  // }

  reset(){
    this.userVal = ""
  }
  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public userscomboName="users";
  userVal: string="";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      users:{},
    };
    this.initComboSharedInputValues(this.userscomboName);
    this.getUsers()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting Users for dropdown select---------------------- */

  getUsersBody(){
    let body={}
    if(this.shrdInpt[this.userscomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.userscomboName].pageNumber,
        pageSize: this.shrdInpt[this.userscomboName].pageSize,
        searchString: this.shrdInpt[this.userscomboName].searchText,
        isPaginated: true,
      }
    }else{
      this.shrdInpt[this.userscomboName].pageNumberBeforeSearch = this.shrdInpt[this.userscomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.userscomboName].pageNumber,
        pageSize: this.shrdInpt[this.userscomboName].pageSize,
        isPaginated: true,
      }
    } 
    return body;
  }

  getUsers() {
    let body = this.getUsersBody();
    this.shrdInpt[this.userscomboName].isLoading = true;
    const subscr = this.halaAccountsService.GetUsersDataActive(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data.users){
          let data = result.Data.users;
          // case not Searching
          if(!this.shrdInpt[this.userscomboName].isSearching){
            this.shrdInpt[this.userscomboName].comboBoxList = []
              if(data.length){
                this.shrdInpt[this.userscomboName].endData = false;
                for (let i = 0; i < data.length; i++) {
                  //------USE THIS FOR ID SELECTOR-----
                  // this.shrdInpt[this.userscomboName].comboBoxList.push(`[${data[i].Id.substring(0,8)}] - ${data[i].FullName}`);
                  this.shrdInpt[this.userscomboName].comboBoxList.push(`[${data[i].PhoneNumber}] - ${data[i].FullName}`);
                 
                  this.shrdInpt[this.userscomboName].loadedList.push(data[i]);
                 
                }
                this.shrdInpt[this.userscomboName].comboboxref.addItem(this.shrdInpt[this.userscomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.userscomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.userscomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.userscomboName].endData = true;
              }
          }else{
            if(data.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.userscomboName].oldSearchText!=this.shrdInpt[this.userscomboName].searchText){
                this.shrdInpt[this.userscomboName].searchList= [];
                //update old value
                this.shrdInpt[this.userscomboName].oldSearchText = this.shrdInpt[this.userscomboName].searchText;
              }
              this.shrdInpt[this.userscomboName].endData = false;
              for (let i = 0; i < data.length; i++) {
                //------USE THIS FOR ID SELECTOR-----
                // this.shrdInpt[this.userscomboName].searchList.push(`[${data[i].Id.substring(0,8)}] - ${data[i].FullName}`);
                this.shrdInpt[this.userscomboName].searchList.push(`[${data[i].PhoneNumber}] - ${data[i].FullName}`);
              }
              this.shrdInpt[this.userscomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.userscomboName].combBoxEvent.updateData(this.shrdInpt[this.userscomboName].searchList);
            }else{
              this.shrdInpt[this.userscomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.userscomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.userscomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.userscomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }

  /*----------------------when Choosing User---------------------- */
  changeSelectedUser(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let indexofendId = selectedValue.indexOf(']')
       //------USE THIS FOR ID SELECTOR NOW CHANGED TO PHONE-----
      // let userSelectedId = selectedValue.substring(1,indexofendId)
      // let user = this.shrdInpt[this.userscomboName].loadedList.filter(u=>u.Id.substring(0,8)==userSelectedId)
      // console.log(user[0].Id)
      // this.updateParent.emit(user[0].Id);   
      
      //for phone
      let userSelected = selectedValue.substring(1,indexofendId)
      this.userVal = selectedValue;
      this.updateParent.emit(userSelected);   
    }else{
      this.updateParent.emit("");   
    }
  }
}
