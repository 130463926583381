import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThBulkOrderCardService {

  API_URLGetInsertBulkOrderCardsData = `${environment.apiUrl}/ThdbBulkOrderCard/GetInsertBulkOrderCardsData`;
  API_URLInsertBulkOrderCardsData = `${environment.apiUrl}/ThdbBulkOrderCard/InsertBulkOrderCardsData`;
  API_URLGetClientCardPrice = `${environment.apiUrl}/ThdbBulkOrderCard/GetClientCardPrice`;

  constructor(private http:HttpClient) { }

  GetInsertBulkOrderCardsData(body): Observable<any> {
    const url = this.API_URLGetInsertBulkOrderCardsData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  InsertBulkOrderCardsData(body): Observable<any> {
    const url = this.API_URLInsertBulkOrderCardsData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetClientCardPrice(serviceId,classNo): Observable<any> {
    const url = this.API_URLGetClientCardPrice;
    //Http request-
    return this.http.get<any>(`${url}?service_id=${serviceId}&class_no=${classNo}`)
  }
}
