import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThAdminMovementService } from 'src/app/core/services/thuraya/th-admin-movement.service';
import { ThAdvancedAccountReportService } from 'src/app/core/services/thuraya/th-advanced-account-report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-admin-movement-data',
  templateUrl: './get-admin-movement-data.component.html',
  styleUrls: ['./get-admin-movement-data.component.scss']
})
export class GetAdminMovementDataComponent implements OnInit {


  //[IMPORTANT] This component IS USED FOR AdminMovement AND AdvancedAccountReportData so we decide from route and with these varibale we change logic
  IsAdminMovementComponent:boolean = false;
  IsAdvancedAccountReportDataComponent:boolean = false;

  //Cards list variable
  infoList

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  isExporting:boolean = false 
  isPrinting:boolean = false 

  @ViewChild("allCustomersDropDown") allCustomersDropDown;

  constructor(
    private fb: FormBuilder,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    public dateFormatService:DateFormatService,
    private thAdminMovementService:ThAdminMovementService,
    private thAdvancedAccountReportService:ThAdvancedAccountReportService,
    private spinner: NgxSpinnerService,
    private datePipe:DatePipe,
    private router: Router,
  ) { 

    if(this.router.url=="/thuraya/admin-movement/get-admin-data"){
      this.IsAdminMovementComponent = true;
      this.IsAdvancedAccountReportDataComponent = false;
    }else if(this.router.url=="/thuraya/advanced-account-report-data/get-advanced-account-report-data"){
      this.IsAdminMovementComponent = false;
      this.IsAdvancedAccountReportDataComponent = true;
    }
  }

  ngOnInit(): void {
    this.initForm();

    this.infoList = [
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCreditAmount')], icon: 'comments-dollar', iconColor: '#4f0091',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalWithdraw')], icon: 'coins', iconColor: '#181c32', amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
      { headerText: [this.msgTranslateService.msgTranslate('Common.TotalCommissions')], icon: 'comments-dollar', iconColor: '#4f0091',amount:[0], endText:[this.msgTranslateService.msgTranslate('Common.Rial')]},
    ];
  }

  /*------------------------ Excel Export / Print All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      cust_account_id:this.f.cust_account_id.value
    }
    this.thAdminMovementService.GetAdminMovementData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم المستند": data[i].row_no,
              "تاريخ العمليه": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateFormat),
              "رقم الحساب": data[i].cust_account_id,
              "اسم العميل": data[i].CUSTOMER_NAME,
              "مدير النظام": data[i].account_id,
              "اضافه": data[i].credit_amount,
              "سحب": data[i].debit_amount,
              "البيان": data[i].trans_note,
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "الاجمالى": Number.parseFloat(result.Data.SumTotalSellPrice).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }
  
  exportExcelAdvancedReport(){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      cust_account_id:this.f.cust_account_id.value
    }
    this.thAdvancedAccountReportService.GetAdvancedAccountReportData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {     
            let sheet={ 
              "رقم المستند": data[i].row_no,
              "نوع العملية": data[i].trans_type,
              "تاريخ العمليه": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateFormat),
              "رقم الحساب": data[i].cust_account_id,
              "اسم العميل": data[i].CUSTOMER_NAME,
              "مدير النظام": data[i].account_id,
              "اضافه": data[i].credit_amount,
              "سحب": data[i].debit_amount,
              "البيان": data[i].trans_note,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "اضافه": Number.parseFloat(result.Data.totalAdditions).toFixed(2),
            "سحب": Number.parseFloat(result.Data.totalWithdraw).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReport(){
    this.isPrinting =true;
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      cust_account_id:this.f.cust_account_id.value
    }
    this.thAdvancedAccountReportService.GetAdvancedAccountReportData(body).subscribe((result) => {
      this.isPrinting =false;
      let newWindow = window.open('print/print-advanced-account-report','_blank');
      if(result.IsSuccess && result.Data.resultList.length>0){
        newWindow["result"] = result;
        newWindow["dateFrom"] = this.fromDateCom;
        newWindow["dateTo"] = this.toDateCom;
      }
    },(err)=>{
      this.isPrinting = false
    })
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
  
  
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      cust_account_id: new FormControl(""),
    });
  }
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAdminMovementData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      cust_account_id:this.f.cust_account_id.value
    }
    return this.thAdminMovementService.GetAdminMovementData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  getAdvancedAccountReportData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      cust_account_id:this.f.cust_account_id.value
    }
    return this.thAdvancedAccountReportService.GetAdvancedAccountReportData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.infoList[0].amount[0]= result.Data.totalAdditions
        this.infoList[1].amount[0]= result.Data.totalWithdraw
        this.infoList[2].amount[0]= result.Data.totalCommission
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.infoList[0].amount[0]= 0
        this.infoList[1].amount[0]= 0
        this.infoList[2].amount[0]= 0
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    // if(!this.toDateCom || !this.fromDateCom){
    //   Swal.fire({
    //     title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
    //     icon: 'error',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false
    //   });
    // }
    if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.IsAdminMovementComponent ? this.getAdminMovementData() : this.getAdvancedAccountReportData()
    } 
  }

  reset(){
    this.initForm()
    this.allCustomersDropDown.reset()
    this.datasource =[] 
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0
    this.IsAdminMovementComponent ? this.getAdminMovementData() : this.getAdvancedAccountReportData()
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    // if(this.toDateCom && this.fromDateCom){
      this.IsAdminMovementComponent ? this.getAdminMovementData() : this.getAdvancedAccountReportData()
    // }
  }

  /*----------------------when Choosing customer---------------------- */
  setSelectedCustomer(event){
    this.form.controls.cust_account_id.setValue(event)
  }
  

}
