import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobApplicationRoutingModule } from './job-application-routing.module';
import { GetJobApplicationsComponent } from './get-job-applications/get-job-applications.component';
import { JobAppTableComponent } from './get-job-applications/_components/job-app-table/job-app-table.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetJobApplicationsComponent,
    JobAppTableComponent
  ],
  imports: [
    CommonModule,
    JobApplicationRoutingModule,
    CoreModule
  ]
})
export class JobApplicationModule { }
