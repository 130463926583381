import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ThPosScratchReportService } from 'src/app/core/services/thuraya/th-pos-scratch-report.service';
import { ThDistributorReportsService } from 'src/app/core/services/thuraya/th-distributor-reports.service';

@Component({
  selector: 'app-get-invoice-data',
  templateUrl: './get-invoice-data.component.html',
  styleUrls: ['./get-invoice-data.component.scss']
})
export class GetInvoiceDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;
  isPrinting:boolean = false;

  @ViewChild("servicesDropDown") servicesDropDown;


  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thDistributorReportsService:ThDistributorReportsService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  
  /*------------------------ Excel Export / Print All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
    }
    this.thDistributorReportsService.GetInvoiceData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultList.length>0){
          let data = result.Data.resultList
          for (let i = 0; i < data.length; i++) {            
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "اسم الفئة": data[i].note,
              "تاريخ العمليه": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateOnlyFormat),
              "الحساب": data[i].account_id,
              "سعر البيع": data[i].sell_price,
              "السريال":data[i].card_serial,
              "الخدش":data[i].card_scratch,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر البيع":Number.parseFloat(result.Data.SumSellPrice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReport(){
    this.isPrinting =true;
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
    }
    this.thDistributorReportsService.GetInvoiceData(body).subscribe((result) => {
      this.isPrinting = false
      let newWindow = window.open('print/print-distributor-report-invoice','_blank');
      if(result.IsSuccess && result.Data.resultList.length>0){
        newWindow["result"] = result;
        newWindow["dateFrom"] = this.fromDateCom;
        newWindow["dateTo"] = this.toDateCom;
      }
    },(err)=>{
      this.isPrinting = false
    }
   )
  }


  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl(""),
      class_no: new FormControl(""),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getInvoiceData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      service_id: this.f.ServiceId.value,
      class_no: this.f.class_no.value,
    }
    return this.thDistributorReportsService.GetInvoiceData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.resultList.length>0){
        this.datasource = result.Data.resultList
        this.totalCount = result.Data.resultList[0].TotalRows;
      }
      if(result.Data.resultList.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );  
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    if(this.fromDateCom && this.toDateCom){
      this.getInvoiceData();
    }
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    if(!this.toDateCom || !this.fromDateCom){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }
    else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    }else{
      this.getInvoiceData() 
    } 
  }

  reset(){
    this.initForm()
    this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0;
    this.datasource = []
    // this.getPosScratchReportData()
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

}
