import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HalaDataUpdateService {

  API_URLGetAllDataUpdates = `${environment.apiUrl}/HalaDataUpdate/GetAllDataUpdates`;
  API_URLUpdateDataUpdates = `${environment.apiUrl}/HalaDataUpdate/UpdateDataUpdates`;
  
  constructor(private http:HttpClient) { }

  GetAllDataUpdates(body): Observable<any> {
    const url = this.API_URLGetAllDataUpdates;
    //Http request
    return this.http.post<any>(url,body)
  }

  UpdateDataUpdates(body): Observable<any> {
    const url = this.API_URLUpdateDataUpdates;
    //Http request
    return this.http.post<any>(url,body)
  }
}
