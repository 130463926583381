import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class SadadServicesService {

  //API URLS
  API_URLGetTransactions = `${environment.apiUrl}/SadadServiceApi/GetTransactions`;  
  API_URLGetRequestesByTransactionId = `${environment.apiUrl}/SadadServiceApi/GetRequestesByTransactionId`;  
  API_URLGetRequests = `${environment.apiUrl}/SadadServiceApi/GetRequests`;  
  API_URLGetSadadBalance = `${environment.apiUrl}/SadadServiceApi/GetSadadBalance`;  
  API_URLApproveRequest = `${environment.apiUrl}/SadadServiceApi/ApproveRequest`;  

  constructor( private http: HttpClient ) {
  }

  GetTransactions(body):Observable<any> {
    const url = this.API_URLGetTransactions;    
    return this.http.post<any>(url,body)
  }

  GetRequestesByTransactionId(id):Observable<any> {
    const url = this.API_URLGetRequestesByTransactionId;    
    return this.http.get<any>(`${url}?transactionId=${id}`)
  }

  GetRequests(body):Observable<any> {
    const url = this.API_URLGetRequests;    
    return this.http.post<any>(url,body)
  }

  GetSadadBalance(body):Observable<any> {
    const url = this.API_URLGetSadadBalance;    
    return this.http.post<any>(url,body)
  }

  ApproveRequest(body):Observable<any> {
    const url = this.API_URLApproveRequest;    
    return this.http.post<any>(url,body)
  }
}
