<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">
                <div class="form-group mb-3">
                    <label class="required">{{'Common.ClientName'|translate}}</label>
                    <input class="form-control" type="text" formControlName="agentName" [(ngModel)]="oldsimData.AgentName">
                    <!-- <p class="invalid-input" *ngIf="f.fullName.invalid && f.fullName.touched">{{'Form.MustEnter'|translate}} {{'Form.FullName'|translate}}</p> -->
                </div>
                <div class="form-group mb-3">
                    <label>{{'Common.SimOldSerialNumber'|translate}}</label>
                    <input class="form-control" type="text" formControlName="oldSerial" [(ngModel)]="oldsimData.OldSerial">
                    <!-- <p class="invalid-input" *ngIf="f.phone.invalid && f.phone.touched">{{'Form.MustEnter'|translate}} {{'Form.Phone'|translate}}</p> -->
                </div>
                <div class="form-group mb-3">
                    <label class="required">{{'Common.ClientPhone'|translate}}</label>
                    <input class="form-control" type="text" formControlName="agentPhoneNo" [(ngModel)]="oldsimData.AgentPhoneNo">
                    <!-- <p class="invalid-input" *ngIf="f.companyName.invalid && f.companyName.touched">{{'Form.MustEnter'|translate}} {{'Form.CompanyName'|translate}}</p> -->
                </div>
                <div class="form-group mb-3">
                    <label class="required">{{'Common.SimNewSerialNumber'|translate}}</label>
                    <input class="form-control" type="text" formControlName="newSerial" [(ngModel)]="oldsimData.NewSerial">
                    <!-- <p class="invalid-input" *ngIf="f.email.invalid && f.email.touched">{{'Form.MustEnter'|translate}} {{'Form.Email'|translate}}</p> -->
                </div>
                <div class="form-group mb-3">
                    <label class="required">{{'Common.Status'|translate}}</label>
                    <select formControlName="isAction" [(ngModel)]="oldsimData.IsAction" class="form-select">
                        <option style="font-size: 16px;" [ngValue]="true">{{'Common.ActionsTaken'|translate}}</option>
                        <option style="font-size: 16px;" [ngValue]="false">{{'Common.ActionsNotTaken'|translate}}</option>
                    </select>
                    <!-- <p class="invalid-input" *ngIf="f.email.invalid && f.email.touched">{{'Form.MustEnter'|translate}} {{'Form.Email'|translate}}</p> -->
                </div>
        
            <!-- Action Buttons -->
            <div class="d-flex justify-content-end mb-3">
                <div>
                    <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="form.invalid" (click)="saveChanges()">{{ 'Common.Edit'|translate }}</button>
                    <button type="button" class="btn btn-light" (click)="cancelChanges()">{{'Common.Discard'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>