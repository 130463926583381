import { Component, OnInit, Input } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-additions-table',
  templateUrl: './additions-table.component.html',
  styleUrls: ['./additions-table.component.scss']
})
export class AdditionsTableComponent implements OnInit {

  @Input() datasource;
  @Input() totalCreditAmount;
  
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
