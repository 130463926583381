import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceClassSettingsRoutingModule } from './service-class-settings-routing.module';
import { GetServiceClassDataComponent } from './get-service-class-data/get-service-class-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { ClassDataTableComponent } from './get-service-class-data/_components/class-data-table/class-data-table.component';
import { CreateUpdateClassDialogComponent } from './get-service-class-data/_components/create-update-class-dialog/create-update-class-dialog.component';


@NgModule({
  declarations: [
    GetServiceClassDataComponent,
    ClassDataTableComponent,
    CreateUpdateClassDialogComponent
  ],
  imports: [
    CommonModule,
    ServiceClassSettingsRoutingModule,
    CoreModule
  ]
})
export class ServiceClassSettingsModule { }
