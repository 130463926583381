import { Component, HostListener } from '@angular/core';
import { NavService } from '../shared/services/nav.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  showMobileMenu:boolean = false;
  screenWidth = 0;
  isMobileView$ = this.navService.isMobileView$

  constructor(private navService:NavService){}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window: resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth >= 576) {
      this.showMobileMenu =false;
    }
  }

  onToggleSideNav(event:boolean){
    this.showMobileMenu = true
    this.isMobileView$.set(true)
  }
}
