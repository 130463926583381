import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageAccountsRoutingModule } from './manage-accounts-routing.module';
import { GetAllCreatedAccountsComponent } from './get-all-created-accounts/get-all-created-accounts.component';
import { CoreModule } from 'src/app/core/core.module';
import { AccountsTableComponent } from './get-all-created-accounts/_components/accounts-table/accounts-table.component';
import { AddAccountComponent } from './get-all-created-accounts/_components/add-account/add-account.component';


@NgModule({
  declarations: [
    GetAllCreatedAccountsComponent,
    AccountsTableComponent,
    AddAccountComponent
  ],
  imports: [
    CommonModule,
    ManageAccountsRoutingModule,
    CoreModule
  ]
})
export class ManageAccountsModule { }
