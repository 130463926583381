<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
            <th class="align-middle">{{'Common.CardName'| translate}}</th>
          <th class="align-middle">{{'Common.Total'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}}</th>
          <th class="align-middle">{{'Common.TotalCost'| translate}}</th>
          <th class="align-middle">{{'Common.AddType'| translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.note}}</td>
          <td>{{data.total}}</td>
          <td>{{data.cost_amount}}</td>
          <td>{{data.totalCostAmount}}</td>
          <td>{{data.sourceType}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotal==0 ? '-' :SumTotal | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalCostAmount==0 ? '-' :SumTotalCostAmount | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>