import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { HalaManageCardsService } from 'src/app/core/services/hala/hala-manage-cards.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-active-cards-table',
  templateUrl: './active-cards-table.component.html',
  styleUrls: ['./active-cards-table.component.scss']
})
export class ActiveCardsTableComponent implements OnInit {

  @Input() datasource;
  @Input() IsActivateComponent;
  @Output() showDetails = new EventEmitter<any>();

  constructor(
    public dateFormatService:DateFormatService,
    private halaManageCardsService: HalaManageCardsService,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
  }

  details(data){
    this.showDetails.emit(data)
  }

  /*----------------------Approve card box / Return Alert box---------------------- */

  showActivateConfirmBox(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.ApproveActivation'),
      icon:"info",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.activeThisBatchCards(data);
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  activeThisBatchCards(data){
    this.halaManageCardsService.ActiveThisBatchCards(data.batch_no).subscribe((result) => {
      if(result.IsSuccess){ 
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccActivate'), "", {
          positionClass: 'toast-bottom-left',
        });

        this.datasource = this.datasource.filter(d=>d.batch_no != data.batch_no)
      }
      if(result.Data.length==0){
        this.toastr.error(result.message, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    },(err)=>{
      this.toastr.error(err.error.message, "", {
        positionClass: 'toast-bottom-left',
      });
    }
    );
  }

}
