<!-- Card-->
<div class="row">
    <div class="col-xl-4 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Agents  -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.Agents'|translate}} :</label>
                        <app-agents-dropdown #agentsDropDown (updateParent)="setSelectedAgent($event)"></app-agents-dropdown>
                    </div>
                    <!-- Distributors  -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.Distributors'|translate}} :</label>
                        <ejs-combobox #distributorCombo [dataSource]="distributorsList"  [fields]="distrFields" formControlName="distributorsId" (change)="changeSelectedDistributor($event)" ></ejs-combobox>
                    </div>
                    <!-- Card Type -->
                    <div class="col-lg-3 mb-3 " *ngIf="!isLevelOne">
                        <label>{{'Common.CardType'|translate}}:</label>
                        <select formControlName="cardTypeId" class="form-control">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                            <option style="font-size: 16px !important" value="0"> {{'Common.Voice'|translate}}</option>
                            <option style="font-size: 16px !important" value="1"> {{'Common.Data'|translate}}</option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div>
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Action Buttons -->
                    <div class="col-lg-3 mb-3 d-flex align-items-end">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel/Print Report -->
<div class="d-flex justify-content-end">
    <div class="me-3">
        <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
    </div>
    <app-print-report [datasource]="datasource" [isPrinting]="isPrinting" (printReport)="printReport()"></app-print-report>
</div>

<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-target-table [datasource]="datasource" [isMainTarget]="isMainTarget" [isLevelOne]="isLevelOne" (showStatusInExcel)="showStatusInExcel($event)"></app-target-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>