<div class="table-responsive mb-0">
  <table class="table table-centered">
    <thead class="table-light table-nowrap">
      <tr>
        <th class="align-middle" *ngIf="permissionService.checkPermission(['ThdbManageAccounts.Update'])">{{'Common.Edit' | translate}}</th>
        <th class="align-middle">{{'Common.ClientType' | translate}}</th>
        <th class="align-middle">{{'Common.ClientPhone' | translate}}</th>
        <th class="align-middle">{{'Common.ClientName' | translate}}</th>
        <th class="align-middle">{{'Common.CreationDate' | translate}}</th>
        <th class="align-middle">{{'Common.ClientStatus' | translate}}</th>
        <th class="align-middle">{{'Common.Address' | translate}}</th>
        <th class="align-middle">{{'Common.Address' | translate}} ١</th>
        <th class="align-middle">{{'Common.Address' | translate}} ٢</th>
        <th class="align-middle">{{'Common.Email' | translate}}</th>
        <th class="align-middle">{{'Common.CompanyNo' | translate}}</th>
        <th class="align-middle">{{'Common.ParentNo' | translate}}</th>
        <th class="align-middle">{{'Common.ParentName' | translate}}</th>
        <th class="align-middle">{{'Common.TaxCard' | translate}}</th>
        <th class="align-middle">{{'Common.CommercialRegister' | translate}}</th>
        <th class="align-middle">{{'Common.Balance' | translate}}</th>
        <th class="align-middle"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datasource">
        <td class="text-center" *ngIf="permissionService.checkPermission(['ThdbManageAccounts.Update'])">
          <i class='mdi mdi-pencil font-size-18 text-success' style="margin-inline-end: 10px;" (click)="editAccount(data)"></i>
        </td>
        <td>
          <a class="text-body fw-bold">{{data.account_type}}</a>
        </td>
        <td>{{data.CUSTOMER_ID}}</td>
        <td>{{data.CUSTOMER_NAME}}</td>
        <td>
          <div>{{data.last_login_time | date: dateFormatService.dateOnlyFormat}}</div>
          <div>{{data.last_login_time | date: dateFormatService.timeOnlyFormat}}</div>
        </td>
        <td>{{data.status}}</td>
        <td>{{data.cust_address}}</td>
        <td>{{data.rt1}}</td>
        <td>{{data.rt2}}</td>
        <td>{{data.Email}}</td>
        <td>{{data.company_no}}</td>
        <td>{{data.parentNo!=0 ? data.parentNo : '-' }}</td>
        <td>{{data.parentName ? data.parentName : '-'}}</td>
        <td>{{data.rt3 ? data.rt3 : '-'}}</td>
        <td>{{data.rt4 ? data.rt4 : '-'}}</td>
        <td>{{data.balance}}</td>
        <!-- <td> 
          <button class="btn btn-info" (click)="createManageIncomingAmountData(data)">
              تحديد سقف التحويلات
          </button>
        </td> -->
        <!-- <td class="text-center">
          <i class='mdi mdi-pencil font-size-18 text-success' style="margin-inline-end: 10px;" (click)="editCompany(data)"></i>
        </td>
        <td class="text-center">
          <i class='mdi mdi-delete font-size-18 text-danger' (click)="showAlert(data)"></i>
        </td>
        <td class="text-center">
          <i class='mdi mdi-plus font-size-18 text-primary' (click)="addBranch(data)"></i>
        </td>
        <td class="text-center mt-2">
          <i class='fa-solid fa-circle-info font-size-14' (click)="viewBranches(data)"></i>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>