import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'sadad-service-api', loadChildren: () => import('./sadad-service-api/sadad-service-api.module').then(m => m.SadadServiceApiModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SadadRoutingModule { }
