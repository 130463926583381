import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  
  worksheets:any = {
    Sheet1:[]
  };

  emptysheet(){
    this.worksheets.Sheet1= [];
  }

  addToSheet(row){
    this.worksheets.Sheet1.push(row);
  }
  excelExport(fileName?){  
    // Create a new XLSX file
    const newBook = XLSX.utils.book_new();
    const newSheet = XLSX.utils.json_to_sheet(this.worksheets.Sheet1);
    //protect sheet from editing
    //  newSheet['!protect'] = {};

    //  newSheet['!protect'] =
    //  {
    //    password: 'C@1235#' 
 
    //  }
    
    XLSX.utils.book_append_sheet(newBook, newSheet, fileName ? fileName.length>31 ? `${fileName.substring(0,31)}` : `${fileName}` : "Sheet1");
    XLSX.writeFile(newBook, fileName ? `${fileName}.xlsx` : "new-book.xlsx");
  }

}
