import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ThEffectivePosReportService } from 'src/app/core/services/thuraya/th-effective-pos-report.service';
import { ThGamePurchaseInvoiceService } from 'src/app/core/services/thuraya/th-game-purchase-invoice.service';

@Component({
  selector: 'app-get-game-purchase-data',
  templateUrl: './get-game-purchase-data.component.html',
  styleUrls: ['./get-game-purchase-data.component.scss']
})
export class GetGamePurchaseDataComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Date Variables
  fromDateCom:any
  toDateCom:any;
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown lists variables
  ClassNameList:any = []

  //Export Variable for loading
  isExporting:boolean = false;

  @ViewChild("usersDropDown") usersDropDown;
  @ViewChild("servicesDropDown") servicesDropDown;


  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thGamePurchaseInvoiceService:ThGamePurchaseInvoiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      serviceId: this.f.serviceId.value,
      classNo: this.f.classNo.value,
      createUser: this.f.createUser.value,
      batchNo: this.f.batchNo.value,
      provider: this.f.provider.value,
      orderBy: this.f.orderBy.value
    }
    this.thGamePurchaseInvoiceService.GetGamePurchaseInvoicesHeaderData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {            
            let sheet={ 
              "مقدم الخدمة":data[i].serviceId,
              "رقم الفئة":data[i].classNo,
              "اسم الكرت":data[i].className,
              "سعر الفئة":data[i].classPrice,
              "تكلفه الفئة":data[i].classCost,
              "اجمالي المدفوع":data[i].totalPaid,
              "تاريخ الانشاء":data[i].createDate,
              "إنشاء بواسطة":data[i].createUser,
              "رقم المنتج":data[i].productId,
              "عدد الطلبات":data[i].orderCount,
              "عدد المسترجع":data[i].retrivedCount,
              "المورد":data[i].provider,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر الفئة": Number.parseFloat(data[0].SumTotalClassPrice).toFixed(2),
            "تكلفه الفئة":  Number.parseFloat(data[0].SumTotalClassCost).toFixed(2),
            "اجمالي المدفوع": Number.parseFloat(data[0].SumTotalPaid).toFixed(2), 
          }

          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }
 
 
  /*----------------------Form Functions---------------------- */
  initForm(){
  this.form = this.fb.group({
    serviceId: new FormControl(""),
    classNo: new FormControl(""),
    createUser: new FormControl(""),
    batchNo: new FormControl(""),
    provider: new FormControl(""),
    orderBy: new FormControl(""),
  });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getGamePurchaseInvoicesHeaderData() {
    this.spinner.show(); 
    let body={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      serviceId: this.f.serviceId.value,
      classNo: this.f.classNo.value,
      createUser: this.f.createUser.value,
      batchNo: this.f.batchNo.value,
      provider: this.f.provider.value,
      orderBy: this.f.orderBy.value
    }
    return this.thGamePurchaseInvoiceService.GetGamePurchaseInvoicesHeaderData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data
        this.totalCount = result.Data[0].TotalRows;
      }
      if(result.Data.length==0){
        this.datasource = []
        this.totalCount = 0
        this.pageNumber =1;
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );  
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getGamePurchaseInvoicesHeaderData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    // if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
    //   Swal.fire({
    //     title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
    //     icon: 'error',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false
    //   });
    // }else{
      this.getGamePurchaseInvoicesHeaderData() 
    // } 
  }

  reset(){
    this.initForm()
    this.usersDropDown.reset()
    this.servicesDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset()
    this.toDateCom = ""
    this.toDateTimeStamp = "";
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.totalCount = 0;
    this.datasource = []
    // this.getGamePurchaseInvoicesHeaderData()
  }

  /*----------------------when Choosing User---------------------- */
  setSelectedUser(event){
    this.form.controls.createUser.setValue(event)
  }

  /*----------------------Getting class name for dropdown select---------------------- */

  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.serviceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.serviceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.classNo.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.serviceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.classNo.setValue("")
      this.ClassNameList = [];
    }
  }

}
