import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HalaCompaniesSettingsService {

  API_URLGetAllGroups = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllGroups`;
  API_URLAddUpdateNewGroup = `${environment.apiUrl}/HalaCompaniesGrouping/AddUpdateNewGroup`;
  API_URLDeleteGroup = `${environment.apiUrl}/HalaCompaniesGrouping/DeleteGroup`;
  API_URLGetAllCompaniesGroupingData = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllCompaniesGroupingData`;
  API_URLGetAllCompaniesDataNotInGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllCompaniesDataNotInGrouping`;
  API_URLAddNewCompaniesGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/AddNewCompaniesGrouping`;
  API_URLDeleteCompaniesGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/DeleteCompaniesGrouping`;
  
  constructor(private http:HttpClient) { }

  GetAllGroups(): Observable<any> {
    const url = this.API_URLGetAllGroups;
    //Http request
    return this.http.get<any>(url)
  }

  AddUpdateNewGroup(body): Observable<any> {
    const url = this.API_URLAddUpdateNewGroup;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteGroup(body): Observable<any> {
    const url = this.API_URLDeleteGroup;
    //Http request
    return this.http.post<any>(url,body)
  }

  GetAllCompaniesGroupingData(body): Observable<any> {
    const url = this.API_URLGetAllCompaniesGroupingData;
    //Http request
    return this.http.post<any>(url,body)
  }

  GetAllCompaniesDataNotInGrouping(body): Observable<any> {
    const url = this.API_URLGetAllCompaniesDataNotInGrouping;
    //Http request
    return this.http.post<any>(url,body)
  }

  AddNewCompaniesGrouping(body): Observable<any> {
    const url = this.API_URLAddNewCompaniesGrouping;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteCompaniesGrouping(id): Observable<any> {
    const url = this.API_URLDeleteCompaniesGrouping;
    //Http request
    return this.http.get<any>(`${url}?rowId=${id}`)
  }

}
