import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThurayaRoutingModule } from './thuraya-routing.module';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ThurayaRoutingModule
  ],
})
export class ThurayaModule { }
