import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-card-batches-table',
  templateUrl: './card-batches-table.component.html',
  styleUrls: ['./card-batches-table.component.scss']
})
export class CardBatchesTableComponent implements OnInit {

  @Input() datasource
  @Input() SumBatchesCount
  @Input() SumRealBatchCount 
  @Input() SumDifferenceBatchCount 

  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  /*------------------------ Show more file name ------------------------ */
  showMore(filename) {
    Swal.fire({
      title: filename,
      // showCloseButton: true,
      confirmButtonText: "اغلاق"
    });
  }

}
