<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Customer choose -->
                    <!-- <div class="col-lg-3 mb-3">
                        <label>{{'Common.ClientName'|translate}} :</label>
                        <app-agents-dropdown #agentsDropDown (updateParent)="setSelectedAgent($event)"></app-agents-dropdown>
                    </div> -->
                    <!-- Client (Signedin name and phone)-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.ClientName'|translate}}:</label>
                        <input class="form-control" disabled formControlName="account_id" [(ngModel)]="authUser">
                    </div>
                    <!-- Service-->
                    <div class="col-lg-3 mb-3">
                        <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                    </div>
                    <!-- Card Name-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                        <select formControlName="class_no" class="form-select" (change)="setSelectedCard()">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                            [{{ item.class_no }}] - {{ item.class_name }}
                            </option>
                        </select>
                    </div>
                    <!-- Client (Agent) Price-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.ClientPrice'|translate}}:</label>
                        <input class="form-control" disabled formControlName="ClientPrice"  [(ngModel)]="agentCardDetails.price">
                    </div>
                    <!-- Company Cost-->
                    <!-- <div class="col-lg-3 mb-3">
                        <label>{{'Common.CompanyCost'|translate}}:</label>
                        <input class="form-control" disabled formControlName="CompanyCost" [(ngModel)]="agentCardDetails.cost">
                    </div> -->
                    <!-- Available Quantity-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.AvailableQuantity'|translate}}:</label>
                        <input class="form-control" disabled formControlName="Available"  [(ngModel)]="agentCardDetails.availQuant">
                    </div>
                    <!-- Quantity-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.Amount'|translate}}:</label>
                        <input class="form-control" type="number" min="1" [max]="agentCardDetails.availQuant" formControlName="quantity" (input)="setTotalPrice($event)">
                    </div>
                    <!-- Total-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.Total'|translate}}:</label>
                        <input class="form-control" disabled formControlName="Total">
                    </div>

                    <!-- Client Balance-->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.Balance'|translate}}:</label>
                        <input class="form-control" disabled formControlName="TotalBalance" [(ngModel)]="agentCardDetails.ClientBalance">
                    </div>

                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" [disabled]="form.invalid" (click)="confirmAlertBeforeInsert()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Add'|translate}}
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>


<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-inserted-orders-table [datasource]="datasource"></app-inserted-orders-table>
    
    <!-- Pagination -->
    <!-- <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator> -->
</div>