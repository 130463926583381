<!-- Export Excel case of details in transactions only (request page case is the in the requests component)-->
<div *ngIf="!showConfirmDeclineColumn" class="px-5 ">
  <app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>
</div>

<!-- Table -->
<div class="table-responsive mb-0" [ngClass]="showConfirmDeclineColumn ? '' : 'px-5 py-2 scroll-table'">
  <table class="table table-centered">
    <thead class="table-light">
      <tr>
        <th class="text-center" style="width: 120px;">{{ 'Common.RequestDate' | translate}}</th>
        <th class="text-center">{{ 'Common.Amount2' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestorName' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestorPhone' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestExecuterName' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestExecuterPhone' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestType' | translate}}</th>
        <th class="text-center">{{ 'Common.RequestStatus' | translate}}</th>
        <th class="text-center">{{ 'Common.PaymentType' | translate}}</th>
        <th class="text-center">{{ 'Common.Note' | translate}}</th>
        <th class="text-center">{{ 'Common.TransactionPhoto' | translate}}</th>
        <th class="text-center" *ngIf="showConfirmDeclineColumn">{{ 'Common.Approve' | translate}} / {{ 'Common.Decline' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datasource">
        <td class="text-center" style="width: 120px;">{{ data.CreateDate | date: "yyyy-MM-dd"}}</td>
        <td class="text-center">{{ data.Amount }}</td>
        <td class="text-center">{{ data.RequestorUser.UserFullName }}</td>
        <td class="text-center">{{ data.RequestorUser.UserName }}</td>
        <td class="text-center">{{ data.ApprovalUser.UserFullName }}</td>
        <td class="text-center">{{ data.ApprovalUser.UserName }}</td>
        <td class="text-center">{{ requestType[data.RequestType]}}</td>
        <td class="text-center">{{ requestStatus[data.RequestStatus] }}</td>
        <td class="text-center">{{ data.RequestPaymentType ? requestPaymentType[data.RequestPaymentType] : '-' }}</td>
        <td class="text-center">{{ data.Note ? data.Note : '-' }}</td>
        <td class="text-center">
          <button class="btn btn-primary" *ngIf="data.PaymentAttachment" (click)="showPaymentAttachment(data)">{{ 'Common.Show' | translate}}</button>
          <span *ngIf="!data.PaymentAttachment"> - </span>
        </td>
        <td class="text-center" *ngIf="showConfirmDeclineColumn">
          <div class="d-flex flex-column" *ngIf="data.CanApprove && data.RequestStatus==requestStatus.Pendding ">
              <!-- Can approve or reject if data we get has the the CanApprove filed true -->
              <button *ngIf="data.CanApprove" class="btn btn-success mb-3" (click)="show('2',data)">{{ 'Common.Approve' | translate}}</button>
              <button *ngIf="data.CanApprove" class="btn btn-danger" (click)="show('3',data)">{{ 'Common.Decline' | translate}}</button>
          </div>
          <span *ngIf="!data.CanApprove"> - </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
