<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light table-nowrap">
        <tr>
          <th class="align-middle">{{'HALA.JobApplication.FullName'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.Nationality'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.ForeignerNationality'| translate}} </th>
          <th class="align-middle">{{'HALA.JobApplication.Id1'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.PhoneNumber'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.ResidenceValidity'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.ResidenceJob'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.JobName'| translate}}</th>
          <th class="align-middle">{{'Common.Email'| translate}}</th>
          <th class="align-middle">{{'Common.Address'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.LastCompany'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.QuitReason'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.ResponsibleNamePhone'| translate}}</th>
          <th class="align-middle">{{'HALA.JobApplication.Attachments'| translate}}</th>
          <!-- <th class="align-middle" *ngIf="permissionService.checkPermission(['HalaDataUpdate.Update'])">{{'Common.Edit'| translate}}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.FullName}}</a> </td>
          <!-- <td>{{data.CreateDate | date: dateFormatService.dateOnlyFormat}}</td> -->
          <td>
            <span *ngIf="data.NationalityType==1"> {{'HALA.JobApplication.Saudi'| translate}}</span>
            <span *ngIf="data.NationalityType==2"> {{'HALA.JobApplication.Foreigner'| translate}} </span>
          </td>
          <td>{{data.ForeignerNationality}}</td>
          <td> {{data.IdentityId}} </td>
          <td>{{data.PhoneNumber}}</td>
          <td>{{data.ResidenceValidity}}</td>
          <td>{{data.ResidenceJob}}</td>
          <td>{{data.JobTitle}}</td>
          <td>{{data.Email}}</td>
          <td>{{data.CurrentAdress}}</td>
          <td>{{data.LastCompany}}</td>
          <td>{{data.ReasonLeftCompany}}</td>
          <td>{{data.NameAndNumberForResponsable}}</td>
          <td>
            <button (click)="downloadAttachment(data.Attachment)" class="btn btn-success">
                <i class="fas fa-download" role="button"></i>
            </button>
          </td>
          <!-- <td style="text-align: center;" *ngIf="permissionService.checkPermission(['HalaDataUpdate.Update'])">
            <i class='mdi mdi-pencil font-size-18 text-success' role="button" (click)="openEditDialog(data)"></i>
          </td> -->
        </tr>
      </tbody>
    </table>
</div>