import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RequestPaymentType } from 'src/app/core/enum/requestPaymentType';
import { RequestStatus } from 'src/app/core/enum/requestStatus';
import { RequestType } from 'src/app/core/enum/requestType';
import { UserType } from 'src/app/core/enum/userType';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { SadadServicesService } from 'src/app/core/services/sadad/sadad-services.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-get-sadad-balance',
  templateUrl: './get-sadad-balance.component.html',
  styleUrls: ['./get-sadad-balance.component.scss']
})
export class GetSadadBalanceComponent implements OnInit {

  addform: FormGroup;
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;
   
  //enums
  requestStatus = RequestStatus;
  requestType = RequestType;
  requestPaymentType = RequestPaymentType;
  userType = UserType;

  @ViewChild("allCustomersDropDown") allCustomersDropDown;

  isLoading:boolean = false;
  isLoadingSearch:boolean = false;
  isExporting:boolean = false;

  showRequestPaymentType:boolean = false

  constructor(
    private fb: FormBuilder,
    private sadadServicesService:SadadServicesService,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    // this.initAddForm();
    this.initForm();
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body ={
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    }
    this.sadadServicesService.GetSadadBalance(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "التاريخ": this.datePipe.transform(data[i].trans_date, this.dateFormatService.dateOnlyFormat),
              "رقم الحساب": data[i].account_id,
              "الموزع": data[i].dest_account_id,
              "المندوب": data[i].agent_account_id,
              "رقم الشركة": data[i].company_no,
              "اجمالى سعر النقطه": data[i].SumPointPrice,
              "الرصيد": data[i].balance,
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "المسحوب": Number.parseFloat(result.Data[0].TotalDebitAmount).toFixed(2),
          //   "المضاف": Number.parseFloat(result.Data[0].TotalCreditAmount).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  
  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }

  /*------------------------ Form functions ------------------------ */

  initForm(){
    this.form = this.fb.group({
      dateFrom: new FormControl(""),
      dateTo: new FormControl(""),
    });
  }

  // get addf() {
  //   return this.addform.controls;
  // }

  get f() {
    return this.form.controls;
  }

  /*------------------------ API Requests ------------------------ */

  getSadadBalance() {
    this.spinner.show()
    this.isLoadingSearch = true
    let body={
      pageNumber:this.pageNumber,
      pageSize:this.pageSize,
      isPaginated: true,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom
    };

    return this.sadadServicesService.GetSadadBalance(body).subscribe(
      (result) => {
        this.spinner.hide()
        if(result.IsSuccess && result.Data.length){
          this.isLoadingSearch = false
          this.datasource = result.Data;
          this.totalCount = result.Data.TotalCount
        }else{
          this.datasource = []
          this.totalCount = 0
          this.pageNumber =1;
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
       
        // this.ref.detectChanges();
      },(err)=>{
        this.spinner.hide()
        console.log("err",err)
        this.toastr.error(err.message, "",{
          positionClass: 'toast-bottom-left',
        });
        this.isLoadingSearch = false;
        // this.ref.detectChanges();
      }
    );
  }

  /*------------------------ Search ------------------------ */
  search(){
    this.pageNumber = 1;
    this.datasource = [];
    this.getSadadBalance();
  }

  reset(){
    this.initForm()
    this.allCustomersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset();
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.datasource = []
    this.getSadadBalance()
  }

  /*------------------------ Pagination ------------------------ */
  changePage(event){
    this.pageNumber = event;
    this.getSadadBalance();
  }

}
