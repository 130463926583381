import { Injectable, signal } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class NavService {

  isMobileView$ = signal(false);

  constructor() {}
}
