import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PosScratchReportRoutingModule } from './pos-scratch-report-routing.module';
import { GetPosScratchDataComponent } from './get-pos-scratch-data/get-pos-scratch-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { ScratchDataTableComponent } from './get-pos-scratch-data/_components/scratch-data-table/scratch-data-table.component';


@NgModule({
  declarations: [
    GetPosScratchDataComponent,
    ScratchDataTableComponent
  ],
  imports: [
    CommonModule,
    PosScratchReportRoutingModule,
    CoreModule
  ]
})
export class PosScratchReportModule { }
