import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThMoveBlanaceBetweenAccountsService } from 'src/app/core/services/thuraya/th-move-blanace-between-accounts.service';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import Swal from 'sweetalert2';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-balance-transfer-dialog',
  templateUrl: './balance-transfer-dialog.component.html',
  styleUrls: ['./balance-transfer-dialog.component.scss']
})
export class BalanceTransferDialogComponent implements OnInit {
  
  //Form variables
  form: FormGroup;

  //dropdown variables
  customersList:any = []
  public customerFields =  { text: 'customer_name', value: 'customer_id' };
  public value: string = '';
  public height: string = '400px';

  constructor(
    private fb: FormBuilder,
    private modalService:NgbModal,
    private thMoveBlanaceBetweenAccountsService:ThMoveBlanaceBetweenAccountsService,
    private msgTranslateService:MessageTranslateService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.initForm();
    this.getAllCustomerAccountData();
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      customerId: new FormControl("",Validators.required),
      credit_amount: new FormControl("",Validators.required),
      trans_note: new FormControl("",Validators.required),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAllCustomerAccountData(){
    let body = {
      isPaginated: false,
      address: "",
    }

    this.thMoveBlanaceBetweenAccountsService.GetAllCustomerAccountData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.customersList = result.Data;
        }
    })
  }

  moveBalanceBetweenAccounts(){
    //Check fields 
    if (this.form.invalid || this.f.credit_amount.value==0){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
  
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.InsertPin'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      inputValidator:(value) => {
        if (!value || isNaN(parseInt(value)) ) {
          return this.msgTranslateService.msgTranslate('SwalMessages.PinReq')
        }
      },
      preConfirm: (pin) => {
        let body = {
          customerId: this.f.customerId.value,
          credit_amount: this.f.credit_amount.value.toString(),
          trans_note: this.f.trans_note.value,
          userPIN: pin
        };
        this.thMoveBlanaceBetweenAccountsService.MoveBalanceBetweenAccounts(body).subscribe(
          (result) => {
          if(result.IsSuccess){
            this.modalService.dismissAll();
            this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccAdd'), "", {
              positionClass: 'toast-bottom-left',
            });
          }else{
            this.toastr.error(result.Data, "", {
              positionClass: 'toast-bottom-left',
            });
          }
        },(err)=>{
          this.toastr.error(err.error.Message, "", {
            positionClass: 'toast-bottom-left',
          });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  /*----------------------Close Dialog---------------------- */
  discard(){
    this.modalService.dismissAll()
  }

  /*----------------------Filtering event ng dropdown---------------------- */
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    let predicateQuery = query.where(new Predicate('customer_name', 'contains', e.text, true).or('customer_id', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.customersList, query);
  };
  

}
