import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { isArray } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  //Authed user roles and permissions 
  userPermissions:any;
  userRoles:any;

  constructor() { }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  setPermissionsFromToken(){
    let userToken = localStorage.getItem("defaultAppToken");
    if(userToken){
      const tokenInfo = this.getDecodedAccessToken(userToken); // decode token
     //Check first user has any role
    if(tokenInfo.role){
      this.userRoles = tokenInfo.role;
      //only if he has roles he has permissions
      this.userPermissions = tokenInfo.Permission;
    }
      }
    
  }

  getUserPermssions(){
    return this.userPermissions;
  }

  getUserRoles(){
    return this.userRoles;
  }

  checkRole(roles){
    // if no roles required then return true
    if(roles==undefined || roles.length==0 ){
      return true
    }
   //check if user has any rules first
   if(this.userRoles){
    if(Array.isArray(this.userRoles)){//Then check if has multiple roles
      //check userroles contianis any of the roles sent
      const found = this.userRoles.some(r=> roles.indexOf(r) >= 0)
      if(found) return true 
    }else{
      // has single role
      const found = roles.findIndex(r => r==this.userRoles)
      if(found>=0) return true;
    }
   }
   return false;
  }

  //Check User Permissions for every view
  checkPermission(permissions){
    // if no permissions required then return true
    if(permissions==undefined || permissions.length==0 ){
      return true
    }
    //Check first permissions is defined (not defined in case of no roles)
    if(this.userPermissions){
      //Then check if has multiple permissions
      if(Array.isArray(this.userPermissions)){
        //check userpermissions contianis any of the permissions sent
        const found = this.userPermissions.some(p=> permissions.indexOf(p) >= 0)
        if(found) return true 
      }else{
        // has single permission
        const found = permissions.findIndex(p => p==this.userPermissions)
        if(found>=0) return true;
      }
     }
     return false;
  }

  //Check Routes Roles and permissions
  checkRouteRole(component){
    let routeRoles = component.data.roles
    // if no roles required for the page then return true
    if(routeRoles==undefined || routeRoles.length==0 ){
      return true
    }
    //else check the role
    //check if user has any rules first
    if(this.userRoles){
     if(Array.isArray(this.userRoles)){//Then check if has multiple roles
      //check userroles contianis any of the roles sent
      const found = this.userRoles.some(r=> routeRoles.indexOf(r) >= 0)
      if(found) return true 
     }else{
      // has single role
      const found = routeRoles.findIndex(r => r==this.userRoles)
      if(found>=0) return true;
     }
    }
    return false;
  }
  
  checkRoutePermissions(component){ 
    let routePermissions = component.data.permission  
    // if no permission required for the page then return true
    if(routePermissions==undefined || routePermissions.length==0 ){
      return true
    }
    //else check the permission
    
    //Check first permissions is defined (not defined in case of no roles)
    if(this.userPermissions){
      //Then check if has multiple permissions
      if(Array.isArray(this.userPermissions)){
        //check userpermissions contianis any of the permissions sent
        const found = this.userPermissions.some(p=> routePermissions.indexOf(p) >= 0)
        if(found) return true 
        
      }else{
        // has single permission
        const found = routePermissions.findIndex(p => p==this.userPermissions)
        // console.log("found222",found)
        if(found>=0) return true;
      }
     }
     return false;
  }
}
