import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DistributorPagesRoutingModule } from './distributor-pages-routing.module';
import { GetDistributorDataComponent } from './get-distributor-data/get-distributor-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { DistTableComponent } from './get-distributor-data/dist-table/dist-table.component';


@NgModule({
  declarations: [
    GetDistributorDataComponent,
    DistTableComponent,
  ],
  imports: [
    CommonModule,
    DistributorPagesRoutingModule,
    CoreModule
  ]
})
export class DistributorPagesModule { }
