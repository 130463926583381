
<!-- Card -->
<div class="row">
    <div class="col-xl-3 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!-- Filters -->
<div class="card card-custom gutter-b">
    <div class="card-body">
      <form class="form" [formGroup]="form">
        <div class="card-body">
          <div class="form-group row ">
            <!-- Date From Field -->
            <div class="col-lg-3">
              <label>{{'Common.DateFrom' | translate}}:</label>
              <app-date-selector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
            </div>
             <!-- Date To Field -->
            <div class="col-lg-3">
              <label>{{'Common.DateTo' | translate}}:</label>
              <app-date-selector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
            </div>
            <!-- Transaction ref Field -->
            <div class="col-lg-3">
              <label>{{'HALA.RecAmounts.TrxRef' | translate}}:</label>
              <input type="text" class="form-control" placeholder="" formControlName="trxNumber" />
            </div>
            <!-- Mobile Field -->
            <div class="col-lg-3">
              <label>{{'HALA.RecAmounts.MobileNumber' | translate}}:</label>
              <input type="text" class="form-control" placeholder="" formControlName="User_Phone" />
            </div>
            <!-- Thuraya number Field -->
            <div class="col-lg-3 mt-3">
              <label>{{'HALA.RecAmounts.ThurayaNumber' | translate}}:</label>
              <input type="text" class="form-control" placeholder="" formControlName="user_Id" />
            </div>
            <!-- <div class="col-lg-3 mt-3">
              <label class="custom-labels"> {{'HALA.RecAmounts.UserId' | translate}} :</label> -->
              <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="userscomboName" (getData)="getData($event)" (updateSharedData)="updateSharedData($event)" (changeSelectedUser)="changeSelectedUser($event)"></app-shared-combo> -->
              <!-- <input type="text" class="form-control" placeholder="" formControlName="user_Id" />
          </div> -->
            <div class="col-lg-3 action-btns d-flex align-items-end">
              <button type="submit" (click)="submit()" class="btn btn-primary font-weight-bold px-10 mr-2">{{'Common.Search'|translate}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="dataSource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

<!-- Table -->
<div class="card py-3 px-3">
  <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
  <app-amounts-table [datasource]="dataSource"></app-amounts-table>
  <!-- Pagination -->
  <app-paginator [totalCount]="totalCount" [pageNumber]="pageNo" (changePage)="changePage($event)" ></app-paginator>
</div>
