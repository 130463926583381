import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetUsersComponent } from './get-users/get-users.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  { path: 'register', component: RegisterComponent,canActivate: [AuthGuard],  data: {permission: ['HalaAccounts.Create'], roles:[]}},
  { path: 'getusers', component: GetUsersComponent,canActivate: [AuthGuard],   data: {permission: ['HalaAccounts.View','HalaAccounts.Update'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
