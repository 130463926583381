import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-purchase-rate-table',
  templateUrl: './purchase-rate-table.component.html',
  styleUrls: ['./purchase-rate-table.component.scss']
})
export class PurchaseRateTableComponent implements OnInit {

  @Input() datasource;

  sumTotalCost:number = 0;
  sumTotalPrice:number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.sumTotalCost = this.datasource[0].SumTotalCost;
      this.sumTotalPrice = this.datasource[0].SumTotalPrice;
    }else{
      this.sumTotalCost = 0;
      this.sumTotalPrice = 0;
    }
}

}
