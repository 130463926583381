import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-game-invoice-table',
  templateUrl: './game-invoice-table.component.html',
  styleUrls: ['./game-invoice-table.component.scss']
})
export class GameInvoiceTableComponent implements OnInit {

  @Input() datasource;

  SumTotalClassPrice:number = 0
  SumTotalClassCost:number = 0
  SumTotalPaid:number = 0

  constructor(
    public dateFormatService:DateFormatService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumTotalClassPrice = this.datasource[0].SumTotalClassPrice;
      this.SumTotalClassCost = this.datasource[0].SumTotalClassCost;
      this.SumTotalPaid = this.datasource[0].SumTotalPaid;
    }else{
      this.SumTotalClassPrice = 0;
      this.SumTotalClassCost = 0;
      this.SumTotalPaid = 0;
    }
  }

}
