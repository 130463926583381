import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAllPurchasesDataComponent } from './get-all-purchases-data/get-all-purchases-data.component';

const routes: Routes = [
  { path: 'get-all-purchases-data', component: GetAllPurchasesDataComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbPurchases.View'], roles:[]} },
  { path: 'get-all-purchases-rate-data', component: GetAllPurchasesDataComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbPurchasesRatePerDay.View'], roles:[]} },
  { path: 'get-all-purchases-target-data', component: GetAllPurchasesDataComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbPurchasesTarget.View'], roles:[]} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchasesRoutingModule { }
