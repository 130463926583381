<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PurchaseReport.ProductNo'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PurchaseReport.ProductType'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PurchaseReport.ClassName'| translate}}</th>
          <th class="align-middle">{{'Common.TotalValue'| translate}} </th>
          <th class="align-middle">{{'Common.TotalCost'| translate}} </th>
          <th class="align-middle">{{'Common.Amount'| translate}} </th>
          <th class="align-middle"> {{'REPORTS.PurchaseReport.UnitCostRate'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.product_id}}</td>
          <td>{{data.cardType}}</td>
          <td>{{data.ClassName}}</td>
          <td>{{data.TotalPrice}}</td>
          <td>{{data.TotalCost}}</td>
          <td>{{data.Amount}}</td>
          <td>{{data.AvgCost}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalValue'| translate}}</span> 
              <span>{{totalAmount==0 ? '-' :totalAmount | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalCost'| translate}}</span> 
              <span>{{totalCost==0 ? '-' :totalCost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Amount'| translate}}</span> 
              <span>{{amount==0 ? '-' :amount | number : '1.0-2'}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>