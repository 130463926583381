import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { RegisterComponent } from './register/register.component';
import { CoreModule } from 'src/app/core/core.module';
import { GetUsersComponent } from './get-users/get-users.component';
import { UsersTableComponent } from './get-users/_components/users-table/users-table.component';
import { EditRolesDialogComponent } from './get-users/_components/edit-roles-dialog/edit-roles-dialog.component';
import { EditDataDialogComponent } from './get-users/_components/edit-data-dialog/edit-data-dialog.component';


@NgModule({
  declarations: [
    RegisterComponent,
    GetUsersComponent,
    UsersTableComponent,
    EditRolesDialogComponent,
    EditDataDialogComponent
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    CoreModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountsModule { }
