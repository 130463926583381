import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThTrxRefundService } from 'src/app/core/services/thuraya/th-trx-refund.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-refunds',
  templateUrl: './get-refunds.component.html',
  styleUrls: ['./get-refunds.component.scss']
})
export class GetRefundsComponent implements OnInit {

  //Form variables
  form: FormGroup;
  
  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Export Variable for loading
  isExporting:boolean = false;
  
  private subscriptions: Subscription[] = [];

 
  constructor( 
    private fb: FormBuilder,
    private thTrxRefundService:ThTrxRefundService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true;
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      card_serial: this.f.card_serial.value
    }
    const subscr = this.thTrxRefundService.GetAllTrxRefundData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          let finalSheet={};
          let sumCost = 0;
          for (let i = 0; i < data.length; i++) {    
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "رقم المنتج": data[i].product_id,
              "اسم الكرت": data[i].class_name,
              "السريال":data[i].card_serial,
              "الخدش":data[i].card_scratch,
              "التكلفه":data[i].class_cost,
              "رقم المرجع":data[i].txref,
              "الحاله":data[i].ResponseStatus,
              "الرساله":data[i].ResponseMessage,
            }
            this.excelService.addToSheet(sheet);
            sumCost = Number.parseFloat(data[0].SumTotalCost)
          }
          let total={ 
            "التكلفه":  sumCost.toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      card_serial: new FormControl(""),
    });
  }
  
  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getAllTrxRefundData() {
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      card_serial: this.f.card_serial.value
    }
    return this.thTrxRefundService.GetAllTrxRefundData(body).subscribe((result) => {
      if(result.IsSuccess && result.Data.length>0){
        this.datasource = result.Data;
        this.totalCount = result.Data[0].TotlaRows
      }
      if(result.Data.length==0){
        this.datasource = []
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.spinner.hide(); 
    },(err)=>{
      this.spinner.hide(); 
    }
    );
  }

  showRefundnBox(data){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate('SwalMessages.Return'),
      icon:"info",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranslateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranslateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.refundThisCard(data);
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  refundThisCard(data){
    return this.thTrxRefundService.RefundThisCard(data.id).subscribe((result) => {
      if(result.IsSuccess && result.Data[0].ResponseStatus=="200"){
        this.toastr.success(this.msgTranslateService.msgTranslate(result.Data[0].ResponseMessage), "", {
          positionClass: 'toast-bottom-left',
        });
        this.updateTableAfterRefund(data.id)
      }else{
        this.toastr.error(this.msgTranslateService.msgTranslate(result.Data[0].ResponseMessage), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    });
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getAllTrxRefundData();
  }

  /*----------------------Search and reset search functions---------------------- */
  search(){
  //reset page number
   this.getAllTrxRefundData() 
  }

  /*----------------------Update table after refund---------------------- */
  updateTableAfterRefund(id){
    let index = this.datasource.findIndex(d=>d.id == id)
    this.datasource[index].ResponseStatus = "200"
    this.datasource[index].ResponseMessage = "Transaction successful"
  }

  reset(){
    this.initForm()
    this.pageNumber = 1
    this.getAllTrxRefundData()
  }
  

}
