<!-- Card (only for purchase report page case)-->
<div class="row" *ngIf="IsPurchaseReportComponent">
    <div class="col-xl-4 col-6" *ngFor="let info of infoList">
        <app-info-card [headerText]="info.headerText" [icon]="info.icon" [iconColor]="info.iconColor" [amount]="info.amount" [endText]="info.endText"></app-info-card>
    </div>
</div>

<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3 mb-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Service (single select in case report and rate page)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsPurchaseReportComponent || IsPurcaseRateComponent">
                        <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                    </div>
                    <!-- Service (multi select in case target page)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsPurchaseTargetComponent">
                        <app-services-multiselect #serviceMultiSelect (addToList)="addToServicesList($event)" (removeFromList)="removeFromServicesList($event)"></app-services-multiselect>
                    </div>
                    <!-- Card Name (single select in case report and rate page)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsPurchaseReportComponent || IsPurcaseRateComponent">
                        <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                        <select formControlName="class_no" class="form-select">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                            [{{ item.class_no }}] - {{ item.class_name }}
                            </option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div>
                    <!-- Card Name (multi select in case target page)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsPurchaseTargetComponent">
                        <app-class-multiselect #classMultiSelect (addToList)="addToClassList($event)" (removeFromList)="removeFromClassList($event)"></app-class-multiselect>
                    </div> 
                    <!-- Type -->
                    <div class="col-lg-3 mb-3">
                        <label>{{IsPurchaseReportComponent ? ('REPORTS.PurchaseReport.ImportType'|translate): ('REPORTS.PurchaseRate.CardType'|translate) }}:</label>
                        <select formControlName="isOnline" class="form-control">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'Common.Choose'|translate}}--</option>
                            <option style="font-size: 16px !important" value="0"> {{'REPORTS.PurchaseReport.Offline'|translate}}</option>
                            <option style="font-size: 16px !important" value="1"> {{'REPORTS.PurchaseReport.Online'|translate}}</option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div>

                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export  *ngIf="!IsPurchaseTargetComponent" [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>


<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-purchase-table *ngIf="IsPurchaseReportComponent" [datasource]="datasource" [totalAmount]="infoList[0].amount[0]" [totalCost]="infoList[1].amount[0]" [amount]="infoList[2].amount[0]"></app-purchase-table>
    <app-purchase-rate-table *ngIf="IsPurcaseRateComponent" [datasource]="datasource"></app-purchase-rate-table>
    <app-purchase-target-table *ngIf="IsPurchaseTargetComponent" [dataSource]="datasource"> </app-purchase-target-table>
    <!-- Pagination -->
    <app-paginator *ngIf="!IsPurchaseTargetComponent" [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>