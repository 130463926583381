import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-edit-data-dialog',
  templateUrl: './edit-data-dialog.component.html',
  styleUrls: ['./edit-data-dialog.component.scss']
})
export class EditDataDialogComponent implements OnInit {

  @Input() userData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  oldUserData:any
  //Form variables
  form: FormGroup;
  
  constructor( 
    private fb: FormBuilder,
    private modalService:NgbModal,
    private halaAccountsService:HalaAccountsService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,) { }

  ngOnInit(): void {
    this.initForm();
    this.oldUserData = {...this.userData}
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      fullName:new FormControl("",[Validators.required, Validators.pattern('^[\u0621-\u064Aa-zA-Z0-9#?!@$%^&*-._ ]+$')]),
      phoneNumber: new FormControl("",[Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      email:new FormControl("",[Validators.required,Validators.email]),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Discard---------------------- */
  cancelChanges(){
    this.modalService.dismissAll()
  }

  /*----------------------Form Functions---------------------- */
  saveChanges(){
    //Check fields 
    if (this.form.invalid){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body={
      userId: this.oldUserData.Id,
      fullName: this.f.fullName.value,
      email: this.f.email.value,
      phoneNumber: this.f.phoneNumber.value
    }
    return this.halaAccountsService.RegisterOrUpdate(body).subscribe(
      (result) => {
        if(result.IsSuccess){
          this.updateParent.emit(body)
          this.modalService.dismissAll();
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      err => {
        this.modalService.dismissAll()
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

}
