<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
            <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
            <th class="align-middle">{{'Common.ClassNo'| translate}}</th>
            <th class="align-middle">{{'Common.CardName'| translate}}</th>
            <th class="align-middle">{{'Common.ClassPrice'| translate}}</th>
            <th class="align-middle">{{'Common.ClassCost'| translate}}</th>
            <th class="align-middle">{{'Common.TotalPaid'| translate}}</th>
            <th class="align-middle">{{'Common.CreationDate'| translate}}</th>
            <th class="align-middle">{{'Common.CreateUser'| translate}}</th>
            <th class="align-middle">{{'Common.ProductId'| translate}}</th>
            <th class="align-middle">{{'Common.OrdersNumber'| translate}}</th>
            <th class="align-middle">{{'Common.RetrivedNumber'| translate}}</th>
            <th class="align-middle">{{'Common.ProviderId'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.serviceId}}</a>
          </td>
          <td>{{data.classNo}}</td>
          <td>{{data.className}}</td>
          <td>{{data.classPrice}}</td>
          <td>{{data.classCost}}</td>
          <td>{{data.totalPaid}}</td>
          <td>{{data.createDate | date: dateFormatService.dateFormat}}</td>
          <td>{{data.createUser}}</td>
          <td>{{data.productId}}</td>
          <td>{{data.orderCount}}</td>
          <td>{{data.retrivedCount}}</td>
          <td>{{data.provider}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalPaid==0 ? '-' :SumTotalPaid | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalClassPrice==0 ? '-' :SumTotalClassPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{SumTotalClassCost==0 ? '-' :SumTotalClassCost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
</div>