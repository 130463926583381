<div class="px-2 py-2">
 <!-- Filters Row-->
 <form class="form" [formGroup]="form">
    <div class="card-body">
        <!-- First Row -->
        <div class="form-group row">
            <!-- Clients under branch-->
            <div class="col-lg-12 mb-4">
                <label>{{'Common.ClientName'|translate}} :</label>
                <ejs-dropdownlist showClearButton="true" formControlName="customerId" id='' [value]='value' [fields]='customerFields'
                [popupHeight]='height' [dataSource]='customersList'
                [allowFiltering]='customersList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                filterBarPlaceholder='{{"Common.Search"|translate}}' 
                (filtering)='onFiltering($event)'>
                </ejs-dropdownlist>                                                   
            </div>

            <div class="col-lg-12 mb-4">
                <label>{{'Common.AddAmount'|translate}} :</label>
                <input type="number" class="form-control" formControlName="credit_amount" min="1" >                         
            </div>

            <div class="col-lg-12 mb-4">
                <label>{{'Common.Notice'|translate}} :</label>
                <input  type="text" class="form-control" formControlName="trans_note" >
                                                                  
            </div>
            <!-- Action Buttons -->
            <div class="col-lg-2 mb-3 d-flex align-items-end justify-content-end w-100">
                <div class="action-btns" style="margin-inline-end: 20px;">
                    <button type="submit" (click)="moveBalanceBetweenAccounts()" [disabled]="form.invalid"
                    class="btn btn-primary font-weight-bold px-10 mr-2">
                        {{'Common.Add'|translate}}
                    </button>
                </div>
                <div class="action-btns">
                    <button (click)="discard()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                        {{'Common.Discard'|translate}}
                    </button>
                </div>
            </div>
        </div>

    </div>
</form>
</div>