import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() totalCount;
  @Input() pageNumber;
  @Output() changePage = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  change(event){
    this.changePage.emit(event)

  }

}
