import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-company-payment-table',
  templateUrl: './company-payment-table.component.html',
  styleUrls: ['./company-payment-table.component.scss']
})
export class CompanyPaymentTableComponent implements OnInit {

  @Input() datasource;
  @Input() IsCompanyPaymentsPage
  @Input() IsBalanceTransferPage
  @Input() IsCompanyInvoicePage
  TotalCreditAmount:number = 0;
  TotalDebitAmount:number = 0;
 
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.TotalCreditAmount = this.datasource[0].TotalCreditAmount;
      this.TotalDebitAmount = this.datasource[0].TotalDebitAmount;
    }else{
      this.TotalCreditAmount = 0;
      this.TotalDebitAmount = 0
    }
  }

}
