import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThBulkOrderCardService } from 'src/app/core/services/thuraya/th-bulk-order-card.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import { AuthenticationService } from 'src/app/core/services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ThProfitUserService } from 'src/app/core/services/thuraya/th-profit-user.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ThMoveBlanaceBetweenAccountsService } from 'src/app/core/services/thuraya/th-move-blanace-between-accounts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profit-user-data',
  templateUrl: './profit-user-data.component.html',
  styleUrls: ['./profit-user-data.component.scss']
})
export class ProfitUserDataComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR All Profit User pages 
  IsCompanyPage:boolean = false;
  IsCompanyCardPage:boolean = false;
  IsCompanyClientPage:boolean = false;
  IsCompanyCardClientPage:boolean = false;
  IsCompanyPaymentsPage:boolean = false;
  IsClientPage:boolean = false;
  IsBalanceTransferPage:boolean = false;
  IsCompanyInvoicePage:boolean = false;
 
  //Form variables
  form: FormGroup;

  authUser = ""

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;
 
  //dropdown lists variables
  ClassNameList:any = []

  @ViewChild("servicesDropDown") servicesDropDown;

  //dropdown variables
  agentText:string = "-"
  posDataList:any = []
  public posFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };
  public value: string = '';
  public height: string = '400px';
  branchesList:any = []


  isExporting:boolean = false;
  isPrinting:boolean = false;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thMoveBlanaceBetweenAccountsService:ThMoveBlanaceBetweenAccountsService,
    private thProfitUserService:ThProfitUserService,
    private authService:AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelService:ExcelService,
  ) { 
    //[IMPORTANT] This component IS USED FOR APPROVE AND REPORT 
    // console.log("route",this.router.url)
    if(this.router.url=="/thuraya/profit-user/profit-user-company"){
      this.resetRoute();
      this.IsCompanyPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-company-card"){
      this.resetRoute();
      this.IsCompanyCardPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-company-client"){
      this.resetRoute();
      this.IsCompanyClientPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-company-card-client"){
      this.resetRoute();
      this.IsCompanyCardClientPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-company-payments"){
      this.resetRoute();
      this.IsCompanyPaymentsPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-balance-transfers"){
      this.resetRoute();
      this.IsBalanceTransferPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-client"){
      this.resetRoute();
      this.IsClientPage = true
    }else if(this.router.url=="/thuraya/profit-user/profit-user-company-invoice"){
      this.resetRoute();
      this.IsCompanyInvoicePage = true
    }
  }
  
  resetRoute(){
    this.IsCompanyPage = false;
    this.IsCompanyCardPage = false;
    this.IsCompanyClientPage = false;
    this.IsCompanyCardClientPage = false;
    this.IsCompanyPaymentsPage = false;
    this.IsBalanceTransferPage = false;
    this.IsClientPage = false;
    this.IsCompanyInvoicePage = false;
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllPosByDistributer();
    this.getProfitUserBranches();
    // console.log(this.authService.authUser);
    this.authUser = `${this.authService.authUser.PhoneNumber}_${this.authService.authUser.FullName}`
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body = {
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      address:this.f.address.value,
      accountId: this.authService.authUser.PhoneNumber,
      endPointId: this.f.endPointId.value,
      service_id: this.f.ServiceId.value,
      note:this.f.class_name.value,
      timeFrom: this.f.timeFrom.value,
      timeTo: this.f.timeTo.value,
    }
    if(this.IsCompanyPage){
      this.exportExcelCompany(body)
    }else if(this.IsCompanyCardPage){
      this.exportExcelCompanyCard(body)
    }
    else if(this.IsCompanyClientPage){
      this.exportExcelCompanyClient(body)
    }
    else if(this.IsCompanyCardClientPage){
      this.exportExcelCompanyCardClient(body)
    }
    else if(this.IsCompanyPaymentsPage){
      this.exportExcelCompanyPayments(body)
    }
    else if(this.IsBalanceTransferPage){
      this.exportExcelBalanceTransfer(body)
    }
    else if(this.IsClientPage){
      this.exportExcelClient(body)
    }
    else if(this.IsCompanyInvoicePage){
      this.exportExcelCompanyInvoice(body)
    }
  }

  printReport(){
    let body = {
      isPaginated: false,
      dateFrom: this.fromDateCom,
      dateTo: this.toDateCom,
      address:this.f.address.value,
      accountId: this.authService.authUser.PhoneNumber,
      endPointId: this.f.endPointId.value,
      service_id: this.f.ServiceId.value,
      note:this.f.class_name.value,
      timeFrom: this.f.timeFrom.value,
      timeTo: this.f.timeTo.value,
    }

    if(this.IsCompanyPage){
      this.printReportCompany(body)
    }else if(this.IsCompanyCardPage){
      this.printReportCompanyCard(body)
    }
    else if(this.IsCompanyClientPage){
      this.printReportCompanyClient(body)
    }
    else if(this.IsCompanyCardClientPage){
      this.printReportCompanyCardClient(body)
    }
    else if(this.IsCompanyPaymentsPage){
      this.printReportCompanyPayments(body)
    }
    else if(this.IsBalanceTransferPage){
      this.printReportBalanceTransfer(body)
    }
    else if(this.IsClientPage){
      this.printReportClient(body)
    }
    else if(this.IsCompanyInvoicePage){
      this.printReportCompanyInvoice(body)
    }
  }

  exportExcelCompany(body){
    this.thProfitUserService.GetProfitUserCompanyData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "سعر نقط البيع": data[i].EndPointPrice,
              "ربح نقط البيع": data[i].EndPointProfit,
              "سعر الموزع": data[i].DistPrice,
              "ربح الموزع	": data[i].LastProfit,
              "العدد": data[i].rowsCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر نقط البيع": Number.parseFloat(result.Data.AllEndPointPrice).toFixed(2),
            "ربح نقط البيع": Number.parseFloat(result.Data.AllEndPointProfit).toFixed(2),
            "سعر الموزع": Number.parseFloat(result.Data.AllDistPrice).toFixed(2),
            "ربح الموزع	": Number.parseFloat(result.Data.AllLastProfit).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompany(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyData(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelCompanyCard(body){
    this.thProfitUserService.GetProfitUserCompanyCardData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "اسم الفئة": data[i].note,
              "سعر نقط البيع": data[i].EndPointPrice,
              "ربح نقط البيع": data[i].EndPointProfit,
              "سعر الموزع": data[i].DistPrice,
              "ربح الموزع	": data[i].LastProfit,
              "العدد": data[i].rowsCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر نقط البيع": Number.parseFloat(result.Data.AllEndPointPrice).toFixed(2),
            "ربح نقط البيع": Number.parseFloat(result.Data.AllEndPointProfit).toFixed(2),
            "سعر الموزع": Number.parseFloat(result.Data.AllDistPrice).toFixed(2),
            "ربح الموزع	": Number.parseFloat(result.Data.AllLastProfit).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompanyCard(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyCardData(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelCompanyClient(body){
    this.thProfitUserService.GetProfitUserCompanyClientData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "رقم العميل": data[i].account_id,
              "اسم العميل": data[i].UserName,
              "سعر نقط البيع": data[i].EndPointPrice,
              "ربح نقط البيع": data[i].EndPointProfit,
              "سعر الموزع": data[i].DistPrice,
              "ربح الموزع	": data[i].LastProfit,
              "العدد": data[i].rowsCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر نقط البيع": Number.parseFloat(result.Data.AllEndPointPrice).toFixed(2),
            "ربح نقط البيع": Number.parseFloat(result.Data.AllEndPointProfit).toFixed(2),
            "سعر الموزع": Number.parseFloat(result.Data.AllDistPrice).toFixed(2),
            "ربح الموزع	": Number.parseFloat(result.Data.AllLastProfit).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompanyClient(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyClientData(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelCompanyCardClient(body){
    this.thProfitUserService.GetProfitUserCompanyCardClientData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "مقدم الخدمة": data[i].service_id,
              "اسم الفئة": data[i].note,
              "رقم العميل": data[i].account_id,
              "اسم العميل": data[i].UserName,
              "سعر نقط البيع": data[i].EndPointPrice,
              "ربح نقط البيع": data[i].EndPointProfit,
              "سعر الموزع": data[i].DistPrice,
              "ربح الموزع	": data[i].LastProfit,
              "العدد": data[i].rowsCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر نقط البيع": Number.parseFloat(result.Data.AllEndPointPrice).toFixed(2),
            "ربح نقط البيع": Number.parseFloat(result.Data.AllEndPointProfit).toFixed(2),
            "سعر الموزع": Number.parseFloat(result.Data.AllDistPrice).toFixed(2),
            "ربح الموزع	": Number.parseFloat(result.Data.AllLastProfit).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompanyCardClient(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyCardClientData(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelClient(body){
    this.thProfitUserService.GetProfitUserClientData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم العميل": data[i].account_id,
              "اسم العميل": data[i].UserName,
              "سعر نقط البيع": data[i].EndPointPrice,
              "ربح نقط البيع": data[i].EndPointProfit,
              "سعر الموزع": data[i].DistPrice,
              "ربح الموزع	": data[i].LastProfit,
              "العدد": data[i].rowsCount,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "سعر نقط البيع": Number.parseFloat(result.Data.AllEndPointPrice).toFixed(2),
            "ربح نقط البيع": Number.parseFloat(result.Data.AllEndPointProfit).toFixed(2),
            "سعر الموزع": Number.parseFloat(result.Data.AllDistPrice).toFixed(2),
            "ربح الموزع	": Number.parseFloat(result.Data.AllLastProfit).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportClient(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserClientData(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelCompanyPayments(body){
    this.thProfitUserService.GetProfitUserCompanyPayments(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم العميل": data[i].account_id,
              "البيانات": data[i].trans_note              ,
              "رقم نقطه البيع": data[i].PosPhone,
              "اسم نقط البيع": data[i].PosName,
              "الفرع": data[i].address,
              "المضاف": data[i].credit_amount,
              "المسحوب": data[i].debit_amount,
              "التاريخ": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateOnlyFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "المضاف": Number.parseFloat(result.Data.TotalCreditAmount).toFixed(2),
            "المسحوب": Number.parseFloat(result.Data.TotalDebitAmount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompanyPayments(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyPayments(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelBalanceTransfer(body){
    this.thProfitUserService.GetProfitUserBalanceTransfers(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم العميل": data[i].account_id,
              "البيانات": data[i].trans_note              ,
              "رقم نقطه البيع": data[i].PosPhone,
              "اسم نقط البيع": data[i].PosName,
              "المضاف": data[i].credit_amount,
              "المسحوب": data[i].debit_amount,
              "التاريخ": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateOnlyFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "المضاف": Number.parseFloat(result.Data.TotalCreditAmount).toFixed(2),
            "المسحوب": Number.parseFloat(result.Data.TotalDebitAmount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportBalanceTransfer(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserBalanceTransfers(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  exportExcelCompanyInvoice(body){
    this.thProfitUserService.GetProfitUserCompanyInvoice(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.resultData.length){
          let data = result.Data.resultData
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم العميل": data[i].account_id,
              "البيانات": data[i].trans_note              ,
              "رقم نقطه البيع": data[i].PosPhone,
              "اسم نقط البيع": data[i].PosName,
              "المضاف": data[i].credit_amount,
              "التاريخ": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateOnlyFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "المضاف": Number.parseFloat(result.Data.TotalCreditAmount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  printReportCompanyInvoice(body){
    this.isPrinting = true;
    this.thProfitUserService.GetProfitUserCompanyInvoice(body).subscribe(
      (result)=>{
        this.isPrinting = false;
        this.openReportPage(result)
      },(err)=>{
        this.isPrinting = false;
      }
    )
  }

  openReportPage(result){
    let newWindow = window.open('print/print-profit-user-report','_blank');
        if(result.IsSuccess && result.Data.resultData.length>0){
          newWindow["result"] = result;
          newWindow["dateFrom"] = this.fromDateCom;
          newWindow["dateTo"] = this.toDateCom;
          newWindow["timeFrom"] = this.f.timeFrom.value;
          newWindow["timeTo"] = this.f.timeTo.value;
          newWindow["IsCompanyPage"] = this.IsCompanyPage;
          newWindow["IsCompanyCardPage"] = this.IsCompanyCardPage;
          newWindow["IsCompanyClientPage"] = this.IsCompanyClientPage;
          newWindow["IsCompanyCardClientPage"] = this.IsCompanyCardClientPage;
          newWindow["IsCompanyPaymentsPage"] = this.IsCompanyPaymentsPage;
          newWindow["IsClientPage"] = this.IsClientPage;
          newWindow["IsBalanceTransferPage"] = this.IsBalanceTransferPage;
          newWindow["IsCompanyInvoicePage"] = this.IsCompanyInvoicePage;
        }
  }
 
  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl(""),
      class_name: new FormControl(""),
      account_id: new FormControl(""),
      timeFrom: new FormControl(""),
      timeTo: new FormControl(""),
      endPointId: new FormControl(""),
      address: new FormControl(""),
    });
    //the account id is the user logged in so always set
    this.f.account_id.setValue(this.authUser)
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  getApiName(body){
    let api = null
    if(this.IsCompanyPage){
      api = this.thProfitUserService.GetProfitUserCompanyData(body)

    }else if(this.IsCompanyCardPage){
      api = this.thProfitUserService.GetProfitUserCompanyCardData(body)

    }else if(this.IsCompanyClientPage){
      api = this.thProfitUserService.GetProfitUserCompanyClientData(body)

    }else if(this.IsCompanyCardClientPage){
      api = this.thProfitUserService.GetProfitUserCompanyCardClientData(body)

    }else if(this.IsCompanyPaymentsPage){
      api = this.thProfitUserService.GetProfitUserCompanyPayments(body)

    }else if(this.IsBalanceTransferPage){
      api = this.thProfitUserService.GetProfitUserBalanceTransfers(body)

    }else if(this.IsClientPage){
      api = this.thProfitUserService.GetProfitUserClientData(body)
    }
    else if(this.IsCompanyInvoicePage){
      api = this.thProfitUserService.GetProfitUserCompanyInvoice(body)
    }
    return api
  }
  
  getData(){
    this.spinner.show()
    let api = null
    let body ={
      dateFrom: this.fromDateCom ? this.fromDateCom : "" ,
      dateTo: this.toDateCom ? this.toDateCom : "",
      pageNumber:this.pageNumber,
      pageSize:this.pageSize,
      isPaginated: true,
      address:this.f.address.value,
      accountId: this.authService.authUser.PhoneNumber,
      endPointId: this.f.endPointId.value,
      service_id: this.f.ServiceId.value,
      note:this.f.class_name.value,
      timeFrom: this.f.timeFrom.value,
      timeTo: this.f.timeTo.value,
    }
    //Api depend on which page we are
    api = this.getApiName(body);
    return api.subscribe((result) => {
      this.spinner.hide()
      if(result.IsSuccess && result.Data.resultData.length){
        this.datasource = result.Data.resultData;
        this.totalCount = result.Data.resultData[0].TotalRows
      }
      else{
        this.spinner.hide()
        this.datasource = []
        this.totalCount = 0;
      }
      },(err)=>{
        this.spinner.hide()
        this.datasource = []
        this.totalCount = 0;
      }
    );
  }

  // get pos under the auth user for dropdown select
  getAllPosByDistributer(){
    let body = {
      isPaginated: false,
      company_No: "",
      accountId: this.authService.authUser.PhoneNumber
    }

    this.thGeneralSystemService.GetAllPosByDistributer(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.posDataList = result.Data;
        }
    })
  }

  //get branches under the auth user for dropdown select
  getProfitUserBranches(){
    let body = {
      isPaginated: false,
      userPhoneNumber: this.authService.authUser.PhoneNumber
    }

    this.thMoveBlanaceBetweenAccountsService.GetProfitUserBranches(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.branchesList = result.Data;
        }
    })
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }


  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    // if(this.fromDateCom =="" || this.toDateCom ==""){
    //   Swal.fire({
    //     title: this.msgTranslateService.msgTranslate("SwalMessages.DateRequired"),
    //     icon: 'error',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false
    //   });
    // }
    // else if (this.toDateCom!="" && this.toDateTimeStamp < this.fromDateTimeStamp ) {
    //   Swal.fire({
    //     title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidToDate"),
    //     icon: 'error',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false
    //   });
    // }else{
    //  this.getMonthlyTarget()
    // } 
    this.getData();
    
  }

   /*----------------------Pagination---------------------- */
   changePage(event){
    this.pageNumber = event;
    this.getData();
  }

  reset(){
    this.initForm()
    //Some page does not have the sevice dropdown so check or else error
    this.servicesDropDown ? this.servicesDropDown.reset() : ""
    this.dateFromSelector.reset()
    this.dateToSelector.reset();
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    // this.getMonthlyTarget()
  }

  /*----------------------Getting class name for dropdown select---------------------- */
  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
  }

  /*----------------------Filtering event ng dropdown---------------------- */
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    
    let predicateQuery = query.where(new Predicate('CUSTOMER_NAME', 'contains', e.text, true).or('CUSTOMER_ID', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.posDataList, query);
  };
  
  /*----------------------when Choosing Agent---------------------- */
  setSelectedAgent(event){
    this.form.controls.account_id.setValue(event)
  }

  /*----------------------when Choosing service---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before
      this.form.controls.class_name.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_name.setValue("")
      this.ClassNameList = [];
    }
  }

}
