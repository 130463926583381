import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HalaCompaniesSettingsService } from 'src/app/core/services/hala/hala-companies-settings.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { ThPriceSettingsService } from 'src/app/core/services/thuraya/th-price-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss']
})
export class CopyDialogComponent implements OnInit {

  formCopyPriceSetting: FormGroup;

  //Dropdown variables
  AllGroupList: any[];
  serivceList: any;

  selectedPriceFrom:any[] = []
  allSelected:boolean = false
  allSelectedChecked:boolean = false

  @ViewChild("companiesNotInGroupDropDown") companiesNotInGroupDropDown;

  constructor(
    private fb: FormBuilder,
    private halaCompaniesSettingsService: HalaCompaniesSettingsService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thPriceSettingsService:ThPriceSettingsService,
    private modalService:NgbModal,
    private msgTranlsateService:MessageTranslateService,
    private toastr: ToastrService,
    private ref:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.GetAllServiceData();
    this.GetAllGroups()
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.formCopyPriceSetting = this.fb.group({
      compNoCopy: new FormControl("", Validators.required),
      // selectedPriceFrom: new FormArray([], Validators.required),
      groupId: new FormControl("", Validators.required),

    });
  }
  
  get f(){
    return this.formCopyPriceSetting.controls;
  }
  
  /*----------------------API REQUESTS---------------------- */
  GetAllGroups() {
    return this.halaCompaniesSettingsService.GetAllGroups().subscribe(
      (result) => {
        if(result.IsSuccess && result.Data.length){
          this.AllGroupList = result.Data
        }
    });
  }

  GetAllServiceData() {
    let body = {
      isPaginated:false
    }
    return this.thGeneralSystemService.GetServicesData(body).subscribe((result) => {
      this.serivceList = result.Data;
      
    });
  }
  
  /*----------------------When selecting services to copy price from---------------------- */
  onCheckboxChangeFrom(event: any) {
    if (event.target.checked) {
      this.selectedPriceFrom.push(event.target.value);
      //check if every item is selected then mark select all as checked
      if(this.selectedPriceFrom.length == this.serivceList.length ){
        this.allSelectedChecked = true
        this.allSelected = true
      }
      // console.log("add",this.selectedPriceFrom)
    } else {
      //when any item is unselected uncheck selectAll
      this.allSelectedChecked = false;
      let foundIndex:any = this.selectedPriceFrom.findIndex(s => s == event.target.value)
      this.selectedPriceFrom.splice(foundIndex,1);
      // console.log("delete",this.selectedPriceFrom)
    }
    
  }

  /*----------------------When selecting All---------------------- */
  selectDeselectAll(event){
    //empty list first in case some were added manually to not duplicate in list
    this.selectedPriceFrom = []
    this.allSelected = false
    this.ref.detectChanges()

    if(event.target.checked){
      for (let i = 0; i < this.serivceList.length; i++) {
        this.selectedPriceFrom.push(this.serivceList[i].service_id)
      }
      this.allSelected = true
      this.allSelectedChecked = true
      // console.log("selectall",this.selectedPriceFrom)
    }else{
      this.allSelectedChecked = false
      // console.log("deselect all",this.selectedPriceFrom)
    }
  }

  saveData() {
    //Check fields 
    if (this.formCopyPriceSetting.invalid || this.selectedPriceFrom.length==0){
      Swal.fire({
        title: this.msgTranlsateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    let body = {
      compNoCopy: this.f.compNoCopy.value,
      selectedPriceFrom: this.selectedPriceFrom,
      groupId: this.f.groupId.value
    }
    // console.log(body);
    this.thPriceSettingsService.CopyPriceFromTo(body).subscribe((result) => {
      if (result.IsSuccess == true) {
        this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccCopy'), "",{
          positionClass: 'toast-bottom-left',
        });
      } else {
        this.toastr.error(result.Data, "",{
          positionClass: 'toast-bottom-left',
        });
      }
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.title, "",{
        positionClass: 'toast-bottom-left',
      });
    }
    );
  }

  reset(){
    this.initForm()
    this.companiesNotInGroupDropDown.reset()
    this.AllGroupList=[];
    this.serivceList = [];
    this.modalService.dismissAll();
  }

  /*----------------------when Choosing Company---------------------- */
  setSelectedCompany(event){
    this.formCopyPriceSetting.controls.compNoCopy.setValue(event)
  }

}
