import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetInsertedOrderDataComponent } from './get-inserted-order-data/get-inserted-order-data.component';

const routes: Routes = [
  { path: 'get-bulk-order-cards-data', component: GetInsertedOrderDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbBulkOrderCard.Create'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BulkOrderCardRoutingModule { }
