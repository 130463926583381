import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThTrxRefundService } from 'src/app/core/services/thuraya/th-trx-refund.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-refunds-table',
  templateUrl: './refunds-table.component.html',
  styleUrls: ['./refunds-table.component.scss']
})
export class RefundsTableComponent implements OnInit {

  @Input() datasource;
  @Output() returnCard = new EventEmitter<any>();

  SumTotalCost:number = 0;


  constructor(
    public permissionService: PermissionService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.SumTotalCost = this.datasource[0].SumTotalCost;
    }else{
      this.SumTotalCost = 0;
    }
  }

  refud(data){
    this.returnCard.emit(data)
  }

}
