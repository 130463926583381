import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountReportRoutingModule } from './account-report-routing.module';
import { GetAccountBillDataComponent } from './get-account-bill-data/get-account-bill-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { BillModule } from '../../big-data/bill/bill.module';


@NgModule({
  declarations: [
    GetAccountBillDataComponent
  ],
  imports: [
    CommonModule,
    AccountReportRoutingModule,
    CoreModule,
    BillModule
  ]
})
export class AccountReportModule { }
