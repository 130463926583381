import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThBulkOrderCardService } from 'src/app/core/services/thuraya/th-bulk-order-card.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import { AuthenticationService } from 'src/app/core/services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ThProfitUserService } from 'src/app/core/services/thuraya/th-profit-user.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ThMoveBlanaceBetweenAccountsService } from 'src/app/core/services/thuraya/th-move-blanace-between-accounts.service';
import { DatePipe } from '@angular/common';
import { PermissionService } from 'src/app/core/services/permission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalanceTransferDialogComponent } from './_components/balance-transfer-dialog/balance-transfer-dialog.component';

@Component({
  selector: 'app-get-movement-data',
  templateUrl: './get-movement-data.component.html',
  styleUrls: ['./get-movement-data.component.scss']
})
export class GetMovementDataComponent implements OnInit {


  //Form variables
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;
 

  //dropdown variables
  agentText:string = "-"
  customersList:any = []
  public customerFields =  { text: 'customer_name', value: 'customer_id' };
  public value: string = '';
  public height: string = '400px';
  branchesList:any = []


  isExporting:boolean = false;

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thGeneralSystemService:ThGeneralSystemService,
    private thMoveBlanaceBetweenAccountsService:ThMoveBlanaceBetweenAccountsService,
    private thProfitUserService:ThProfitUserService,
    public permissionService:PermissionService,
    private modalService:NgbModal,
    private authService:AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelService:ExcelService,
  ) { 
   
  }
  
  ngOnInit(): void {
    this.initForm();
    this.getProfitUserBranches();
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body ={
      dateFrom: this.fromDateCom ? this.fromDateCom : "" ,
      dateTo: this.toDateCom ? this.toDateCom : "",
      isPaginated: false,
      address:this.f.address.value,
      customerId: this.f.customerId.value,
    }
    this.thMoveBlanaceBetweenAccountsService.GetMoveBalanceBetweenAccountsData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "رقم العميل": data[i].cust_account_id,
              "البيان": data[i].trans_note,
              "رقم نقطه البيع": data[i].PosPhone,
              "اسم نقطه البيع": data[i].PosName,
              "الفرع": data[i].address,
              "المسحوب": data[i].debit_amount,
              "المضاف": data[i].credit_amount,
              "التاريخ": this.datePipe.transform(data[i].trans_date, this.dateFormatService.dateOnlyFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "المسحوب": Number.parseFloat(result.Data[0].TotalDebitAmount).toFixed(2),
            "المضاف": Number.parseFloat(result.Data[0].TotalCreditAmount).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      customerId: new FormControl(""),
      address: new FormControl(""),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------API Requests---------------------- */
  
  getMoveBalanceBetweenAccountsData(){
    this.spinner.show()
    let api = null
    let body ={
      dateFrom: this.fromDateCom ? this.fromDateCom : "" ,
      dateTo: this.toDateCom ? this.toDateCom : "",
      pageNumber:this.pageNumber,
      pageSize:this.pageSize,
      isPaginated: true,
      address:this.f.address.value,
      customerId: this.f.customerId.value,
    }
    //Api depend on which page we are
    return this.thMoveBlanaceBetweenAccountsService.GetMoveBalanceBetweenAccountsData(body).subscribe((result) => {
      this.spinner.hide()
      if(result.IsSuccess && result.Data.length){
        this.datasource = result.Data;
        this.totalCount = result.Data[0].TotalRows
      }
      else{
        this.spinner.hide()
        this.datasource = []
        this.totalCount = 0;
      }
      },(err)=>{
        this.spinner.hide()
        this.datasource = []
        this.totalCount = 0;
      }
    );
  }

  // get clinets under the selected branch for dropdown select
  getAllCustomerAccountData(){
    let body = {
      isPaginated: false,
      address: this.f.address.value,
    }

    this.thMoveBlanaceBetweenAccountsService.GetAllCustomerAccountData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.customersList = result.Data;
        }
    })
  }

  //get branches under the auth user for dropdown select
  getProfitUserBranches(){
    let body = {
      isPaginated: false,
      userPhoneNumber: this.authService.authUser.PhoneNumber
    }

    this.thMoveBlanaceBetweenAccountsService.GetProfitUserBranches(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.branchesList = result.Data;
        }
    })
  }

  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }


  /*----------------------Search and reset search functions---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getMoveBalanceBetweenAccountsData();
  }

   /*----------------------Pagination---------------------- */
   changePage(event){
    this.pageNumber = event;
    this.getMoveBalanceBetweenAccountsData();
  }

  reset(){
    this.initForm()
    this.dateFromSelector.reset()
    this.dateToSelector.reset();
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.datasource = []
    // this.getMonthlyTarget()
  }

  /*----------------------Filtering event ng dropdown---------------------- */
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    
    let predicateQuery = query.where(new Predicate('customer_name', 'contains', e.text, true).or('customer_id', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.customersList, query);
  };
  
  /*----------------------when Choosing Agent---------------------- */
  changeSelectedBranch(){
    this.customersList = [];
    this.f.customerId.setValue("");
    if(this.f.address.value!=""){
      this.getAllCustomerAccountData();
    }
  }

  /*----------------------Add modal---------------------- */
  transferBalance() {
    let modalRef = this.modalService.open(BalanceTransferDialogComponent, { centered: true });
    // modalRef.componentInstance.updateParent.subscribe((newData) => {
    //   // console.log("newdata",newData)
    //   if(this.datasource.length==10){
    //     //drop last element to insert new at top
    //     this.datasource.pop();
    //     this.datasource.unshift(newData)
    //   }
    // })
  }

}
