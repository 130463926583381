import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RequestPaymentType } from 'src/app/core/enum/requestPaymentType';
import { RequestStatus } from 'src/app/core/enum/requestStatus';
import { RequestType } from 'src/app/core/enum/requestType';
import { UserType } from 'src/app/core/enum/userType';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { SadadServicesService } from 'src/app/core/services/sadad/sadad-services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-get-requests',
  templateUrl: './get-requests.component.html',
  styleUrls: ['./get-requests.component.scss']
})
export class GetRequestsComponent implements OnInit {

  addform: FormGroup;
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  
  //Date Variables
  fromDateCom:any
  toDateCom:any
  fromDateTimeStamp;
  toDateTimeStamp
  @ViewChild("dateFromSelector") dateFromSelector;
  @ViewChild("dateToSelector") dateToSelector;
   
  //enums
  requestStatus = RequestStatus;
  requestType = RequestType;
  requestPaymentType = RequestPaymentType;
  userType = UserType;

  @ViewChild("allCustomersDropDown") allCustomersDropDown;

  isLoading:boolean = false;
  isLoadingSearch:boolean = false;
  isExporting:boolean = false;

  showRequestPaymentType:boolean = false

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private sadadServicesService:SadadServicesService,
    private msgTranslateService:MessageTranslateService,
    private spinner: NgxSpinnerService,
    private authService:AuthenticationService,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,) { }

  ngOnInit(): void {
    // this.initAddForm();
    this.initForm();
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body ={
      isPaginated: false,
      requestStatus: this.f.requestStatus.value,
      requestType: this.f.requestType.value,
      requestPaymentType: this.f.requestPaymentType.value,
      userName: this.f.userId.value,
      fromDate: this.fromDateCom,
      toDate: this.toDateCom
    }
    this.sadadServicesService.GetRequests(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "تاريخ الطلب": this.datePipe.transform(data[i].CreateDate, this.dateFormatService.dateOnlyFormat),
              "المبلغ": data[i].Amount,
              "اسم مقدم الطلب": data[i].RequestorUser.UserFullName,
              "رقم مقدم الطلب": data[i].RequestorUser.UserName,
              "اسم منفذ الطلب": data[i].ApprovalUser.UserFullName,
              "رقم منفذ الطلب": data[i].ApprovalUser.UserName,
              "نوع الطلب": this.requestType[data[i].RequestType],
              "حاله الطلب":this.requestStatus[data[i].RequestStatus],
              "نوع الدفع": this.requestPaymentType[data[i].RequestPaymentType],
              "ملاحظات": data[i].Note,
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "المسحوب": Number.parseFloat(result.Data[0].TotalDebitAmount).toFixed(2),
          //   "المضاف": Number.parseFloat(result.Data[0].TotalCreditAmount).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  
  /*--------------------------Date for requests-------------------------- */
  setSelectedDate(event,type){
    // console.log("date", event)
    if(type=="from"){
      this.fromDateCom = event.date
      this.fromDateTimeStamp = event.dateTimeStamp;
    }else if(type=="to"){
      this.toDateCom = event.date
      this.toDateTimeStamp = event.dateTimeStamp 
    }
  }

  /*------------------------ Form functions ------------------------ */
  // initAddForm(){
  //   this.addform = this.fb.group({
  //     amount: new FormControl("",Validators.required),
  //   });
  // }

  initForm(){
    this.form = this.fb.group({
      requestStatus: new FormControl(""),
      requestType: new FormControl(""),
      requestPaymentType: new FormControl(""),
      userId: new FormControl(""),
      dateFrom: new FormControl(""),
      dateTo: new FormControl(""),
    });
  }

  // get addf() {
  //   return this.addform.controls;
  // }

  get f() {
    return this.form.controls;
  }

  /*------------------------ Check Permission ------------------------ */
  checkAccess(permission: String) {
    return this.permissionService.checkPermission(permission);
  }

  /*------------------------ API Requests ------------------------ */
  showCreateRequestDialog(){
    Swal.fire({
      title: "طلب رصيد",
      input: 'number',
      inputLabel: "ادخل الرصيد المطلوب",
      inputValue: "",
      showCancelButton: true,
      cancelButtonText:"الغاء",
      confirmButtonText: 'تاكيد',
      showLoaderOnConfirm: true,
      inputValidator: (value:any) => {
        if (!value || value<0) {
          return 'يجب ادخال الرصيد المطلوب'
        }
      },
      preConfirm: (value) => {
        this.CreateRequest(value);       
      },
    })
  
  }
  
  CreateRequest(amountValue) {
    // this.isLoading = true
    //Check fields 
    // if (this.addform.invalid){
    //   Swal.fire({
    //     title: "يجب ادخال الرصيد المطلوب",
    //     icon: 'info',
    //     showCloseButton: true,
    //     showCancelButton: false,
    //     showConfirmButton:false,
    //     focusConfirm: false,
    //   });
    //   this.isLoading = false
    //   return;
    // }

    // const formDataSend = new FormData();
    // formDataSend.append('Amount', amountValue);
    // formDataSend.append('RequestType', this.requestType.Balance.toString());  // always request type balance

    // return this.sadadServicesService.CreateRequest(formDataSend).subscribe(
    //   (result) => {
    //     this.isLoading = false
    //     if(result.IsSuccess){
    //       this.toastr.success('تم طلب الرصيد', "",{
    //         positionClass: 'toast-bottom-left',
    //       });
    //       // this.initAddForm();
    //     }else{
    //       this.toastr.error(result.Data, "",{
    //         positionClass: 'toast-bottom-left',
    //       });
    //     }
    //   },(err)=>{
    //       this.isLoading = false
    //       this.toastr.error(err.error.Message, "",{
    //         positionClass: 'toast-bottom-left',
    //       });
    //     }
    // );
  }
  
  getRequests() {
    this.spinner.show()
    this.isLoadingSearch = true
    let body={
      pageNumber:this.pageNumber,
      pageSize:this.pageSize,
      isPaginated: true,
      requestStatus: this.f.requestStatus.value,
      requestType: this.f.requestType.value,
      requestPaymentType: this.f.requestPaymentType.value,
      userName: this.f.userId.value,
      fromDate: this.fromDateCom,
      toDate: this.toDateCom
    };

    return this.sadadServicesService.GetRequests(body).subscribe(
      (result) => {
        this.spinner.hide()
        if(result.IsSuccess && result.Data.length){
          this.isLoadingSearch = false
          this.datasource = result.Data;
          this.totalCount = result.TotalCount
        }else{
          this.datasource = []
          this.totalCount = 0
          this.pageNumber = 1;
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        // this.ref.detectChanges();
      },(err)=>{
        this.spinner.hide()
        console.log("err",err)
        this.toastr.error(err.message, "",{
          positionClass: 'toast-bottom-left',
        });
        this.isLoadingSearch = false;
        // this.ref.detectChanges();
      }
    );
  }

  approveRequest(body,newStatus){
    this.sadadServicesService.ApproveRequest(body).subscribe(
      (result)=>{
        let text = newStatus == this.requestStatus.Accepted ? "تم الموافقه على طلب الرصيد" :  "تم رفض طلب الرصيد"
        if(result.IsSuccess){
          this.toastr.success(text, "",{
            positionClass: 'toast-bottom-left',
          });
          let index = this.datasource.findIndex(d=>d.Id==body.id)
          this.datasource[index].RequestStatus = newStatus;
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },(err)=>{
        console.log(err)
          this.toastr.error(err.error.Message, "",{
            positionClass: 'toast-bottom-left',
          });
        }
    )
  }

  showApproveRequest(event){
    let headerText = event.newStatus == this.requestStatus.Accepted ? "الموافقه على طلب الرصيد" :  "رفض طلب الرصيد"
    let labelText = event.newStatus == this.requestStatus.Accepted ? "كتابه ملاحظه (اختيارى)" :  "كتابه ملاحظه (اجبارى)"
    Swal.fire({
      title: headerText,
      input: 'textarea',
      inputLabel: labelText,
      inputValue: "",
      showCancelButton: true,
      cancelButtonText:"الغاء",
      confirmButtonText: 'تاكيد',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        // only in case of rejecting must enter the note
        if (!value && event.newStatus== this.requestStatus.Rejected) {
          return 'يجب كتابه ملاحظه'
        }
      },
      preConfirm: (value) => {
        let body ={
          id: event.data.Id,
          requestStatus: parseInt(event.newStatus),
          note: value,
        }
        this.approveRequest(body,parseInt(event.newStatus))
       
      },
    })
  
  }

  /*------------------------ Search ------------------------ */
  search(){
    this.pageNumber = 1;
    this.datasource = [];
    this.getRequests();
  }

  reset(){
    this.initForm()
    this.allCustomersDropDown.reset()
    this.dateFromSelector.reset()
    this.dateToSelector.reset();
    this.toDateCom = ""
    this.toDateTimeStamp = ""
    this.fromDateCom = ""
    this.fromDateTimeStamp = ""
    this.pageNumber = 1
    this.datasource = []
    this.getRequests()
  }

  /*------------------------ Pagination ------------------------ */
  changePage(event){
    this.pageNumber = event;
    this.getRequests();
  }

  /*------------------------ Changing request type event ------------------------ */
  changeRequestType(event){
    if(event.target.value!="" && event.target.value!=this.requestType.Balance){
      this.showRequestPaymentType = true
    }else{
      this.showRequestPaymentType = false
      this.f.requestPaymentType.setValue("")
    }
  }

  /*------------------------  Checks for adding and confirming request  ------------------------ */
  checkCanAdd(){
    //Must have any user type to add (accountant for ex. do not have user type)
    if( 
      (this.authService.authUser.UserType == "Agent") 
      || (this.authService.authUser.UserType == "Distrebuter") 
      || (this.authService.authUser.UserType == "POS") 
      ){
      return true
    }else{
      return false
    }
  }

  checkCanView(){
    return (this.authService.authUser.UserType || this.checkAccess('Sadad.View'))
  }

  /*----------------------when Choosing customer---------------------- */
  setSelectedCustomer(event){
    //here we need the name not the phone so we can not change from the shared component as used in many other pages, we get here the name
    let customerName = this.allCustomersDropDown.getName();
    let indexofendId = customerName.indexOf('-')
    let customerSelected = customerName.substring(indexofendId+1,customerName.length).trim()
    this.form.controls.userId.setValue(customerSelected)
  }

}
