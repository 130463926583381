<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    
                    <!-- Client (Signedin name and phone)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsClientOfUserPage">
                        <label>{{'Common.ClientName'|translate}}:</label>
                        <input class="form-control" disabled formControlName="account_id" [(ngModel)]="authUser">
                    </div>

                    <!-- Companies  -->
                    <div class="col-lg-3 mb-3" *ngIf="!IsClientOfUserPage">
                        <app-companies-dropdown #companiesDropDown (updateParent)="setSelectedCompany($event)"></app-companies-dropdown>
                    </div>

                    <!-- Clients(agents/distr/pos) controlled by the Selected company (subordinbates)-->
                    <div class="col-lg-3 mb-3" *ngIf="IsClientOfClientPage || IsClientWithdrawalsPage">
                        <label>{{'Common.ClientName'|translate}} :</label>
                        <ejs-dropdownlist showClearButton="true" formControlName="shared_account_id" id='' [value]='value' [fields]='accountsFields'
                        [popupHeight]='height' [dataSource]='accountsDataList'
                        [allowFiltering]='accountsDataList.length>10 ? true: false' [ignoreAccent]='true' placeholder='--{{"Common.Choose"|translate}}--'
                        filterBarPlaceholder='{{"Common.Search"|translate}}'
                        (filtering)='onFiltering($event)'>
                        </ejs-dropdownlist>                                                   
                    </div>

                    <!-- Action Buttons -->
                    <div class="col-lg-3 d-flex align-items-end mb-3" *ngIf="!IsClientOfUserPage">
                        <div class="action-btns" style="margin-inline-end: 20px;">
                            <button type="submit" (click)="search()"
                            class="btn btn-primary font-weight-bold px-10 mr-2">
                                {{'Common.Search'|translate}}
                            </button>
                        </div>
                        <div class="action-btns">
                            <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                {{'Common.Clear'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>


<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-dist-table [datasource]="datasource" [IsClientOfUserPage]="IsClientOfUserPage" [IsClientWithdrawalsPage]="IsClientWithdrawalsPage"></app-dist-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>