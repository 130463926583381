import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ThStartInvBalanceService {

  API_URLGetInventoryDataOverallAndDetails = `${environment.apiUrl}/ThdbStartInventoryBalance/GetInventoryDataOverallAndDetails`;
  // API_URLGetInventoryDataDetails = `${environment.apiUrl}/ThdbStartInventoryBalance/GetInventoryDataDetails`;

  constructor(private http:HttpClient) { }

  GetInventoryDataOverallAndDetails(body): Observable<any> {
    const url = this.API_URLGetInventoryDataOverallAndDetails;
    //Http request-
    return this.http.post<any>(url,body)
  }

  // GetInventoryDataDetails(body): Observable<any> {
  //   const url = this.API_URLGetInventoryDataDetails;
  //   //Http request-
  //   return this.http.post<any>(url,body)
  // }
}
