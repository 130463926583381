<div style="width: 380px;">

    <form class="form" [formGroup]="updateform">
        <div class="card-body">
            <!-- Provider -->
            <div>
                <label>{{'HALA.SendCardsEmployee.ProviderId'|translate}} :</label>
                <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="providerscomboName" [_value]="prvoideComboValue" (getData)="getProviders()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedProvider($event)"></app-shared-combo> 
            </div>
             <!-- Order No -->
             <div class="form-group row">
                <div class=" mt-3">
                        <label>{{'HALA.ApproveCards.OrderNo'|translate}}:</label>
                        <input type="number" formControlName="orderNo" [(ngModel)]="selectedValues.orderNo" class="form-control">
                    </div> 
            </div>
            <!-- Receive No -->
            <div class="form-group row">
                <div class=" mt-3">
                    <label>{{'HALA.ApproveCards.ReceiveNo'|translate}}:</label>
                    <input type="number" formControlName="oracleNo" [(ngModel)]="selectedValues.oracleNo" class="form-control">
                    <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                </div> 
            </div>
            <!-- Transfer No -->
            <div class="form-group row">
                <div class=" mt-3">
                    <label>{{'HALA.ApproveCards.TransferNo'|translate}}:</label>
                    <input type="number" formControlName="transNo" [(ngModel)]="selectedValues.transNo" class="form-control">
                </div> 
            </div>
           
    
           
        </div>
    </form>

     <!-- Action Buttons -->
     <div class="d-flex align-items-center justify-content-center mt-3">
        <div class="action-btns" style="margin-inline-end: 20px;">
            <button type="submit" (click)="editCard()" [disabled]="updateform.invalid"
            class="btn btn-primary font-weight-bold px-10 mr-2">
                {{'Common.Edit'|translate}}
            </button>
        </div>
        <div class="action-btns">
            <button (click)="cancelEdit()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                {{'Common.Discard'|translate}}
            </button>
        </div>
    </div>
</div>