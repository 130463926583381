import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-cust-sales-table',
  templateUrl: './cust-sales-table.component.html',
  styleUrls: ['./cust-sales-table.component.scss']
})
export class CustSalesTableComponent implements OnInit {

  @Input() datasource

  totalSellPrice:number = 0
  
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.datasource.length>0){
      this.totalSellPrice = this.datasource[0].SumTotalSellPrice
    }else{
      this.totalSellPrice = 0;
    }
  }

}
