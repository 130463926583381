<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.BillNo'| translate}}</th>
          <th class="align-middle"> {{'Common.Details'| translate}}</th>
          <!-- <th class="align-middle">{{'Common.BillCode'| translate}}</th> -->
          <th class="align-middle">{{'Common.Date'| translate}}</th>
          <th class="align-middle">{{'Common.ClientPhone'| translate}}</th>
          <!-- <th class="align-middle">{{'Common.CardName'| translate}} </th> -->
          <th class="align-middle">{{'Common.RequestedNumber'| translate}} </th>
          <th class="align-middle">{{'Common.ActualNumber'| translate}} </th>
          <th class="align-middle">{{'Common.Total'| translate}} </th>
          <th class="align-middle"> {{'Common.Printed'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
            <td> <a class="text-body fw-bold">{{data.Id}}</a> </td>
            <td class="">
              <button class="btn btn-primary" (click)="details(data)"> {{ 'Common.ShowPinDetails'| translate}} </button>
            </td>
            <!-- <td>{{data.InvoiceGuid}}</td> -->
            <td> <p> {{data.InvoiceDate | date:dateFormatService.dateOnlyFormat}} </p></td>
            <td>{{data.CustomerPhone}}</td>
            <!-- <td>{{data.totalRcrd_No}}</td> -->
            <td>{{data.CardsNoRequested}}</td>
            <td>{{data.CardsNoExecuted}}</td>
            <td>{{data.totalInvoice}}</td>
            <td class="text-center">
                <i *ngIf="data.IsPrinted=='False'" class="fa-solid fa-xmark text-danger" style="font-size: 18px;"></i>
                <i *ngIf="data.IsPrinted=='True'" class="fa-solid fa-check text-success" style="font-size: 18px;"></i>
            </td>
        </tr>
      </tbody>
    </table>
</div>