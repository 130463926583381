import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input() public headerText="";
  @Input() public icon="";
  @Input() public iconColor="";
  @Input() public amount="";
  @Input() public endText="";
  // isNaN: Function = Number.isNaN;

  constructor() {
    
   }

  ngOnInit(): void {
  }

}
