import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StartInvBalanceRoutingModule } from './start-inv-balance-routing.module';
import { GetInventoryDataComponent } from './get-inventory-data/get-inventory-data.component';
import { CoreModule } from 'src/app/core/core.module';
import { StartInvTableComponent } from './get-inventory-data/_components/start-inv-table/start-inv-table.component';


@NgModule({
  declarations: [
    GetInventoryDataComponent,
    StartInvTableComponent
  ],
  imports: [
    CommonModule,
    StartInvBalanceRoutingModule,
    CoreModule
  ]
})
export class StartInvBalanceModule { }
