import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-client-price-table',
  templateUrl: './client-price-table.component.html',
  styleUrls: ['./client-price-table.component.scss']
})
export class ClientPriceTableComponent implements OnInit {

  @Input() datasource  
  @Output() updatePrice: EventEmitter<any> = new EventEmitter();
  
  constructor(
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
  }

  update(data){
    this.updatePrice.emit(data)
  }

}
