import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAllBillDetailsComponent } from './get-all-bill-details/get-all-bill-details.component';
import { GetInvoiceDataComponent } from './get-invoice-data/get-invoice-data.component';

const routes: Routes = [
  { path: 'get-invoice-data', component: GetInvoiceDataComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerReports.View'], roles:[]} },
  { path: 'get-all-bill-details', component: GetAllBillDetailsComponent,canActivate: [AuthGuard],  data: {permission: ['ThdbDistributerReports.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DistributorReportsRoutingModule { }
