import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerSalesDetailsRoutingModule } from './customer-sales-details-routing.module';
import { GetCustSalesDetailsComponent } from './get-cust-sales-details/get-cust-sales-details.component';
import { CoreModule } from 'src/app/core/core.module';
import { CustSalesTableComponent } from './get-cust-sales-details/_components/cust-sales-table/cust-sales-table.component';


@NgModule({
  declarations: [
    GetCustSalesDetailsComponent,
    CustSalesTableComponent
  ],
  imports: [
    CommonModule,
    CustomerSalesDetailsRoutingModule,
    CoreModule
  ]
})
export class CustomerSalesDetailsModule { }
