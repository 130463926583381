import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetCurrentStockDataComponent } from './get-current-stock-data/get-current-stock-data.component';

const routes: Routes = [
  { path: 'get-current-stock-data', component: GetCurrentStockDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbCurrentStockBalance.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CurrentStockBalanceRoutingModule { }
