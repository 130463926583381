import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestStatus } from 'src/app/core/enum/requestStatus';
import { RequestType } from 'src/app/core/enum/requestType';
import { RequestPaymentType } from 'src/app/core/enum/requestPaymentType';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import Swal from 'sweetalert2';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { DatePipe } from '@angular/common';
import { SadadServicesService } from 'src/app/core/services/sadad/sadad-services.service';

@Component({
  selector: 'app-sadad-requests-table',
  templateUrl: './sadad-requests-table.component.html',
  styleUrls: ['./sadad-requests-table.component.scss']
})
export class SadadRequestsTableComponent implements OnInit {

  @Input() id;  
  @Input() datasource;  
  // this table used in details pop from sdad transactions page (so in that page do not show last column)
  @Input() showConfirmDeclineColumn;
  @Output() showDialog: EventEmitter<any> = new EventEmitter();

   //enums
   requestStatus = RequestStatus;
   requestType = RequestType;
   requestPaymentType = RequestPaymentType;

   isExporting:boolean = false;

  constructor(
    private authService:AuthenticationService,
    private permissionService: PermissionService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private sadadServicesService:SadadServicesService,
  ) { }

  ngOnInit(): void {
  }

  

  /*------------------------ Excel Export All data (we made it here as this table is used in transaction details so also there we need export) ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    this.sadadServicesService.GetRequestesByTransactionId(this.id).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {
            let sheet={ 
              "تاريخ الطلب": this.datePipe.transform(data[i].CreateDate, this.dateFormatService.dateOnlyFormat),
              "المبلغ": data[i].Amount,
              "اسم مقدم الطلب": data[i].RequestorUser.UserFullName,
              "رقم مقدم الطلب": data[i].RequestorUser.UserName,
              "اسم منفذ الطلب": data[i].ApprovalUser.UserFullName,
              "رقم منفذ الطلب": data[i].ApprovalUser.UserName,
              "نوع الطلب": this.requestType[data[i].RequestType],
              "حاله الطلب":this.requestStatus[data[i].RequestStatus],
              "نوع الدفع": this.requestPaymentType[data[i].RequestPaymentType],
              "ملاحظات": data[i].Note,
            }
            this.excelService.addToSheet(sheet);
          }
          // let total={ 
          //   "المسحوب": Number.parseFloat(result.Data[0].TotalDebitAmount).toFixed(2),
          //   "المضاف": Number.parseFloat(result.Data[0].TotalCreditAmount).toFixed(2),
          // }
          // this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  
  /*------------------------ Check Permission ------------------------ */
  checkAccess(permission: String) {
    return this.permissionService.checkPermission(permission);
  }


  /*------------------------  Show payment photo  ------------------------ */
  showPaymentAttachment(data){
    Swal.fire({
      width:500,
      imageHeight:500,
      imageUrl: data.PaymentAttachment,
    })
  }

  show(newStatus,data){
    let body={
      newStatus:newStatus,
      data:data
    }
    this.showDialog.emit(body)
  }

}
