import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { HalaDataUpdateService } from 'src/app/core/services/hala/hala-data-update.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-data-dialog',
  templateUrl: './edit-data-dialog.component.html',
  styleUrls: ['./edit-data-dialog.component.scss']
})
export class EditDataDialogComponent implements OnInit {

  @Input() updateData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  oldData:any
  //Form variables
  form: FormGroup;
  
  constructor( 
    private fb: FormBuilder,
    private modalService:NgbModal,
    private halaDataUpdateService:HalaDataUpdateService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,) { }

  ngOnInit(): void {
    this.initForm();
    this.oldData = {...this.updateData}
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      requiredBalance: new FormControl("",Validators.required),
      commercialName: new FormControl("",Validators.required),
      commercialRegistrationNo: new FormControl("",Validators.required),
      posUserNumber: new FormControl("",Validators.required),
      userName: new FormControl(""),
      companyNumber: new FormControl(""),
      serialNumber: new FormControl("",Validators.required),
      email: new FormControl("",Validators.required),
      address: new FormControl("",Validators.required),
      deviceType: new FormControl("1",Validators.required),
      isAction: new FormControl("",Validators.required),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Discard---------------------- */
  cancelChanges(){
    this.modalService.dismissAll()
  }

  /*----------------------Form Functions---------------------- */
  saveChanges(){
    //Check fields 
    if (this.form.invalid){
      this.toastr.warning(this.msgTranslateService.msgTranslate("SwalMessages.InsertData"), "",{
        positionClass: 'toast-bottom-left',
      });
      return;
    }
    let body={
      id:this.updateData.Id,
      requiredBalance: this.f.requiredBalance.value,
      commercialName: this.f.commercialName.value,
      commercialRegistrationNo: this.f.commercialRegistrationNo.value,
      posUserNumber: this.f.posUserNumber.value,
      userName: this.f.userName.value,
      companyNumber: this.f.companyNumber.value,
      serialNumber: this.f.serialNumber.value,
      email: this.f.email.value,
      address: this.f.address.value,
      deviceType: this.f.deviceType.value,
      isAction: this.f.isAction.value,
    }

    return this.halaDataUpdateService.UpdateDataUpdates(body).subscribe(
      (result) => {
        if(result.IsSuccess){
          this.updateParent.emit(result.Data)
          this.modalService.dismissAll();
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      err => {
        this.modalService.dismissAll()
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

  // device Type radio changes
  changeDeviceType(event:any){
    this.f.deviceType.setValue(event.target.value)
  }


}
