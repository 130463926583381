import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ThCardBatchesService } from 'src/app/core/services/thuraya/th-card-batches.service';
import { ThOutstandingCardsService } from 'src/app/core/services/thuraya/th-outstanding-cards.service';

@Component({
  selector: 'app-get-outstand-cards',
  templateUrl: './get-outstand-cards.component.html',
  styleUrls: ['./get-outstand-cards.component.scss']
})
export class GetOutstandCardsComponent implements OnInit {

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;
  //Export Variable for loading
  isExporting:boolean = false;
 
  private subscriptions: Subscription[] = [];

  constructor(
    private msgTranslateService:MessageTranslateService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    public dateFormatService:DateFormatService,
    private thOutstandingCardsService:ThOutstandingCardsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
    }
    const subscr = this.thOutstandingCardsService.GetOutstandingCardsData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {         
            let sheet={ 
              "رقم التسلسل": data[i].row_no,
              "مقدم الخدمة": data[i].service_id,
              "اسم الكرت": data[i].class_name,
              "طريقة الاضافة": data[i].source_type,
              "اجمالي التكلفة	": data[i].class_cost,
              "اجمالى الربح	": data[i].class_price,
              "تاريخ العمليه": this.datePipe.transform(data[i].exp_date,this.dateFormatService.dateFormat),
            }
            this.excelService.addToSheet(sheet);
          }
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
    this.subscriptions.push(subscr);
  }
   
  /*----------------------API Requests---------------------- */
  getOutstandingCardsData() {
      this.spinner.show(); 
      let body={
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isPaginated: true,
      }
      return this.thOutstandingCardsService.GetOutstandingCardsData(body).subscribe((result) => {
        if(result.IsSuccess && result.Data.length>0){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows;
        }
        if(result.Data.length==0){
          this.datasource = []
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.spinner.hide(); 
      }
      );
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event
    this.getOutstandingCardsData();
  }

}
