<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <form class="form" [formGroup]="form">
            <div class="card-body">
                <div class="form-group row">
                    <!-- Date From -->
                    <div class="col-lg-3">
                        <label>{{'Common.DateFrom'|translate}}</label>
                        <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                    </div>
                    <!-- Date to -->
                    <div class="col-lg-3">
                        <label>{{'Common.DateTo'|translate}}</label>
                        <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                    </div>
                    <!-- Service -->
                    <div class="col-lg-3">
                        <app-services-dropdown #servicesDropDown (updateParent)="setSelectedService($event)"></app-services-dropdown>
                    </div>
                    <!-- Card Name -->
                    <div class="col-lg-3">
                        <label>{{'HALA.SendCardsEmployee.CardName'|translate}}:</label>
                        <select formControlName="class_no" class="form-control">
                            <option style="font-size: 16px !important" value="" [selected]="true">--{{'HALA.SendCardsEmployee.ChooseName'|translate}}--</option>
                            <option style="font-size: 16px !important" *ngFor="let item of ClassNameList" value="{{ item.class_no }}">
                            [{{ item.class_no }}] - {{ item.class_name }}
                            </option>
                        </select>
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                    <!-- Serial No -->
                    <div class="col-lg-3  mt-3">
                        <label>{{'Common.Serial'|translate}}:</label>
                        <input type="text" formControlName="serialNo" class="form-control">
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                    <!-- Receive No (Only shown for angerReportComponent) -->
                    <div class="col-lg-3  mt-3" *ngIf="IsMangerReportComponent">
                        <label>{{'HALA.ApproveCards.ReceiveNo'|translate}}:</label>
                        <input type="text" formControlName="oracleNo" class="form-control">
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                     <!-- Transfer No -->
                     <div class="col-lg-3  mt-3" *ngIf="IsMangerReportComponent">
                        <label>{{'HALA.ApproveCards.TransferNo'|translate}}:</label>
                        <input type="text" formControlName="transNo" class="form-control">
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                    <!-- Order No -->
                    <div class="col-lg-3  mt-3">
                        <label>{{'HALA.ApproveCards.OrderNo'|translate}}:</label>
                        <input type="text" formControlName="orderNo" class="form-control">
                        <!-- <app-shared-combo [_shrdInpt]='shrdInpt' [_comboName]="cardcomboName" [_value]="classNoVal" (getData)="getClassNames()" (updateSharedData)="updateSharedData($event)" (changeSelected)="changeSelectedClass($event)"></app-shared-combo> -->
                    </div> 
                    <!-- Provider -->
                    <div class="col-lg-3 mt-3 ">
                        <app-providers-dropdown #providersDropDown (updateParent)="setSelectedProvider($event)"></app-providers-dropdown>
                    </div>
                    <!--User Id -->
                    <div class="col-lg-3 mt-3">
                        <app-users-dropdown  #usersDropDown (updateParent)="setSelectedUser($event)"></app-users-dropdown>
                    </div>
                    <!-- Action Buttons -->
                    <div class="d-flex align-items-center mt-3">
                    
                            <div class="action-btns" style="margin-inline-end: 20px;">
                                <button type="submit" (click)="search()"
                                class="btn btn-primary font-weight-bold px-10 mr-2">
                                    {{'Common.Search'|translate}}
                                </button>
                            </div>
                            <div class="action-btns">
                                <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
                                    {{'Common.Clear'|translate}}
                                </button>
                            </div>
                        
                    </div>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>


<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>   

<!-- Table and pagination -->
<div class="card card-custom gutter-b px-4 py-2">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-cards-table [datasource]="datasource" [IsApproveComponent]="IsApproveComponent" [IsMangerReportComponent]="IsMangerReportComponent" 
        (approveCards)="showApproveBox($event)" 
        (returnCards)="showReturnBox($event)" 
        (showDetails)="showDetails($event)"
        (editCards)="editCard($event)"></app-cards-table>
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>




 <!--Detail Table (Serila Pin)-->
<div class="row mt-5" #detailsRow>
    <div class="col-xl-12">
        <div class="card card-custom gutter-b px-4 py-2">
            <h3 class="mb-3">{{'HALA.ApproveCards.CardDetails'|translate}}</h3>
            <div class="table-striped table-responsive">
                <ejs-grid #detailgrid [enableRtl]="languageService.selectedLanguage=='ar'" [pageSettings]="pageSettings" [toolbar]="toolbarOptions"
                    [allowPaging]="true" [allowExcelExport]="true" [dataSource]="detailSource"
                    [allowSelection]="false" (toolbarClick)="toolbarClickDetails($event)">
                    >
                    <e-columns>

                    <e-column field="SerialCard" style="font-weight: 700; font-size: large;" headerText="{{'Common.Serial'|translate}}">
                    </e-column>
                    <e-column field="PinCard" style="font-weight: bold;" headerText="{{'Common.Pin'|translate}}"></e-column>
                    <e-column field="CreateDate" style="font-weight: bold;" type="date" [format]="format" [valueAccessor]='dateFormatter'
                        headerText="{{'Common.CreateDate'|translate}}"></e-column>
                    <e-column field="CreateDate" style="font-weight: bold;" type="date" 
                    [valueAccessor]='timeFormatter' headerText="{{'Common.CreateTime'|translate}}">
                        <ng-template #template let-dataSource>
                            <span>{{dataSource.CreateDate | date: "hh:mm a"}}</span>
                        </ng-template>
                    </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
            

    </div>
</div>