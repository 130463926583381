import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

   
  encryptBody(body: any): string {
    const jsonString = JSON.stringify(body);
    
    // Convert the key string to a UTF-8 encoded WordArray
    const key = CryptoJS.enc.Utf8.parse(environment.encryptionKeyString);
    // Convert the IV string to a UTF-8 encoded WordArray
    const iv = CryptoJS.enc.Utf8.parse(environment.encryptionIVString);


    // Encrypt using AES with IV and Secret Key
    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });


    // Encrypt using AES
    return encrypted.toString();
  }
}
