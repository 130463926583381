import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThClientEditPricesService {

  API_URLGetClientEditPricesData = `${environment.apiUrl}/ThdbClientEditPrices/GetClientEditPricesData`;
  API_URLupdateClientEditPrice = `${environment.apiUrl}/ThdbClientEditPrices/updateClientEditPrice`;
  
  constructor(private http:HttpClient) { }

  GetClientEditPricesData(body): Observable<any> {
    const url = this.API_URLGetClientEditPricesData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  updateClientEditPrice(body): Observable<any> {
    const url = this.API_URLupdateClientEditPrice;
    //Http request-
    return this.http.post<any>(url,body)
  }

}
