import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { ThDistributorsPagesService } from 'src/app/core/services/thuraya/th-distributors-pages.service';
import Swal from 'sweetalert2';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data';
import { Predicate } from '@syncfusion/ej2-dropdowns/node_modules/@syncfusion/ej2-data'
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-get-distributor-data',
  templateUrl: './get-distributor-data.component.html',
  styleUrls: ['./get-distributor-data.component.scss']
})
export class GetDistributorDataComponent implements OnInit {

  //[IMPORTANT] This component IS USED FOR All Profit User pages 
  IsClientBalancesPage:boolean = false;
  IsClientOfClientPage:boolean = false;
  IsClientOfUserPage:boolean = false;
  IsClientWithdrawalsPage:boolean = false;

  //Form variables
  form: FormGroup;

  //Table variables
  datasource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  //dropdown variables
  accountsDataList:any = []
  public accountsFields =  { text: 'CUSTOMER_NAME', value: 'CUSTOMER_ID' };
  public value: string = '';
  public height: string = '400px';

  isExporting:boolean = false

  @ViewChild("companiesDropDown") companiesDropDown;
  authUser = ""
 
  
  constructor(
    private fb: FormBuilder,
    private thDistributorsPagesService:ThDistributorsPagesService,
    private msgTranslateService:MessageTranslateService,
    private thGeneralSystemService:ThGeneralSystemService,
    private authService:AuthenticationService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private excelService:ExcelService,
    private datePipe:DatePipe,
    private dateFormatService:DateFormatService,
    private router: Router,
  ) { 
    //[IMPORTANT] This component IS USED FOR MULTIPLE PAGES
    // console.log("route",this.router.url)
    if(this.router.url=="/thuraya/distributor-pages/client-balances"){
      this.resetRoute();
      this.IsClientBalancesPage = true
    }else if(this.router.url=="/thuraya/distributor-pages/clients-of-clients"){
      this.resetRoute();
      this.IsClientOfClientPage = true
    }
    else if(this.router.url=="/thuraya/distributor-pages/clients-of-user"){
      this.resetRoute();
      this.IsClientOfUserPage = true
    }
    else if(this.router.url=="/thuraya/distributor-pages/clients-withdrawals"){
      this.resetRoute();
      this.IsClientWithdrawalsPage = true
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.authUser = `${this.authService.authUser.PhoneNumber}_${this.authService.authUser.FullName}`
  }

  resetRoute(){
    this.IsClientBalancesPage = false;
    this.IsClientOfClientPage = false;
    this.IsClientOfUserPage = false;
    this.IsClientWithdrawalsPage = false;
  }
  
  /*------------------------ Excel Export All data ------------------------ */
  exportExcel(mainData){

    if(this.IsClientWithdrawalsPage){
      this.exportExcelWithdrawals(mainData);
      return;
    }

    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      company_no: this.f.company_no.value,
      shared_account_id: this.IsClientOfUserPage ? this.authService.authUser.PhoneNumber : this.f.shared_account_id.value
    }
    this.thDistributorsPagesService.GetDistributerPagesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {  
            let sheet={}
            if(this.IsClientOfUserPage){
              sheet = { 
                "رقم العميل": data[i].CUSTOMER_ID,
                "اسم العميل": data[i].CUSTOMER_NAME,
                "الرصيد": data[i].balance,
                "العنوان": data[i].cust_address,
              }
            }else{
              sheet = { 
                "رقم العميل": data[i].CUSTOMER_ID,
                "اسم العميل": data[i].CUSTOMER_NAME,
                "مندوب": data[i].shared_account_id,
                "الرصيد": data[i].balance,
                "العنوان": data[i].cust_address,
              }
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "الرصيد": Number.parseFloat(result.Data[0].TotalBalance).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  exportExcelWithdrawals(mainData){
    this.isExporting = true
    this.excelService.emptysheet();
    let body={
      isPaginated: false,
      company_no: this.f.company_no.value,
      shared_account_id: this.f.shared_account_id.value
    }
    this.thDistributorsPagesService.GetDistributerPagesDatesWithdrawals(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length>0){
          let data = result.Data
          for (let i = 0; i < data.length; i++) {  
            let sheet={ 
              "رقم العميل": data[i].CUSTOMER_ID,
              "اسم العميل": data[i].CUSTOMER_NAME,
              "مندوب": data[i].shared_account_id,
              "الرصيد": data[i].balance,
              "العنوان": data[i].cust_address,
              "مقدم الخدمة": data[i].service_id,
              "اسم الفئة": data[i].note,
              "التاريخ": this.datePipe.transform(data[i].trans_date,this.dateFormatService.dateOnlyFormat),
              "سعر نقط البيع": data[i].point_price,
              "سعر البيع": data[i].sell_price,
              "الحاله": data[i].trans_status,
            }
            this.excelService.addToSheet(sheet);
          }
          let total={ 
            "الرصيد": Number.parseFloat(result.Data[0].TotalBalance).toFixed(2),
            "سعر نقط البيع":Number.parseFloat(result.Data[0].TotalPointPrice).toFixed(2),
            "سعر البيع": Number.parseFloat(result.Data[0].TotalSellPrice).toFixed(2),
          }
          this.excelService.addToSheet(total);
          this.excelService.excelExport();
        }
        this.isExporting = false;
      },
      (err)=>{
        this.isExporting = false
      }
    )
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      account_id: new FormControl(""),
      company_no: new FormControl(""),
      shared_account_id: new FormControl(""),
    });
     //the account id is the user logged in so always set
     this.f.account_id.setValue(this.authUser)
  }
  
  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getDistributerPagesData() {
    //Check fields 
    if (this.f.company_no.value=="" && !this.IsClientOfUserPage){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      company_no: this.f.company_no.value,
      shared_account_id: this.IsClientOfUserPage ? this.authService.authUser.PhoneNumber : this.f.shared_account_id.value
    }
    this.thDistributorsPagesService.GetDistributerPagesData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }else{
          this.datasource = []
          this.totalCount = 0
        }
        this.spinner.hide(); 
      },(err)=>{
        this.datasource = []
        this.totalCount = 0
        this.spinner.hide(); 
      }
    )
  }

  getDistributerPagesDatesWithdrawals(){
    //Check fields 
    if (this.f.company_no.value==""){
      Swal.fire({
        title: this.msgTranslateService.msgTranslate('SwalMessages.InsertData'),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false,
        focusConfirm: false,
      });
      return;
    }
    this.spinner.show(); 
    let body = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      company_no: this.f.company_no.value,
      shared_account_id: this.f.shared_account_id.value
    }
    this.thDistributorsPagesService.GetDistributerPagesDatesWithdrawals(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.datasource = result.Data
          this.totalCount = result.Data[0].TotalRows
        }else{
          this.datasource = []
          this.totalCount = 0
        }
        this.spinner.hide(); 
      },(err)=>{
        this.datasource = []
        this.totalCount = 0
        this.spinner.hide(); 
      }
    )
  }

  // get accounts (agents/distr/pos) controlled by selected company
  getAllAccountsByCompany(){
    let body = {
      isPaginated: false,
      company_No: this.f.company_no.value,
    }

    this.thGeneralSystemService.GetAllAccountsByCompany(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.accountsDataList = result.Data;
        }
    })
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    //in case of client of user page get data on page load no search is made
    this.IsClientOfUserPage && this.getDistributerPagesData()
    
    //rest of pages case
    if(this.datasource.length!=0 && !this.IsClientOfUserPage){
       this.IsClientWithdrawalsPage ? this.getDistributerPagesDatesWithdrawals() : this.getDistributerPagesData();    
    }
  }

  /*----------------------Filter---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.IsClientWithdrawalsPage ? this.getDistributerPagesDatesWithdrawals() : this.getDistributerPagesData()
  }

  reset(){
    this.initForm()
    !this.IsClientOfUserPage && this.companiesDropDown.reset()
    this.pageNumber = 1
    this.totalCount =0
    this.datasource = [];
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedCompany(event){
    //empty pos data and selected from before on any change
    this.accountsDataList = [];
    this.f.shared_account_id.setValue("");
    //set new data
    this.form.controls.company_no.setValue(event)
    if(this.f.company_no.value!=""){
      this.getAllAccountsByCompany()
    }
  }

  /*----------------------Filtering event ej dropdown---------------------- */
  public onFiltering = (e: FilteringEventArgs) => {
    let query = new Query();
    
    let predicateQuery = query.where(new Predicate('CUSTOMER_NAME', 'contains', e.text, true).or('CUSTOMER_ID', 'contains', e.text, true));
    query = (e.text !== '') ? predicateQuery : query;
    e.updateData(this.accountsDataList, query);
  };

}
