<div class="d-flex">
    <div class="action-btns" style="margin-inline-end: 20px;">
        <button type="submit" (click)="search()"
        class="btn btn-primary font-weight-bold px-10 mr-2">
            {{'Common.Search'|translate}}
        </button>
    </div>
    <div class="action-btns">
        <button (click)="reset()" type="submit" class="btn btn-danger font-weight-bold px-10 mr-2">
            {{'Common.Clear'|translate}}
        </button>
    </div>
</div>