import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetAccountBillDataComponent } from './get-account-bill-data/get-account-bill-data.component';

const routes: Routes = [
  { path: 'get-account-bill-data', component: GetAccountBillDataComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbAccountReport.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountReportRoutingModule { }
