<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'REPORTS.CompaniesSales.Company'| translate}}</th>
          <th class="align-middle">{{'REPORTS.CompaniesSales.CardAmount'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}} </th>
          <th class="align-middle">{{'REPORTS.CompaniesSales.DistributorPrice'| translate}}</th>
          <th class="align-middle">{{'REPORTS.CompaniesSales.Profit'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.ServiceId}}</a>
          </td>
          <td>{{data.CardNO}}</td>
          <td>{{data.Cost}}</td>
          <td>{{data.SellPrice}}</td>
          <td>{{data.Profit}}</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalCardsNo==0 ? '-' :TotalCardsNo}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalCost==0 ? '-' :TotalCost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalSellPrice==0 ? '-' :TotalSellPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{TotalProfit==0 ? '-' :TotalProfit | number : '1.0-2'}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>