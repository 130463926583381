import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-dist-table',
  templateUrl: './dist-table.component.html',
  styleUrls: ['./dist-table.component.scss']
})
export class DistTableComponent implements OnInit {

  @Input() datasource
  @Input() IsClientOfUserPage
  @Input() IsClientWithdrawalsPage

  TotalBalance = 0
  TotalPointPrice = 0
  TotalSellPrice = 0

  constructor(
    public dateFormatService:DateFormatService
  ) { }

  ngOnInit(): void {
  }

  
  ngOnChanges() { 
    if(this.datasource.length>0){
      this.TotalBalance = this.datasource[0].TotalBalance;
      this.TotalPointPrice = this.datasource[0].TotalPointPrice;
      this.TotalSellPrice = this.datasource[0].TotalSellPrice;
    }else{
      this.TotalBalance = 0;
      this.TotalPointPrice = 0;
      this.TotalSellPrice = 0;
    }
  }

}
