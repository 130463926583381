import { Component, OnInit,Input, ViewChild,Output,EventEmitter } from '@angular/core';
import { ComboBoxComponent, PopupEventArgs } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-shared-combo',
  templateUrl: './shared-combo.component.html',
  styleUrls: ['./shared-combo.component.scss']
})
export class SharedComboComponent implements OnInit {

  @ViewChild('combBox') public combBox: ComboBoxComponent
  // @Input() _dataSource;
  // @Input() _fields
  @Input() _shrdInpt;
  @Input() _comboName;
  @Input() _value;


  @Output() getData = new EventEmitter<any>();
  @Output() updateSharedData = new EventEmitter<any>();
  @Output() changeSelected = new EventEmitter<any>();


  constructor(
  ) { }

  ngOnInit(): void {
    //Set the refernce after 0.1 as ononit first does not see the combobox in html 
    setTimeout(() => {
      this.updateSharedData.emit({key:this._comboName,subkey:"comboboxref", value:this.combBox})
    }, 0.1);
  }

  /*----------------------Comboboxes Functions---------------------- */
  // COMBO BOX FUNCTIONS (USERS FILTER)
  onOpen(event: PopupEventArgs){    
    let listElement: HTMLElement = (this.combBox as any).list;
    listElement.addEventListener('scroll', () => {
      if ((Math.round(listElement.scrollTop)+ listElement.offsetHeight === listElement.scrollHeight)) {
        this.combBox.showSpinner();
        //check data did not end then we will get new users
        if(!this._shrdInpt[this._comboName].endData && !this._shrdInpt[this._comboName].isLoading){
          this.updateSharedData.emit({key:this._comboName,subkey:"pageNumber", value:this._shrdInpt[this._comboName].pageNumber+1})          
          this.getData.emit()
        }else{
          this.combBox.hideSpinner();
        }
      }
    })
  }
  onFiltering (e) {
    // load overall data when search key empty.
    if (e.text == '') {
      this.updateSharedData.emit({key:this._comboName,subkey:"endData", value:false})    
      //return to the last page we were before searching
      this.updateSharedData.emit({key:this._comboName,subkey:"pageNumber", value:this._shrdInpt[this._comboName].pageNumberBeforeSearch})  
      //If no search text empty all search result
      this.updateSharedData.emit({key:this._comboName,subkey:"isSearching", value:false})    
      //Store previous search text to check if search value changed
      this.updateSharedData.emit({key:this._comboName,subkey:"oldSearchText", value:""})   
      this.updateSharedData.emit({key:this._comboName,subkey:"searchList", value:[]})   
      this.updateSharedData.emit({key:this._comboName,subkey:"searchText", value:""})  
      e.updateData([])
      // this._shrdInpt[this._comboName].comboboxref.addItem(this._shrdInpt[this._comboName].loadedList)
      // this.getData.emit()
      
    } else {
      this.updateSharedData.emit({key:this._comboName,subkey:"combBoxEvent", value:e})
      this.updateSharedData.emit({key:this._comboName,subkey:"pageNumber", value:1})
      this.updateSharedData.emit({key:this._comboName,subkey:"pageSize", value:10})
      this.updateSharedData.emit({key:this._comboName,subkey:"isSearching", value:true})
      this.updateSharedData.emit({key:this._comboName,subkey:"searchText", value:e.text})
      // let body = {
      //   pageNumber: this._shrdInpt[this._comboName].pageNumber,
      //   pageSize: this._shrdInpt[this._comboName].pageSize,
      //   isPaginated: true,
      //   searchString:e.text
      // }
      // this.getData.emit(body)
      this.getData.emit()
    }
  };

  checkSelected(event){
    // let itemIndex =  
    // console.log("event",event.value)
    if(event.value){ 
      setTimeout(() => {
        
        this.changeSelected.emit(event.value)
      }, 100);
    }
    else{
      setTimeout(() => {
        
        this.changeSelected.emit(null)
      }, 100);
    }
  }


}
