import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThPriceSettingsService } from 'src/app/core/services/thuraya/th-price-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.scss']
})
export class PricesTableComponent implements OnInit {

  @Input() datasource 
  @Input() showingExcelData 
  @Output() updatePrice: EventEmitter<any> = new EventEmitter();

  constructor(
    private msgTranlsateService:MessageTranslateService,
    private thPriceSettingsService: ThPriceSettingsService,
    private toastr: ToastrService,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
  }

  //Update price function in parent because if the company is new it has row_no = 0  
  //so we can not update price but in back end we insert new data and company get a new row no. so in this case we need we must send company no in the updateprice api (from select box)
  update(data){
    this.updatePrice.emit(data)
  }

}
