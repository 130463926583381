<form class="form" [formGroup]="form">
    <div class="card-body">
        <div class="form-group row">
           
            <!-- Client Name -->
            <div class="col-lg-12 mb-3">
                <label for="fullName" class="mb-0"> <span class="requiredField">*</span> {{'Common.Name'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="fullName" [(ngModel)]="oldUserData.FullName"
                    type="text" formControlName="fullName" >
                <div *ngIf="form.controls.fullName.touched && form.controls.fullName.errors?.required"
                    class="invalid-input">
                    {{'Common.NameReq'|translate}}
                </div>
                <div *ngIf="form.controls.fullName.touched && form.controls.fullName.hasError('pattern')"
                    class="invalid-input">
                    {{'Common.Invalid'|translate}}
                </div>
            </div>
            <!-- phone number -->
            <div class="col-lg-12 mb-3">
                <label for="phoneNumber" class="mb-0"> <span class="requiredField">*</span> {{'Common.Phone'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="phoneNumber" [(ngModel)]="oldUserData.PhoneNumber"
                type="text" formControlName="phoneNumber" >
                <div *ngIf="form.controls.phoneNumber.touched && form.controls.phoneNumber.errors?.required"
                    class="invalid-input">
                    {{'Common.PhoneReq'|translate}}
                </div>
                <div *ngIf="form.controls.phoneNumber.touched && form.controls.phoneNumber.hasError('pattern')"
                    class="invalid-input">
                    {{'Common.PhoneFormat'|translate}}
                </div>
                <div *ngIf="form.controls.phoneNumber.touched && form.controls.phoneNumber.hasError('minlength')"
                    class="invalid-input">
                    {{'Common.PhoneSmall'|translate}}
                </div>
                <div *ngIf="form.controls.phoneNumber.touched && form.controls.phoneNumber.hasError('maxLength')"
                    class="invalid-input">
                    {{'Common.PhoneHuge'|translate}}
                </div>
            </div>
            <!-- email -->
            <div class="col-lg-12 mb-3">
                <label for="validationCustom02" class="mb-0"> <span class="requiredField">*</span> {{'Common.Email'|translate}} :</label>
                <input class="form-control col-xl-8 col-sm-7" id="validationCustom01"  [(ngModel)]="oldUserData.Email"
                type="text" formControlName="email">
                <div *ngIf="form.controls.email.touched && form.controls.email.errors?.required ||  form.controls.email.hasError('email') "
                    class="invalid-input">
                    {{'Common.EmailReq'|translate}}
                </div>
            </div>
        
            <!-- Action Buttons -->
            <div class="d-flex justify-content-end mb-3">
                <div>
                    <button type="button" class="btn btn-primary mr-1" style="margin-inline-end: 20px;" [disabled]="form.invalid" (click)="saveChanges()">{{ 'Common.Edit'|translate }}</button>
                    <button type="button" class="btn btn-light" (click)="cancelChanges()">{{'Common.Discard'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>