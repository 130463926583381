import { Component, OnInit,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EditDataDialogComponent } from '../edit-data-dialog/edit-data-dialog.component';
import { EditRolesDialogComponent } from '../edit-roles-dialog/edit-roles-dialog.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {

  @Input() datasource:any;
  private unsubscribe: Subscription[] = [];

  constructor(
    private halaAccountsService:HalaAccountsService,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    public dateFormatService:DateFormatService,
    private modalService:NgbModal,
    public permissionService:PermissionService) { }

  ngOnInit(): void {
  }

  /*----------------------API Requests---------------------- */
  changeUserStatus(event,user){
    // console.log("event",event)
    const subscr = this.halaAccountsService.LockOrUnLockUser(user.Id, !event.checked).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "", {
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(this.msgTranslateService.msgTranslate(result.Data), "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log(err)
        this.toastr.error(this.msgTranslateService.msgTranslate(err.error.title), "", {
          positionClass: 'toast-bottom-left',
        });
      }
    )
    this.unsubscribe.push(subscr)
  }

  /*----------------------Edit Roles Dialog---------------------- */
  editUserRoles(data){
    let modalRef = this.modalService.open(EditRolesDialogComponent, { centered: true });
      modalRef.componentInstance.userData = data;
      modalRef.componentInstance.updateParent.subscribe((newData) => {
        let index = this.datasource.findIndex(d=>d.Id== newData.userId)
        this.datasource[index].Roles =  newData.newRoles
      })
  }

  /*----------------------Edit Roles Dialog---------------------- */
  openEditDialog(data){
    let modalRef = this.modalService.open(EditDataDialogComponent, { centered: true });
      modalRef.componentInstance.userData = data;
      modalRef.componentInstance.updateParent.subscribe((newData) => {
        // console.log("newData",newData)
        let index = this.datasource.findIndex(d=>d.Id== newData.userId)
        this.datasource[index].FullName =  newData.fullName
        this.datasource[index].Email =  newData.email
        this.datasource[index].PhoneNumber =  newData.phoneNumber
      })
  }

  /*----------------------Component Destroy---------------------- */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
