<div class="table-responsive mb-0">
    <table class="table table-centered ">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ClassName'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}} </th>
          <th class="align-middle">{{'Common.EndUserPrice' | translate}}</th>
          <th class="align-middle">{{'Common.Price' | translate}}</th>
          <th class="align-middle" *ngIf="permissionService.checkPermission(['ThdbClientEditPrices.Update'])"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.class_name}}</a>
          </td>
          <td>{{data.class_cost}}</td>
          <td>{{data.class_price}}</td>
          <td>{{data.end_point_amount}}</td>
          <td *ngIf="permissionService.checkPermission(['ThdbClientEditPrices.Update'])">
            <button class="btn btn-info" (click)="update(data)">{{'Common.UpdatePrice' | translate}}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>