import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThOutstandingClassServiceService {

  API_URLGetOutstandingClassServicesData = `${environment.apiUrl}/ThdbOutstandingClassServices/GetOutstandingClassServicesDatas`;
  API_URLSaveUserNote = `${environment.apiUrl}/ThdbOutstandingClassServices/SaveUserNote`;

  constructor(private http:HttpClient) { }

  GetOutstandingClassServicesData(body): Observable<any> {
    const url = this.API_URLGetOutstandingClassServicesData;
    //Http request
    return this.http.post<any>(url,body)
  }

  SaveUserNote(body): Observable<any> {
    const url = this.API_URLSaveUserNote;
    //Http request
    return this.http.post<any>(url,body)
  }

}
