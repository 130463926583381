import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatService } from 'src/app/core/services/date-format.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { EditDataDialogComponent } from '../edit-data-dialog/edit-data-dialog.component';

@Component({
  selector: 'app-card-updates-table',
  templateUrl: './card-updates-table.component.html',
  styleUrls: ['./card-updates-table.component.scss']
})
export class CardUpdatesTableComponent implements OnInit {

  @Input() datasource;
  
  constructor(
    public dateFormatService:DateFormatService,
    private modalService:NgbModal,
    public permissionService:PermissionService) { }

  ngOnInit(): void {
  }

  /*----------------------Edit Roles Dialog---------------------- */
  openEditDialog(data){
    let modalRef = this.modalService.open(EditDataDialogComponent, { centered: true });
      modalRef.componentInstance.simData = data;
      modalRef.componentInstance.updateParent.subscribe((newData) => {
        // console.log("newData",newData)
        let index = this.datasource.findIndex(d=>d.Id== newData.Id)
        this.datasource[index] = newData;
        // keep the old date as the new data coming has differnet case(C capital in update but small in get) so the date is not shown
        this.datasource[index].createDate = data.createDate
      })
  }

}
