import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HalaCompaniesSettingsService } from '../../services/hala/hala-companies-settings.service';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';

@Component({
  selector: 'app-companies-not-in-group-dropdown',
  templateUrl: './companies-not-in-group-dropdown.component.html',
  styleUrls: ['./companies-not-in-group-dropdown.component.scss']
})
export class CompaniesNotInGroupDropdownComponent implements OnInit {

  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(private halaCompaniesSettingsService:HalaCompaniesSettingsService,) { }

  ngOnInit(): void {
    this.initComboSharedInput()
  }

  /*----------------------Functions Called from parent---------------------- */
  // emptyData(data){
  //   this.initComboSharedInputValues("companies");
  //   this.shrdInpt["companies"].comboboxref.dataSource=[];
  //   this.shrdInpt["companies"].comboboxref.dataBind();
  //   this.getAllCompanies();
  // }

  reset(){
    this.companiesNameVal = ""
  }

  /*----------------------Combox variables and functions---------------------- */
  //Combox variable
  public comboFiled: Object ={};
  public comboData =[];
  public companiescomboName="companies";
  companiesNameVal: string="";
  shrdInpt = {}

  initComboSharedInput(){
    this.shrdInpt = {
      companies:{},
    };

    this.initComboSharedInputValues(this.companiescomboName);
    
    this.getAllCompanies()
  }

  initComboSharedInputValues(comboname){
    // this.shrdInpt[comboname].comboboxref =null
    this.shrdInpt[comboname].combBoxEvent = null;
    this.shrdInpt[comboname].comboBoxList = [];
    this.shrdInpt[comboname].loadedList = [];
    this.shrdInpt[comboname].searchList = [];
    this.shrdInpt[comboname].endData = false;
    this.shrdInpt[comboname].selectedData= null;
    this.shrdInpt[comboname].isSearching = false;
    this.shrdInpt[comboname].searchText = "";
    this.shrdInpt[comboname].oldSearchText = "";
    this.shrdInpt[comboname].pageNumberBeforeSearch=1;
    this.shrdInpt[comboname].pageNumber=1;
    this.shrdInpt[comboname].pageSize=10;
    this.shrdInpt[comboname].isLoading = false;
  }

  /*----------------------Getting Companies for dropdown select---------------------- */
  getCompaniesBody(){
    let body={}
    if(this.shrdInpt[this.companiescomboName].isSearching){
      body={
        pageNumber: this.shrdInpt[this.companiescomboName].pageNumber,
        pageSize: this.shrdInpt[this.companiescomboName].pageSize,
        searchStrings: this.shrdInpt[this.companiescomboName].searchText,
        isPaginated: true,
      }
    }else{
      this.shrdInpt[this.companiescomboName].pageNumberBeforeSearch = this.shrdInpt[this.companiescomboName].pageNumber
      body={
        pageNumber: this.shrdInpt[this.companiescomboName].pageNumber,
        pageSize: this.shrdInpt[this.companiescomboName].pageSize,
        isPaginated: true,
      }
    } 
    return body;
  }

  getAllCompanies() {
    let body = this.getCompaniesBody();
    this.shrdInpt[this.companiescomboName].isLoading = true;
    const subscr = this.halaCompaniesSettingsService.GetAllCompaniesDataNotInGrouping(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          let resultData = result.Data;
          // case not Searching
          if(!this.shrdInpt[this.companiescomboName].isSearching){
            this.shrdInpt[this.companiescomboName].comboBoxList = []
              if(resultData.length){
                this.shrdInpt[this.companiescomboName].endData = false;
                for (let i = 0; i < resultData.length; i++) {
                  this.shrdInpt[this.companiescomboName].comboBoxList.push(`[${resultData[i].company_no}] - ${resultData[i].company_name}`);
                  this.shrdInpt[this.companiescomboName].loadedList.push(resultData[i]);
                  
                }
                this.shrdInpt[this.companiescomboName].comboboxref.addItem(this.shrdInpt[this.companiescomboName].comboBoxList  as { [key: string]: Object }[])
                this.shrdInpt[this.companiescomboName].comboboxref.hideSpinner();
                // this.comboFiled = { text: 'fullName', value: 'service_id' };
              }else{
                this.shrdInpt[this.companiescomboName].comboboxref.hideSpinner();
                //To detect when no more data avaialbale 
                this.shrdInpt[this.companiescomboName].endData = true;
              }
          }else{
            if(resultData.length){
              //only empty the search list if search string changed [so that if search not changed we have paginated results] 
              if(this.shrdInpt[this.companiescomboName].oldSearchText!=this.shrdInpt[this.companiescomboName].searchText){
                this.shrdInpt[this.companiescomboName].searchList= [];
                //update old value
                this.shrdInpt[this.companiescomboName].oldSearchText = this.shrdInpt[this.companiescomboName].searchText;
              }
              this.shrdInpt[this.companiescomboName].endData = false;
              for (let i = 0; i < resultData.length; i++) {
                this.shrdInpt[this.companiescomboName].searchList.push(`[${resultData[i].company_no}] - ${resultData[i].company_name}`);
              }
              this.shrdInpt[this.companiescomboName].comboboxref.hideSpinner();
              this.shrdInpt[this.companiescomboName].combBoxEvent.updateData(this.shrdInpt[this.companiescomboName].searchList);
            }else{
              this.shrdInpt[this.companiescomboName].comboboxref.hideSpinner();
              //To detect when no more data avaialbale 
              this.shrdInpt[this.companiescomboName].endData = true;
            }
          }
        }
        this.shrdInpt[this.companiescomboName].isLoading = false;
        // this.ref.detectChanges();
      },(err)=>{
        this.shrdInpt[this.companiescomboName].isLoading = false;
      }
    );
  }

  /*----------------------updating shared data---------------------- */

  updateSharedData(event){
    let key=event.key;
    let subkey=event.subkey;
    this.shrdInpt[key][subkey] = event.value
    // console.log(this.shrdInpt)
  }

  /*----------------------when Choosing Company---------------------- */
  changeSelectedCompany(selectedValue){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      //Getting id of selected service and setting value in for combobox and in form
      let indexofendId = selectedValue.indexOf(']')
      let companySelected = selectedValue.substring(1,indexofendId)
      this.companiesNameVal = selectedValue;
      this.updateParent.emit(companySelected);   
    }else{
      this.updateParent.emit("");
    }
  }

}
