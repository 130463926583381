import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrxRefundRoutingModule } from './trx-refund-routing.module';
import { GetRefundsComponent } from './get-refunds/get-refunds.component';
import { CoreModule } from 'src/app/core/core.module';
import { RefundsTableComponent } from './get-refunds/_components/refunds-table/refunds-table.component';


@NgModule({
  declarations: [
    GetRefundsComponent,
    RefundsTableComponent
  ],
  imports: [
    CommonModule,
    TrxRefundRoutingModule,
    CoreModule
  ]
})
export class TrxRefundModule { }
