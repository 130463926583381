import { Component, Input, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/core/services/date-format.service';

@Component({
  selector: 'app-bulk-details-table',
  templateUrl: './bulk-details-table.component.html',
  styleUrls: ['./bulk-details-table.component.scss']
})
export class BulkDetailsTableComponent implements OnInit {

  @Input() datasource;
  @Input() detailsTotalCount;
  
  constructor(public dateFormatService:DateFormatService) { }

  ngOnInit(): void {
  }

}
