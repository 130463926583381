import { HttpClient } from '@angular/common/http';
import { Injectable,  } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HalaRoleService {

 
  API_URLGetRoles = `${environment.apiUrl}/HalaRole/GetRoles`;
  API_URLGetClaims = `${environment.apiUrl}/HalaRole/GetClaims`;
  API_URLUpdateRoleClaims = `${environment.apiUrl}/HalaRole/UpdateRoleClaims`;
  API_URLDeleteRole = `${environment.apiUrl}/HalaRole/DeleteRole`;

  constructor(private http:HttpClient) { }

  GetRoles(): Observable<any> {
    const url = this.API_URLGetRoles;
    //Http request
    return this.http.get<any>(url);
  }

  GetClaims(): Observable<any> {
    const url = this.API_URLGetClaims;
    //Http request
    return this.http.get<any>(url)
  }

  UpdateRoleClaims(body:any): Observable<any> {
    const url = this.API_URLUpdateRoleClaims;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteRole(roleName:any): Observable<any> {
    const url = this.API_URLDeleteRole;
    let param = {RoleName: roleName};
    //Http request
    return this.http.delete<any>(url,{params: param})
  }
}
