<!-- Filters Row  -->
<div class="row">
    <div class="col-xl-12">
        <div
        class="card card-custom gutter-b example example-compact px-8 py-2"
        >
        <form class="form" [formGroup]="form">
            <div class="card-body">
            <div class="form-group row">
                <!-- Date from -->
                <div class="col-lg-3">
                    <label>{{'Common.DateFrom'|translate}}:</label>
                    <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                </div>
                <!-- Date To -->
                <div class="col-lg-3">
                    <label>{{'Common.DateTo'|translate}}:</label>
                    <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                </div>
                    <!-- Customer account -->
                <div class="col-lg-3">
                    <label>{{'HALA.Additions.CustomerAccount'|translate}}  :</label>
                    <input type="text" class="form-control" placeholder="" formControlName="cust_account_id" />
                </div>
                <!-- Action Buttons -->
                <div class="col-lg-3 d-flex align-items-end" >
                    <div class="form-group row">
                    <div class="col-lg-4 action-btns">
                        <button
                        type="submit"
                        (click)="submitSearch()"
                        class="btn btn-primary font-weight-bold px-10 mr-2"
                        >
                        {{'Common.Search'|translate}}
                        </button>
                    </div>
                    <div class="col-lg-4 action-btns">
                        <button
                        type="submit"
                        (click)="reset()"
                        class="btn btn-danger font-weight-bold px-10 mr-2"
                        >
                        {{'Common.Discard'|translate}}
                        </button>
                    </div>
                    <div class="col-lg-4"></div>
                    </div>
                </div>
            </div>
            
            </div>
        </form>
        </div>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="dataSource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>

<div class="card py-3 px-3">
    <!-- Table -->
    <ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>
    <app-additions-table [datasource]="dataSource" [totalCreditAmount]="totalCreditAmount"></app-additions-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>

  
