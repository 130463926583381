import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetBillHeaderComponent } from './get-bill-header/get-bill-header.component';

const routes: Routes = [
  { path: 'get-bill-header', component: GetBillHeaderComponent,canActivate: [AuthGuard],  data: {permission: ['BigDataBill.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillRoutingModule { }
