import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ProfitUserDataComponent } from './profit-user-data/profit-user-data.component';
import { UserSummaryDataComponent } from './user-summary-data/user-summary-data.component';

const routes: Routes = [
  { path: 'profit-user-company', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-company-card', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-company-client', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-company-card-client', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-company-payments', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-balance-transfers', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-client', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'profit-user-company-invoice', component: ProfitUserDataComponent, canActivate: [AuthGuard],  data: {permission: ['ThdbProfitUser.View'], roles:[]} },
  { path: 'user-summary-data', component: UserSummaryDataComponent, canActivate: [AuthGuard],  data: {permission: [], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfitUserRoutingModule { }
