import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { GetCustSalesDetailsComponent } from './get-cust-sales-details/get-cust-sales-details.component';

const routes: Routes = [
  { path: 'get-customer-sales-details', component: GetCustSalesDetailsComponent,canActivate: [AuthGuard],   data: {permission: ['ThdbCustomerSalesDetails.View'], roles:[]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerSalesDetailsRoutingModule { }
