import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OutstandingCardsRoutingModule } from './outstanding-cards-routing.module';
import { GetOutstandCardsComponent } from './get-outstand-cards/get-outstand-cards.component';
import { OutstandingTableComponent } from './get-outstand-cards/_components/outstanding-table/outstanding-table.component';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    GetOutstandCardsComponent,
    OutstandingTableComponent
  ],
  imports: [
    CommonModule,
    OutstandingCardsRoutingModule,
    CoreModule
  ]
})
export class OutstandingCardsModule { }
