import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HalaAccountsService } from 'src/app/core/services/hala/hala-accounts.service';
import { HalaRoleService } from 'src/app/core/services/hala/hala-role.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';

@Component({
  selector: 'app-edit-roles-dialog',
  templateUrl: './edit-roles-dialog.component.html',
  styleUrls: ['./edit-roles-dialog.component.scss']
})
export class EditRolesDialogComponent implements OnInit {


  @Input() userData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

   
   //local Variables
   defaultUserRoles = [];
   selectedUserRoles = []
   result: any;
   roles:any;
   isChecked=false;
   changes=false;
 
   constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private halaRoleService: HalaRoleService,
    private halaAccountsService:HalaAccountsService,
    private modalService:NgbModal,
    private msgTranslateService:MessageTranslateService,
   ) { }
 
   ngOnInit(): void {
    // adding user existing roles to the array with spread operator to copy only values without reference 
    this.GetRoles();
    this.spinner.show();
    this.defaultUserRoles = [...this.userData.Roles];
    this.selectedUserRoles = [...this.userData.Roles];
 
   }
 
  //Getting Available Roles
  GetRoles() {
    return this.halaRoleService.GetRoles().subscribe(
      (result) => {
        this.spinner.hide();
        this.roles = result.Data;
      }
    );
  }
   
   //Check if selected user has the role already
   checkRoleExist(role:any){
     if(this.userData.Roles!=null && this.userData.Roles.includes(role.RoleName) ){
       return true;
     }
     else{
       return false;
     }
      
   }
 
   //Checks default user permission with the changes
   checkChanges = (newArray, oldArray) => {
     //check that every value in the default array of permessions (oldArray) already in new array of permission (newArray) and newarray does not contian others (same length) 
     let nochange= oldArray.every(v => newArray.includes(v)) && oldArray.length==newArray.length;
     return nochange
   } 
 
   //Toggle Changes
   fieldsChange(event:any, role:any){
    // console.log(event)
      if(event.checked){
       this.selectedUserRoles.push(role.RoleName)
     }else{
       this.selectedUserRoles = this.selectedUserRoles.filter(userRole =>{ return userRole !== role.RoleName});
     }
 
     //Check changes with default user permissions. if there is a change we can save
     let noChanges = this.checkChanges(this.selectedUserRoles,this.defaultUserRoles);
       if(noChanges){
         this.changes=false;
       }else{
         this.changes=true;
       }
    }
 
   //Discard changes
   cancelChanges(){
     this.modalService.dismissAll()
     //Return the user roles to default
     this.selectedUserRoles = []
    //  this.userData.Roles = [...this.defaultUserRoles];
   }
 
   //Submit Changes
   saveChanges(){
     let body={
       userId:this.userData.Id,
       roleNames:this.selectedUserRoles
     }
     return this.halaAccountsService.UpdateUserRoles(body).subscribe(
       (result) => {
         //sending the updated roles to the parent (user management to update table UI)
         let newData = {
          userId:this.userData.Id,
          newRoles:this.selectedUserRoles
         }
         this.updateParent.emit(newData)
         this.modalService.dismissAll();
         this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
           positionClass: 'toast-bottom-left',
         });
       },
       err => {
         this.modalService.dismissAll()
         this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
           positionClass: 'toast-bottom-left',
         });
       }
     );
   }
 

}
