import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThPosBulkBillService {


  API_URLGetPosBulkBillData = `${environment.apiUrl}/ThdbPosBulkBill/GetPosBulkBillData`;

  constructor(private http:HttpClient) { }

  GetPosBulkBillData(body): Observable<any> {
    const url = this.API_URLGetPosBulkBillData;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
