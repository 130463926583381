import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HalaCardUpdateService } from 'src/app/core/services/hala/hala-card-update.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-data-dialog',
  templateUrl: './edit-data-dialog.component.html',
  styleUrls: ['./edit-data-dialog.component.scss']
})
export class EditDataDialogComponent implements OnInit {

  @Input() simData;
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  oldsimData:any
  //Form variables
  form: FormGroup;
  
  constructor( 
    private fb: FormBuilder,
    private modalService:NgbModal,
    private toastr: ToastrService,
    private msgTranslateService:MessageTranslateService,
    private halaCardUpdateService:HalaCardUpdateService,) { }

  ngOnInit(): void {
    this.initForm();
    this.oldsimData = {...this.simData}
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      agentName: new FormControl("",Validators.required),
      oldSerial: new FormControl(""),
      agentPhoneNo: new FormControl("",Validators.required),
      newSerial: new FormControl("",Validators.required),
      isAction: new FormControl("",Validators.required),
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Discard---------------------- */
  cancelChanges(){
    this.modalService.dismissAll()
  }

  /*----------------------Form Functions---------------------- */
  saveChanges(){
    //Check fields 
    if (this.form.invalid){
      this.toastr.warning(this.msgTranslateService.msgTranslate("SwalMessages.InsertData"), "",{
        positionClass: 'toast-bottom-left',
      });
      return;
    }
    let body={
      id:this.simData.Id,
      agentName: this.f.agentName.value,
      oldSerial: this.f.oldSerial.value,
      agentPhoneNo: this.f.agentPhoneNo.value,
      newSerial: this.f.newSerial.value,
      isAction: this.f.isAction.value,
    }
    
    return this.halaCardUpdateService.UpdateCardUpdates(body).subscribe(
      (result) => {
        if(result.IsSuccess){
          this.updateParent.emit(result.Data)
          this.modalService.dismissAll();
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccUpdate'), "",{
            positionClass: 'toast-bottom-left',
          });
        }else{
          this.toastr.error(result.Data, "",{
            positionClass: 'toast-bottom-left',
          });
        }
      },
      err => {
        this.modalService.dismissAll()
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErrorMsg'), "",{
          positionClass: 'toast-bottom-left',
        });
      }
    );
  }

}
