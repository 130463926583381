import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HalaCompaniesSettingsService } from 'src/app/core/services/hala/hala-companies-settings.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MessageTranslateService } from 'src/app/core/services/message-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ThGeneralSystemService } from 'src/app/core/services/thuraya/th-general-system.service';
import Swal from 'sweetalert2';
import { AddCompaniesDialogComponent } from './_components/add-companies-dialog/add-companies-dialog.component';

@Component({
  selector: 'app-get-all-groups',
  templateUrl: './get-all-groups.component.html',
  styleUrls: ['./get-all-groups.component.scss']
})
export class GetAllGroupsComponent implements OnInit {

  //Form variables
  form: FormGroup;

  //Grid Variables
  public pageOptions: Object;
  @ViewChild("grid") grid: GridComponent;
  @ViewChild("groupMembers") groupMembers: ElementRef ;
  public dataSource = []
  public toolbarOptions: ToolbarItems[];
  public aggreagtes: Object;
  public pageSettings: Object;

  //Table variables
  companiesDataSource = []
  pageNumber:number = 1;
  pageSize:number = 10;
  totalCount:number = 0;

  selectedGroupId="";

  @ViewChild("companiesDropDown") companiesDropDown;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private halaCompaniesSettingsService: HalaCompaniesSettingsService,
    private thGeneralSystemService:ThGeneralSystemService,
    private msgTranlsateService:MessageTranslateService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public permissionService:PermissionService,
    public languageService:LanguageService,
    ) { }

  ngOnInit(): void {
    this.toolbarOptions = ["ExcelExport", "Print"];
    this.getAllGroups();
    this.initForm();
  }

  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      company_no: new FormControl(""),
    });
    }
  
  get f(){
    return this.form.controls;
  }

  /*----------------------API REQUESTS---------------------- */
  getAllGroups() {
    this.spinner.show(); 
    this.halaCompaniesSettingsService.GetAllGroups().subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.dataSource = result.Data
        }
        this.spinner.hide(); 
      },(err)=>{
        this.spinner.hide(); 
      }
    )
  }

  addUpdateGroup(bool,data) {
    let isEditing = bool =="true" ? true : false
    Swal.fire({
      input: "text",
      inputLabel: this.msgTranlsateService.msgTranslate('HALA.GroupsSettings.GroupName'),
      inputValue: isEditing ? data.GroupName : "",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: isEditing ? this.msgTranlsateService.msgTranslate('Common.Edit') : this.msgTranlsateService.msgTranslate('Common.Add'),
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return this.msgTranlsateService.msgTranslate('HALA.GroupsSettings.GroupNameReq')
        }
      },
      preConfirm: (value) => {
        let body = {}
        if(isEditing){
           body ={
            id: data.Id, 
            groupName: value
          }
        }else{
          body ={
            groupName: value
          }
        }
        this.halaCompaniesSettingsService.AddUpdateNewGroup(body).subscribe(
          (result)=>{
            if(result.IsSuccess){
              this.dataSource = result.Data
              this.grid.refresh();
              let msg = isEditing ? this.msgTranlsateService.msgTranslate('ToastMessages.SuccUpdate') : this.msgTranlsateService.msgTranslate('ToastMessages.SuccAdd')
              this.toastr.success(msg, "",{
                positionClass: 'toast-bottom-left',
              });
            }
          },(err)=>{
            this.toastr.error(err.error.errors, "",{
              positionClass: 'toast-bottom-left',
            });
          }
        )
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  showAlert(data){
    Swal.fire({
      title: this.msgTranlsateService.msgTranslate('SwalMessages.Delete'),
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:this.msgTranlsateService.msgTranslate('Common.Discard'),
      confirmButtonText: this.msgTranlsateService.msgTranslate('Common.Confirm'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.deleteGroup(data);
      },
    })
  }
  
  deleteGroup(data){
    let body = {
      id: data.Id,
    }
    this.halaCompaniesSettingsService.DeleteGroup(body).subscribe(
      (result)=>{
        if(result.IsSuccess){
          this.dataSource = this.dataSource.filter(d=> d.Id != data.Id);
          this.grid.refresh();
          this.toastr.success(this.msgTranlsateService.msgTranslate('ToastMessages.SuccDelete'), "",{
            positionClass: 'toast-bottom-left',
          });
        }
        this.spinner.hide(); 
      },(err)=>{
        this.spinner.hide(); 
      }
    )
  }

  getAllCompaniesGroupingData(data){
    //on change page keep track of group id until we select new group
    if(data){
      this.selectedGroupId = data.Id
    }
    this.spinner.show(); 
    let body ={
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      isPaginated: true,
      companyNo: this.f.company_no.value,
      groupId: this.selectedGroupId, 
    }
    this.halaCompaniesSettingsService.GetAllCompaniesGroupingData(body).subscribe(
      (result)=>{
        if(result.IsSuccess && result.Data.length){
          this.companiesDataSource = result.Data
          this.totalCount = result.Data[0].TotalRows
          let scope = this
          setTimeout(function(){scope.groupMembers.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" }); },100);
        }else{
          this.companiesDataSource = []
          this.totalCount = 0;
          this.toastr.warning(this.msgTranlsateService.msgTranslate("ToastMessages.NoMembers"), "",{
            positionClass: 'toast-bottom-left',
          })
        }
        this.spinner.hide(); 
      },(err)=>{
        this.companiesDataSource = [];
        this.totalCount = 0;
        this.spinner.hide(); 
      }
    )
  }

  /*----------------------Pagination---------------------- */
  changePage(event){
    this.pageNumber = event;
    if(this.selectedGroupId!=""){
      this.getAllCompaniesGroupingData(null);
    }
    // console.log("pagechange",event)
  }

  /*----------------------Filter---------------------- */
  search(){
    //reset page number
    this.pageNumber = 1;
    this.getAllCompaniesGroupingData(null)
  
  }

  reset(){
    this.initForm()
    this.companiesDropDown.reset()
    this.pageNumber = 1
    this.companiesDataSource = []
    this.totalCount =0
  }

  /*----------------------Add modal---------------------- */
  addCompaniesToGroup(data){
    let modalRef = this.modalService.open(AddCompaniesDialogComponent, { centered: true });
    modalRef.componentInstance.groupData = data;
    modalRef.componentInstance.updateParent.subscribe(() => {
      this.dataSource.filter(d=>d.Id== data.Id)[0].companiesCount++;
      this.grid.refresh();
    })
  }

  /*----------------------when Choosing Agent---------------------- */
  setSelectedCompany(event){
    this.form.controls.company_no.setValue(event)
  }

}
