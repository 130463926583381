<div class="table-responsive mb-0">
    <table class="table table-centered">
      <thead class="table-light table-nowrap">
        <tr>
          <th class="align-middle" *ngIf="!IsClientPage" >{{'Common.ServiceId' | translate}}</th>
          <th class="align-middle" *ngIf="IsCompanyCardPage || IsCompanyCardClientPage">{{'Common.ClassName' | translate}}</th>
          <th class="align-middle" *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage">{{'Common.ClientPhone' | translate}}</th>
          <th class="align-middle" *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage">{{'Common.ClientName' | translate}}</th>
          <th class="align-middle">{{'Common.POSPrice' | translate}}</th>
          <th class="align-middle">{{'Common.POSProfit' | translate}}</th>
          <th class="align-middle">{{'Common.DistributorPrice' | translate}}</th>
          <th class="align-middle">{{'Common.DistributorProfit' | translate}}</th>
          <th class="align-middle">{{'Common.Number' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td *ngIf="!IsClientPage">
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td *ngIf="IsCompanyCardPage || IsCompanyCardClientPage">{{data.note}}</td>
          <td *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage">{{data.account_id}}</td>
          <td *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage">{{data.UserName}}</td>
          <td>{{data.EndPointPrice}}</td>
          <td>{{data.EndPointProfit}}</td>
          <td>{{data.DistPrice }}</td>
          <td>{{data.LastProfit}}</td>
          <td>{{data.rowsCount}}</td>
        </tr>
        <tr >
          <td  *ngIf="!IsClientPage"></td>
          <td  *ngIf="IsCompanyCardPage || IsCompanyCardClientPage"></td>
          <td  *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage"></td>
          <td  *ngIf="IsCompanyClientPage || IsCompanyCardClientPage || IsClientPage"></td>
          <td>
              <div class="d-flex flex-column">
                <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
                <span>{{AllEndPointPrice==0 ? '-' :AllEndPointPrice | number : '1.0-2'}}</span>
              </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{AllEndPointProfit==0 ? '-' :AllEndPointProfit | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{AllDistPrice==0 ? '-' :AllDistPrice | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.Total'| translate}}</span> 
              <span>{{AllLastProfit==0 ? '-' :AllLastProfit | number : '1.0-2'}}</span>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>