<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="table-light">
        <tr>
          <th class="align-middle">{{'Common.ServiceId'| translate}}</th>
          <th class="align-middle">{{'Common.CardName'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PurchaseRate.CardType'| translate}}</th>
          <th class="align-middle">{{'REPORTS.PurchaseRate.CardAmount'| translate}}</th>
          <th class="align-middle">{{'Common.Cost'| translate}} </th>
          <th class="align-middle">{{'Common.TotalCost'| translate}} </th>
          <th class="align-middle">{{'Common.TotalPrice'| translate}} </th>
          <th class="align-middle"> {{'REPORTS.PurchaseRate.DaysNo'| translate}}</th>
          <th class="align-middle"> {{'REPORTS.PurchaseRate.Rate'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td>
            <a class="text-body fw-bold">{{data.service_id}}</a>
          </td>
          <td>{{data.class_name}}</td>
          <td>{{data.source_type}}</td>
          <td>{{data.count}}</td>
          <td>{{data.class_cost}}</td>
          <td>{{data.TotalCost}}</td>
          <td>{{data.TotalPrice}}</td>
          <td>{{data.DiffDays}}</td>
          <td>{{data.Rate}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalCost'| translate}}</span> 
              <span>{{sumTotalCost==0 ? '-' :sumTotalCost | number : '1.0-2'}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span style="font-weight: 800;">{{'Common.TotalPrice'| translate}}</span> 
              <span>{{sumTotalPrice==0 ? '-' :sumTotalPrice | number : '1.0-2'}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
</div>