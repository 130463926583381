<ejs-grid #grid id="Grid" [enableRtl]="languageService.selectedLanguage=='ar'" [dataSource]="dataSource"
    [toolbar]="toolbarOptions" [allowPaging]="true" [allowExcelExport]="true" 
    (toolbarClick)="toolbarClick($event)">
    <e-columns>
        <e-column field="service_id" headerText=" {{'Common.ServiceId'| translate}}  "></e-column>
        <e-column field="class_name" headerText="  {{'Common.CardName'| translate}} "></e-column>
        <e-column field="source_type" headerText="{{'REPORTS.PurchaseRate.CardType'| translate}}"></e-column>
        <e-column field="count" headerText="{{'REPORTS.PurchaseRate.CardAmount'| translate}}" ></e-column>
        <e-column field="class_cost" headerText="  {{'Common.Cost'| translate}} "></e-column>
        <e-column field="TotalCost" headerText="  {{'Common.TotalCost'| translate}} "></e-column>
        <e-column field="TotalPrice" headerText="  {{'Common.TotalPrice'| translate}} "></e-column>
        <e-column field="DiffDays" headerText="  {{'REPORTS.PurchaseRate.DaysNo'| translate}} "></e-column>
        <e-column field="Rate" headerText="{{'REPORTS.PurchaseRate.Rate'| translate}}" ></e-column>
    </e-columns>
</ejs-grid>