import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.scss']
})
export class CompanyTableComponent implements OnInit {

  @Input() datasource;
  @Input() IsCompanyPage
  @Input() IsCompanyCardPage
  @Input() IsCompanyClientPage
  @Input() IsCompanyCardClientPage
  @Input() IsClientPage

  AllDistPrice:number = 0;
  AllEndPointPrice:number = 0;
  AllEndPointProfit:number = 0;
  AllLastProfit:number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() { 
    if(this.datasource.length>0){
      this.AllDistPrice = this.datasource[0].AllDistPrice;
      this.AllEndPointPrice = this.datasource[0].AllEndPointPrice;
      this.AllEndPointProfit = this.datasource[0].AllEndPointProfit;
      this.AllLastProfit = this.datasource[0].AllLastProfit;
    }else{
      this.AllDistPrice = 0;
      this.AllEndPointPrice = 0
      this.AllEndPointProfit = 0;
      this.AllLastProfit = 0;
    }
  }

}
