<!--Filter Row-->
<div class="row">
    <div class="col-xl-12">
    <div class="card card-custom gutter-b example example-compact px-8 py-2">
        <div class="card-body">
            <div class="form-group row">
                <!-- Date From -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateFrom'|translate}}</label>
                    <app-date-selector #dateFromSelector (setSelectedDate)="setSelectedDate($event,'from')"></app-date-selector>
                </div>
                <!-- Date to -->
                <div class="col-lg-3 mb-3">
                    <label>{{'Common.DateTo'|translate}}</label>
                    <app-date-selector #dateToSelector (setSelectedDate)="setSelectedDate($event,'to')"></app-date-selector>
                </div>
                <!-- Action Buttons -->
                <div class="col-lg-3 d-flex align-items-end mb-3">
                    <app-action-buttons (onSearch)="search()" (onReset)="reset()"></app-action-buttons>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>

<!-- Export Excel -->
<app-excel-export [datasource]="datasource" [isExporting]="isExporting" (exportExcel)="exportExcel($event)"></app-excel-export>


<ngx-spinner type="ball-scale-pulse" [fullScreen]="true" color="white" size="large"></ngx-spinner>

<div class="card py-3 px-3">
    <!-- Table -->
    <app-cust-sales-table [datasource]="datasource" ></app-cust-sales-table>
    
    <!-- Pagination -->
    <app-paginator [totalCount]="totalCount" [pageNumber]="pageNumber" (changePage)="changePage($event)" ></app-paginator>
</div>
