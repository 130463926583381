
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbDatepickerConfig, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { GridComponent, PageSettingsModel, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Swal from 'sweetalert2';
import { DatePipe } from "@angular/common";
import { HalaManageCardsService } from "src/app/core/services/hala/hala-manage-cards.service";
import { ThGeneralSystemService } from "src/app/core/services/thuraya/th-general-system.service";
import { HalaProvidersService } from "src/app/core/services/hala/hala-providers.service";
import { DeclarationHelper } from "src/app/core/helpers/declaration-helper";
import { ToastrService } from "ngx-toastr";
import { MessageTranslateService } from "src/app/core/services/message-translate.service";
import { PermissionService } from "src/app/core/services/permission.service";
import { LanguageService } from "src/app/core/services/language.service";

@Component({
  selector: 'app-send-cards',
  templateUrl: './send-cards.component.html',
  styleUrls: ['./send-cards.component.scss']
})
export class SendCardsComponent implements OnInit {

  public toolbarOptions: ToolbarItems[] | object;
  @ViewChild("grid")
  public grid: GridComponent;
  public gridDetails: GridComponent;
  dataSource = [];
  private subscriptions: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  form: FormGroup;
  formCard: FormGroup;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  

  isLoading: boolean;
  isEditing:boolean = false;

  public result: any;
  public trxFormat: object;
  public aggreagtes: Object;
  public pageSettings: Object;

  //Header Details table variables 
  selectedCardHeaderId:any=null
  selectedCardProviderName:any=null
  pageNumber: number=1;
  pageSize: number=10;

  @Input() dateDay: string = "";
  @Input() dateTo: string = "";


  focusSerial: boolean = true;
  @ViewChild('FocusInputSerial') serialInput: ElementRef;

  focusPin: boolean = true;
  @ViewChild('FocusInputPin') PinInput: ElementRef;


  fromDateCom: any;
  toDateCom: any;
  fromDateTimeStamp;
  toDateTimeStamp

  public data: Object[] = [];
  public format = { type: "date", format: "yyyy/MM/dd" };
  public formatTime = { type: "date", format: " hh:mm a" };
  ServicesList = [];
  ClassNameList = [];
  minDate = undefined;

  dateFromVal: any;
  dateToVal: any;
  classNoVal: string;
  orderNoVal: string;

  public selectedRowIndex;
  public key: any = null;
  public detailSource:any = [];
  @ViewChild("detailgrid") detailGrid: GridComponent;
  @ViewChild("servicesDropDown") servicesDropDown;
  @ViewChild("providersDropDown") providersDropDown;
  @ViewChild("dateToSelector") dateToSelector;
  public master: Object = [];


  constructor(
    public halaManageCardsService: HalaManageCardsService,
    public halaProvidersService: HalaProvidersService,
    private thGeneralSystemService:ThGeneralSystemService,
    private msgTranslateService:MessageTranslateService,
    public permissionService:PermissionService,
    public languageService:LanguageService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    this.initForm()
    this.initCardForm()
  }

  public initialPage: PageSettingsModel;
  ngOnInit() {
    // this.GetAllProvidersData();
    // this.GetServicesData();
    this.getAllHeaders({
      isPaginated: false
    });
    // this.initialPage = { pageSize: 250 };
    this.toolbarOptions = [
      "ExcelExport",
      "Print"
    ];
    this.pageSettings={
      pageSize:10
    }
    onkeydown = function (e) {
      //e.preventDefault();
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)) {
        return false;
      }
    }

    this.aggreagtes = [
      {
        columns: [
          {
            type: "Sum",
            field: "totalCardsNo",
            format: "N2",

          },
          {
            type: "Sum",
            field: "totalCost",
            format: "N2",
          },
          {
            type: "Sum",
            field: "totalPrice",
            format: "N2",
          }

        ],
      },
    ];
  }
  
  //format date to be also exported in excel with this format
  dateFormatter(field: string, data: any, column: Object): string { 
    const date = new Date(data[field]);
    if (date instanceof Date) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    } else {
      return "";
    }
  }

  timeFormatter(field: string, data: any, column: Object): string { 
    const date = new Date(data[field]);
    if (date instanceof Date) {
      return `${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',hour12: true })}`;
    } else {
      return "";
    }
  }

  /*----------------------Form Functions---------------------- */
  initForm(){
    this.form = this.fb.group({
      ServiceId: new FormControl(""),
      class_no: new FormControl(""),
      providerId: new FormControl(""),
      orderNo: new FormControl(""),
    });
  }

  initCardForm(){
    this.formCard = this.fb.group({
      serialCard: new FormControl("",[
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(6),]),
      pinCard: new FormControl("",[
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(6)
      ])
    });
  }

  get f(){
    return this.form.controls;
  }

  /*----------------------Grid Function---------------------- */
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport();
        break;
    }
  }

  toolbarClickDetails(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Excel Export":
        this.detailGrid.excelExport();
        break;
    }
  }

  /*----------------------Edit Row and cancel row edit functions---------------------- */
  rowSelected(data) {
    this.isEditing = true;
    this.selectedCardHeaderId = data.CardHeaderId;
    this.selectedRowIndex = this.selectedCardHeaderId;
    this.halaManageCardsService.GetCardsHeaderById(this.selectedCardHeaderId)
      .subscribe((result) => {
        this.result = result;
        // console.log("here",result)
        let newDateT = new Date(result.Data.ExpirationDate);
        // this.dateToVal = { year: newDateT.getFullYear(), month: newDateT.getMonth() + 1, day: newDateT.getDate() };
        // this.form.controls.dateTo.setValue(this.dateToVal)
        this.fromDateCom = result.Data.AddedDate
        this.toDateCom = result.Data.ExpirationDate; 

        //Combobox does not support ngmodel so we do it manullay for each combobox (here service case)
        // this.servicesDropDown.setValue(result.Data)
        this.form.controls.ServiceId.setValue(result.Data.Service_Id)
        // console.log("service",this.form.controls.ServiceId.value)
        
        //Providers
        // this.providersDropDown.setValue(result.Data)
        this.selectedCardProviderName = result.Data.ProviderName
        this.form.controls.providerId.setValue(result.Data.ProviderId)
        
        //Class no (card)
        // this.classNoVal = `[${result.Data.class_no}] - ${result.Data.Class_Name}`;
        this.form.controls.class_no.setValue(result.Data.class_no)
        // console.log("clas",this.form.controls.class_no.value)
        
        // this.orderNoVal = result.Data.OrderNo; //now we hide the form so we can not depend on ngModel
        this.form.controls.orderNo.setValue(result.Data.OrderNo)
      });

    let body = {
      isPaginated: false,
      cardHeaderId: this.selectedCardHeaderId,
      isManager: false
    }
    return this.halaManageCardsService.GetThisHeaderDetails(body)
      .subscribe((result) => {
        this.detailSource = result.Data;
        this.ref.detectChanges();
      });

  }

  cancelEdit(){
    this.isEditing = false;
    this.selectedRowIndex =null;
    this.selectedCardHeaderId = null;
    this.detailSource = [];
    this.reset()
  }

  /*--------------------------Date for requests-------------------------- */
  pipe = new DatePipe('en-US');
  setSelectedDate(event,type){
    this.fromDateCom = new Date();
    this.fromDateTimeStamp = this.fromDateCom.getTime()
    this.fromDateCom = this.pipe.transform(this.fromDateCom, 'MM/dd/yyyy');
    
    this.toDateCom = event.date
    this.toDateTimeStamp = event.dateTimeStamp
  }

  /*----------------------API Requests---------------------- */
  getAllHeaders(body) {
    return this.halaManageCardsService.GetAllHeadersNotSentForThisUser(body).subscribe((result) => {
      this.result = result;
      if(result.IsSuccess){
        this.dataSource = result.Data
      }
      if(result.Data.length==0){
        this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.EmptyData'), "", {
          positionClass: 'toast-bottom-left',
        });
      }
      this.ref.detectChanges();
    });
  }

  getCards() {
    let body = {
      isPaginated: false,
      cardHeaderId: this.selectedCardHeaderId,
      isManager: false
    }
    return this.halaManageCardsService.GetThisHeaderDetails(body)
      .subscribe((result) => {
        if(result.IsSuccess){
          this.result = result;
          this.detailGrid.dataSource = result.Data;
          this.ref.detectChanges();
      }
    });
  }

  showAlertDeleteHeader(dataSource){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate("SwalMessages.Delete"),
      icon: 'info',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText:this.msgTranslateService.msgTranslate("Common.Confirm"),
      cancelButtonText:this.msgTranslateService.msgTranslate("Common.Discard"),
      preConfirm: ()=> {
        this.deleteCardHeader(dataSource)
      },
    }); 
  }

  deleteCardHeader(dataSource){
    return this.halaManageCardsService.DeleteCardHeader(dataSource.CardHeaderId).subscribe((result) => {
      if(result.IsSuccess){
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccDelete'),"",{
          positionClass: 'toast-bottom-left',
        })
        this.updateDataSource(dataSource.CardHeaderId, "deleteHeader")
      }
    });
  }

  showAlert(dataSource){
    Swal.fire({
      title: this.msgTranslateService.msgTranslate("SwalMessages.Send"),
      icon: 'info',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText:this.msgTranslateService.msgTranslate("Common.Confirm"),
      cancelButtonText:this.msgTranslateService.msgTranslate("Common.Discard"),
      preConfirm: ()=> {
        this.SendTheseCardsToApprove(dataSource)
      },
    }); 
  }
  
  SendTheseCardsToApprove(dataSource) {
    let rowId = dataSource.CardHeaderId;
    //cancel edit before send
    this.cancelEdit()
    // console.log("row",rowId)
    return this.halaManageCardsService.SendCards(rowId).subscribe((result) => {
      if(result.IsSuccess){
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccSend'),"",{
          positionClass: 'toast-bottom-left',
        })
        this.updateDataSource(dataSource.CardHeaderId, "send")
        // this.getAllHeaders({});
      }
      this.ref.detectChanges();
    });
  }

  DeleteThisCard(card: any) {
    return this.halaManageCardsService.DeleteCardDetails(card.Id)
      .subscribe((result) => {
        if(result.IsSuccess){
          this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccDelete'), "", {
            positionClass: 'toast-bottom-left',
          });

          //Update the headers with the new card numbers
          // this.getAllHeaders({});
          this.detailSource = this.detailSource.filter(c=>c.Id != card.Id);
          this.updateDataSource(this.selectedCardHeaderId, "delete")
        }else{
          this.toastr.error(this.msgTranslateService.msgTranslate('ToastMessages.ErroMsg'), "", {
            positionClass: 'toast-bottom-left',
          });
        }
      },
      (err)=>{
        // console.log("err",err)
        this.toastr.error(err.error.Message, "", {
          positionClass: 'toast-bottom-left',
        });
      }
      );
  }

  tryToAddCard() {
    const formData = this.form.value;
    let ServiceId = formData.ServiceId;
    let class_no = formData.class_no;
    let providerId = formData.providerId;
    let orderNo = formData.orderNo;

    const formDataCard = this.formCard.value;
    let serialCard = formDataCard.serialCard;
    let pinCard = formDataCard.pinCard;

    if (serialCard == undefined) {
      serialCard = "";
    }
    if (pinCard == undefined) {
      pinCard = "";
    }
    if (orderNo == undefined) {
      orderNo = "";
    }
    if (providerId == undefined) {
      providerId = "";
    }

    let myDate = new Date();

    if (ServiceId == "" || orderNo == "" || providerId == "" || class_no == "" || this.toDateCom == "") {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InsertData"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    } else if (this.toDateCom == "Invalid Date" || !this.isEditing && this.toDateTimeStamp < this.fromDateTimeStamp ) {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidDate"),
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    } else if (serialCard == "" || this.formCard.invalid) {
      this.focusSerial = true;
      this.serialInput.nativeElement.focus();

    } else if (pinCard == "" || this.formCard.invalid) {
      this.focusPin = true;
      this.PinInput.nativeElement.focus();
    } else {
      let provideritem = []
      //When editing no providers dropdown is shown 
      if(!this.isEditing){
        provideritem= this.providersDropDown.getProviderFromList(providerId);
      }
      //when we edit row we might not have the provider from edit loaded in combox so we get it from edit
      let providerName = this.isEditing ? this.selectedCardProviderName : provideritem[0].ProviderName;
      let body = {
        service_id: ServiceId,
        class_no: class_no,
        serialCard: serialCard,
        pinCard: pinCard,
        dateFrom: this.fromDateCom,
        dateTo: this.toDateCom,
        providerId: providerId,
        providerName: providerName,
        orderNo: orderNo
      }
      // console.log("body to add",body)
      this.AddCard(body);

    }
  }

  serialCardVal: string;
  pinCardVal: string;
  AddCard(body) {
    return this.halaManageCardsService.AddNewCard(body).subscribe((result) => {
      this.result = result;
      if(result.IsSuccess){
        this.toastr.success(this.msgTranslateService.msgTranslate('ToastMessages.SuccAdd'), "", {
          positionClass: 'toast-bottom-left',
        });
        this.serialCardVal = "";
        this.pinCardVal = "";
        //Get headers again to update card numbers (or if adding new then add new row to header table)
        this.getAllHeaders({});
        //Get cards to update datasource
        this.getCards();
        this.ref.detectChanges();
        this.focusSerial = true;
        this.serialInput.nativeElement.focus();
      }else{

        this.toastr.error(result.Data, "", {
          positionClass: 'toast-bottom-left',
        });
      }
    });
  }


   /*----------------------Update table after approve/return---------------------- */
   updateDataSource(CardHeaderId,status){
    //If send then remove from table
    if(status =="send"){
      this.dataSource = this.dataSource.filter(c=>c.CardHeaderId != CardHeaderId)
      // console.log("dataSource after change", this.dataSource)
      this.grid.refresh()
    }
    //If Delete all header
    else if(status=="deleteHeader"){
      this.dataSource = this.dataSource.filter(c=>c.CardHeaderId != CardHeaderId)
      this.grid.refresh()
    }
    //If Delete card, update the number in header table
    else if(status=="delete"){
      let index = this.dataSource.findIndex(c=>c.CardHeaderId == this.selectedCardHeaderId)
      this.dataSource[index].cardsNo = (parseInt(this.dataSource[index].cardsNo)- 1).toString()
      //if removed all card then remove the header and cancel editing
      if(this.dataSource[index].cardsNo == 0){
        this.dataSource = this.dataSource.filter(c=>c.CardHeaderId != this.selectedCardHeaderId)
        this.isEditing = false;
      }
      this.grid.refresh()
    }
  }

  /*----------------------Reset Entered Data---------------------- */
  reset() {
    this.dateToVal = "";
    this.servicesDropDown.reset()
    // this.dateFromVal = "";
    this.providersDropDown.reset();
    this.dateToSelector.reset()
    this.classNoVal = ""
    this.orderNoVal = "";
    this.initForm();
    // this.GetServicesData();
    this.ClassNameList = [];
    this.getAllHeaders({});
    this.detailSource =[];

  }

  /*----------------------Search Sumbit---------------------- */
  submitSearch() {
    this.showSwalAlert();
    const formData = this.form.value;
    let ServiceId = formData.ServiceId;
    let class_no = formData.class_no;
    let providerId = formData.providerId
    
    
    if (ServiceId == "" || class_no == "" || this.toDateCom == "") {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InsertData"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    } else if (this.fromDateCom == "Invalid Date" || this.toDateCom == "Invalid Date") {
      Swal.fire({
        title: this.msgTranslateService.msgTranslate("SwalMessages.InvalidDate"),
        icon: 'info',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton:false
      });
    } else {
      let body = {
        service_id: this.f.ServiceId.value,
        class_no: this.f.class_no.value ,
        // dateFrom: this.fromDateCom,
        dateTo: this.toDateCom,
        providerId: this.f.providerId.value,
        orderNo:this.f.orderNo.value
      };
      this.getAllHeaders(body);
      this.getCards()
      this.CloseSwalAlert()
    }
  }

  providerList: any[];
  
  /*----------------------Alert Functions---------------------- */
  Toast = Swal.mixin({
    toast: true,
    position: 'top-start',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  showSwalAlert() {
    Swal.fire({
      title: ' <div class="row"><div class="col-xl-12"><div class="card card-custom gutter-b example example-compact px-8 py-2"><h3 style="text-align: center;">...Please wait</h3><progress style="width: 100%;"></progress></div></div></div>',
      allowOutsideClick: false,
      showCancelButton: false, // There won't be any cancel button
      showConfirmButton: false // There won't be any confirm button

    });
  }

  CloseSwalAlert() {
    Swal.close();
  }

  
  


  getClassNames(){
    let body={
      isPaginated: false,
      service_id: this.f.ServiceId.value
    }
    const subscr = this.thGeneralSystemService.GetClassNameData(body).subscribe(
      (result) => {
        if(result.IsSuccess && result.Data){
          this.ClassNameList = result.Data;
        }
      }
    );
    this.subscriptions.push(subscr)
  }
 
  /*----------------------Select Service from dropdown---------------------- */
  setSelectedService(event){
    if(event!=""){
      this.form.controls.ServiceId.setValue(event)
      //Empty selected class (card) from before only if not editing
      !this.isEditing && this.form.controls.class_no.setValue("")
      //get new cards
      this.getClassNames()
    }else{
      this.form.controls.ServiceId.setValue("")
      //Empty selected class (card) from before
      this.form.controls.class_no.setValue("")
      this.ClassNameList = [];
    }
  }

  /*----------------------when Choosing Provider---------------------- */
  setSelectedProvider(event){ 
    this.form.controls.providerId.setValue(event)
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

}
